import { Dispatch } from "react"
import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import reducers from "../bll/bllReducers"

const composeEnhancers =
	typeof window === "object" && typeof (window as any).__REDUX_DEVTOOLS_EXTENSION__ !== "undefined"
		? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
		: (f: any) => f

const store = createStore(reducers, compose(applyMiddleware(thunk), composeEnhancers))

export default store

export const dispatchStore = store.dispatch as typeof store.dispatch | Dispatch<any>
