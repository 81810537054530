import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedPersona } from "../../../shared/sharedPersona"
import { apiCallInterface } from "../../../apiCallInterface"
import { sharedPersonasVehiculos } from "../../../shared/sharedPersonasVehiculos"

export namespace apiCallIG_Personas_ActualizarVehiculoDePersona {
	export interface params {
		idPersonaVehiculo: number
		idTipoVehiculo: number
		avatarUrl: string | null
		descripcion: string
		marca: string
		modelo: string
		anio: number
		fotoUrl: string | null
	}
	export const paramsName = {
		idPersonaVehiculo: "idPersonaVehiculo",
		idTipoVehiculo: "idTipoVehiculo",
		avatarUrl: "avatarUrl",
		descripcion: "descripcion",
		marca: "marca",
		modelo: "modelo",
		anio: "anio",
		fotoUrl: "fotoUrl",
	}
	export const paramsDesc = {
		idPersonaVehiculo: "Id PersonaVehiculo",
		idTipoVehiculo: "Tipo de vehículo",
		avatarUrl: "URL del avatar",
		descripcion: "Descripción",
		marca: "Marca",
		modelo: "Modelo",
		anio: "Año",
		fotoUrl: "URL de la foto",
	}

	export interface IRes {
		sharedPersona: sharedPersona.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Personas/ActualizarVehiculoDePersona`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedPersona: sharedPersona.getSharedOne_FromApiObject(apiCallRes) }
	}
}
