/* eslint-disable react/jsx-pascal-case*/
import { Alert, Card, Container } from "@mui/material"
import { useEffect } from "react"
import { bllSaludonnet_CentrosUrgencias } from "../../../bll/Saludonnet/bllSaludonnet_CentrosUrgencias"
import useGeneral from "../../../hooksData/useGeneral"
import useSntCentrosUrgencias from "../../../hooksData/useSntCentrosUrgencias"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import { globalAssets } from "../../../globals/globalAssets"

export default function SaludonnetProtocoloHubener() {
	const ug = useGeneral("SaludonnetProtocoloHubener")

	return (
		<Container sx={{ position: "relative" }}>
			<Card sx={{ p: 4 }}>En desarrollo...</Card>
		</Container>
	)

	// const pdf = globalAssets.staticPaths.pilotapPdf

	// return (
	// 	<Container sx={{ position: "relative" }}>
	// 		<Card>
	// 			<iframe src={pdf} width="100%" height="600px" />
	// 		</Card>
	// 	</Container>
	// )
}
