import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Personas_GetMainPersonaByUserId } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetMainPersonaByUserId"
import { apiCallIG_RodadasPersonas_InvitarEmail } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_InvitarEmail"
import { apiCallIS_User_GetUserIdByEmail } from "../../api/calls/IS/apiCallIS_User_GetUserIdByEmail"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { bllDispatchs_GlobalsServer } from "../bllDispatchs_GlobalsServer"
import { bllRodadas_Settings } from "./bllRodadas_Settings"

export namespace bllRodadas_Settings_InviteByEmail {
	//ET = enumTypes
	enum ET {
		setInitialState = "Rodadas_Settings_InviteByEmail_setInitialState",
		getUserIdByEmail = "Rodadas_Settings_InviteByEmail_getUserIdByEmail",
		getPersonaByUserId = "Rodadas_Settings_InviteByEmail_getPersonaByUserId",
		invitarEmail = "Rodadas_Settings_InviteByEmail_invitarEmail",
	}

	export interface IInterface {
		userIdByEmail: string | null
		personaByUserId: sharedPersona.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			userIdByEmail: null,
			personaByUserId: sharedPersona.objectDefault,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Rodada_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getUserIdByEmail(apiCallParams: apiCallInterface, params: apiCallIS_User_GetUserIdByEmail.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIS_User_GetUserIdByEmail.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "userIdByEmail">

				const updateObject: actionSubType = {
					userIdByEmail: apiCallRes.userId,
				}

				utilRedux.TA(D, ET.getUserIdByEmail, updateObject)

				D(getPersonaByUserId(apiCallParams, { userId: apiCallRes.userId }))
			}
		}
	}

	export function getPersonaByUserId(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_GetMainPersonaByUserId.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_GetMainPersonaByUserId.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "personaByUserId">

				const updateObject: actionSubType = {
					personaByUserId: apiCallRes.sharedPersona,
				}

				utilRedux.TA(D, ET.getPersonaByUserId, updateObject)
			}
		}
	}

	export function invitarEmail(apiCallParams: apiCallInterface, params: apiCallIG_RodadasPersonas_InvitarEmail.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasPersonas_InvitarEmail.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await bllDispatchs_GlobalsServer.setMessage(apiCallParams.key, "Invitación realizada correctamente.")

				D(bllRodadas_Settings.updateRodadasPersonas(apiCallParams, apiCallRes.sharedRodadasPersonas))

				utilRedux.TA(D, ET.invitarEmail, {})
			}
		}
	}
}
