import { sharedLoginRes } from "../api/shared/sharedLoginRes"
import { utilStorage } from "./utilStorage"

export namespace utilUser {
	/* ---------------------------------- Logout ---------------------------------- */
	export const logout = () => {
		utilStorage.clearStorage()
	}

	/* ---------------------------------- Token ---------------------------------- */
	// export const getUserToken = (): string | null => {
	// 	const userToken = getUserTokenFromStorage() || ""
	// 	if (!userToken) return null
	// 	return userToken
	// }

	// export const IsLogged = (): boolean => {
	// 	const token = getUserToken()
	// 	if (token) return true
	// 	return false
	// }

	/* ---------------------------------- LoginRes ---------------------------------- */
	export const getStorageLoginRes = (): sharedLoginRes.IShared | null => {
		const loginResJson = utilStorage.getLoginResFromStorage() || ""
		if (!loginResJson) return null
		const loginRes: sharedLoginRes.IShared = JSON.parse(loginResJson)
		return loginRes
	}

	export const setStorageLoginRes = (loginRes: sharedLoginRes.IShared) => {
		const loginResJson = JSON.stringify(loginRes)
		utilStorage.setLoginResInStorage(loginResJson)
	}

	//TODO: Armar función en backend (y en frontend la llamada desde algún panel administrador) que desloguee uno o todos los usuarios. Esto serviría por si se cambia el objeto LoginRes por ejemplo.
}
