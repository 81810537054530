import { utilRedux } from "../../utils/utilRedux"
import produce from "immer"

export namespace bllGlobalsServer_UploadLoadingList {
	//ET = enumTypes
	enum ET {
		setUploadLoading = "GlobalsServer_UploadLoadingList_setUploadLoading",
		stopAllUploadLoading = "GlobalsServer_UploadLoadingList_stopAllUploadLoading",
	}

	interface IUploadLoading {
		isLoading: boolean
	}

	export interface IInterface {
		[key: string]: IUploadLoading
	}

	const getInitialState = (): IInterface => {
		return {}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			case ET.setUploadLoading:
				return produce(state, (draft: IInterface) => {
					draft[action.key] = action.UploadLoading
				})
				break
			case ET.stopAllUploadLoading:
				return produce(state, (draft: IInterface) => {
					draft = getInitialState()
				})
			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setUploadLoading(key: string, isLoading: boolean) {
		//D = dispatch
		return async (D: any) => {
			const UploadLoading: IUploadLoading = {
				isLoading: isLoading,
			}

			utilRedux.TA(D, ET.setUploadLoading, {
				key: key,
				UploadLoading: UploadLoading,
			})
		}
	}

	export function stopAllUploadLoading() {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.stopAllUploadLoading, {})
		}
	}
}
