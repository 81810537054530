import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Notificaciones_AddOrUpdateUserMedioDeContacto_DeviceSuscriptionId } from "../../api/calls/IG/Notificaciones/apiCallIG_Notificaciones_AddOrUpdateUserMedioDeContacto_DeviceSuscriptionId"
import { apiCallIG_Notificaciones_GetLast20Notificaciones_ForUserLogged } from "../../api/calls/IG/Notificaciones/apiCallIG_Notificaciones_GetLast20Notificaciones_ForUserLogged"
import { apiCallIG_Notificaciones_GetSuscripciones_ForUserLogged } from "../../api/calls/IG/Notificaciones/apiCallIG_Notificaciones_GetSuscripciones_ForUserLogged"
import { apiCallIG_Notificaciones_SetAllAsViewedForUserLogged } from "../../api/calls/IG/Notificaciones/apiCallIG_Notificaciones_SetAllAsViewedForUserLogged"
import { apiCallIG_Notificaciones_SetSuscriptionEnabled } from "../../api/calls/IG/Notificaciones/apiCallIG_Notificaciones_SetSuscriptionEnabled"
import { sharedNotifSuscripciones } from "../../api/shared/sharedNotifSuscripciones"
import { sharedNotificacion } from "../../api/shared/sharedNotificacion"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllNotificaciones_Suscripciones {
	//ET = enumTypes
	enum ET {
		setInitialState = "Notificaciones_Suscripciones_setInitialState",
		load = "Notificaciones_Suscripciones_loadLast20",
		setSuscripcionEnabled = "Notificaciones_Suscripciones_setSuscripcionEnabled",
	}

	export interface IInterface {
		suscripciones: sharedNotifSuscripciones.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			suscripciones: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function load(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Notificaciones_GetSuscripciones_ForUserLogged.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Notificaciones_GetSuscripciones_ForUserLogged.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "suscripciones">
				const updateObject: actionSubType = {
					suscripciones: apiCallRes.sharedNotifSuscripcionesList,
				}

				utilRedux.TA(D, ET.load, updateObject)
			}
		}
	}

	export function setSuscripcionEnabled(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Notificaciones_SetSuscriptionEnabled.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Notificaciones_SetSuscriptionEnabled.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "suscripciones">
				const updateObject: actionSubType = {
					suscripciones: apiCallRes.sharedNotifSuscripcionesList,
				}

				utilRedux.TA(D, ET.setSuscripcionEnabled, updateObject)
			}
		}
	}

	export function AddOrupdateUserMedioDeContacto_DeviceSuscriptionId(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Notificaciones_AddOrUpdateUserMedioDeContacto_DeviceSuscriptionId.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Notificaciones_AddOrUpdateUserMedioDeContacto_DeviceSuscriptionId.apiCall(
				apiCallParams,
				params
			)

			if (apiCallRes) {
				// type actionSubType = Pick<IInterface, "suscripciones">
				// const updateObject: actionSubType = {
				// 	suscripciones: apiCallRes.sharedNotifSuscripcionesList,
				// }
				// utilRedux.TA(D, ET.setSuscripcionEnabled, updateObject)
			}
		}
	}
}
