import { globalInterfaces } from "../../../globals/globalInterfaces"
import { utilLogger } from "../../../utils/utilLogger"

export namespace sharedSN_CentroUrgencia {
	export interface IShared {
		id: string
		nombre: string
		provincia: string
		ciudad: string
		codigoPostal: string
		direccion: string
		telefono: string
		latitud: number
		longitud: number
	}

	export const objectDefault: IShared = {
		id: "",
		nombre: "",
		provincia: "",
		ciudad: "",
		codigoPostal: "",
		direccion: "",
		telefono: "",
		latitud: 0,
		longitud: 0,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}
}
