/* eslint-disable react/jsx-pascal-case*/
import { LinearProgress } from "@mui/material"
import { styled } from "@mui/material/styles"
import useDisplayAfter500 from "../../../hooks/useDisplayAfter500"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
	right: 0,
	bottom: 0,
	zIndex: 99900,
	width: "100%",
	height: "100%",
	position: "absolute",
	display: "absolute",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: theme.palette.background.default,
}))

// ----------------------------------------------------------------------

export default function SharedLoadingComponent() {
	const { displayAfter500 } = useDisplayAfter500()

	return (
		<>
			{displayAfter500 && (
				<RootStyle>
					<LinearProgress />
				</RootStyle>
			)}
		</>
	)
}
