import { sharedAgrupacionVehiculo } from "./sharedAgrupacionVehiculo"
import { sharedCircuito } from "./sharedCircuito"
import { sharedEmpresa } from "./sharedEmpresa"
import { sharedEscuela } from "./sharedEscuela"
import { sharedFederacion } from "./sharedFederacion"
import { sharedMotoclub } from "./sharedMotoclub"
import { sharedOrganizador } from "./sharedOrganizador"
import { sharedRodada } from "./sharedRodada"
import { sharedVehiculo } from "./sharedVehiculo"

export namespace sharedInitialCallRes {
	export interface IShared {
		allCircuitos: sharedCircuito.IShared[]
		allEmpresas: sharedEmpresa.IShared[]
		allEscuelas: sharedEscuela.IShared[]
		allFederaciones: sharedFederacion.IShared[]
		allMotoclubs: sharedMotoclub.IShared[]
		allOrganizadores: sharedOrganizador.IShared[]
		allRodadas: sharedRodada.IShared[]
		allVehiculos: sharedVehiculo.IShared[]
		allAgrupacionesVehiculos: sharedAgrupacionVehiculo.IShared[]
	}

	export const objectDefault: IShared = {
		allCircuitos: [],
		allEmpresas: [],
		allEscuelas: [],
		allFederaciones: [],
		allMotoclubs: [],
		allOrganizadores: [],
		allRodadas: [],
		allVehiculos: [],
		allAgrupacionesVehiculos: [],
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...objectDefault,
			...apiObject,
			allCircuitos: apiObject.allCircuitos && sharedCircuito.getSharedList_FromApiObject(apiObject.allCircuitos),
			allEmpresas: apiObject.allEmpresas && sharedEmpresa.getSharedList_FromApiObject(apiObject.allEmpresas),
			allEscuelas: apiObject.allEscuelas && sharedEscuela.getSharedList_FromApiObject(apiObject.allEscuelas),
			allFederaciones:
				apiObject.allFederaciones && sharedFederacion.getSharedList_FromApiObject(apiObject.allFederaciones),
			allMotoclubs: apiObject.allMotoclubs && sharedMotoclub.getSharedList_FromApiObject(apiObject.allMotoclubs),
			allOrganizadores:
				apiObject.allOrganizadores && sharedOrganizador.getSharedList_FromApiObject(apiObject.allOrganizadores),
			allRodadas: apiObject.allRodadas && sharedRodada.getSharedList_FromApiObject(apiObject.allRodadas),
			allVehiculos: apiObject.allVehiculos && sharedVehiculo.getSharedList_FromApiObject(apiObject.allVehiculos),
			allAgrupacionesVehiculos:
				apiObject.allAgrupacionesVehiculos &&
				sharedAgrupacionVehiculo.getSharedList_FromApiObject(apiObject.allAgrupacionesVehiculos),
		}

		return res
	}
}
