import { combineReducers } from "redux"
import { bllOrganizadores_Profile } from "./bllOrganizadores_Profile"
import { bllOrganizadores_Create } from "./bllOrganizadores_Create"
import { bllOrganizadores_Settings } from "./bllOrganizadores_Settings"

export default combineReducers({
	Profile: bllOrganizadores_Profile.reducerFunction,
	Settings: bllOrganizadores_Settings.reducerFunction,
	CreateOrganizador: bllOrganizadores_Create.reducerFunction,
})

export interface IOrganizadores {
	Profile: bllOrganizadores_Profile.IInterface
	Settings: bllOrganizadores_Settings.IInterface
	CreateOrganizador: bllOrganizadores_Create.IInterface
}
