import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedCircuito } from "../../api/shared/sharedCircuito"
import { apiCallIG_Circuitos_CreateCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_CreateCircuito"
import { bllGlobalsGeneralData_AllCircuitos } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllCircuitos"
import { bllGlobalsUser_CurrentPersonaCircuitos } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaCircuitos"
import { bllDispatchs_GlobalsServer } from "../bllDispatchs_GlobalsServer"
import { apiCallInterface } from "../../api/apiCallInterface"
import { sharedCircuitoFecha } from "../../api/shared/sharedCircuitoFecha"
import { apiCallIG_CircuitosFechas_GetFechasByIdCircuito } from "../../api/calls/IG/CircuitosFechas/apiCallIG_CircuitosFechas_GetFechasByIdCircuito"
import { apiCallIG_CircuitosFechas_DeleteCircuitoFecha } from "../../api/calls/IG/CircuitosFechas/apiCallIG_CircuitosFechas_DeleteCircuitoFecha"
import { apiCallIG_CircuitosFechas_CrearOActualizarCircuitoFecha } from "../../api/calls/IG/CircuitosFechas/apiCallIG_CircuitosFechas_CrearOActualizarCircuitoFecha"

export namespace bllCircuitos_Fechas {
	//ET = enumTypes
	enum ET {
		setInitialState = "Circuito_Fechas_setInitialState",
		set = "Circuito_Fechas_set",
	}

	export interface IInterface {
		circuitosFechas: sharedCircuitoFecha.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			circuitosFechas: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function setCircuitosFechas(D: any, circuitosFechas: sharedCircuitoFecha.IShared[]) {
		type actionSubType = Pick<IInterface, "circuitosFechas">
		const updateObject: actionSubType = {
			circuitosFechas: circuitosFechas,
		}

		utilRedux.TA(D, ET.set, updateObject)
	}

	export function loadCircuitoFechas(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosFechas_GetFechasByIdCircuito.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_CircuitosFechas_GetFechasByIdCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				setCircuitosFechas(D, apiCallRes.sharedCircuitosFechasList)
			}
		}
	}

	//DeleteCircuitoFecha
	export function deleteCircuitoFecha(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosFechas_DeleteCircuitoFecha.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_CircuitosFechas_DeleteCircuitoFecha.apiCall(apiCallParams, params)

			if (apiCallRes) {
				setCircuitosFechas(D, apiCallRes.sharedCircuitosFechasList)
			}
		}
	}

	export function createOrUpdateCircuitoFecha(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosFechas_CrearOActualizarCircuitoFecha.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_CircuitosFechas_CrearOActualizarCircuitoFecha.apiCall(apiCallParams, params)

			if (apiCallRes) {
				setCircuitosFechas(D, apiCallRes.sharedCircuitosFechasList)
			}
		}
	}
}
