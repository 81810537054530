/* eslint-disable react/jsx-pascal-case*/
import { Box, Button, Container, Dialog, DialogTitle, Typography, useTheme } from "@mui/material"
import { m } from "framer-motion"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import { bllGlobalsUI_DialogWorkInProgress } from "../../bll/GlobalsUI/bllGlobalsUI_DialogWorkInProgress"
import { varBounce } from "../../globals/globalAnimate/variants/bounce"
import useWindowSize from "../../hooks/useWindowSize"
import AnimateMotionContainer from "../Animate/AnimateMotionContainer"
import AssetComingSoonIllustration from "../Assets/AssetComingSoonIllustration"
import { SharedCountdown } from "../Shared/SharedCountdown"
import SharedBasicScrollbar from "../Shared/Basic/SharedBasicScrollbar"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"

export const GlobalDialogWorkInProgress = () => {
	const theme = useTheme()
	const D = useDispatch()

	const isOpenState = useSelector((state: IState) => state.GlobalsUI.DialogWorkInProgress.open)

	const handleClose = () => {
		D(bllGlobalsUI_DialogWorkInProgress.close())
	}

	return (
		<>
			<Dialog fullWidth maxWidth="xs" open={isOpenState} onClose={handleClose}>
				<SharedBasicScrollbar lessToWindowSizeHeight={120}>
					<DialogTitle>Funcionalidad en desarrollo</DialogTitle>

					<Container component={AnimateMotionContainer} sx={{ mx: "auto", pb: 2 }}>
						<Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
							<SharedBasicTypo color={theme.palette.text.secondary} sx={{ textAlign: "left" }}>
								Estamos trabajando arduamente en esta funcionalidad
							</SharedBasicTypo>

							<m.div variants={varBounce().in}>
								<AssetComingSoonIllustration sx={{ height: 260, my: 3 }} />
							</m.div>

							<SharedCountdown comingInDays={100} sx={{ my: 4 }} />

							<Button size="large" variant="contained" onClick={handleClose}>
								Cerrar
							</Button>
						</Box>
					</Container>
				</SharedBasicScrollbar>
			</Dialog>
		</>
	)
}
