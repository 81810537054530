import _ from "lodash"

export namespace utilFunctions {
	export const findStringItemInStringEnum = (objectToFind: string, enumObject: any) => {
		if (enumObject) {
			for (let item in enumObject) {
				if (enumObject[item].toLowerCase() === objectToFind.toLowerCase()) {
					return enumObject[item]
				}
			}
		}
	}

	export const sleep = (ms: number) => {
		return new Promise((resolve) => {
			setTimeout(resolve, ms)
		})
	}

	export const scrollToTop = () => {
		setTimeout(() => {
			window.scrollTo(0, 0)
		}, 300)
	}

	export const clearObjectRemovingNullValues = (obj: any) => {
		// for (var propName in obj) {
		// 	if (obj[propName] === null || obj[propName] === undefined) {
		// 		delete obj[propName]
		// 	}
		// }
		// return obj
		// SEGUIR ACÁ. ESTA FUNCIÓN TIENE QUE SER RECURSIVA
		return _.omitBy(obj, _.isNil)
	}

	export function clearObjectRemovingNullValuesDeep(obj: any) {
		return (function prune(current) {
			_.forOwn(current, function (value, key) {
				if (
					_.isUndefined(value) ||
					_.isNull(value) ||
					_.isNaN(value) ||
					(_.isString(value) && _.isEmpty(value)) ||
					(_.isObject(value) && _.isEmpty(prune(value)))
				) {
					delete current[key]
				}
			})
			// remove any leftover undefined values from the delete
			// operation on an array
			if (_.isArray(current)) _.pull(current, undefined)

			return current
		})(_.cloneDeep(obj)) // Do not modify the original object, create a clone instead
	}

	export const isJwtTokenExpired = (token: string) => {
		if (!token) return true
		const decoded = JSON.parse(atob(token.split(".")[1]))
		return decoded.exp < Date.now() / 1000
	}
}
