import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedPersona } from "../../../shared/sharedPersona"
import { sharedPersonasUsuarios } from "../../../shared/sharedPersonasUsuarios"
import { apiCallInterface } from "../../../apiCallInterface"

export namespace apiCallIG_Personas_GetPersonasDeUsuarioLogueado {
	export const paramsDesc = {}

	export interface IRes {
		sharedPersona: sharedPersona.IShared
		sharedPersonasDelegadas: sharedPersona.IShared[]
		sharedPersonasUsuarios: sharedPersonasUsuarios.IShared[]
	}

	// const validations = async (apiCallParams: apiCallInterface, params: bodyParams) => {
	// 	const serverModelErrors: bllGlobals_ServerModelErrorsList.IServerModelErrors = {}

	// 	return await dispatchServerModelErrors(key, serverModelErrors)
	// }

	export const apiCall = async (apiCallParams: apiCallInterface): Promise<IRes | null> => {
		// if (await validations(apiCallParams, params)) {
		// 	return null
		// }

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.get
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Personas/GetPersonasDeUsuarioLogueado`
		const sharedParamsQuery = null
		const sharedParamsBody = null

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedPersona: sharedPersona.getSharedOne_FromApiObject(apiCallRes.persona),
			sharedPersonasDelegadas: sharedPersona.getSharedList_FromApiObject(apiCallRes.personasDelegadas),
			sharedPersonasUsuarios: sharedPersonasUsuarios.getSharedList_FromApiObject(apiCallRes.personasUsuarios),
		}
	}
}
