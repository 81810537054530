import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Personas_ActualizarAvatar } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarAvatar"
import { apiCallIG_Personas_ActualizarContact } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarContact"
import { apiCallIG_Personas_ActualizarCover } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarCover"
import { apiCallIG_Personas_ActualizarDireccionGoogle } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarDireccionGoogle"
import { apiCallIG_Personas_ActualizarDireccionNotGoogle } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarDireccionNotGoogle"
import { apiCallIG_Personas_ActualizarGeneralFields } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarGeneralFields"
import { apiCallIG_Personas_ActualizarModalidadDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarModalidadDePersona"
import { apiCallIG_Personas_ActualizarPosteoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarPosteoDePersona"
import { apiCallIG_Personas_ActualizarProfileFields } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarProfileFields"
import { apiCallIG_Personas_ActualizarSocialInfo } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarSocialInfo"
import { apiCallIG_Personas_ActualizarVehiculoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarVehiculoDePersona"
import { apiCallIG_Personas_AgregarModalidadParaPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_AgregarModalidadParaPersona"
import { apiCallIG_Personas_AgregarPosteoParaPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_AgregarPosteoParaPersona"
import { apiCallIG_Personas_AgregarVehiculoParaPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_AgregarVehiculoParaPersona"
import { apiCallIG_Personas_EliminarModalidadDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_EliminarModalidadDePersona"
import { apiCallIG_Personas_EliminarPosteoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_EliminarPosteoDePersona"
import { apiCallIG_Personas_EliminarVehiculoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_EliminarVehiculoDePersona"
import { apiCallIG_Personas_EnviarNotificacionASeguidores } from "../../api/calls/IG/Personas/apiCallIG_Personas_EnviarNotificacionASeguidores"
import { apiCallIG_Personas_GetPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersona"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { bllGlobalsUser_CurrentPersona } from "../GlobalsUser/bllGlobalsUser_CurrentPersona"
import { bllGlobalsUser_Personas } from "../GlobalsUser/bllGlobalsUser_Personas"

export namespace bllPersonas_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Personas_Settings_setInitialState",
		loadPersona = "Personas_Settings_loadPersona",
		actualizarSocialInfo = "Personas_Settings_actualizarSocialInfo",
		actualizarProfileFields = "Personas_Settings_actualizarProfileFields",
		actualizarCover = "Personas_Settings_actualizarCover",
		actualizarAvatar = "Personas_Settings_actualizarAvatar",
		actualizarGeneralFields = "Personas_Settings_actualizarGeneralFields",
		actualizarDireccionNotGoogle = "Personas_Settings_actualizarDireccionNotGoogle",
		actualizarDireccionGoogle = "Personas_Settings_actualizarDireccionGoogle",
		actualizarContactFields = "Personas_Settings_actualizarContactFields",
		actualizarVehiculoDePersona = "Personas_Settings_actualizarVehiculoDePersona",
		agregarVehiculoParaPersona = "Personas_Settings_agregarVehiculoParaPersona",
		eliminarVehiculoDePersona = "Personas_Settings_eliminarVehiculoDePersona",

		actualizarModalidadDePersona = "Personas_Settings_actualizarModalidadDePersona",
		agregarModalidadParaPersona = "Personas_Settings_agregarModalidadParaPersona",
		eliminarModalidadDePersona = "Personas_Settings_eliminarModalidadDePersona",

		// actualizarPosteoDePersona = "Personas_Settings_actualizarPosteoDePersona",
		// agregarPosteoParaPersona = "Personas_Settings_agregarPosteoParaPersona",
		// eliminarPosteoDePersona = "Personas_Settings_eliminarPosteoDePersona",
		enviarNotificacionASeguidores = "Rodadas_Settings_enviarNotificacionASeguidores",
	}

	export interface IInterface {
		persona: sharedPersona.IShared
		notificacionEnviada: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			persona: sharedPersona.objectDefault,
			notificacionEnviada: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadPersona(apiCallParams: apiCallInterface, params: apiCallIG_Personas_GetPersona.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Personas_GetPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "persona">
				const updateObject: actionSubType = {
					persona: apiCallRes.sharedPersona,
				}

				utilRedux.TA(D, ET.loadPersona, updateObject)
			}
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_EnviarNotificacionASeguidores.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_Personas_EnviarNotificacionASeguidores.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}

	export function updatePersonaWithSharedPersona(sharedPersona: sharedPersona.IShared) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "persona">
			const updateObject: actionSubType = {
				persona: sharedPersona,
			}

			utilRedux.TA(D, ET.loadPersona, updateObject)

			//Se supone que cuando actualizo las settings de una persona, currentPersona es esa misma persona que estoy actualizando, así que actualizo currentPersona también
			D(bllGlobalsUser_CurrentPersona.updateOnlyPersonaInfoWithSharedPersona(sharedPersona))
			D(bllGlobalsUser_Personas.updateInfoDePersona(sharedPersona))
		}
	}

	export function actualizarSocialInfo(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarSocialInfo.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarSocialInfo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarSocialInfo, {})
			}
		}
	}

	export function actualizarProfileFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarProfileFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarProfileFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarProfileFields, {})
			}
		}
	}

	export function actualizarCover(apiCallParams: apiCallInterface, params: apiCallIG_Personas_ActualizarCover.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarCover.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarCover, {})
			}
		}
	}

	export function actualizarGeneralFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarGeneralFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarGeneralFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarGeneralFields, {})
			}
		}
	}

	export function actualizarAvatar(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarAvatar.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarAvatar.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarAvatar, {})
			}
		}
	}

	export function actualizarDireccionNotGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarDireccionNotGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarDireccionNotGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarDireccionNotGoogle, {})
			}
		}
	}

	export function actualizarDireccionGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarDireccionGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarDireccionGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarDireccionGoogle, {})
			}
		}
	}

	export function actualizarContactFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarContact.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarContact.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarContactFields, {})
			}
		}
	}

	export function actualizarVehiculoDePersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarVehiculoDePersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarVehiculoDePersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarVehiculoDePersona, {})
			}
		}
	}

	export function eliminarVehiculoDePersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_EliminarVehiculoDePersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_EliminarVehiculoDePersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.eliminarVehiculoDePersona, {})
			}
		}
	}

	export function agregarVehiculoParaPersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_AgregarVehiculoParaPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_AgregarVehiculoParaPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.agregarVehiculoParaPersona, {})
			}
		}
	}

	export function actualizarModalidadDePersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_ActualizarModalidadDePersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_ActualizarModalidadDePersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.actualizarModalidadDePersona, {})
			}
		}
	}

	export function eliminarModalidadDePersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_EliminarModalidadDePersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_EliminarModalidadDePersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.eliminarModalidadDePersona, {})
			}
		}
	}

	export function agregarModalidadParaPersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_AgregarModalidadParaPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_AgregarModalidadParaPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

				utilRedux.TA(D, ET.agregarModalidadParaPersona, {})
			}
		}
	}

	// export function actualizarPosteoDePersona(
	// 	apiCallParams: apiCallInterface,
	// 	params: apiCallIG_Personas_ActualizarPosteoDePersona.params
	// ) {
	// 	//D = dispatch
	// 	return async (D: any) => {
	// 		const apiCallRes = await apiCallIG_Personas_ActualizarPosteoDePersona.apiCall(apiCallParams, params)

	// 		if (apiCallRes) {
	// 			await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

	// 			utilRedux.TA(D, ET.actualizarPosteoDePersona, {})
	// 		}
	// 	}
	// }

	// export function eliminarPosteoDePersona(
	// 	apiCallParams: apiCallInterface,
	// 	params: apiCallIG_Personas_EliminarPosteoDePersona.params
	// ) {
	// 	//D = dispatch
	// 	return async (D: any) => {
	// 		const apiCallRes = await apiCallIG_Personas_EliminarPosteoDePersona.apiCall(apiCallParams, params)

	// 		if (apiCallRes) {
	// 			await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

	// 			utilRedux.TA(D, ET.eliminarPosteoDePersona, {})
	// 		}
	// 	}
	// }

	// export function agregarPosteoParaPersona(
	// 	apiCallParams: apiCallInterface,
	// 	params: apiCallIG_Personas_AgregarPosteoParaPersona.params
	// ) {
	// 	//D = dispatch
	// 	return async (D: any) => {
	// 		const apiCallRes = await apiCallIG_Personas_AgregarPosteoParaPersona.apiCall(apiCallParams, params)

	// 		if (apiCallRes) {
	// 			await D(updatePersonaWithSharedPersona(apiCallRes.sharedPersona))

	// 			utilRedux.TA(D, ET.agregarPosteoParaPersona, {})
	// 		}
	// 	}
	// }
}
