import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { sharedCircuito } from "../../../shared/sharedCircuito"
import { sharedCircuitoFecha } from "../../../shared/sharedCircuitoFecha"

export namespace apiCallIG_CircuitosFechas_CrearOActualizarCircuitoFecha {
	export interface params {
		idCircuitoFecha: number | null
		idCircuito: number
		nroOrden: number | null
		tipoAcontecimiento: string
		fechaInicio: Date | null
		fechaFin: Date | null
		lunesHoraInicio1: Date | null
		lunesHoraFin1: Date | null
		lunesHoraInicio2: Date | null
		lunesHoraFin2: Date | null
		martesHoraInicio1: Date | null
		martesHoraFin1: Date | null
		martesHoraInicio2: Date | null
		martesHoraFin2: Date | null
		miercolesHoraInicio1: Date | null
		miercolesHoraFin1: Date | null
		miercolesHoraInicio2: Date | null
		miercolesHoraFin2: Date | null
		juevesHoraInicio1: Date | null
		juevesHoraFin1: Date | null
		juevesHoraInicio2: Date | null
		juevesHoraFin2: Date | null
		viernesHoraInicio1: Date | null
		viernesHoraFin1: Date | null
		viernesHoraInicio2: Date | null
		viernesHoraFin2: Date | null
		sabadoHoraInicio1: Date | null
		sabadoHoraFin1: Date | null
		sabadoHoraInicio2: Date | null
		sabadoHoraFin2: Date | null
		domingoHoraInicio1: Date | null
		domingoHoraFin1: Date | null
		domingoHoraInicio2: Date | null
		domingoHoraFin2: Date | null
		descripcion: string | null
	}

	export const paramsName = {
		idCircuitoFecha: "idCircuitoFecha",
		idCircuito: "idCircuito",
		nroOrden: "nroOrden",
		tipoAcontecimiento: "tipoAcontecimiento",
		fechaInicio: "fechaInicio",
		fechaFin: "fechaFin",
		lunesHoraInicio1: "lunesHoraInicio1",
		lunesHoraFin1: "lunesHoraFin1",
		lunesHoraInicio2: "lunesHoraInicio2",
		lunesHoraFin2: "lunesHoraFin2",
		martesHoraInicio1: "martesHoraInicio1",
		martesHoraFin1: "martesHoraFin1",
		martesHoraInicio2: "martesHoraInicio2",
		martesHoraFin2: "martesHoraFin2",
		miercolesHoraInicio1: "miercolesHoraInicio1",
		miercolesHoraFin1: "miercolesHoraFin1",
		miercolesHoraInicio2: "miercolesHoraInicio2",
		miercolesHoraFin2: "miercolesHoraFin2",
		juevesHoraInicio1: "juevesHoraInicio1",
		juevesHoraFin1: "juevesHoraFin1",
		juevesHoraInicio2: "juevesHoraInicio2",
		juevesHoraFin2: "juevesHoraFin2",
		viernesHoraInicio1: "viernesHoraInicio1",
		viernesHoraFin1: "viernesHoraFin1",
		viernesHoraInicio2: "viernesHoraInicio2",
		viernesHoraFin2: "viernesHoraFin2",
		sabadoHoraInicio1: "sabadoHoraInicio1",
		sabadoHoraFin1: "sabadoHoraFin1",
		sabadoHoraInicio2: "sabadoHoraInicio2",
		sabadoHoraFin2: "sabadoHoraFin2",
		domingoHoraInicio1: "domingoHoraInicio1",
		domingoHoraFin1: "domingoHoraFin1",
		domingoHoraInicio2: "domingoHoraInicio2",
		domingoHoraFin2: "domingoHoraFin2",
		descripcion: "descripcion",
	}
	export const paramsDesc = {
		idCircuitoFecha: "idCircuitoFecha",
		idCircuito: "idCircuito",
		tipoAcontecimiento: "tipoAcontecimiento",
		fechaInicio: "Fecha Inicio",
		fechaFin: "Fecha Fin",
		lunesHoraInicio1: "lunesHoraInicio1",
		lunesHoraFin1: "lunesHoraFin1",
		lunesHoraInicio2: "lunesHoraInicio2",
		lunesHoraFin2: "lunesHoraFin2",
		martesHoraInicio1: "martesHoraInicio1",
		martesHoraFin1: "martesHoraFin1",
		martesHoraInicio2: "martesHoraInicio2",
		martesHoraFin2: "martesHoraFin2",
		miercolesHoraInicio1: "miercolesHoraInicio1",
		miercolesHoraFin1: "miercolesHoraFin1",
		miercolesHoraInicio2: "miercolesHoraInicio2",
		miercolesHoraFin2: "miercolesHoraFin2",
		juevesHoraInicio1: "juevesHoraInicio1",
		juevesHoraFin1: "juevesHoraFin1",
		juevesHoraInicio2: "juevesHoraInicio2",
		juevesHoraFin2: "juevesHoraFin2",
		viernesHoraInicio1: "viernesHoraInicio1",
		viernesHoraFin1: "viernesHoraFin1",
		viernesHoraInicio2: "viernesHoraInicio2",
		viernesHoraFin2: "viernesHoraFin2",
		sabadoHoraInicio1: "sabadoHoraInicio1",
		sabadoHoraFin1: "sabadoHoraFin1",
		sabadoHoraInicio2: "sabadoHoraInicio2",
		sabadoHoraFin2: "sabadoHoraFin2",
		domingoHoraInicio1: "domingoHoraInicio1",
		domingoHoraFin1: "domingoHoraFin1",
		domingoHoraInicio2: "domingoHoraInicio2",
		domingoHoraFin2: "domingoHoraFin2",
		descripcion: "Descripción",
	}

	export interface IRes {
		sharedCircuitosFechasList: sharedCircuitoFecha.IShared[]
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/CircuitosFechas/CrearOActualizarCircuitoFecha`
		const sharedParamsQuery: params | null = null
		const sharedParamsBody = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedCircuitosFechasList: sharedCircuitoFecha.getSharedList_FromApiObject(apiCallRes),
		}
	}
}
