/* eslint-disable react/jsx-pascal-case*/
// icons
import { Icon, IconifyIcon } from "@iconify/react"
// @mui
import { Box, BoxProps, SxProps } from "@mui/material"

// ----------------------------------------------------------------------

interface Props extends BoxProps {
	sx?: SxProps
	iconifyIcon?: IconifyIcon | string
	customIcon?: React.ElementType
}

export default function SharedBasicIconify({ iconifyIcon, customIcon, sx, ...other }: Props) {
	if (iconifyIcon) {
		return <Box component={Icon} icon={iconifyIcon} sx={{ ...sx }} {...other} />
	} else {
		return <Box component={customIcon} sx={{ ...sx }} {...other} />
	}
}
