import { globalInterfaces } from "../../globals/globalInterfaces"
import { utilLogger } from "../../utils/utilLogger"

export namespace sharedTipoSexo {
	export interface IShared {
		id: number
		descripcion: string
	}

	export const sharedEnum = {
		hombre: { id: 1, descripcion: "Hombre" },
		mujer: { id: 2, descripcion: "Mujer" },
		noBinario: { id: 3, descripcion: "No binario" },
	}

	export const sharedList: IShared[] = [sharedEnum.hombre, sharedEnum.mujer, sharedEnum.noBinario]

	export const getShared_Desc_FromId = (idTipoSexo: number | null) => {
		if (!idTipoSexo) return "No definido"

		const sharedTipoSexo = sharedList.find((tipoSexo) => tipoSexo.id === idTipoSexo)

		if (sharedTipoSexo) return sharedTipoSexo.descripcion

		utilLogger.error(`Error en getSharedTipoSexoDescFromId. Vino un idTipoSexo erroneo.`, idTipoSexo)
	}

	export const sharedList_ForRadioGroup = (): globalInterfaces.IValueNumberLabelString[] => {
		return sharedList.map((tipoSexo) => {
			return {
				value: tipoSexo.id,
				label: tipoSexo.descripcion,
			}
		})
	}
}
