import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedCircuito } from "../../../shared/sharedCircuito"
import { sharedRodada } from "../../../shared/sharedRodada"
import { apiCallInterface } from "../../../apiCallInterface"
import { sharedPosteos } from "../../../shared/sharedPosteos"

/*
 [HttpGet(ApiRoutesPosteos.GetPosteosByIdPersonaCreadora)]
  [AllowAnonymous]
  public async Task<ActionResult> GetPosteosByIdPersonaCreadora(int idPersonaCreadora)
  {
    var posteos = await _posteosService.GetPosteosByIdPersonaCreadora(idPersonaCreadora);

    var posteosForClient = InxurPosteosForClient.CreateList(posteos);
    return Ok(posteosForClient);
  }

		*/

export namespace apiCallIG_Posteos_GetPosteosByIdPersonaCreadora {
	export interface params {
		idPersonaCreadora: number
	}

	export interface IRes {
		sharedPosteos: sharedPosteos.IShared[]
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.get
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Posteos/GetPosteosByIdPersonaCreadora`
		const sharedParamsQuery: params | null = params
		const sharedParamsBody = null

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		const res: IRes = {
			sharedPosteos: sharedPosteos.getSharedList_FromApiObject(apiCallRes),
		}

		return res
	}
}
