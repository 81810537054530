/* eslint-disable react/jsx-pascal-case*/
import { Alert, Box, SxProps, Theme } from "@mui/material"
import * as React from "react"
import { Helmet } from "react-helmet-async"
import { useDispatch } from "react-redux"
import { Navigate } from "react-router-dom"
import { sharedUserRoles } from "../../../api/shared/sharedUserRoles"
import { globalConfig } from "../../../globals/globalConfig"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useGlobalsUser from "../../../hooksData/useGlobalsUser"
import { utilRouter } from "../../../utils/utilRouter"
import useGlobalsUserRoles from "../../../hooksData/useGlobalsUserRoles"
import { utilLogger } from "../../../utils/utilLogger"

interface IProps {
	title: string
	userMustBeLogged?: boolean
	userCantBeLogged?: boolean
	comingSoon?: boolean
	dniMustBeVerified?: boolean
	rolesAllowed?: sharedUserRoles.IShared[]
	sx?: SxProps<Theme>
	children: React.ReactNode
}

export const SharedBasicPage = ({
	title,
	userMustBeLogged = false,
	userCantBeLogged = false,
	comingSoon = false,
	dniMustBeVerified = false,
	rolesAllowed = [],
	sx,
	children,
}: IProps) => {
	const { userIsLogged, currentPersona } = useGlobalsUser()

	const { userCurrentRol } = useGlobalsUserRoles()

	if (comingSoon) {
		const comingSoonPath = globalPathsApp.pageInsideUrl.ComingSoon()
		return <Navigate to={comingSoonPath} />
	}

	if (userCantBeLogged && userIsLogged) {
		const pageHome = globalPathsApp.pageInsideUrl.Home()
		return <Navigate to={pageHome} />
	}

	if ((userMustBeLogged || rolesAllowed.length > 0) && !userIsLogged) {
		const pathLogin = globalPathsApp.pageInsideUrl.UsersLogin()
		return <Navigate to={pathLogin} />
	}

	if (rolesAllowed.length > 0 && rolesAllowed.filter((rol) => rol.id === userCurrentRol.id).length === 0) {
		const pathPage403 = globalPathsApp.pageInsideUrl.Error403()
		return <Navigate to={pathPage403} />
	}

	if (dniMustBeVerified && !currentPersona.dniVerificado) {
		return <Alert severity="warning">Para poder ver esta página, primero debes verificar tu DNI</Alert>
	}

	let pageTitle = `${title} | ${globalConfig.appName}`

	const currentPage = utilRouter.getCurrentPage()

	if (currentPage === undefined || currentPage === null) pageTitle = ""

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
			</Helmet>

			<Box sx={{ mt: 0, ...sx }}>{children}</Box>
		</>
	)
}
