import { apiCallIG_Registrarse_UserCreationInvitationAcepted } from "../../api/calls/IG/Registrarse/apiCallIG_Registrarse_UserCreationInvitationAcepted"
import { utilRedux } from "../../utils/utilRedux"
import { apiCallInterface } from "../../api/apiCallInterface"
import { bllUsers_Login } from "./bllUsers_Login"

export namespace bllUsers_UserCreationInvitationAcepted {
	//ET = enumTypes
	enum ET {
		setInitialState = "Users_UserCreationInvitationAcepted_setInitialState",
		userCreationInvitationAcepted = "Users_userCreationInvitationAcepted",
	}

	export interface IInterface {
		isUserCreatedAndInvitationAcepted: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			isUserCreatedAndInvitationAcepted: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.User_UserCreationInvitationAcepted_UserCreationInvitationAcepted:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function userCreationInvitationAcepted(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Registrarse_UserCreationInvitationAcepted.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Registrarse_UserCreationInvitationAcepted.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isUserCreatedAndInvitationAcepted">
				const updateObject: actionSubType = {
					isUserCreatedAndInvitationAcepted: true,
				}

				await D(bllUsers_Login.setLoginRes(apiCallParams, apiCallRes.sharedLoginRes))

				utilRedux.TA(D, ET.userCreationInvitationAcepted, updateObject)
			}
		}
	}
}
