import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedPersona } from "../../../shared/sharedPersona"
import { apiCallInterface } from "../../../apiCallInterface"
import { sharedRodada } from "../../../shared/sharedRodada"

export namespace apiCallIG_Rodadas_ActualizarDisclaimer {
	export interface params {
		idRodada: number
		disclaimerHtml: string
	}
	export const paramsName = {
		idRodada: "idRodada",
		disclaimerHtml: "disclaimerHtml",
	}
	export const paramsDesc = {
		idRodada: "Id Rodada",
		disclaimerHtml: "HTML del disclaimer",
	}

	export interface IRes {
		sharedRodada: sharedRodada.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Rodadas/ActualizarDisclaimer`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedRodada: sharedRodada.getSharedOne_FromApiObject(apiCallRes) }
	}
}
