import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedPersona } from "../../../shared/sharedPersona"

export namespace apiCallIG_Personas_ActualizarGeneralFields {
	export interface params {
		idPersona: number
		nombre: string
		apellido1: string
		apellido2: string | null
		fechaNacimiento: Date
		dni: string
		idTipoSexo: number
		alias: string | null
	}
	export const paramsName = {
		idPersona: "idPersona",
		nombre: "nombre",
		apellido1: "apellido1",
		apellido2: "apellido2",
		fechaNacimiento: "fechaNacimiento",
		dni: "dni",
		idTipoSexo: "idTipoSexo",
		alias: "alias",
	}
	export const paramsDesc = {
		idPersona: "Id Persona",
		nombre: "Nombre",
		apellido1: "Primer apellido",
		apellido2: "Segundo apellido",
		fechaNacimiento: "Fecha de nacimiento",
		dni: "DNI",
		idTipoSexo: "Género",
		alias: "Alias",
	}

	export interface IRes {
		sharedPersona: sharedPersona.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//nombre
		apiValidations2.validateMaxNumberOfChars(
			paramsName.nombre,
			paramsDesc.nombre,
			params.nombre,
			100,
			serverModelErrors
		)

		//apellido1
		apiValidations2.validateMaxNumberOfChars(
			paramsName.apellido1,
			paramsDesc.apellido1,
			params.apellido1,
			100,
			serverModelErrors
		)

		//apellido2
		if (params.apellido2) {
			apiValidations2.validateMaxNumberOfChars(
				paramsName.apellido2,
				paramsDesc.apellido2,
				params.apellido2,
				100,
				serverModelErrors
			)
		}

		//dni
		apiValidations2.validateMaxNumberOfChars(paramsName.dni, paramsDesc.dni, params.dni, 20, serverModelErrors)

		//alias
		if (params.alias) {
			apiValidations2.validateMaxNumberOfChars(paramsName.alias, paramsDesc.alias, params.alias, 100, serverModelErrors)
		}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Personas/ActualizarGeneralFields`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedPersona: sharedPersona.getSharedOne_FromApiObject(apiCallRes) }
	}
}
