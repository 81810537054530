import { utilLogger } from "./utilLogger"

export interface IApiCallHeaders {
	userToken?: string
	bdCode?: string
	fingerprint?: number
}

export namespace utilApiCalls {
	export const getHeaders = (apiCallHeaders: IApiCallHeaders) => {
		let headers: any = {}
		if (apiCallHeaders.userToken) headers.Authorization = `Bearer ${apiCallHeaders.userToken}`
		if (apiCallHeaders.bdCode) headers.bdCode = apiCallHeaders.bdCode
		if (apiCallHeaders.fingerprint) headers.fingerprint = apiCallHeaders.fingerprint

		return headers
	}

	export const getHeadersAsQueryParameters = (apiCallHeaders: IApiCallHeaders) => {
		const queryParams = getQueryParamsFromObjects(apiCallHeaders)
		return queryParams
	}

	export const getQueryParamsFromObjects = (paramsObject: any) => {
		let queryParams = ""

		for (const item in paramsObject) {
			const value = encodeURIComponent(paramsObject[item].toString()) //Le pongo un tostring porque si es 0 por ej, no me lo toma abajo

			if (value && String(value) !== "All") {
				if (queryParams === "") {
					queryParams += "?"
				} else {
					queryParams += "&"
				}

				queryParams += `${item}=${String(value)}`
			}
		}

		return queryParams
	}
}
