/* eslint-disable react/jsx-pascal-case*/
import { Alert, Box, Card, Container } from "@mui/material"
import { useEffect } from "react"
import { bllSaludonnet_CentrosUrgencias } from "../../../bll/Saludonnet/bllSaludonnet_CentrosUrgencias"
import useGeneral from "../../../hooksData/useGeneral"
import useSntCentrosUrgencias from "../../../hooksData/useSntCentrosUrgencias"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import { globalAssets } from "../../../globals/globalAssets"
import useSeguros from "../../../hooksData/useSeguros"
import { bllSeguros_GetSegurosVigentes } from "../../../bll/Seguros/bllSeguros_GetSegurosVigentes"
import useGlobalsUser from "../../../hooksData/useGlobalsUser"
import { utilLogger } from "../../../utils/utilLogger"
import SaludonnetShared_SeguroCard from "../Shared/SaludonnetShared_SeguroCard"

export default function SaludonnetSegurosVigentes() {
	const ug = useGeneral("SaludonnetSegurosVigentes")
	const { segurosVigentes } = useSeguros()
	const { currentPersona, userIsLogged } = useGlobalsUser()

	useEffect(() => {
		return () => {
			ug.D(bllSeguros_GetSegurosVigentes.setInitialState())
		}
	}, [])

	useEffect(() => {
		if (userIsLogged && currentPersona.id !== 0 && currentPersona.dniVerificado) {
			ug.D(bllSeguros_GetSegurosVigentes.load(ug.apiCallParams, { idPersona: currentPersona.id }))
		}
	}, [userIsLogged, currentPersona])

	return (
		<Container sx={{ position: "relative" }}>
			{ug.serverState.isLoading && <SharedLoadingComponent />}

			{ug.serverState.errorMessage && <Alert severity="error">{ug.serverState.errorMessage}</Alert>}

			{!currentPersona.dniVerificado ? (
				<Alert severity="warning">Para poder ver los seguros vigentes, primero debes verificar tu DNI</Alert>
			) : (
				<>
					<Box
						gap={3}
						display="grid"
						sx={{ p: 3 }}
						gridTemplateColumns={{
							xs: "repeat(1, 1fr)",
							sm: "repeat(2, 1fr)",
							md: "repeat(3, 1fr)",
						}}
					>
						{segurosVigentes.map((seguroVigente) => (
							<SaludonnetShared_SeguroCard key={seguroVigente.id} seguro={seguroVigente} />
						))}
					</Box>

					{segurosVigentes.length === 0 && <Card sx={{ p: 4 }}>No hay seguros vigentes</Card>}
				</>
			)}
		</Container>
	)
}
