/* eslint-disable react/jsx-pascal-case*/
import { ReactElement } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { Box, Link, Typography, BreadcrumbsProps, Breadcrumbs as MUIBreadcrumbs } from "@mui/material"

type TLink = {
	href?: string
	name: string
	icon?: ReactElement
}

export interface Props extends BreadcrumbsProps {
	links: TLink[]
	activeLast?: boolean
}

export default function SharedBreadcrumbs({ links, activeLast = false, ...other }: Props) {
	const currentLink = links[links.length - 1].name

	const listDefault = links.map((link) => <LinkItem key={link.name} link={link} />)

	const listActiveLast = links.map((link) => (
		<div key={link.name}>
			{link.name !== currentLink ? (
				<LinkItem link={link} />
			) : (
				<Typography
					variant="body2"
					sx={{
						maxWidth: 260,
						overflow: "hidden",
						whiteSpace: "nowrap",
						color: "text.disabled",
						textOverflow: "ellipsis",
					}}
				>
					{currentLink}
				</Typography>
			)}
		</div>
	))

	return (
		<MUIBreadcrumbs
			separator={<Box component="span" sx={{ width: 4, height: 4, borderRadius: "50%", bgcolor: "text.disabled" }} />}
			{...other}
		>
			{activeLast ? listDefault : listActiveLast}
		</MUIBreadcrumbs>
	)
}

// ----------------------------------------------------------------------

type LinkItemProps = {
	link: TLink
}

function LinkItem({ link }: LinkItemProps) {
	const navigate = useNavigate()

	const linkClick = (e: any) => {
		e.preventDefault()
		if (link.href) {
			navigate(link.href)
		}
	}

	return (
		<Link
			key={link.name}
			variant="body2"
			component={RouterLink}
			to={link.href || "#"}
			onClick={(e) => linkClick(e)}
			sx={{
				lineHeight: 2,
				display: "flex",
				alignItems: "center",
				color: "text.primary",
				"& > div": { display: "inherit" },
			}}
		>
			{link.icon && <Box sx={{ mr: 1, "& svg": { width: 20, height: 20 } }}>{link.icon}</Box>}
			{link.name}
		</Link>
	)
}
