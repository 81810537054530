import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedOrganizador } from "../../api/shared/sharedOrganizador"
import { apiCallIG_Organizadores_GetOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_GetOrganizador"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { apiCallIG_Organizadores_BloquearOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_BloquearOrganizador"
import { apiCallIG_Organizadores_DesbloquearOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_DesbloquearOrganizador"
import { apiCallIG_Organizadores_AprobarOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_AprobarOrganizador"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_OrganizadoresSeguidores_SeguirOrganizador } from "../../api/calls/IG/OrganizadoresSeguidores/apiCallIG_OrganizadoresSeguidores_SeguirOrganizador"
import { bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos"
import { apiCallIG_OrganizadoresSeguidores_DejarDeSeguirOrganizador } from "../../api/calls/IG/OrganizadoresSeguidores/apiCallIG_OrganizadoresSeguidores_DejarDeSeguirOrganizador"
import { apiCallIG_Organizadores_ActualizarPosteoDeOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_ActualizarPosteoDePersona"
import { apiCallIG_Organizadores_EliminarPosteoDeOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_EliminarPosteoDeOrganizador"
import { apiCallIG_Organizadores_AgregarPosteoParaOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_AgregarPosteoParaOrganizador"

export namespace bllOrganizadores_Profile {
	//ET = enumTypes
	enum ET {
		setInitialState = "Organizador_Profile_setInitialState",
		loadOrganizador = "Organizador_Profile_loadOrganizador",
		updateWithSharedOrganizador = "Organizadores_Profile_updateWithSharedOrganizador",

		actualizarPosteoDeOrganizador = "Organizadores_Profile_actualizarPosteoDeOrganizador",
		agregarPosteoParaOrganizador = "Organizadores_Profile_agregarPosteoParaOrganizador",
		eliminarPosteoDeOrganizador = "Organizadores_Profile_eliminarPosteoDeOrganizador",

		seguirOrganizador = "Organizador_Profile_seguirOrganizador",
		dejarDeSeguirOrganizador = "Organizador_Profile_dejarDeSeguirOrganizador",
	}

	export interface IInterface {
		organizador: sharedOrganizador.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			organizador: sharedOrganizador.defaultObject,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_GetOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Organizadores_GetOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "organizador">
				const updateObject: actionSubType = {
					organizador: apiCallRes.sharedOrganizador,
				}

				utilRedux.TA(D, ET.loadOrganizador, updateObject)
			}
		}
	}

	export function updateOrganizadorWithSharedOrganizador(
		apiCallParams: apiCallInterface,
		sharedOrganizador: sharedOrganizador.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "organizador">
			const updateObject: actionSubType = {
				organizador: sharedOrganizador,
			}

			utilRedux.TA(D, ET.updateWithSharedOrganizador, updateObject)
		}
	}

	export function seguirOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_OrganizadoresSeguidores_SeguirOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_OrganizadoresSeguidores_SeguirOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(
					bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos.setPersonaOrganizadoresSeguidos(
						apiCallRes.sharedOrganizadoresSeguidos
					)
				)

				type actionSubType = Pick<IInterface, "organizador">
				const updateObject: actionSubType = {
					organizador: apiCallRes.sharedOrganizador,
				}

				utilRedux.TA(D, ET.seguirOrganizador, updateObject)
			}
		}
	}

	export function dejarDeSeguirOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_OrganizadoresSeguidores_DejarDeSeguirOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_OrganizadoresSeguidores_DejarDeSeguirOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(
					bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos.setPersonaOrganizadoresSeguidos(
						apiCallRes.sharedOrganizadoresSeguidos
					)
				)

				type actionSubType = Pick<IInterface, "organizador">
				const updateObject: actionSubType = {
					organizador: apiCallRes.sharedOrganizador,
				}

				utilRedux.TA(D, ET.dejarDeSeguirOrganizador, updateObject)
			}
		}
	}

	export function actualizarPosteoDeOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_ActualizarPosteoDeOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_ActualizarPosteoDeOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "organizador">
				const updateObject: actionSubType = {
					organizador: apiCallRes.sharedOrganizador,
				}

				utilRedux.TA(D, ET.actualizarPosteoDeOrganizador, updateObject)
			}
		}
	}

	export function eliminarPosteoDeOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_EliminarPosteoDeOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_EliminarPosteoDeOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "organizador">
				const updateObject: actionSubType = {
					organizador: apiCallRes.sharedOrganizador,
				}

				utilRedux.TA(D, ET.eliminarPosteoDeOrganizador, updateObject)
			}
		}
	}

	export function agregarPosteoParaOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_AgregarPosteoParaOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_AgregarPosteoParaOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "organizador">
				const updateObject: actionSubType = {
					organizador: apiCallRes.sharedOrganizador,
				}

				utilRedux.TA(D, ET.agregarPosteoParaOrganizador, updateObject)
			}
		}
	}
}
