import { number } from "yup"

export namespace sharedRodadaInvitation {
	export interface IShared {
		id: number
		idRodada: number
		email: string
		phoneNumber: string
		autoConfirmAsParticipant: boolean | null
		nombre: string | null
		apellido1: string | null
		confirmada: boolean
		fechaInvitacion: Date | null
		fechaConfirmacion: Date | null
	}

	export const objectDefault: IShared = {
		id: 0,
		idRodada: 0,
		email: "",
		phoneNumber: "",
		autoConfirmAsParticipant: false,
		nombre: "",
		apellido1: "",
		confirmada: false,
		fechaInvitacion: null,
		fechaConfirmacion: null,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}
}
