/* eslint-disable react/jsx-pascal-case*/

import { Badge, Button, Divider, List, Tooltip, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { useEffect, useState } from "react"
import { bllNotificaciones_All } from "../../bll/Notificaciones/bllNotificaciones_All"
import { globalPathsApp } from "../../globals/globalPathsApp"
import useGeneral from "../../hooksData/useGeneral"
import useNotificacionesAll from "../../hooksData/useNotificacionesAll"
import { utilLogger } from "../../utils/utilLogger"
import SharedBasicIconButtonAnimated from "../Shared/Basic/SharedBasicIconButtonAnimated"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import SharedBasicScrollbar from "../Shared/Basic/SharedBasicScrollbar"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"
import SharedMenuPopover from "../Shared/SharedMenuPopover"
import { Layout_Header_Notifications_Item } from "./Layout_Header_Notifications_Item"

export const Layout_Header_Notifications = () => {
	const ug = useGeneral("Layout_Header_Notifications")

	const { last20Notificaciones } = useNotificacionesAll()

	const [open, set_open] = useState<HTMLElement | null>(null)

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		set_open(event.currentTarget)

		ug.D(bllNotificaciones_All.setAllAsViewedForUserLogged(ug.apiCallParams, {}))
	}

	const handleClose = () => {
		set_open(null)
	}

	const notificationClicked = () => {
		handleClose()
	}

	const totalUnRead = last20Notificaciones.filter((m) => !m.viewed).length

	const handleRefresh = () => {
		ug.D(bllNotificaciones_All.loadLast20(ug.apiCallParams, {}))
	}

	return (
		<>
			<SharedBasicIconButtonAnimated
				sx={{ color: open ? "primary" : "default", width: 40, height: 40 }}
				onClick={handleOpen}
			>
				<Badge badgeContent={totalUnRead} color="error">
					<SharedBasicIconify iconifyIcon="eva:bell-fill" width={20} height={20} />
				</Badge>
			</SharedBasicIconButtonAnimated>

			<SharedMenuPopover open={open} onClose={() => handleClose()} sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}>
				<Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="subtitle1">Notificaciones</Typography>
						<Typography variant="body2" sx={{ color: "text.secondary" }}>
							Tienes {totalUnRead} notificaciones sin leer
						</Typography>
					</Box>

					<Tooltip title="Recargar notificaciones">
						<SharedBasicIconButtonAnimated sx={{ color: "primary" }} onClick={handleRefresh}>
							<SharedBasicIconify iconifyIcon="eva:refresh-fill" width={20} height={20} />
						</SharedBasicIconButtonAnimated>
					</Tooltip>
				</Box>

				<Divider sx={{ borderStyle: "dashed" }} />

				<SharedBasicScrollbar lessToWindowSizeHeight={120}>
					<List disablePadding>
						{last20Notificaciones.map((notif) => {
							return (
								<Layout_Header_Notifications_Item
									key={notif.id}
									notificacion={notif}
									notificationClicked={notificationClicked}
									// type="tipo1"
								/>
							)
						})}
					</List>

					{/* <Divider sx={{ borderStyle: "dashed" }} />

					<Box sx={{ p: 1 }}>
						<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.UsersNotifications()}>
							<Button fullWidth disableRipple onClick={handleClose}>
								Ver todas
							</Button>
						</SharedLinkInsideAppWrapper>
					</Box> */}
				</SharedBasicScrollbar>
			</SharedMenuPopover>
		</>
	)
}
