import { utilRedux } from "../../utils/utilRedux"
import produce from "immer"

export namespace bllGlobalsUI_Screen {
	//ET = enumTypes
	enum ET {
		setIsDesktop = "GlobalsUI_Screen_setIsDesktop",
	}

	export interface IInterface {
		isDesktop: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			isDesktop: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Update_ScreenOpen:
			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.ScreenOpen = action.ScreenOpen
			// 	})
			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	ScreenOpen: action.ScreenOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setIsDesktop(isDesktop: boolean) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "isDesktop">
			const updateObject: actionSubType = {
				isDesktop: isDesktop,
			}

			utilRedux.TA(D, ET.setIsDesktop, updateObject)
		}
	}
}
