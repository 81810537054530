import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedCircuito } from "../../../shared/sharedCircuito"
import { sharedRodada } from "../../../shared/sharedRodada"
import { apiCallInterface } from "../../../apiCallInterface"
import { sharedPosteos } from "../../../shared/sharedPosteos"

/*

  [HttpPost(ApiRoutesPosteos.ComentarPosteo)]
  public async Task<ActionResult> ComentarPosteo(ComentarPosteoReq req)
  {
    var userId = User.GetUserId();

    var posteo = await GetPosteoWithExceptionIfNull(req.IdPosteo);

    var comentario = new InxurPosteosComentarios
    {
      IdPosteo = req.IdPosteo,
      IdPersonaComenta = req.IdPersona,
      TextoComentario = req.TextoComentario,
      FechaComentario = DateTime.Now
    };

    await _posteosService.AddComentarioToPosteo(comentario);

    var posteoResult = await GetPosteoWithExceptionIfNull(posteo.Id);
    var posteoForClient = InxurPosteosForClient.Create(posteoResult);
    return Ok(posteoForClient);
  }
		*/

export namespace apiCallIG_Posteos_DeleteComentario {
	export interface params {
		idComentario: number
	}
	export const paramsName = {
		idComentario: "idComentario",
	}
	export const paramsDesc = {
		idComentario: "idComentario",
	}

	export interface IRes {
		sharedPosteo: sharedPosteos.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Posteos/DeleteComentario`
		const sharedParamsQuery: params | null = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedPosteo: sharedPosteos.getSharedOne_FromApiObject(apiCallRes),
		}
	}
}
