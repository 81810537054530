import { combineReducers } from "redux"
import { bllUsers_Login } from "./bllUsers_Login"
import { bllUsers_Registrarse } from "./bllUsers_Registrarse"
import { bllUsers_ConfirmEmail } from "./bllUsers_ConfirmEmail"
import { bllUsers_ResendConfirmationEmail } from "./bllUsers_ResendConfirmationEmail"
import { bllUsers_GetPasswordResetToken } from "./bllUsers_GetPasswordResetToken"
import { bllUsers_ResetPassword } from "./bllUsers_ResetPassword"
import { bllUsers_Settings } from "./bllUsers_Settings"
import { bllUsers_UserCreationInvitationAcepted } from "./bllUsers_UserCreationInvitationAcepted"

export default combineReducers({
	Login: bllUsers_Login.reducerFunction,
	Registrarse: bllUsers_Registrarse.reducerFunction,
	ConfirmEmail: bllUsers_ConfirmEmail.reducerFunction,
	ResendConfirmationEmail: bllUsers_ResendConfirmationEmail.reducerFunction,
	GetPasswordResetToken: bllUsers_GetPasswordResetToken.reducerFunction,
	ResetPassword: bllUsers_ResetPassword.reducerFunction,
	UserCreationInvitationAcepted: bllUsers_UserCreationInvitationAcepted.reducerFunction,
	Settings: bllUsers_Settings.reducerFunction,
})

export interface IUsers {
	Login: bllUsers_Login.IInterface
	Registrarse: bllUsers_Registrarse.IInterface
	ConfirmEmail: bllUsers_ConfirmEmail.IInterface
	ResendConfirmationEmail: bllUsers_ResendConfirmationEmail.IInterface
	GetPasswordResetToken: bllUsers_GetPasswordResetToken.IInterface
	ResetPassword: bllUsers_ResetPassword.IInterface
	UserCreationInvitationAcepted: bllUsers_UserCreationInvitationAcepted.IInterface
	Settings: bllUsers_Settings.IInterface
}
