import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedMotoclub } from "../../../shared/sharedMotoclub"
import { apiCallInterface } from "../../../apiCallInterface"

export namespace apiCallMP_Seguros_SaveProtocoloAccidenteConCampos {
	export interface params {
		idPersona: number
		firmaResponsableCircuitoUrl: string | null
		firmaAseguradoOTutorUrl: string | null
		nroDocumento: string
		telefonoContacto: string
		email: string
		circuitoLugarOcurrencia: string
		idCircuito: number | null
		detalleClaramenteComoOcurrioElSiniestro: string
		fechaAccidente: Date
		nombresTestigos: string
		descripcionLesiones: string
		idSeguro: number
		idTipoSeguro: number
		// tipoDocumento: string
		// apellidosNombre: string
		// domicilio: string
		// poblacionProvincia: string
	}
	export const paramsName = {
		idPersona: "idPersona",
		firmaResponsableCircuitoUrl: "firmaResponsableCircuitoUrl",
		firmaAseguradoOTutorUrl: "firmaAseguradoOTutorUrl",
		nroDocumento: "nroDocumento",
		telefonoContacto: "telefonoContacto",
		email: "email",
		circuitoLugarOcurrencia: "circuitoLugarOcurrencia",
		idCircuito: "idCircuito",
		detalleClaramenteComoOcurrioElSiniestro: "detalleClaramenteComoOcurrioElSiniestro",
		fechaAccidente: "fechaAccidente",
		nombresTestigos: "nombresTestigos",
		descripcionLesiones: "descripcionLesiones",
		idSeguro: "idSeguro",
		idTipoSeguro: "idTipoSeguro",
		// tipoDocumento: "tipoDocumento",
		// apellidosNombre: "apellidosNombre",
		// domicilio: "domicilio",
		// poblacionProvincia: "poblacionProvincia",
	}
	export const paramsDesc = {
		idPersona: "Id Persona",
		firmaResponsableCircuitoUrl: "Firma responsable circuito",
		firmaAseguradoOTutorUrl: "Firma asegurado o tutor",
		nroDocumento: "Nro. Documento",
		telefonoContacto: "Teléfono de contacto",
		email: "Email",
		circuitoLugarOcurrencia: "Circuito / Lugar de ocurrencia",
		idCircuito: "Circuito",
		detalleClaramenteComoOcurrioElSiniestro: "Detalle claramente cómo ocurrió el siniestro",
		fechaAccidente: "Fecha Accidente",
		nombresTestigos: "Nombres de testigos",
		descripcionLesiones: "Descripción de las lesiones",
		idSeguro: "Id Seguro",
		idTipoSeguro: "Id Tipo Seguro",
		// tipoDocumento: "DNI/NIF/Pasaporte",
		// apellidosNombre: "Apellidos y nombre",
		// domicilio: "Domicilio",
		// poblacionProvincia: "Población y provincia",
	}

	export interface IRes {
		urlPdf: string
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseMotopoliza}/Seguros/SaveProtocoloAccidenteConCampos`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { urlPdf: apiCallRes }
	}
}
