import { SxProps, Theme, Typography, TypographyProps } from "@mui/material"

interface Props extends TypographyProps {
	sx?: SxProps<Theme>
	fontFamily?: undefined | "Sansation"
	color?: string
	children: React.ReactNode
	component?: React.ElementType
}

export default function SharedBasicTypo({ children, color, sx, fontFamily, component = "p", ...other }: Props) {
	return (
		<Typography sx={{ color: color, fontFamily: fontFamily, ...sx }} {...other} component={component}>
			{children}
		</Typography>
	)
}
