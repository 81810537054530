/* eslint-disable react/jsx-pascal-case*/
import { sharedChatType } from "../../../api/shared/sharedChatType"
import Chat from "../../Chat/Chat"
import { Layout } from "../../Layout/Layout"
import { SharedBasicPage } from "../../Shared/Basic/SharedBasicPage"

export default function PageChat() {
	return (
		<Layout>
			<SharedBasicPage title="Chat">
				<Chat chatType={sharedChatType.sharedEnum.personAndPerson} />
			</SharedBasicPage>
		</Layout>
	)
}
