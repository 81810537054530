import { useSelector } from "react-redux"
import { IState } from "../bll/bllReducers"

export default function useAdminNotificaciones() {
	const notificacionEnviada = useSelector((state: IState) => state.AdminNotificaciones.General.notificacionEnviada)

	return {
		notificacionEnviada,
	}
}
