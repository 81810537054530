import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Personas_GetPersonasDeUsuarioLogueado } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersonasDeUsuarioLogueado"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { sharedPersonasUsuarios } from "../../api/shared/sharedPersonasUsuarios"
import { utilLogger } from "../../utils/utilLogger"
import { utilRedux } from "../../utils/utilRedux"
import store from "../../utils/utilReduxStore"
import { bllGlobalsUser_CurrentPersona } from "./bllGlobalsUser_CurrentPersona"

export namespace bllGlobalsUser_Personas {
	//ET = enumTypes
	enum ET {
		setInitialState = "GlobalsUser_Personas_setInitialState",
		load = "GlobalsUser_Personas_load",
		actualizarPersonaEntreLasPersonasDelUsuarioLogueado = "GlobalsUser_Personas_actualizarPersonaEntreLasPersonasDelUsuarioLogueado",
		updateInfoDePersona = "GlobalsUser_Personas_updateInfoDePersona",
	}

	export interface IInterface {
		loaded: boolean
		persona: sharedPersona.IShared
		personasDelegadas: sharedPersona.IShared[]
		personasUsuarios: sharedPersonasUsuarios.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			loaded: false,
			persona: sharedPersona.objectDefault,
			personasDelegadas: [],
			personasUsuarios: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_PersonasDeUsuarioLogueado_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function updateInfoDePersona(sharedUpdatedPersona: sharedPersona.IShared) {
		//D = dispatch
		return async (D: any) => {
			// D(actionGlobals_FullScreenLoading_Start())
			// D(setInitialState())

			let persona: sharedPersona.IShared = store.getState().GlobalsUser.Personas.persona
			let personasDelegadas = [...store.getState().GlobalsUser.Personas.personasDelegadas]

			if (persona.id === sharedUpdatedPersona.id) {
				persona = sharedUpdatedPersona
			}

			var personasDelegadasIndex = personasDelegadas.findIndex((m) => m.id === sharedUpdatedPersona.id)
			if (personasDelegadasIndex !== -1) {
				personasDelegadas[personasDelegadasIndex] = sharedUpdatedPersona
			}

			type actionSubType = Pick<IInterface, "persona" | "personasDelegadas">
			const updateObject: actionSubType = {
				persona: persona,
				personasDelegadas: personasDelegadas,
			}

			utilRedux.TA(D, ET.updateInfoDePersona, updateObject)
		}
	}

	export function loadPersonasDeUsuarioLogueado(apiCallParams: apiCallInterface) {
		//D = dispatch
		return async (D: any) => {
			// D(actionGlobals_FullScreenLoading_Start())
			D(setInitialState())

			const apiCallRes = await apiCallIG_Personas_GetPersonasDeUsuarioLogueado.apiCall(apiCallParams)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "loaded" | "persona" | "personasDelegadas" | "personasUsuarios">
				const updateObject: actionSubType = {
					loaded: true,
					persona: apiCallRes.sharedPersona,
					personasDelegadas: apiCallRes.sharedPersonasDelegadas,
					personasUsuarios: apiCallRes.sharedPersonasUsuarios,
				}

				D(setCurrentPersona(apiCallParams, updateObject.persona, updateObject.personasDelegadas))

				utilRedux.TA(D, ET.load, updateObject)
			}

			// D(actionGlobals_FullScreenLoading_Stop())
		}
	}

	export function setCurrentPersona(
		apiCallParams: apiCallInterface,
		persona: sharedPersona.IShared,
		personasDelegadas: sharedPersona.IShared[]
	) {
		//D = dispatch
		return async (D: any) => {
			const currentIdPersona = store.getState().GlobalsUser.CurrentPersona.currentPersona.id

			if (currentIdPersona && currentIdPersona !== persona.id) {
				const personaDelegada = personasDelegadas.find((personaDelegada) => {
					return personaDelegada.id === currentIdPersona
				})

				if (personaDelegada) {
					D(bllGlobalsUser_CurrentPersona.change(apiCallParams, personaDelegada))
				} else {
					D(bllGlobalsUser_CurrentPersona.change(apiCallParams, persona))
				}
			} else {
				D(bllGlobalsUser_CurrentPersona.change(apiCallParams, persona))
			}
		}
	}
}
