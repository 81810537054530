import { utilRouter } from "../utils/utilRouter"
import { globalConfig } from "./globalConfig"
import AdminNotificaciones from "../components/Admin/Notificaciones/AdminNotificaciones"

export namespace globalPathsApp {
	export const baseUrl = "App"

	export const frontEndUrl_And_BaseUrl = globalConfig.frontendUrl + baseUrl + "/"

	export enum pagesEnum {
		//
		Home = "",
		//
		Chat = "Chat",
		//
		Admin = "Admin",
		AdminNotifications = "AdminNotifications",
		//
		Error404 = "Error404",
		Error403 = "Error403",
		ComingSoon = "ComingSoon",
		//
		Prueba1 = "Prueba1",
		Prueba2 = "Prueba2",
		Prueba3 = "Prueba3",
		PruebaOcr = "PruebaOcr",
		PruebaProtected = "PruebaProtected",
		Prueba404 = "Prueba404",
		//
		UsersLogin = "UsersLogin",
		UsersResendConfirmationEmail = "UsersResendConfirmationEmail",
		UsersConfirmationEmailSent = "UsersConfirmationEmailSent",
		UsersConfirmEmail = "UsersConfirmEmail",
		UsersRegistration = "UsersRegistration",
		UsersSettings = "UsersSettings",
		UsersResetPassword = "UsersResetPassword",
		UsersCreationInvitation = "UsersCreationInvitation",
		UsersResetPasswordCompleted = "UsersResetPasswordCompleted",
		UsersGetPasswordResetToken = "UsersGetPasswordResetToken",
		UsersGetPasswordResetTokenEmailSent = "UsersGetPasswordResetTokenEmailSent",
		UsersNotifications = "UsersNotifications",
		//
		Circuitos = "Circuitos",
		CircuitosLists = "CircuitosLists",
		CircuitosProfile = "CircuitosProfile",
		CircuitosCreate = "CircuitosCreate",
		CircuitosSettings = "CircuitosSettings",
		//
		PersonasProfile = "PersonasProfile",
		PersonasSettings = "PersonasSettings",
		PersonasSeguros = "PersonasSeguros",
		//
		Rodadas = "Rodadas",
		RodadasLists = "RodadasLists",
		RodadasCreate = "RodadasCreate",
		RodadasSettings = "RodadasSettings",
		RodadasProfile = "RodadasProfile",
		RodadasConfirmInvitation = "RodadasConfirmInvitation",
		RodadasConfirmEmailInvitation = "RodadasConfirmEmailInvitation",
		RodadasConfirmPhoneNumberInvitation = "RodadasConfirmPhoneNumberInvitation",
		//
		Pilotos = "Pilotos",
		//
		Empresas = "Empresas",
		EmpresasLists = "EmpresasLists",
		EmpresasProfile = "EmpresasProfile",
		EmpresasCreate = "EmpresasCreate",
		EmpresasSettings = "EmpresasSettings",
		//
		Escuelas = "Escuelas",
		EscuelasLists = "EscuelasLists",
		EscuelasProfile = "EscuelasProfile",
		EscuelasCreate = "EscuelasCreate",
		EscuelasSettings = "EscuelasSettings",
		//
		Motoclubs = "Motoclubs",
		MotoclubsLists = "MotoclubsLists",
		MotoclubsProfile = "MotoclubsProfile",
		MotoclubsCreate = "MotoclubsCreate",
		MotoclubsSettings = "MotoclubsSettings",
		//
		Federaciones = "Federaciones",
		FederacionesLists = "FederacionesLists",
		FederacionesProfile = "FederacionesProfile",
		FederacionesCreate = "FederacionesCreate",
		FederacionesSettings = "FederacionesSettings",
		//
		Organizadores = "Organizadores",
		OrganizadoresLists = "OrganizadoresLists",
		OrganizadoresProfile = "OrganizadoresProfile",
		OrganizadoresCreate = "OrganizadoresCreate",
		OrganizadoresSettings = "OrganizadoresSettings",
		//
		Motopoliza = "Motopoliza",
		//
		Seguros = "Seguros",
		SegurosSeguro = "SegurosSeguro",
		SegurosTarjetaPiloto = "SegurosTarjetaPiloto",
		SegurosTarjetaSeguro = "SegurosTarjetaSeguro",
		SegurosProtocoloAccidente = "SegurosProtocoloAccidente",
		//
		PrivacyPolicy = "PrivacyPolicy",
		TermsOfService = "TermsOfService",
	}

	//
	export enum tabsCircuitosEnum {
		all = "all",
		map = "map",
		own = "own",
		following = "following",
	}
	export enum tabsCircuitoProfileEnum {
		general = "general",
		rodadas = "rodadas",
		pistas = "pistas",
		// gallery = "gallery",
	}
	export enum tabsCircuitoSettingsEnum {
		general = "general",
		address = "address",
		profile = "profile",
		pistas = "pistas",
		horarios = "horarios",
		social = "social",
		contact = "contact",
		characteristics = "characteristics",
		admin = "admin",
		notifications = "notifications",
		messages = "messages",
	}
	//
	export enum tabsAdminEnum {
		general = "general",
		notificaciones = "notificaciones",
	}
	export enum tabsAdminNotificationsEnum {
		general = "general",
	}
	//
	export enum tabsEmpresasEnum {
		all = "all",
		own = "own",
		following = "following",
	}
	export enum tabsEmpresaProfileEnum {
		general = "general",
		followers = "followers",
		gallery = "gallery",
	}
	export enum tabsEmpresaSettingsEnum {
		general = "general",
		address = "address",
		profile = "profile",
		social = "social",
		contact = "contact",
		characteristics = "characteristics",
		admin = "admin",
		notifications = "notifications",
	}
	//
	export enum tabsEscuelasEnum {
		all = "all",
		own = "own",
		following = "following",
	}
	export enum tabsEscuelaProfileEnum {
		general = "general",
		followers = "followers",
		gallery = "gallery",
	}
	export enum tabsEscuelaSettingsEnum {
		general = "general",
		address = "address",
		profile = "profile",
		social = "social",
		contact = "contact",
		characteristics = "characteristics",
		admin = "admin",
		notifications = "notifications",
	}
	//
	export enum tabsMotoclubsEnum {
		all = "all",
		own = "own",
		following = "following",
	}
	export enum tabsMotoclubProfileEnum {
		general = "general",
		followers = "followers",
		gallery = "gallery",
	}
	export enum tabsMotoclubSettingsEnum {
		general = "general",
		address = "address",
		profile = "profile",
		social = "social",
		contact = "contact",
		characteristics = "characteristics",
		admin = "admin",
		notifications = "notifications",
	}
	//
	export enum tabsFederacionesEnum {
		all = "all",
		own = "own",
		following = "following",
	}
	export enum tabsFederacionProfileEnum {
		general = "general",
		followers = "followers",
		gallery = "gallery",
	}
	export enum tabsFederacionSettingsEnum {
		general = "general",
		address = "address",
		profile = "profile",
		social = "social",
		contact = "contact",
		characteristics = "characteristics",
		admin = "admin",
		notifications = "notifications",
	}
	//
	export enum tabsOrganizadoresEnum {
		all = "all",
		own = "own",
		following = "following",
	}
	export enum tabsOrganizadorProfileEnum {
		general = "general",
		// followers = "followers",
		// gallery = "gallery",
	}
	export enum tabsOrganizadorSettingsEnum {
		general = "general",
		address = "address",
		profile = "profile",
		social = "social",
		contact = "contact",
		characteristics = "characteristics",
		admin = "admin",
		notifications = "notifications",
		messages = "messages",
	}
	//
	export enum tabsPersonaProfileEnum {
		general = "general",
		followers = "followers",
		friends = "friends",
		gallery = "gallery",
	}
	export enum tabsPersonaSettingsEnum {
		general = "general",
		address = "address",
		profile = "profile",
		social = "social",
		contact = "contact",
		vehiculos = "vehiculos",
		modalidades = "modalidades",
		notifications = "notifications",
	}
	//
	export enum tabsPilotosEnum {
		search = "search",
		following = "following",
	}
	//
	export enum tabsRodadasEnum {
		all = "all",
		own = "own",
		following = "following",
	}
	export enum tabsRodadaProfileEnum {
		general = "general",
		followers = "followers",
		gallery = "gallery",
	}
	export enum tabsRodadaSettingsEnum {
		general = "general",
		profile = "profile",
		participantes = "participantes",
		notifications = "notifications",
		disclaimer = "disclaimer",
		messages = "messages",
	}
	//
	export enum tabsUsersSettingsEnum {
		general = "general",
		notifications = "notifications",
		notifSuscriptions = "notifSuscriptions",
		changepass = "changepass",
	}
	//
	export enum tabsSaludonnetEnum {
		seguro = "seguro",
		seguroActual = "seguroActual",
		segurosVigentes = "segurosVigentes",
		protocoloHubener = "protocoloHubener",
		protocoloChubb = "protocoloChubb",
		heTenidoUnAccidente = "heTenidoUnAccidente",
		centrosurgencia = "centrosurgencia",
		protocoloAccidente = "protocoloAccidente",
		historicoSeguros = "historicoSeguros",
	}

	export interface IPageParam {
		name: string
		value: string
	}

	export const pageInsideUrl = {
		//
		Home: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Home),
		//
		Chat: (idChatRoom: number | undefined = undefined) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Chat, [
				{ name: "idChatRoom", value: idChatRoom?.toString() || "" },
			]),
		//
		Error404: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Error404),
		Error403: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Error403),
		ComingSoon: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.ComingSoon),
		//
		Prueba1: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Prueba1),
		Prueba2: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Prueba2),
		Prueba3: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Prueba3),
		PruebaOcr: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.PruebaOcr),
		PruebaProtected: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.PruebaProtected),
		Prueba404: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Prueba404),
		//
		UsersLogin: (email: string = "", redirectUrl: string = "") =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersLogin, [
				{ name: "email", value: email },
				{ name: "redirectUrl", value: redirectUrl },
			]),
		UsersResendConfirmationEmail: () =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersResendConfirmationEmail),
		UsersConfirmationEmailSent: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersConfirmationEmailSent),
		UsersConfirmEmail: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersConfirmEmail),
		UsersRegistration: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersRegistration),
		UsersSettings: (tabName: tabsUsersSettingsEnum = tabsUsersSettingsEnum.general) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersSettings, [{ name: "tabName", value: tabName }]),
		UsersResetPassword: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersResetPassword),
		UsersResetPasswordCompleted: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersResetPasswordCompleted),
		UsersGetPasswordResetToken: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersGetPasswordResetToken),
		UsersGetPasswordResetTokenEmailSent: () =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersGetPasswordResetTokenEmailSent),
		UsersNotifications: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.UsersNotifications),
		//
		Circuitos: (tabName: tabsCircuitosEnum = tabsCircuitosEnum.all) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Circuitos, [{ name: "tabName", value: tabName }]),
		CircuitosProfile: (tabName: tabsCircuitoProfileEnum = tabsCircuitoProfileEnum.general, idCircuito: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.CircuitosProfile, [
				{ name: "tabName", value: tabName },
				{ name: "idCircuito", value: idCircuito.toString() },
			]),
		CircuitosCreate: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.CircuitosCreate),
		CircuitosSettings: (tabName: tabsCircuitoSettingsEnum = tabsCircuitoSettingsEnum.general, idCircuito: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.CircuitosSettings, [
				{ name: "tabName", value: tabName },
				{ name: "idCircuito", value: idCircuito.toString() },
			]),
		//
		PersonasProfile: (tabName: tabsPersonaProfileEnum = tabsPersonaProfileEnum.general, idPersona: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.PersonasProfile, [
				{ name: "tabName", value: tabName },
				{ name: "idPersona", value: idPersona.toString() },
			]),
		PersonasSettings: (tabName: tabsPersonaSettingsEnum = tabsPersonaSettingsEnum.general, idPersona: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.PersonasSettings, [
				{ name: "tabName", value: tabName },
				{ name: "idPersona", value: idPersona.toString() },
			]),
		PersonasSeguros: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.PersonasSeguros),
		Rodadas: (tabName: tabsRodadasEnum = tabsRodadasEnum.all) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Rodadas, [{ name: "tabName", value: tabName }]),
		RodadasProfile: (tabName: tabsRodadaProfileEnum = tabsRodadaProfileEnum.general, idRodada: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.RodadasProfile, [
				{ name: "tabName", value: tabName },
				{ name: "idRodada", value: idRodada.toString() },
			]),
		RodadasCreate: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.RodadasCreate),
		RodadasSettings: (tabName: tabsRodadaSettingsEnum = tabsRodadaSettingsEnum.general, idRodada: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.RodadasSettings, [
				{ name: "tabName", value: tabName },
				{ name: "idRodada", value: idRodada.toString() },
			]),
		RodadasConfirmInvitation: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.RodadasConfirmInvitation),
		RodadasConfirmEmailInvitation: () =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.RodadasConfirmEmailInvitation),

		RodadasConfirmPhoneNumberInvitation: () =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.RodadasConfirmPhoneNumberInvitation),
		//
		Pilotos: (tabName: tabsPilotosEnum = tabsPilotosEnum.search) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Pilotos, [{ name: "tabName", value: tabName }]),
		//
		Empresas: (tabName: tabsEmpresasEnum = tabsEmpresasEnum.all) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Empresas, [{ name: "tabName", value: tabName }]),
		EmpresasProfile: (tabName: tabsEmpresaProfileEnum = tabsEmpresaProfileEnum.general, idEmpresa: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.EmpresasProfile, [
				{ name: "tabName", value: tabName },
				{ name: "idEmpresa", value: idEmpresa.toString() },
			]),
		EmpresasCreate: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.EmpresasCreate),
		EmpresasSettings: (tabName: tabsEmpresaSettingsEnum = tabsEmpresaSettingsEnum.general, idEmpresa: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.EmpresasSettings, [
				{ name: "tabName", value: tabName },
				{ name: "idEmpresa", value: idEmpresa.toString() },
			]),
		//
		Escuelas: (tabName: tabsEscuelasEnum = tabsEscuelasEnum.all) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Escuelas, [{ name: "tabName", value: tabName }]),
		EscuelasProfile: (tabName: tabsEscuelaProfileEnum = tabsEscuelaProfileEnum.general, idEscuela: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.EscuelasProfile, [
				{ name: "tabName", value: tabName },
				{ name: "idEscuela", value: idEscuela.toString() },
			]),
		EscuelasCreate: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.EscuelasCreate),
		EscuelasSettings: (tabName: tabsEscuelaSettingsEnum = tabsEscuelaSettingsEnum.general, idEscuela: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.EscuelasSettings, [
				{ name: "tabName", value: tabName },
				{ name: "idEscuela", value: idEscuela.toString() },
			]),
		//
		Motoclubs: (tabName: tabsMotoclubsEnum = tabsMotoclubsEnum.all) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Motoclubs, [{ name: "tabName", value: tabName }]),
		MotoclubsProfile: (tabName: tabsMotoclubProfileEnum = tabsMotoclubProfileEnum.general, idMotoclub: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.MotoclubsProfile, [
				{ name: "tabName", value: tabName },
				{ name: "idMotoclub", value: idMotoclub.toString() },
			]),
		MotoclubsCreate: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.MotoclubsCreate),
		MotoclubsSettings: (tabName: tabsMotoclubSettingsEnum = tabsMotoclubSettingsEnum.general, idMotoclub: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.MotoclubsSettings, [
				{ name: "tabName", value: tabName },
				{ name: "idMotoclub", value: idMotoclub.toString() },
			]),
		//
		Federaciones: (tabName: tabsFederacionesEnum = tabsFederacionesEnum.all) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Federaciones, [{ name: "tabName", value: tabName }]),
		FederacionesProfile: (
			tabName: tabsFederacionProfileEnum = tabsFederacionProfileEnum.general,
			idFederacion: number
		) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.FederacionesProfile, [
				{ name: "tabName", value: tabName },
				{ name: "idFederacion", value: idFederacion.toString() },
			]),
		FederacionesCreate: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.FederacionesCreate),
		FederacionesSettings: (
			tabName: tabsFederacionSettingsEnum = tabsFederacionSettingsEnum.general,
			idFederacion: number
		) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.FederacionesSettings, [
				{ name: "tabName", value: tabName },
				{ name: "idFederacion", value: idFederacion.toString() },
			]),
		//
		Organizadores: (tabName: tabsOrganizadoresEnum = tabsOrganizadoresEnum.all) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Organizadores, [{ name: "tabName", value: tabName }]),
		OrganizadoresProfile: (
			tabName: tabsOrganizadorProfileEnum = tabsOrganizadorProfileEnum.general,
			idOrganizador: number
		) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.OrganizadoresProfile, [
				{ name: "tabName", value: tabName },
				{ name: "idOrganizador", value: idOrganizador.toString() },
			]),
		OrganizadoresCreate: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.OrganizadoresCreate),
		OrganizadoresSettings: (
			tabName: tabsOrganizadorSettingsEnum = tabsOrganizadorSettingsEnum.general,
			idOrganizador: number
		) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.OrganizadoresSettings, [
				{ name: "tabName", value: tabName },
				{ name: "idOrganizador", value: idOrganizador.toString() },
			]),
		//
		Motopoliza: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Motopoliza),
		//
		Seguros: (tabName: tabsSaludonnetEnum = tabsSaludonnetEnum.centrosurgencia) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Seguros, [{ name: "tabName", value: tabName }]),
		SegurosSeguro: (idSeguro: number, tipo: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.SegurosSeguro, [
				{ name: "idSeguro", value: idSeguro.toString() },
				{ name: "tipo", value: tipo.toString() },
			]),
		SegurosTarjetaPiloto: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.SegurosTarjetaPiloto, []),
		SegurosProtocoloAccidente: (idSeguro: number, tipo: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.SegurosProtocoloAccidente, [
				{ name: "idSeguro", value: idSeguro.toString() },
				{ name: "tipo", value: tipo.toString() },
			]),
		SegurosTarjetaSeguro: (idSeguro: number, tipo: number) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.SegurosTarjetaSeguro, [
				{ name: "idSeguro", value: idSeguro.toString() },
				{ name: "tipo", value: tipo.toString() },
			]),
		//
		PrivacyPolicy: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.PrivacyPolicy),
		TermsOfService: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.TermsOfService),
		Admin: () => utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.Admin),
		AdminNotifications: (tabName: tabsAdminNotificationsEnum = tabsAdminNotificationsEnum.general) =>
			utilRouter.getInsideAppUrl(globalPathsApp.pagesEnum.AdminNotifications, [{ name: "tabName", value: tabName }]),
	}

	export const getFullUrl = (urlDest: string) => {
		return `${frontEndUrl_And_BaseUrl}${urlDest}`
	}

	export const urlsForBackend = {
		confirmEmail: frontEndUrl_And_BaseUrl + pagesEnum.UsersConfirmEmail,
		// resetPassword: frontEndUrl_And_BaseUrl + pagesEnum.UsersResetPassword,
		// rodadaEmailInvitation: frontEndUrl_And_BaseUrl + pagesEnum.RodadasConfirmEmailInvitation,
		// rodadaInvitation: frontEndUrl_And_BaseUrl + pagesEnum.RodadasConfirmInvitation,
		// rodadaPhoneNumberInvitation: frontEndUrl_And_BaseUrl + pagesEnum.RodadasConfirmPhoneNumberInvitation,
	}

	export const pageEnum_byPathname = (pathname: string): pagesEnum | undefined => {
		if (pathname === "/") return undefined
		if (pathname === `/${baseUrl}/`) return pagesEnum.Home

		for (let pathApp in pagesEnum) {
			if (pathname.includes(`/${baseUrl}/${pathApp}`)) {
				return pathApp as pagesEnum
			}
		}
		return undefined
	}
}
