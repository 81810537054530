/* eslint-disable react/jsx-pascal-case*/
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useGlobalsUserRoles from "../../../hooksData/useGlobalsUserRoles"
import { ISharedTabs_Tab } from "../../Shared/Basic/SharedBasicTabs"
import PilotosSearch from "../Search/PilotosSearch"
import PilotosShared_PilotosSeguidos from "../Shared/PilotosShared_PilotosSeguidos"

export const PilotosLanding_Menu = (): ISharedTabs_Tab[] => {
	// const { isUserCurrentRol_OrganizadorDeCircuito } = useGlobalsUserRoles()

	var menu: ISharedTabs_Tab[] = []

	menu.push({
		name: globalPathsApp.tabsPilotosEnum.search,
		label: "Buscar pilotos",
		component: <PilotosSearch />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Pilotos(globalPathsApp.tabsPilotosEnum.search),
	})

	menu.push({
		name: globalPathsApp.tabsPilotosEnum.following,
		label: "Pilotos seguidos",
		component: <PilotosShared_PilotosSeguidos />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Pilotos(globalPathsApp.tabsPilotosEnum.following),
	})

	return menu
}
