/* eslint-disable react/jsx-pascal-case*/
import { Card, CardHeader, Container } from "@mui/material"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import CircuitosShared_CardList from "./CircuitosShared_CardList"

interface IProps {}

export default function CircuitosShared_CircuitosSeguidos({}: IProps) {
	const circuitosSeguidos = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaCircuitosSeguidos.circuitosSeguidos
	)

	return (
		<Container sx={{ position: "relative" }}>
			<Card>
				<CardHeader title="Circuitos Seguidos" sx={{ mb: 3 }} />

				<CircuitosShared_CardList circuitos={circuitosSeguidos} />
			</Card>
		</Container>
	)
}
