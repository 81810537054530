import { globalInterfaces } from "../../globals/globalInterfaces"
import { utilLogger } from "../../utils/utilLogger"

export namespace sharedChatType {
	export interface IShared {
		id: number
		descripcion: string
	}

	export const sharedEnum = {
		personAndPerson: { id: 1, descripcion: "Person and Person" },
		personAndCircuito: { id: 2, descripcion: "Person and Circuito" },
		personAndRodada: { id: 3, descripcion: "Person and Rodada" },
		personAndOrganizador: { id: 4, descripcion: "Person and Organizador" },
	}

	export const sharedList: IShared[] = [
		sharedEnum.personAndPerson,
		sharedEnum.personAndCircuito,
		sharedEnum.personAndRodada,
		sharedEnum.personAndOrganizador,
	]

	export const getShared_Desc_FromId = (idChatType: number | null) => {
		if (!idChatType) return "No definido"

		const sharedTipoSexo = sharedList.find((tipoSexo) => tipoSexo.id === idChatType)

		if (sharedTipoSexo) return sharedTipoSexo.descripcion

		utilLogger.error(`Error en getShared_Desc_FromId. Vino un idChatType erroneo.`, idChatType)
	}

	export const sharedList_ForRadioGroup = (): globalInterfaces.IValueNumberLabelString[] => {
		return sharedList.map((chatType) => {
			return {
				value: chatType.id,
				label: chatType.descripcion,
			}
		})
	}
}
