import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedFederacion } from "../../api/shared/sharedFederacion"
import { apiCallIG_Federaciones_GetFederacion } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_GetFederacion"
import { apiCallIG_Federaciones_ActualizarAvatar } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_ActualizarAvatar"
import { apiCallIG_Federaciones_ActualizarContact } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_ActualizarContact"
import { apiCallIG_Federaciones_ActualizarCover } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_ActualizarCover"
import { apiCallIG_Federaciones_ActualizarDireccionGoogle } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_ActualizarDireccionGoogle"
import { apiCallIG_Federaciones_ActualizarDireccionNotGoogle } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_ActualizarDireccionNotGoogle"
import { apiCallIG_Federaciones_ActualizarGeneralFields } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_ActualizarGeneralFields"
import { apiCallIG_Federaciones_ActualizarProfileFields } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_ActualizarProfileFields"
import { apiCallIG_Federaciones_ActualizarSocialInfo } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_ActualizarSocialInfo"
import { apiCallIG_Federaciones_BloquearFederacion } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_BloquearFederacion"
import { apiCallIG_Federaciones_AprobarFederacion } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_AprobarFederacion"
import { apiCallIG_Federaciones_DesbloquearFederacion } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_DesbloquearFederacion"
import { apiCallIG_Federaciones_DeleteFederacion } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_DeleteFederacion"
import { bllGlobalsGeneralData_AllFederaciones } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllFederaciones"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Federaciones_EnviarNotificacionASeguidores } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_EnviarNotificacionASeguidores"

export namespace bllFederaciones_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Federaciones_Settings_setInitialState",
		loadFederacion = "Federaciones_Settings_loadFederacion",
		updateWithSharedFederacion = "Federaciones_Settings_updateWithSharedFederacion",
		actualizarAvatar = "Federaciones_Settings_actualizarAvatar",
		actualizarCharacteristics = "Federaciones_Settings_actualizarCharacteristics",
		actualizarContact = "Federaciones_Settings_actualizarContact",
		actualizarAdminFields = "Federaciones_Settings_actualizarAdminFields",
		actualizarCover = "Federaciones_Settings_actualizarCover",
		actualizarDireccionGoogle = "Federaciones_Settings_actualizarDireccionGoogle",
		actualizarDireccionNotGoogle = "Federaciones_Settings_actualizarDireccionNotGoogle",
		actualizarGeneralFields = "Federaciones_Settings_actualizarGeneralFields",
		actualizarProfileFields = "Federaciones_Settings_actualizarProfileFields",
		actualizarSocialInfo = "Federaciones_Settings_actualizarSocialInfo",
		deleteFederacion = "Federaciones_Settings_deleteFederacion",
		bloquearFederacion = "Federaciones_Settings_bloquearFederacion",
		desbloquearFederacion = "Federaciones_Settings_desbloquearFederacion",
		aprobarFederacion = "Federaciones_Settings_aprobarFederacion",

		enviarNotificacionASeguidores = "Federaciones_Settings_enviarNotificacionASeguidores",
	}

	export interface IInterface {
		federacion: sharedFederacion.IShared
		isDeleted: boolean
		notificacionEnviada: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			federacion: sharedFederacion.defaultObject,
			isDeleted: false,
			notificacionEnviada: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadFederacion(apiCallParams: apiCallInterface, params: apiCallIG_Federaciones_GetFederacion.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Federaciones_GetFederacion.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "federacion">
				const updateObject: actionSubType = {
					federacion: apiCallRes.sharedFederacion,
				}

				utilRedux.TA(D, ET.loadFederacion, updateObject)
			}
		}
	}

	export function updateFederacionWithSharedFederacion(
		apiCallParams: apiCallInterface,
		sharedFederacion: sharedFederacion.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "federacion">
			const updateObject: actionSubType = {
				federacion: sharedFederacion,
			}

			utilRedux.TA(D, ET.updateWithSharedFederacion, updateObject)
		}
	}

	export function actualizarAvatar(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_ActualizarAvatar.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_ActualizarAvatar.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllFederaciones_Settings.updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion)
				)

				utilRedux.TA(D, ET.actualizarAvatar, {})
			}
		}
	}

	export function actualizarContact(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_ActualizarContact.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_ActualizarContact.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllFederaciones_Settings.updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion)
				)

				utilRedux.TA(D, ET.actualizarContact, {})
			}
		}
	}

	export function actualizarCover(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_ActualizarCover.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_ActualizarCover.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllFederaciones_Settings.updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion)
				)

				utilRedux.TA(D, ET.actualizarCover, {})
			}
		}
	}

	export function actualizarDireccionGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_ActualizarDireccionGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_ActualizarDireccionGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllFederaciones_Settings.updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion)
				)

				utilRedux.TA(D, ET.actualizarDireccionGoogle, {})
			}
		}
	}

	export function actualizarDireccionNotGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_ActualizarDireccionNotGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_ActualizarDireccionNotGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllFederaciones_Settings.updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion)
				)

				utilRedux.TA(D, ET.actualizarDireccionNotGoogle, {})
			}
		}
	}

	export function actualizarGeneralFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_ActualizarGeneralFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_ActualizarGeneralFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllFederaciones_Settings.updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion)
				)

				utilRedux.TA(D, ET.actualizarGeneralFields, {})
			}
		}
	}

	export function actualizarProfileFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_ActualizarProfileFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_ActualizarProfileFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllFederaciones_Settings.updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion)
				)

				utilRedux.TA(D, ET.actualizarProfileFields, {})
			}
		}
	}

	export function actualizarSocialInfo(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_ActualizarSocialInfo.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_ActualizarSocialInfo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllFederaciones_Settings.updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion)
				)

				utilRedux.TA(D, ET.actualizarSocialInfo, {})
			}
		}
	}

	export function deleteFederacion(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_DeleteFederacion.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Federaciones_DeleteFederacion.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isDeleted">
				const updateObject: actionSubType = {
					isDeleted: true,
				}

				D(bllGlobalsGeneralData_AllFederaciones.loadAllFederaciones(apiCallParams))

				utilRedux.TA(D, ET.deleteFederacion, updateObject)
			}
		}
	}

	export function bloquearFederacion(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_BloquearFederacion.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_BloquearFederacion.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion))

				utilRedux.TA(D, ET.bloquearFederacion, {})
			}
		}
	}

	export function aprobarFederacion(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_AprobarFederacion.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_AprobarFederacion.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion))

				utilRedux.TA(D, ET.aprobarFederacion, {})
			}
		}
	}

	export function desbloquearFederacion(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_DesbloquearFederacion.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Federaciones_DesbloquearFederacion.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateFederacionWithSharedFederacion(apiCallParams, apiCallRes.sharedFederacion))

				utilRedux.TA(D, ET.desbloquearFederacion, {})
			}
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Federaciones_EnviarNotificacionASeguidores.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_Federaciones_EnviarNotificacionASeguidores.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}
}
