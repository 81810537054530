/* eslint-disable react/jsx-pascal-case*/
import { useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import useDisplayAfter500 from "../../hooks/useDisplayAfter500"
import { utilRouter } from "../../utils/utilRouter"
import SharedLoadingScreen from "../Shared/Loading/SharedLoadingScreen"

export const GlobalFullScreenLoading = () => {
	const isLoading = useSelector((state: IState) => state.GlobalsUI.FullScreenLoading.isLoading)
	const { displayAfter500 } = useDisplayAfter500()

	return <>{displayAfter500 && isLoading && !utilRouter.isOnLandingPage() && <SharedLoadingScreen />}</>
}
