/* eslint-disable react/jsx-pascal-case*/
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useGlobalsUserRoles from "../../../hooksData/useGlobalsUserRoles"
import { ISharedTabs_Tab } from "../../Shared/Basic/SharedBasicTabs"
import AdminNotificaciones from "../Notificaciones/AdminNotificaciones"
import AdminGeneral from "../General/AdminGeneral"

export const AdminMenu = (): ISharedTabs_Tab[] => {
	var menu: ISharedTabs_Tab[] = []

	menu.push({
		name: globalPathsApp.tabsAdminEnum.general,
		label: "General",
		component: <AdminGeneral />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Admin(),
	})

	menu.push({
		name: globalPathsApp.tabsAdminEnum.notificaciones,
		label: "Notificaciones",
		component: <AdminNotificaciones />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.AdminNotifications(globalPathsApp.tabsAdminNotificationsEnum.general),
	})

	return menu
}
