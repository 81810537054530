/* eslint-disable react/jsx-pascal-case*/
import * as React from "react"
import { Link, SxProps, Theme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { globalPathsApp } from "../../globals/globalPathsApp"
import { utilRouter } from "../../utils/utilRouter"

interface IProps {
	urlDest: string
	sx?: SxProps<Theme>
	children: React.ReactNode
	underline?: "none" | "hover" | "always" | undefined
}

export const SharedLinkInsideAppWrapper = ({ urlDest, sx, children, underline = "none" }: IProps) => {
	const navigate = useNavigate()

	const linkClick = (e: any) => {
		e.preventDefault()
		navigate(urlDest)
	}

	return (
		<Link
			href={urlDest}
			color="inherit"
			underline={underline}
			onClick={(e) => linkClick(e)}
			sx={{ /*display: "contents",*/ ...sx }}
		>
			{/* <a href={urlDest} onClick={(e) => linkClick(e)}> */}
			{children}
			{/* </a> */}
		</Link>
	)
}
