/* eslint-disable react/jsx-pascal-case*/
import { Button, styled, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import { useState } from "react"
import { useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import { globalPathsApp } from "../../globals/globalPathsApp"
import { styleConfig } from "../../Style/styleConfig"
import { PersonasDelegadasDialog } from "../Personas/DelegadasDialog/PersonasDelegadasDialog"
import PersonasGlobals_CurrentAvatar from "../Personas/Globals/PersonasGlobals_CurrentAvatar"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"

const RootStyle = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	backgroundColor: theme.palette.grey[500_12],
	transition: theme.transitions.create("opacity", {
		duration: theme.transitions.duration.shorter,
	}),
}))

export const Layout_LeftMenu_Account = () => {
	const theme = useTheme()
	const idPersona = useSelector((state: IState) => state.GlobalsUser.CurrentPersona.currentPersona.id)
	const personaName = useSelector((state: IState) => state.GlobalsUser.CurrentPersona.currentPersona.nombre)
	const personasDelegadas = useSelector((state: IState) => state.GlobalsUser.Personas.personasDelegadas)
	const userEmail = useSelector((state: IState) => state.Users.Login.loginRes.email)
	// const roles = useSelector((state: IState) => state.Users.Login.loginRes.roles)

	const [dialogOpen, set_dialogOpen] = useState(false)

	return (
		<>
			<RootStyle>
				<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.PersonasProfile(undefined, idPersona)}>
					<PersonasGlobals_CurrentAvatar />
				</SharedLinkInsideAppWrapper>

				<Box
					sx={{
						pl: "12px",
						width: `${styleConfig.leftMenuWidth - 120}px`,
					}}
				>
					<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.PersonasProfile(undefined, idPersona)}>
						<SharedBasicTypo variant="subtitle2" color={theme.palette.grey[900]} noWrap>
							{personaName}
						</SharedBasicTypo>
						<SharedBasicTypo variant="body2" noWrap color={theme.palette.grey[800]}>
							{userEmail}
						</SharedBasicTypo>

						{/* {roles.length > 0 && (
							<SharedBasicTypo variant="body2" color={theme.palette.grey[800]}>
								Roles: {roles.join(", ")}
							</SharedBasicTypo>
						)} */}
					</SharedLinkInsideAppWrapper>

					{personasDelegadas.length > 0 && (
						<Button size="small" sx={{ py: 0, px: 0 }} onClick={() => set_dialogOpen(true)}>
							Cambiar de perfil
						</Button>
					)}
				</Box>
			</RootStyle>

			<PersonasDelegadasDialog isDialogOpen={dialogOpen} close={() => set_dialogOpen(false)} />
		</>
	)
}
