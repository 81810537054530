import { combineReducers } from "redux"
import { bllCircuitos_Profile } from "./bllCircuitos_Profile"
import { bllCircuitos_Create } from "./bllCircuitos_Create"
import { bllCircuitos_Settings } from "./bllCircuitos_Settings"
import { bllCircuitos_Fechas } from "./bllCircuitos_Fechas"

export default combineReducers({
	Profile: bllCircuitos_Profile.reducerFunction,
	Settings: bllCircuitos_Settings.reducerFunction,
	CreateCircuito: bllCircuitos_Create.reducerFunction,
	Fechas: bllCircuitos_Fechas.reducerFunction,
})

export interface ICircuitos {
	Profile: bllCircuitos_Profile.IInterface
	Settings: bllCircuitos_Settings.IInterface
	CreateCircuito: bllCircuitos_Create.IInterface
	Fechas: bllCircuitos_Fechas.IInterface
}
