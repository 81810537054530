import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Personas_GetMainPersonaByUserId } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetMainPersonaByUserId"
import { apiCallIG_Rodadas_ActualizarGeneralFields } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_ActualizarGeneralFields"
import { apiCallIG_Rodadas_ActualizarProfileFields } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_ActualizarProfileFields"
import { apiCallIG_Rodadas_DeleteRodada } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_DeleteRodada"
import { apiCallIG_Rodadas_GetRodada } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_GetRodada"
import { apiCallIG_RodadasInvitations_DeleteById } from "../../api/calls/IG/RodadasInvitations_OLD/apiCallIG_RodadasInvitations_DeleteById"
import { apiCallIG_RodadasInvitations_GetRodadasInvitationsByIdRodada } from "../../api/calls/IG/RodadasInvitations_OLD/apiCallIG_RodadasInvitations_GetRodadasInvitationsByIdRodada"
import { apiCallIG_RodadasInvitations_InvitarEmail } from "../../api/calls/IG/RodadasInvitations_OLD/apiCallIG_RodadasInvitations_InvitarEmail"
import { apiCallIG_RodadasInvitations_InvitarPhoneNumber } from "../../api/calls/IG/RodadasInvitations_OLD/apiCallIG_RodadasInvitations_InvitarPhoneNumber"
import { apiCallIG_RodadasPersonas_ConfirmarPersona } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_ConfirmarPersona"
import { apiCallIG_RodadasPersonas_GetRodadasPersonasDeleteById } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_DeleteById"
import { apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada"
import { apiCallIG_RodadasPersonas_InvitarEmail } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_InvitarEmail"
import { apiCallIG_RodadasPersonas_InvitarPhoneNumber } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_InvitarPhoneNumber"
import { apiCallIS_User_GetUserIdByEmail } from "../../api/calls/IS/apiCallIS_User_GetUserIdByEmail"
import { apiCallIS_User_GetUserIdByPhoneNumber } from "../../api/calls/IS/apiCallIS_User_GetUserIdByPhoneNumber"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { sharedRodadaInvitation } from "../../api/shared/sharedRodadaInvitation"
import { sharedRodadaPersona } from "../../api/shared/sharedRodadaPersona"
import { utilRedux } from "../../utils/utilRedux"
import { bllDispatchs_GlobalsServer } from "../bllDispatchs_GlobalsServer"
import { bllGlobalsGeneralData_AllRodadas } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllRodadas"
import { bllRodadas_Settings } from "./bllRodadas_Settings"

export namespace bllRodadas_Settings_InviteByPhoneNumber {
	//ET = enumTypes
	enum ET {
		setInitialState = "Rodadas_Settings_InviteByEmail_setInitialState",
		getUserIdByPhoneNumber = "Rodadas_Settings_InviteByEmail_getUserIdByPhoneNumber",
		getPersonaByUserId = "Rodadas_Settings_InviteByEmail_getPersonaByUserId",
		invitarPhoneNumber = "Rodadas_Settings_InviteByEmail_invitarPhoneNumber",
	}

	export interface IInterface {
		userIdByPhoneNumber: string | null
		personaByUserId: sharedPersona.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			userIdByPhoneNumber: null,
			personaByUserId: sharedPersona.objectDefault,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Rodada_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getUserIdByPhoneNumber(
		apiCallParams: apiCallInterface,
		params: apiCallIS_User_GetUserIdByPhoneNumber.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIS_User_GetUserIdByPhoneNumber.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "userIdByPhoneNumber">

				const updateObject: actionSubType = {
					userIdByPhoneNumber: apiCallRes.userId,
				}

				utilRedux.TA(D, ET.getUserIdByPhoneNumber, updateObject)

				D(getPersonaByUserId(apiCallParams, { userId: apiCallRes.userId }))
			}
		}
	}

	export function getPersonaByUserId(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_GetMainPersonaByUserId.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Personas_GetMainPersonaByUserId.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "personaByUserId">

				const updateObject: actionSubType = {
					personaByUserId: apiCallRes.sharedPersona,
				}

				utilRedux.TA(D, ET.getPersonaByUserId, updateObject)
			}
		}
	}

	export function invitarPhoneNumber(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_InvitarPhoneNumber.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasPersonas_InvitarPhoneNumber.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await bllDispatchs_GlobalsServer.setMessage(apiCallParams.key, "Invitación realizada correctamente.")

				D(bllRodadas_Settings.updateRodadasPersonas(apiCallParams, apiCallRes.sharedRodadasPersonas))

				utilRedux.TA(D, ET.invitarPhoneNumber, {})
			}
		}
	}
}
