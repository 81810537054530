/* eslint-disable react/jsx-pascal-case*/
import { Drawer, Stack, styled } from "@mui/material"
import Box from "@mui/material/Box"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import { bllGlobalsUI_LeftMenu } from "../../bll/GlobalsUI/bllGlobalsUI_LeftMenu"
import { styleConfig } from "../../Style/styleConfig"
import { SharedLogo } from "../Shared/SharedLogo"
import SharedBasicScrollbar from "../Shared/Basic/SharedBasicScrollbar"
import { Layout_LeftMenu_Account } from "./Layout_LeftMenu_Account"
import Layout_LeftMenu_CollapseButton from "./Layout_LeftMenu_CollapseButton"
import { Layout_LeftMenu_LoginRegistrarse } from "./Layout_LeftMenu_LoginRegistrarse"
import { Layout_LeftMenu_Navbar } from "./Layout_LeftMenu_Navbar"

const LeftSideStyle = styled("div")(({ theme }) => ({
	// borderRight: `1px dashed ${theme.palette.grey[500_32]}`,
}))

export const Layout_LeftMenu = () => {
	const D = useDispatch()

	const isLogged = useSelector((state: IState) => state.Users.Login.isLogged)
	const leftMenuOpen = useSelector((state: IState) => state.GlobalsUI.LeftMenu.open)

	const onToggleCollapse = () => {}

	const collapseClick = () => {
		D(bllGlobalsUI_LeftMenu.close())
	}

	// if (!leftMenuOpen) {
	// 	return null
	// }

	return (
		<Drawer
			open={true}
			variant="persistent"
			// onClose={onCloseSidebar}
			PaperProps={{
				sx: {
					width: styleConfig.leftMenuWidth,
					borderRightStyle: "dashed",
					bgcolor: "background.default",
					transition: (theme) =>
						theme.transitions.create(["width", "opacity"], {
							duration: theme.transitions.duration.standard,
						}),
					...(!leftMenuOpen && {
						width: 0,
						opacity: 0,
					}),
				},
			}}
		>
			<LeftSideStyle>
				<SharedBasicScrollbar
					sx={{
						height: 1,
						"& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
					}}
				>
					<Box>
						<Stack
							spacing={3}
							sx={{
								pb: 2,
								px: 2.5,
								flexShrink: 0,
								// ...(isCollapse && { alignItems: 'center' }),
							}}
						>
							<Stack direction="row" alignItems="center" justifyContent="space-between">
								<SharedLogo />

								<Layout_LeftMenu_CollapseButton onToggleCollapse={false} collapseClick={collapseClick} />
							</Stack>

							{isLogged && <Layout_LeftMenu_Account />}

							{!isLogged && <Layout_LeftMenu_LoginRegistrarse />}

							<Layout_LeftMenu_Navbar />
						</Stack>
					</Box>
				</SharedBasicScrollbar>
			</LeftSideStyle>
		</Drawer>
	)
}
