import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { utilFormatValidations } from "../../../../utils/utilFormatValidations"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedLoginRes } from "../../../shared/sharedLoginRes"

export namespace apiCallIG_Registrarse_UserCreationInvitationAcepted {
	export interface params {
		email: string
		token: string
		newPassword: string
		// recaptchaToken: recaptchaToken, //TODO: poner recaptchaToken
	}
	export const paramsName = {
		email: "email",
		token: "token",
		newPassword: "newPassword",
		recaptchaToken: "recaptchaToken",
	}
	export const paramsDesc = {
		email: "Dirección de email",
		newPassword: "Nueva contraseña",
	}

	export interface IRes {
		sharedLoginRes: sharedLoginRes.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		if (params.email.trim() === "" || params.token.trim() === "" || !utilFormatValidations.isEmail(params.email)) {
			bllDispatchs_GlobalsServer.setErrorMessage(
				apiCallParams.key,
				"La información que obtuvimos del link que te enviamos es inválida. Por favor, vuelve a intentar resetear la contraseña o ponte en contacto con nosotros."
			)

			return true
		}

		//password
		apiValidations2.validateMaxNumberOfChars(
			paramsName.newPassword,
			paramsDesc.newPassword,
			params.newPassword,
			100,
			serverModelErrors
		)

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Registrarse/UserCreationInvitationAcepted`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedLoginRes: sharedLoginRes.getSharedOne_FromApiObject(apiCallRes),
		}
	}
}
