/* eslint-disable react/jsx-pascal-case*/
import { Card, CardHeader, Container } from "@mui/material"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import RodadasShared_Table from "./RodadasShared_Table"
import RodadasShared_CardList from "./RodadasShared_CardList"

interface IProps {}

export default function RodadasShared_AllRodadas({}: IProps) {
	const rodadas = useSelector((state: IState) => state.GlobalsGeneralData.AllRodadas.rodadas)

	return (
		<Container sx={{ position: "relative" }}>
			<Card>
				<CardHeader title="Rodadas" sx={{ mb: 3 }} />

				<RodadasShared_CardList rodadas={rodadas} />
			</Card>
		</Container>
	)
}
