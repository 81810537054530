/* eslint-disable react/jsx-pascal-case*/
import EscuelasLanding from "../../Escuelas/Landing/EscuelasLanding"
import { Layout } from "../../Layout/Layout"
import SaludonnetLanding from "../../Saludonnet/Landing/SaludonnetLanding"
import { SharedBasicPage } from "../../Shared/Basic/SharedBasicPage"

export default function PageSaludonnetLanding() {
	return (
		<Layout>
			<SharedBasicPage title="Seguros" dniMustBeVerified={true}>
				<SaludonnetLanding />
			</SharedBasicPage>
		</Layout>
	)
}
