/* eslint-disable react/jsx-pascal-case*/
import { Container } from "@mui/material"
import { useState } from "react"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import useGeneral from "../../../hooksData/useGeneral"
import useSntCentrosUrgencias from "../../../hooksData/useSntCentrosUrgencias"
import SharedBasicTypo from "../../Shared/Basic/SharedBasicTypo"
import SharedGoogleMap2 from "../../Shared/SharedGoogleMap2"
import SharedGoogleMapMarker2 from "../../Shared/SharedGoogleMapMarker2"
import { SharedLinkInsideAppWrapper } from "../../Shared/SharedLinkInsideAppWrapper"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import { Button } from "@mui/material"

export default function CircuitosLanding_Map() {
	const circuitos = useSelector((state: IState) => state.GlobalsGeneralData.AllCircuitos.circuitos)
	const latitud = useSelector((state: IState) => state.GlobalsUser.General.latitud)
	const longitud = useSelector((state: IState) => state.GlobalsUser.General.longitud)

	const [selectedMarker, set_selectedMarker] = useState<string | number | null>(null)

	return (
		<SharedGoogleMap2 centerLat={latitud} centerLng={longitud} zoom={10}>
			<>
				{circuitos.map(
					(circuito, i) =>
						circuito.direccion &&
						circuito.direccion.lat &&
						circuito.direccion.lng &&
						!isNaN(Number(circuito.direccion.lat.toString())) &&
						!isNaN(Number(circuito.direccion.lng.toString())) && (
							<SharedGoogleMapMarker2
								key={i}
								id={circuito.id}
								centerLat={Number(circuito.direccion.lat)}
								centerLng={Number(circuito.direccion.lng)}
								showInfoWindow={i === 1}
								selectMarker={set_selectedMarker}
								selectedMarker={selectedMarker}
								infoWindowContent={
									<Container>
										<SharedBasicTypo variant="h6">{circuito.nombre}</SharedBasicTypo>
										<SharedBasicTypo variant="body2">{circuito.direccion.direccion}</SharedBasicTypo>
										<SharedLinkInsideAppWrapper
											urlDest={globalPathsApp.pageInsideUrl.CircuitosProfile(undefined, circuito.id)}
										>
											<Button variant="text" sx={{ ml: "-7px" }}>
												Ir al circuito
											</Button>
										</SharedLinkInsideAppWrapper>
										{/* <SharedBasicTypo variant="body2">Teléfono: {centroUrgencia.telefono}</SharedBasicTypo> */}
									</Container>
								}
							/>
						)
				)}
			</>
		</SharedGoogleMap2>
	)
}
