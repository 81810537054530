/* eslint-disable react/jsx-pascal-case*/
import { Box, Drawer, IconButton, IconButtonProps, InputAdornment, Stack, styled } from "@mui/material"
import { useEffect, useState } from "react"
import useResponsive from "../../hooks/useResponsive"
import useRouting from "../../hooks/useRouting"
import useGeneral from "../../hooksData/useGeneral"
import { SharedBasicCustomTextField } from "../Shared/Basic/SharedBasicCustomTextField"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import SharedBasicScrollbar from "../Shared/Basic/SharedBasicScrollbar"
import Chat_Rooms_Account from "./Chat_Rooms_Account"
import Chat_Rooms_List from "./Chat_Rooms_List"
import { sharedChatType } from "../../api/shared/sharedChatType"
import useGlobalsUser from "../../hooksData/useGlobalsUser"

const StyledToggleButton = styled((props) => <IconButton disableRipple {...props} />)<IconButtonProps>(({ theme }) => ({
	left: 0,
	zIndex: 9,
	width: 32,
	height: 32,
	position: "absolute",
	top: theme.spacing(13),
	borderRadius: `0 12px 12px 0`,
	color: theme.palette.primary.contrastText,
	backgroundColor: theme.palette.primary.main,
	boxShadow: theme.customShadows.primary,
	"&:hover": {
		backgroundColor: theme.palette.primary.darker,
	},
}))

const NAV_WIDTH = 320

const NAV_COLLAPSE_WIDTH = 96

export interface IProps {
	chatType: sharedChatType.IShared
	hideRoomType?: boolean
}

export default function Chat_Rooms({ chatType, hideRoomType }: IProps) {
	const ug = useGeneral("Chat_Rooms")
	const { currentPersona } = useGlobalsUser()
	const { isDesktop } = useResponsive()
	const { nombre_FromUrl, nombre_Set } = useRouting()

	const [openNav, setOpenNav] = useState(false)

	const isCollapse = isDesktop && !openNav

	useEffect(() => {
		if (!isDesktop) {
			handleCloseNav()
		} else {
			handleOpenNav()
		}
	}, [isDesktop])

	const handleToggleNav = () => {
		setOpenNav(!openNav)
	}

	const handleOpenNav = () => {
		setOpenNav(true)
	}

	const handleCloseNav = () => {
		setOpenNav(false)
	}

	const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
		try {
			const { value } = event.target

			// setSearchContacts(value);

			// if (value) {
			//   const response = await axios.get('/api/chat/search', {
			//     params: { query: value },
			//   });

			//   setSearchResults(response.data.results);
			// } else {
			//   setSearchResults([]);
			// }
		} catch (error) {
			console.error(error)
		}
	}

	const handleSelectContact = (/*result: IChatContact*/) => {
		// setSearchContacts('');
		// navigate(PATH_DASHBOARD.chat.view(result.username));
	}

	const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		nombre_Set(event.target.value)
	}

	const renderContent = (
		<>
			<Box sx={{ p: 2.5 }}>
				<Stack direction="row" alignItems="center" justifyContent="center">
					{!isCollapse && (
						<>
							<Chat_Rooms_Account />
							<Box sx={{ flexGrow: 1 }} />
						</>
					)}

					<IconButton onClick={handleToggleNav}>
						<SharedBasicIconify iconifyIcon={openNav ? "eva:arrow-ios-back-fill" : "eva:arrow-ios-forward-fill"} />
					</IconButton>
				</Stack>

				<SharedBasicCustomTextField
					fullWidth
					size="small"
					value={nombre_FromUrl || ""}
					onChange={onChangeName}
					placeholder={"Filtrar"}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SharedBasicIconify iconifyIcon="eva:search-fill" sx={{ color: ug.theme.palette.text.disabled }} />
							</InputAdornment>
						),
					}}
					sx={{ mt: 2.5 }}
				/>

				{/* {!isCollapse && (
					<ChatNavSearch
						value={searchContacts}
						onChange={handleChangeSearch}
						onClickAway={() => setSearchContacts("")}
					/>
				)} */}
			</Box>

			<SharedBasicScrollbar>
				<Chat_Rooms_List
					openNav={openNav}
					onCloseNav={handleCloseNav}
					chatType={chatType}
					hideRoomType={hideRoomType}
				/>
			</SharedBasicScrollbar>
		</>
	)

	return (
		<>
			{!isDesktop && (
				<StyledToggleButton onClick={handleToggleNav}>
					<SharedBasicIconify width={16} iconifyIcon="eva:people-fill" />
				</StyledToggleButton>
			)}

			{isDesktop ? (
				<Drawer
					open={openNav}
					variant="persistent"
					PaperProps={{
						sx: {
							pb: 1,
							width: 1,
							position: "static",
							...(isCollapse && {
								transform: "none !important",
								visibility: "visible !important",
							}),
						},
					}}
					sx={{
						width: NAV_WIDTH,
						transition: ug.theme.transitions.create("width"),
						...(isCollapse && {
							width: NAV_COLLAPSE_WIDTH,
						}),
					}}
				>
					{renderContent}
				</Drawer>
			) : (
				<Drawer
					open={openNav}
					onClose={handleCloseNav}
					ModalProps={{ keepMounted: true }}
					PaperProps={{
						sx: {
							pb: 1,
							width: NAV_WIDTH,
						},
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</>
	)
}
