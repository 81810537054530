import { combineReducers } from "redux"
import { bllRodadas_Create } from "./bllRodadas_Create"
import { bllRodadas_Profile } from "./bllRodadas_Profile"
import { bllRodadas_Settings } from "./bllRodadas_Settings"
import { bllRodadas_Settings_InviteByEmail } from "./bllRodadas_Settings_InviteByEmail"
import { bllRodadas_Settings_InviteByPhoneNumber } from "./bllRodadas_Settings_InviteByPhoneNumber"
import { bllRodadas_ConfirmEmailInvitation } from "./bllRodadas_ConfirmEmailInvitation"
import { bllRodadas_ConfirmPhoneNumberInvitation } from "./bllRodadas_ConfirmPhoneNumberInvitation"

export default combineReducers({
	CreateRodada: bllRodadas_Create.reducerFunction,
	Profile: bllRodadas_Profile.reducerFunction,
	Settings: bllRodadas_Settings.reducerFunction,
	Settings_InviteByEmail: bllRodadas_Settings_InviteByEmail.reducerFunction,
	Settings_InviteByPhoneNumber: bllRodadas_Settings_InviteByPhoneNumber.reducerFunction,
	ConfirmEmailInvitation: bllRodadas_ConfirmEmailInvitation.reducerFunction,
	ConfirmPhoneNumberInvitation: bllRodadas_ConfirmPhoneNumberInvitation.reducerFunction,
})

export interface IRodadas {
	CreateRodada: bllRodadas_Create.IInterface
	Profile: bllRodadas_Profile.IInterface
	Settings: bllRodadas_Settings.IInterface
	Settings_InviteByEmail: bllRodadas_Settings_InviteByEmail.IInterface
	Settings_InviteByPhoneNumber: bllRodadas_Settings_InviteByPhoneNumber.IInterface
	ConfirmEmailInvitation: bllRodadas_ConfirmEmailInvitation.IInterface
	ConfirmPhoneNumberInvitation: bllRodadas_ConfirmPhoneNumberInvitation.IInterface
}
