import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { utilLogger } from "../../utils/utilLogger"
import store from "../../utils/utilReduxStore"
import { sharedDireccion } from "./sharedDireccion"

export namespace sharedAgrupacionVehiculo {
	export interface IShared {
		id: number
		nombre: string
	}

	export const objectDefault: IShared = {
		id: 0,
		nombre: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}

	export const listForRadioGroup = (sharedVehiculoList: IShared[]): globalInterfaces.IValueNumberLabelString[] => {
		return sharedVehiculoList.map((sharedVehiculo) => {
			return {
				value: sharedVehiculo.id,
				label: sharedVehiculo.nombre,
			}
		})
	}

	export const getShared_FromId = (sharedVehiculoList: IShared[], idVehiculo: number | null) => {
		if (!idVehiculo) return "No definido"

		if (sharedVehiculoList.length === 0) {
			utilLogger.error(
				`Error en getSharedAgrupacionVehiculoNombreFromId. Estoy tratando de obtener GlobalsGeneralData.AllVehiculos.vehiculos antes de que tenga información.`,
				null
			)
		}

		const sharedVehiculo = sharedVehiculoList.find((vehiculo) => vehiculo.id === idVehiculo)

		if (sharedVehiculo) return sharedVehiculo.nombre

		utilLogger.error(`Error en getSharedAgrupacionVehiculoNombreFromId. Vino un idVehiculo erroneo.`, idVehiculo)

		return "No definido"
	}

	export const sharedList_ForRadioGroup = (
		sharedVehiculoList: IShared[]
	): globalInterfaces.IValueNumberLabelString[] => {
		return sharedVehiculoList.map((sharedVehiculo) => {
			return {
				value: sharedVehiculo.id,
				label: sharedVehiculo.nombre,
			}
		})
	}
}
