/* eslint-disable react/jsx-pascal-case*/

import { Badge, Tooltip } from "@mui/material"
import { globalPathsApp } from "../../globals/globalPathsApp"
import useChat from "../../hooksData/useChat"
import useGeneral from "../../hooksData/useGeneral"
import SharedBasicButtonIconAnimated from "../Shared/Basic/SharedBasicButtonIconAnimated"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"

export const Layout_Header_Chat = () => {
	const ug = useGeneral("Layout_Header_Chat")
	const { chatsUnreadCount, sharedChatRooms } = useChat()

	const totalUnRead = chatsUnreadCount(sharedChatRooms)

	return (
		<>
			<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.Chat()}>
				<Tooltip title="Chat">
					<SharedBasicButtonIconAnimated sx={{ width: 40, height: 40 }}>
						<Badge badgeContent={totalUnRead} color="error">
							<SharedBasicIconify iconifyIcon="material-symbols:chat" width={20} height={20} />
						</Badge>
					</SharedBasicButtonIconAnimated>
				</Tooltip>
			</SharedLinkInsideAppWrapper>
		</>
	)
}
