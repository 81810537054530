/* eslint-disable react/jsx-pascal-case*/
import { Avatar, Box, IconButton, Stack, Tooltip } from "@mui/material"
import { globalPathsApp } from "../../globals/globalPathsApp"
import useChat, { IRoomInfo } from "../../hooksData/useChat"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"
import { utilLogger } from "../../utils/utilLogger"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import useGeneral from "../../hooksData/useGeneral"
import useWindowSize from "../../hooks/useWindowSize"
import useGlobalsUser from "../../hooksData/useGlobalsUser"
import useThemeOwn from "../../hooks/useThemeOwn"
import { sharedChatType } from "../../api/shared/sharedChatType"

interface IProps {
	connected: boolean
	hideRoomType?: boolean
}

export default function Chat_Room_Header({ connected, hideRoomType }: IProps) {
	const ug = useGeneral("Chat_Room_Header")
	const { currentPersona } = useGlobalsUser()

	const windowSize = useWindowSize()
	const { theme } = useThemeOwn()
	const { currentRoom_sharedChatRoom, getRoomInfo } = useChat()

	const handleConnectClick = () => {
		if (ug.userIsLogged) {
			location.reload()
		}
	}

	const handleClickDisconnect = () => {}

	const roomInfo: IRoomInfo | undefined = currentRoom_sharedChatRoom
		? getRoomInfo(currentRoom_sharedChatRoom, currentPersona.id)
		: undefined

	const idRoom = currentRoom_sharedChatRoom?.id

	return (
		<Stack
			direction="row"
			alignItems="center"
			sx={{
				p: (theme) => theme.spacing(2, 1, 2, 2),
			}}
		>
			<Stack flexGrow={1}>
				{roomInfo ? (
					<>
						{!hideRoomType && (
							<Box sx={{ mx: "auto" }}>
								{roomInfo.secondaryNameToShow && (
									<SharedLinkInsideAppWrapper urlDest={roomInfo.secondaryLink || ""}>
										<SharedBasicTypo
											variant="subtitle2"
											noWrap
											sx={{
												maxWidth: windowSize.width < 600 ? "260px" : "600px",
												overflow: "hidden",
												whiteSpace: "nowrap",
												color: theme.palette.primary.main,
												textOverflow: "ellipsis",
											}}
										>
											{roomInfo.secondaryNameToShow}
										</SharedBasicTypo>
									</SharedLinkInsideAppWrapper>
								)}
							</Box>
						)}

						<SharedLinkInsideAppWrapper urlDest={roomInfo.mainLink || ""}>
							<Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
								<Avatar alt={roomInfo.mainNameToShow} src={roomInfo.mainAvatarUrlToShow} />

								<div>
									<SharedBasicTypo variant="subtitle2">{roomInfo.mainNameToShow}</SharedBasicTypo>
								</div>
							</Stack>
						</SharedLinkInsideAppWrapper>
					</>
				) : (
					<Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
						<Avatar alt={"No person"} src={undefined} />

						<div>
							<SharedBasicTypo variant="subtitle2">{"-"}</SharedBasicTypo>
						</div>
					</Stack>
				)}
			</Stack>

			{connected ? (
				<Tooltip title={"Conectado"}>
					<IconButton onClick={handleClickDisconnect}>
						<SharedBasicIconify
							iconifyIcon="fluent:plug-connected-20-filled"
							fontSize="28px"
							color={theme.palette.success.dark}
						/>
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title={"Desconectado. Click aquí para conectarse nuevamente."}>
					<IconButton onClick={handleConnectClick}>
						<SharedBasicIconify
							iconifyIcon="fluent:plug-disconnected-20-filled"
							fontSize="28px"
							color={theme.palette.error.main}
						/>
					</IconButton>
				</Tooltip>
			)}
		</Stack>
	)
}
