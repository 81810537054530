import { utilRedux } from "../../utils/utilRedux"
import produce from "immer"

export namespace bllGlobalsUI_LeftMenu {
	//ET = enumTypes
	enum ET {
		open = "GlobalsUI_LeftMenu_open",
		close = "GlobalsUI_LeftMenu_close",
	}

	export interface IInterface {
		open: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			open: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Update_LeftMenuOpen:
			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.LeftMenuOpen = action.LeftMenuOpen
			// 	})
			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	LeftMenuOpen: action.LeftMenuOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function open() {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "open">
			const updateObject: actionSubType = {
				open: true,
			}

			utilRedux.TA(D, ET.open, updateObject)
		}
	}

	export function close() {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "open">
			const updateObject: actionSubType = {
				open: false,
			}

			utilRedux.TA(D, ET.close, updateObject)
		}
	}
}
