import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedRodada } from "../../../shared/sharedRodada"
import { sharedVehiculo } from "../../../shared/sharedVehiculo"
import { apiCallInterface } from "../../../apiCallInterface"
import { sharedSeguroPdf } from "../../../shared/sharedSeguroPdf"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { utilFunctions } from "../../../../utils/utilFunctions"
import { utilFunctionsFiles } from "../../../../utils/utilFunctionsFiles"

export namespace apiCallMP_Seguros_GetSegurosParaDni {
	export interface params {
		dni: number
	}

	export const paramsName = {
		dni: "dni",
	}

	export const paramsDesc = {
		dni: "DNI",
	}

	export interface IRes {
		sharedSegurosPDF: sharedSeguroPdf.IShared[]
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		//Solo para pruebas
		//return apiCallRes with this object: https://pruebamp.s3.eu-west-3.amazonaws.com/estaticas/pilotap.pdf y ese mismo objeto en base64
		return {
			sharedSegurosPDF: [
				{
					id: 1,
					url: "https://pruebamp.s3.eu-west-3.amazonaws.com/estaticas/pilotap.pdf",
					base64Pdf: await utilFunctionsFiles.getBase64FromPdf(
						"https://pruebamp.s3.eu-west-3.amazonaws.com/estaticas/pilotap.pdf"
					),
				},
				{
					id: 2,
					url: "https://pruebamp.s3.eu-west-3.amazonaws.com/estaticas/pilotap.pdf",
					base64Pdf: await utilFunctionsFiles.getBase64FromPdf(
						"https://pruebamp.s3.eu-west-3.amazonaws.com/estaticas/pilotap.pdf"
					),
				},
			],
		}

		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseMotopoliza}/Seguros/GetSegurosParaDni`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedSegurosPDF: sharedSeguroPdf.getSharedList_FromApiObject(apiCallRes) }
	}
}
