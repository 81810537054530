import { utilDate } from "../../utils/utilDate"

export namespace sharedRodada {
	export interface IShared {
		id: number
		idPersonaCreadora: number
		idCircuito: number
		nombre: string
		disclaimerHtml: string
		descripcionCorta: string
		descripcionLarga: string
		fechaDesde: Date | null
		fechaHasta: Date | null
		cantidadInscriptosSinConfirmar: number
		cantidadInscriptosConfirmados: number
		cantidadSeguidores: number
		idVehiculo: number | null
	}

	export const objectDefault: IShared = {
		id: 0,
		idPersonaCreadora: 0,
		idCircuito: 0,
		disclaimerHtml: "",
		nombre: "",
		descripcionCorta: "",
		descripcionLarga: "",
		fechaDesde: null,
		fechaHasta: null,
		cantidadInscriptosSinConfirmar: 0,
		cantidadInscriptosConfirmados: 0,
		cantidadSeguidores: 0,
		idVehiculo: null,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
			fechaDesde: utilDate.fDateFromNet(apiObject.fechaDesde),
			fechaHasta: utilDate.fDateFromNet(apiObject.fechaHasta),
			cantidadSeguidores: apiObject.cantidadSeguidores || 0,
		}

		return res
	}
}
