import { globalInterfaces } from "../../globals/globalInterfaces"
import { utilLogger } from "../../utils/utilLogger"

export namespace sharedCircuitoEstado {
	export interface IShared {
		id: number
		descripcion: string
	}

	export const sharedEnum = {
		creado: { id: 1, descripcion: "Creado" },
		aprobado: { id: 2, descripcion: "Aprobado" },
		bloqueado: { id: 3, descripcion: "Bloqueado" },
	}

	export const sharedList: IShared[] = [sharedEnum.creado, sharedEnum.aprobado, sharedEnum.bloqueado]

	export const getShared_Desc_FromId = (idCircuitoEstado: number | null) => {
		if (!idCircuitoEstado) return "No definido"

		const sharedCircuitoEstado = sharedList.find((circuitoEstado) => circuitoEstado.id === idCircuitoEstado)

		if (sharedCircuitoEstado) return sharedCircuitoEstado.descripcion

		utilLogger.error(`Error en getSharedCircuitoEstadoDescFromId. Vino un idCircuitoEstado erroneo.`, idCircuitoEstado)
	}

	export const listForRadioGroup = (): globalInterfaces.IValueNumberLabelString[] => {
		return sharedList.map((circuitoEstado) => {
			return {
				value: circuitoEstado.id,
				label: circuitoEstado.descripcion,
			}
		})
	}
}
