/* eslint-disable react/jsx-pascal-case*/

import { Avatar, AvatarProps, SxProps } from "@mui/material"
import { Theme } from "@mui/material/styles"

interface IProps {
	src?: string
	alt?: string
	avatarSx?: SxProps<Theme>
}

//TODO: Si viene src y/o alt undefined, mostrar algo genérico

export const SharedBasicAvatarImg = ({ src, alt, avatarSx }: IProps) => {
	return (
		<Avatar sx={{ bgcolor: "background.neutral", ...avatarSx }}>
			<img alt={alt} src={src} />
		</Avatar>
	)
}
