/* eslint-disable react/jsx-pascal-case*/

import { useEffect } from "react"
import { useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import { bllGlobalsGeneralData_AllCircuitos } from "../../bll/GlobalsGeneralData/bllGlobalsGeneralData_AllCircuitos"
import { bllGlobalsGeneralData_AllRodadas } from "../../bll/GlobalsGeneralData/bllGlobalsGeneralData_AllRodadas"
import { bllGlobalsGeneralData_AllVehiculos } from "../../bll/GlobalsGeneralData/bllGlobalsGeneralData_AllVehiculos"
import { bllGlobalsGeneralData_InitialLoad } from "../../bll/GlobalsGeneralData/bllGlobalsGeneralData_InitialLoad"
import { bllGlobalsUI_FullScreenLoading } from "../../bll/GlobalsUI/bllGlobalsUI_FullScreenLoading"
import { bllGlobalsUI_LeftMenu } from "../../bll/GlobalsUI/bllGlobalsUI_LeftMenu"
import { bllGlobalsUI_Screen } from "../../bll/GlobalsUI/bllGlobalsUI_Screen"
import { bllGlobalsUser_General } from "../../bll/GlobalsUser/bllGlobalsUser_General"
import { bllGlobalsUser_Personas } from "../../bll/GlobalsUser/bllGlobalsUser_Personas"
import { bllNotificaciones_All } from "../../bll/Notificaciones/bllNotificaciones_All"
import { bllUsers_Login } from "../../bll/Users/bllUsers_Login"
import useResponsive from "../../hooks/useResponsive"
import useGeneral from "../../hooksData/useGeneral"
import { utilLogger } from "../../utils/utilLogger"
import { App_RootWrapper_Router } from "./App_RootWrapper_Router"
import { utilWebtonative } from "../../utils/utilWebtonative"
import { bllNotificaciones_Suscripciones } from "../../bll/Notificaciones/bllNotificaciones_Suscripciones"

export const initialCallKey = "initialCallKey"

export const App_RootWrapper = () => {
	const { D, userIsLogged, userToken, apiCallParams } = useGeneral(initialCallKey)
	const { screen_up_lg } = useResponsive()

	const storageSharedLoginResChecked = useSelector((state: IState) => state.Users.Login.storageSharedLoginResChecked)

	const userPersonasLoaded = useSelector((state: IState) => state.GlobalsUser.Personas.loaded)
	const userPersonaCircuitosLoaded = useSelector((state: IState) => state.GlobalsUser.CurrentPersonaCircuitos.loaded)
	const userPersonaRodadasLoaded = useSelector((state: IState) => state.GlobalsUser.CurrentPersonaRodadas.loaded)

	const initialCallLoaded = useSelector((state: IState) => state.GlobalsGeneralData.InitialCall.loaded)

	//Loadings iniciales para el usuario
	useEffect(() => {
		if (storageSharedLoginResChecked) {
			if (userIsLogged) {
				//Loadings iniciales para el usuario logueado
				D(bllGlobalsUser_Personas.loadPersonasDeUsuarioLogueado(apiCallParams))
				D(bllNotificaciones_All.loadLast20(apiCallParams, {}))

				utilWebtonative.getPlayerId2().then(function (playerId: any) {
					utilLogger.two(playerId, "playerId")
					if (playerId) {
						D(
							bllNotificaciones_Suscripciones.AddOrupdateUserMedioDeContacto_DeviceSuscriptionId(apiCallParams, {
								deviceSuscriptionId: playerId,
							})
						)
					}
				})
			}
		} else {
			D(bllUsers_Login.loginFromStorageIfItHasLoginRes(apiCallParams))
		}
	}, [storageSharedLoginResChecked, userIsLogged])

	//Loadings iniciales
	useEffect(() => {
		D(bllGlobalsUser_General.getClientInfo())
		D(bllGlobalsGeneralData_InitialLoad.initialCall(apiCallParams))
	}, [])

	//Stop loading
	useEffect(() => {
		const generalDataLoaded = initialCallLoaded
		const userDataLoaded =
			!userIsLogged || (userIsLogged && userPersonasLoaded && userPersonaCircuitosLoaded && userPersonaRodadasLoaded)

		if (generalDataLoaded && userDataLoaded) {
			D(bllGlobalsUI_FullScreenLoading.stop())
		}
	}, [userIsLogged, userPersonasLoaded, userPersonaCircuitosLoaded, userPersonaRodadasLoaded, initialCallLoaded])

	//Mobile/Desktop
	useEffect(() => {
		const tempIsDesktop = screen_up_lg || false

		D(bllGlobalsUI_Screen.setIsDesktop(tempIsDesktop))

		if (tempIsDesktop) {
			D(bllGlobalsUI_LeftMenu.open())
		} else {
			D(bllGlobalsUI_LeftMenu.close())
		}
	}, [screen_up_lg])

	//User login from storage
	// useEffect(() => {
	// 	if (!userIsLogged) {
	// 		D(bllUsers_Login.loginFromStorageIfItHasLoginRes({ key, userToken }))
	// 	}
	// }, [userIsLogged])

	return <App_RootWrapper_Router />
}
