import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedFederacion } from "../../../shared/sharedFederacion"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiValidations2 } from "../../../apiValidations2"

export namespace apiCallIG_Federaciones_EnviarNotificacionASeguidores {
	export interface params {
		idFederacion: number
		// asunto: string | null
		mensaje: string | null
	}

	export const paramsName = {
		idFederacion: "idFederacion",
		// asunto: "asunto",
		mensaje: "mensaje",
	}

	export const paramsDesc = {
		idFederacion: "Id Federacion",
		// asunto: "Asunto",
		mensaje: "Mensaje",
	}

	export interface IRes {}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		// if (params.asunto) {
		// 	apiValidations2.validateMaxNumberOfChars(
		// 		paramsName.asunto,
		// 		paramsDesc.asunto,
		// 		params.asunto,
		// 		250,
		// 		serverModelErrors
		// 	)
		// }

		//mensaje
		apiValidations2.validateRequired(paramsName.mensaje, paramsDesc.mensaje, params.mensaje, serverModelErrors)

		if (params.mensaje) {
			apiValidations2.validateMaxNumberOfChars(
				paramsName.mensaje,
				paramsDesc.mensaje,
				params.mensaje,
				250,
				serverModelErrors
			)
		}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Federaciones/EnviarNotificacionASeguidores`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {}
	}
}
