/* eslint-disable react/jsx-pascal-case*/
import { Box, Container } from "@mui/material"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import SharedBasicTabs, { ISharedTabs_Tab } from "../../Shared/Basic/SharedBasicTabs"
import CircuitosShared_AllCircuitos from "../Shared/CircuitosShared_AllCircuitos"
import CircuitosShared_CircuitosSeguidos from "../Shared/CircuitosShared_CircuitosSeguidos"
import CircuitosShared_MisCircuitos from "../Shared/CircuitosShared_MisCircuitos"
import useGlobalsUserRoles from "../../../hooksData/useGlobalsUserRoles"
import CircuitosLanding_Map from "./CircuitosLanding_Map"

export const CircuitosLanding_Menu = (): ISharedTabs_Tab[] => {
	const { isUserCurrentRol_OrganizadorDeCircuito } = useGlobalsUserRoles()

	var menu: ISharedTabs_Tab[] = []

	menu.push({
		name: globalPathsApp.tabsCircuitosEnum.all,
		label: "Todos los circuitos",
		component: <CircuitosShared_AllCircuitos />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Circuitos(globalPathsApp.tabsCircuitosEnum.all),
	})

	menu.push({
		name: globalPathsApp.tabsCircuitosEnum.map,
		label: "Mapa",
		component: <CircuitosLanding_Map />,
		iconifyIcon: "carbon:map",
		urlDest: globalPathsApp.pageInsideUrl.Circuitos(globalPathsApp.tabsCircuitosEnum.map),
	})

	if (isUserCurrentRol_OrganizadorDeCircuito) {
		menu.push({
			name: globalPathsApp.tabsCircuitosEnum.own,
			label: "Mis circuitos",
			component: <CircuitosShared_MisCircuitos />,
			iconifyIcon: "maki:racetrack",
			urlDest: globalPathsApp.pageInsideUrl.Circuitos(globalPathsApp.tabsCircuitosEnum.own),
		})
	}

	menu.push({
		name: globalPathsApp.tabsCircuitosEnum.following,
		label: "Circuitos seguidos",
		component: <CircuitosShared_CircuitosSeguidos />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Circuitos(globalPathsApp.tabsCircuitosEnum.following),
	})

	return menu
}
