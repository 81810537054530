import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedFederacion } from "../../api/shared/sharedFederacion"
import { apiCallIG_Federaciones_GetFederacion } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_GetFederacion"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { apiCallIG_Federaciones_BloquearFederacion } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_BloquearFederacion"
import { apiCallIG_Federaciones_DesbloquearFederacion } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_DesbloquearFederacion"
import { apiCallIG_Federaciones_AprobarFederacion } from "../../api/calls/IG/Federaciones/apiCallIG_Federaciones_AprobarFederacion"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_FederacionesSeguidores_SeguirFederacion } from "../../api/calls/IG/FederacionesSeguidores/apiCallIG_FederacionesSeguidores_SeguirFederacion"
import { bllGlobalsUser_CurrentPersonaFederacionesSeguidos } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaFederacionesSeguidos"
import { apiCallIG_FederacionesSeguidores_DejarDeSeguirFederacion } from "../../api/calls/IG/FederacionesSeguidores/apiCallIG_FederacionesSeguidores_DejarDeSeguirFederacion"

export namespace bllFederaciones_Profile {
	//ET = enumTypes
	enum ET {
		setInitialState = "Federacion_Profile_setInitialState",
		loadFederacion = "Federacion_Profile_loadFederacion",
		updateWithSharedFederacion = "Federaciones_Profile_updateWithSharedFederacion",

		seguirFederacion = "Federacion_Profile_seguirFederacion",
		dejarDeSeguirFederacion = "Federacion_Profile_dejarDeSeguirFederacion",
	}

	export interface IInterface {
		federacion: sharedFederacion.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			federacion: sharedFederacion.defaultObject,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getFederacion(apiCallParams: apiCallInterface, params: apiCallIG_Federaciones_GetFederacion.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Federaciones_GetFederacion.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "federacion">
				const updateObject: actionSubType = {
					federacion: apiCallRes.sharedFederacion,
				}

				utilRedux.TA(D, ET.loadFederacion, updateObject)
			}
		}
	}

	export function updateFederacionWithSharedFederacion(
		apiCallParams: apiCallInterface,
		sharedFederacion: sharedFederacion.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "federacion">
			const updateObject: actionSubType = {
				federacion: sharedFederacion,
			}

			utilRedux.TA(D, ET.updateWithSharedFederacion, updateObject)
		}
	}

	export function seguirFederacion(
		apiCallParams: apiCallInterface,
		params: apiCallIG_FederacionesSeguidores_SeguirFederacion.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_FederacionesSeguidores_SeguirFederacion.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(
					bllGlobalsUser_CurrentPersonaFederacionesSeguidos.setPersonaFederacionesSeguidos(
						apiCallRes.sharedFederacionesSeguidos
					)
				)

				type actionSubType = Pick<IInterface, "federacion">
				const updateObject: actionSubType = {
					federacion: apiCallRes.sharedFederacion,
				}

				utilRedux.TA(D, ET.seguirFederacion, updateObject)
			}
		}
	}

	export function dejarDeSeguirFederacion(
		apiCallParams: apiCallInterface,
		params: apiCallIG_FederacionesSeguidores_DejarDeSeguirFederacion.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_FederacionesSeguidores_DejarDeSeguirFederacion.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(
					bllGlobalsUser_CurrentPersonaFederacionesSeguidos.setPersonaFederacionesSeguidos(
						apiCallRes.sharedFederacionesSeguidos
					)
				)

				type actionSubType = Pick<IInterface, "federacion">
				const updateObject: actionSubType = {
					federacion: apiCallRes.sharedFederacion,
				}

				utilRedux.TA(D, ET.dejarDeSeguirFederacion, updateObject)
			}
		}
	}
}
