/* eslint-disable react/jsx-pascal-case*/
import { Stack, styled, Toolbar } from "@mui/material"
import Box from "@mui/material/Box"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import { bllGlobalsUI_LeftMenu } from "../../bll/GlobalsUI/bllGlobalsUI_LeftMenu"
import useGlobalsUser from "../../hooksData/useGlobalsUser"
import AnimateIconButton from "../Animate/AnimateIconButton"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import { LayoutAccountHeaderDropdown } from "./LayoutAccountHeaderDropdown"
import { Layout_Header_LoginRegistrarse } from "./Layout_Header_LoginRegistrarse"
import { Layout_Header_Notifications } from "./Layout_Header_Notifications"
import Layout_Header_Searchbar from "./Layout_Header_Searchbar"
import { Layout_Header_Chat } from "./Layout_Header_Chat"

const RootStyle = styled("div")(({ theme }) => ({}))

export const Layout_Header = () => {
	const D = useDispatch()

	const { userIsLogged } = useGlobalsUser()
	const leftMenuOpen = useSelector((state: IState) => state.GlobalsUI.LeftMenu.open)

	const handleOpenLeftMenu = () => {
		D(bllGlobalsUI_LeftMenu.open())
	}

	return (
		<>
			<RootStyle>
				<Toolbar>
					<Stack direction="row" spacing={1} sx={{ width: "100%" }}>
						{!leftMenuOpen && (
							<AnimateIconButton onClick={handleOpenLeftMenu}>
								<SharedBasicIconify iconifyIcon={"eva:menu-2-fill"} width={20} height={20} />
							</AnimateIconButton>
						)}

						{/* <Layout_Header_Searchbar /> */}

						<Box sx={{ flexGrow: 1 }} />

						{userIsLogged && <Layout_Header_Chat />}

						{userIsLogged && <Layout_Header_Notifications />}

						{userIsLogged && <LayoutAccountHeaderDropdown />}

						{!userIsLogged && <Layout_Header_LoginRegistrarse />}
					</Stack>
				</Toolbar>
			</RootStyle>
		</>
	)
}
