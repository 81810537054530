import { utilLogger } from "../../utils/utilLogger"

export namespace sharedUserRoles {
	export interface IShared {
		id: number
		dbDescripcion: string
		descripcion: string
	}

	export const sharedEnum = {
		noDefinido: { id: 0, dbDescripcion: "NoDefinido", descripcion: "No definido" },
		admin: { id: 1, dbDescripcion: "Admin", descripcion: "Admin" },
		organizadorDeCircuito: { id: 2, dbDescripcion: "OrganizadorCircuito", descripcion: "Organizador de circuitos" },
		colaborador: { id: 4, dbDescripcion: "Colaborador", descripcion: "Colaborador" },
		piloto: { id: 5, dbDescripcion: "piloto", descripcion: "Piloto" },
	}

	export const sharedList: IShared[] = [
		sharedEnum.admin,
		sharedEnum.organizadorDeCircuito,
		sharedEnum.colaborador,
		sharedEnum.piloto,
	]

	// export const userHasRoleAdmin = (userRoles: IShared[]) => {
	// 	const t = userIsInRole(userRoles, sharedEnum.admin)
	// 	return t
	// }

	// export const userHasRoleSaludOnNet = (userRoles: IShared[]) => {
	// 	return userIsInRole(userRoles, sharedEnum.saludOnNet)
	// }

	// export const userHasRoleOrganizadorDeCircuito = (userRoles: IShared[]) => {
	// 	return userIsInRole(userRoles, sharedEnum.organizadorDeCircuito)
	// }

	// export const userHasRoleColaborador = (userRoles: IShared[]) => {
	// 	return userIsInRole(userRoles, sharedEnum.colaborador)
	// }

	export const userIsInRole = (userRoles: IShared[], roleAllowed: IShared) => {
		var r = false
		userRoles.forEach((userRole) => {
			if (userRole.id == roleAllowed.id) {
				r = true
			}
		})

		return r
	}

	export const userHasAtLeastOneRole = (userRoles: IShared[], rolesAllowed: IShared[]) => {
		var r = false

		rolesAllowed.forEach((roleAllowed) => {
			if (userIsInRole(userRoles, roleAllowed)) {
				r = true
			}
		})

		return r
	}

	export const getShared_FromDbDescription = (dbDescription: string) => {
		const sharedObj = sharedList.find((obj) => obj.dbDescripcion === dbDescription)

		if (sharedObj) return sharedObj

		utilLogger.error(
			`Error en sharedUserRoles.getShared_FromDbDescription. Vino un dbDescription inexistente.`,
			dbDescription
		)

		return sharedEnum.noDefinido
	}
}
