import { globalConfig } from "../globals/globalConfig"
import stc from "string-to-color"
import invert from "invert-color"

export namespace utilLogger {
	export const render = (text: string): void => {
		if (globalConfig.environment !== "development") return

		console.log(`%c  Rendering: ${text}  `, "background: #222; color: #bada55; font-size:16px")
	}

	const getStrWithoutHttp = (str: string) => {
		//Le saco el http o https porque si no me lo toma como un link y no me aplica bien el estilo
		return str.replace("https://", "").replace("http://", "")
	}

	const getConsoleStyle = (str: string) => {
		const bgColor = stc(str)
		const color = invert(bgColor)

		return `background: ${bgColor}; color: ${color}; font-size:14px;`
	}

	export const hit = (method: string, key: string, urlWithoutParams: string, paramsObject?: any, body?: any): void => {
		if (globalConfig.environment !== "development") return

		const tempStr = getStrWithoutHttp(urlWithoutParams)
		const style = getConsoleStyle(tempStr)

		console.log(`%c ${method.toUpperCase()} ${key} ${tempStr}`, style, "params: ", paramsObject, "body: ", body)
	}

	export const hitresultElapsedTime = (urlWithoutParams: string, elapsedTime: number): void => {
		if (globalConfig.environment !== "development") return

		const tempStr = getStrWithoutHttp(urlWithoutParams)
		const style = getConsoleStyle(tempStr)

		console.log(`%c elapsedTime: `, style, elapsedTime)
	}

	export const hitresult1 = (urlWithoutParams: string, result: any = {}): void => {
		if (globalConfig.environment !== "development") return

		const tempStr = getStrWithoutHttp(urlWithoutParams)
		const style = getConsoleStyle(tempStr)

		console.log(`%c fullResObj: `, style, result)
	}

	export const hitresult2 = (urlWithoutParams: string, result: any = {}): void => {
		if (globalConfig.environment !== "development") return

		const tempStr = getStrWithoutHttp(urlWithoutParams)
		const style = getConsoleStyle(tempStr)

		console.log(`%c axResObj: `, style, result)
	}

	export const hitresult3 = (urlWithoutParams: string, result: any = {}): void => {
		if (globalConfig.environment !== "development") return

		const tempStr = getStrWithoutHttp(urlWithoutParams)
		const style = getConsoleStyle(tempStr)

		console.log(`%c parsedResObj: `, style, result)
	}

	export const hitResultErrorHandled = (result: any = {}): void => {
		if (globalConfig.environment !== "development") return

		console.log(`%c resultErrorHandled: `, "background: #7a9900; color: #111; font-size:14px", result)
	}

	export const log = (object1: any): void => {
		if (globalConfig.environment !== "development") return

		console.log(object1)
		// console.log(
		// 	"%c...",
		// 	"color: black; background: green; font-size: 14px;",
		// 	object1
		// )
	}

	export const error = (ownMessage: string, object1: any): void => {
		if (globalConfig.environment !== "development") return

		console.log(`%c ${ownMessage} `, "background: #b0e0e6; color: #db4a39; font-size:14px", object1)

		//TODO: Estos errores debería loguearlos en el servidor
		// utilAxRegularCall(ET_Methods.post, "/log/createWebClientError", null, {
		// 	ownMessage: ownMessage,
		// 	errorMessage: JSON.stringify(object1),
		// })

		// console.log(
		// 	"%c...",
		// 	"color: black; background: green; font-size: 14px;",
		// 	object1
		// )
	}

	export const simple = (objeto: any): void => {
		console.log(objeto)
	}

	export const two = (object2: any, text1: string): void => {
		if (globalConfig.environment !== "development") return

		console.log(text1, object2)

		// console.log(
		// 	`%c${text1}`,
		// 	"color: black; background: yellow; font-size: 14px;",
		// 	object2
		// )
	}
}
