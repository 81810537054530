/* eslint-disable react/jsx-pascal-case*/
import orderBy from "lodash/orderBy"
import { useEffect, useRef } from "react"
import { sharedChatMessage } from "../../api/shared/sharedChatMessage"
import useChat, { IRoomInfo } from "../../hooksData/useChat"
import SharedBasicScrollbar from "../Shared/Basic/SharedBasicScrollbar"
import Chat_Room_MessagesList_Item from "./Chat_Room_MessagesList_Item"
import useGeneral from "../../hooksData/useGeneral"
import { Box, Button, Stack } from "@mui/material"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"
import { globalPathsApp } from "../../globals/globalPathsApp"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"
import useGlobalsUser from "../../hooksData/useGlobalsUser"

export default function Chat_Room_MessagesList() {
	const ug = useGeneral("Chat_Room_MessagesList")
	const { currentPersona } = useGlobalsUser()

	const scrollRef = useRef<HTMLDivElement>(null)

	const { currentRoom_sharedChatMessages, currentRoom_sharedChatRoom, getRoomInfo } = useChat()

	const roomInfo: IRoomInfo | undefined = currentRoom_sharedChatRoom
		? getRoomInfo(currentRoom_sharedChatRoom, currentPersona.id)
		: undefined

	const scrollMessagesToBottom = () => {
		if (scrollRef.current) {
			scrollRef.current.scrollTop = scrollRef.current.scrollHeight
		}
	}

	useEffect(() => {
		setTimeout(() => {
			scrollMessagesToBottom()
		}, 2000)
	}, [])

	useEffect(() => {
		scrollMessagesToBottom()

		if (currentRoom_sharedChatMessages.length > 0) {
			setTimeout(() => {
				scrollMessagesToBottom()
			}, 200)
		}
	}, [currentRoom_sharedChatMessages])

	const messages: sharedChatMessage.IShared[] = currentRoom_sharedChatRoom
		? orderBy(currentRoom_sharedChatMessages, ["dateSent"], ["asc"])
		: []

	return (
		<>
			<SharedBasicScrollbar
				scrollableNodeProps={{
					ref: scrollRef,
				}}
				sx={{ p: 3, height: 1 }}
			>
				<>
					{ug.userIsLogged ? (
						currentRoom_sharedChatRoom &&
						messages.map((message) => (
							<Chat_Room_MessagesList_Item
								key={message.id}
								room={currentRoom_sharedChatRoom}
								chatMessage={message}
								nameOtherEntity={roomInfo?.mainNameToShow || ""}
							/>
						))
					) : (
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
							{/* <Box sx={{ mt: "80px", textAlign: "center" }}>
								<SharedBasicTypo variant="body2" sx={{ fontWeight: 600 }} color={ug.theme.palette.grey[600]}>
									{gl.YouNeedToBeLoggedInToBeAbleToChat[ul]}
								</SharedBasicTypo>

								<Stack direction="column" spacing={1} sx={{ mt: 2 }}>
									<Stack direction="row" spacing={1} sx={{ mt: 2 }}>
										<SharedLinkInsideAppWrapper
											urlDest={globalPathsApp.pageInsideApp.UserLogin(undefined, location.href)}
											sx={{ width: "50%" }}
										>
											<Button size="medium" variant="contained" fullWidth>
												{gl.Login[ul]}
											</Button>
										</SharedLinkInsideAppWrapper>

										<SharedLinkInsideAppWrapper
											urlDest={globalPathsApp.pageInsideApp.UserRegistration()}
											sx={{ ml: 1, width: "50%" }}
										>
											<Button size="medium" variant="contained" fullWidth>
												{gl.SignUp[ul]}
											</Button>
										</SharedLinkInsideAppWrapper>
									</Stack>

									<Box sx={{ mt: 1 }}>
										<UserSharedGoogleLoginButton ugKey={ug.key} />
									</Box>
								</Stack> 
							</Box>*/}
						</Box>
					)}
				</>
			</SharedBasicScrollbar>
		</>
	)
}
