import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { sharedEstado } from "./sharedEstado"
import { sharedDireccion } from "./sharedDireccion"

export namespace sharedEscuela {
	export interface IShared {
		id: number
		idPersonaCreadora: number
		nombre: string
		avatarURL: string
		coverURL: string
		direccion: sharedDireccion.IShared | null
		descripcionCorta: string
		descripcionLarga: string
		email: string
		telefonoMovil: string
		linkInstagram: string
		linkFacebook: string
		linkLinkedIn: string
		linkTwitter: string
		cantidadSeguidores: number
		idEstado: number
	}

	export const defaultObject: IShared = {
		id: 0,
		idPersonaCreadora: 0,
		nombre: "",
		avatarURL: globalAssets.staticPaths.escuelaAvatar1,
		coverURL: globalAssets.staticPaths.escuelaCover1,
		direccion: null,
		descripcionCorta: "",
		descripcionLarga: "",
		email: "",
		telefonoMovil: "",
		linkInstagram: "",
		linkFacebook: "",
		linkLinkedIn: "",
		linkTwitter: "",
		cantidadSeguidores: 0,
		idEstado: sharedEstado.sharedEnum.creado.id,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
			direccion: apiObject.direccion && sharedDireccion.getSharedOne_FromApiObject(apiObject.direccion),
			avatarURL: apiObject.avatarURL || globalAssets.staticPaths.escuelaAvatar1,
			coverURL: apiObject.coverURL || globalAssets.staticPaths.escuelaCover1,
			cantidadSeguidores: apiObject.cantidadSeguidores || 0,
		}

		return res
	}

	export const listForRadioGroup = (sharedEscuelaList: IShared[]): globalInterfaces.IValueNumberLabelString[] => {
		return sharedEscuelaList.map((sharedEscuela) => {
			return {
				value: sharedEscuela.id,
				label: sharedEscuela.nombre,
			}
		})
	}
}
