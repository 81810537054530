import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedEmpresa } from "../../api/shared/sharedEmpresa"
import { apiCallIG_Empresas_GetEmpresa } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_GetEmpresa"
import { apiCallIG_Empresas_ActualizarAvatar } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_ActualizarAvatar"
import { apiCallIG_Empresas_ActualizarContact } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_ActualizarContact"
import { apiCallIG_Empresas_ActualizarCover } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_ActualizarCover"
import { apiCallIG_Empresas_ActualizarDireccionGoogle } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_ActualizarDireccionGoogle"
import { apiCallIG_Empresas_ActualizarDireccionNotGoogle } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_ActualizarDireccionNotGoogle"
import { apiCallIG_Empresas_ActualizarGeneralFields } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_ActualizarGeneralFields"
import { apiCallIG_Empresas_ActualizarProfileFields } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_ActualizarProfileFields"
import { apiCallIG_Empresas_ActualizarSocialInfo } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_ActualizarSocialInfo"
import { apiCallIG_Empresas_BloquearEmpresa } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_BloquearEmpresa"
import { apiCallIG_Empresas_AprobarEmpresa } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_AprobarEmpresa"
import { apiCallIG_Empresas_DesbloquearEmpresa } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_DesbloquearEmpresa"
import { apiCallIG_Empresas_DeleteEmpresa } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_DeleteEmpresa"
import { bllGlobalsGeneralData_AllEmpresas } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllEmpresas"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Empresas_EnviarNotificacionASeguidores } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_EnviarNotificacionASeguidores"

export namespace bllEmpresas_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Empresas_Settings_setInitialState",
		loadEmpresa = "Empresas_Settings_loadEmpresa",
		updateWithSharedEmpresa = "Empresas_Settings_updateWithSharedEmpresa",
		actualizarAvatar = "Empresas_Settings_actualizarAvatar",
		actualizarCharacteristics = "Empresas_Settings_actualizarCharacteristics",
		actualizarContact = "Empresas_Settings_actualizarContact",
		actualizarAdminFields = "Empresas_Settings_actualizarAdminFields",
		actualizarCover = "Empresas_Settings_actualizarCover",
		actualizarDireccionGoogle = "Empresas_Settings_actualizarDireccionGoogle",
		actualizarDireccionNotGoogle = "Empresas_Settings_actualizarDireccionNotGoogle",
		actualizarGeneralFields = "Empresas_Settings_actualizarGeneralFields",
		actualizarProfileFields = "Empresas_Settings_actualizarProfileFields",
		actualizarSocialInfo = "Empresas_Settings_actualizarSocialInfo",
		deleteEmpresa = "Empresas_Settings_deleteEmpresa",
		bloquearEmpresa = "Empresas_Settings_bloquearEmpresa",
		desbloquearEmpresa = "Empresas_Settings_desbloquearEmpresa",
		aprobarEmpresa = "Empresas_Settings_aprobarEmpresa",

		enviarNotificacionASeguidores = "Empresas_Settings_enviarNotificacionASeguidores",
	}

	export interface IInterface {
		empresa: sharedEmpresa.IShared
		isDeleted: boolean
		notificacionEnviada: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			empresa: sharedEmpresa.defaultObject,
			isDeleted: false,
			notificacionEnviada: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadEmpresa(apiCallParams: apiCallInterface, params: apiCallIG_Empresas_GetEmpresa.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Empresas_GetEmpresa.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "empresa">
				const updateObject: actionSubType = {
					empresa: apiCallRes.sharedEmpresa,
				}

				utilRedux.TA(D, ET.loadEmpresa, updateObject)
			}
		}
	}

	export function updateEmpresaWithSharedEmpresa(
		apiCallParams: apiCallInterface,
		sharedEmpresa: sharedEmpresa.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "empresa">
			const updateObject: actionSubType = {
				empresa: sharedEmpresa,
			}

			utilRedux.TA(D, ET.updateWithSharedEmpresa, updateObject)
		}
	}

	export function actualizarAvatar(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Empresas_ActualizarAvatar.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_ActualizarAvatar.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEmpresas_Settings.updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.actualizarAvatar, {})
			}
		}
	}

	export function actualizarContact(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Empresas_ActualizarContact.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_ActualizarContact.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEmpresas_Settings.updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.actualizarContact, {})
			}
		}
	}

	export function actualizarCover(apiCallParams: apiCallInterface, params: apiCallIG_Empresas_ActualizarCover.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_ActualizarCover.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEmpresas_Settings.updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.actualizarCover, {})
			}
		}
	}

	export function actualizarDireccionGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Empresas_ActualizarDireccionGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_ActualizarDireccionGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEmpresas_Settings.updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.actualizarDireccionGoogle, {})
			}
		}
	}

	export function actualizarDireccionNotGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Empresas_ActualizarDireccionNotGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_ActualizarDireccionNotGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEmpresas_Settings.updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.actualizarDireccionNotGoogle, {})
			}
		}
	}

	export function actualizarGeneralFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Empresas_ActualizarGeneralFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_ActualizarGeneralFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEmpresas_Settings.updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.actualizarGeneralFields, {})
			}
		}
	}

	export function actualizarProfileFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Empresas_ActualizarProfileFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_ActualizarProfileFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEmpresas_Settings.updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.actualizarProfileFields, {})
			}
		}
	}

	export function actualizarSocialInfo(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Empresas_ActualizarSocialInfo.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_ActualizarSocialInfo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEmpresas_Settings.updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.actualizarSocialInfo, {})
			}
		}
	}

	export function deleteEmpresa(apiCallParams: apiCallInterface, params: apiCallIG_Empresas_DeleteEmpresa.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Empresas_DeleteEmpresa.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isDeleted">
				const updateObject: actionSubType = {
					isDeleted: true,
				}

				D(bllGlobalsGeneralData_AllEmpresas.loadAllEmpresas(apiCallParams))

				utilRedux.TA(D, ET.deleteEmpresa, updateObject)
			}
		}
	}

	export function bloquearEmpresa(apiCallParams: apiCallInterface, params: apiCallIG_Empresas_BloquearEmpresa.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_BloquearEmpresa.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.bloquearEmpresa, {})
			}
		}
	}

	export function aprobarEmpresa(apiCallParams: apiCallInterface, params: apiCallIG_Empresas_AprobarEmpresa.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_AprobarEmpresa.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.aprobarEmpresa, {})
			}
		}
	}

	export function desbloquearEmpresa(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Empresas_DesbloquearEmpresa.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Empresas_DesbloquearEmpresa.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateEmpresaWithSharedEmpresa(apiCallParams, apiCallRes.sharedEmpresa))

				utilRedux.TA(D, ET.desbloquearEmpresa, {})
			}
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Empresas_EnviarNotificacionASeguidores.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_Empresas_EnviarNotificacionASeguidores.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}
}
