import axios from "axios"
import { utilLogger } from "../utils/utilLogger"

export namespace apiRegularCall_Ax_v2 {
	interface IModelErrors {
		[key: string]: string
	}

	interface IRes {
		object?: any
		errorMessage?: string
		modelErrors?: IModelErrors
		forbiddenError?: boolean
		networkError?: boolean
		unexpectedErrorMessage?: string
	}

	export enum Methods_Enum {
		get,
		post,
		delete,
		put,
		patch,
		postFile,
	}

	export const callv2 = async (
		method: Methods_Enum,
		urlWithoutParams: string,
		queryParamsObject?: any,
		bodyParamsObject?: any,
		formData?: any,
		userToken?: string
	): Promise<IRes> => {
		let headers: any = {}

		if (userToken) headers.Authorization = `Bearer ${userToken}`

		const clienteAxios = axios.create({
			headers: headers,
		})

		let res: any
		let url = urlWithoutParams

		if (queryParamsObject) {
			const queryParams = getQueryParamsFromObjects(queryParamsObject)
			url += queryParams
		}

		try {
			switch (method) {
				case Methods_Enum.get:
					if (bodyParamsObject) {
						return { unexpectedErrorMessage: "En una llamada Get no puede haber body" }
					}
					res = await clienteAxios.get(url)
					break
				case Methods_Enum.post:
					res = await clienteAxios.post(url, bodyParamsObject)
					break
				case Methods_Enum.delete:
					if (bodyParamsObject) {
						return { unexpectedErrorMessage: "En una llamada Delete no puede haber body" }
					}
					res = await clienteAxios.delete(url, bodyParamsObject)
					break
				case Methods_Enum.put:
					res = await clienteAxios.put(url, bodyParamsObject)
					break
				case Methods_Enum.patch:
					res = await clienteAxios.patch(url, bodyParamsObject)
					break
				case Methods_Enum.postFile:
					const clienteAxiosPostFile = axios.create({
						headers: { ...headers, "Content-Type": "multipart/form-data" },
					})

					res = await clienteAxiosPostFile.post(url, formData)
					break
				default:
					break
			}
		} catch (err: any) {
			if (err.response && err.response.status === 403) {
				return { forbiddenError: true }
			}

			if (err.toString().includes("Error: Network Error")) {
				return { networkError: true }
			}

			if (!err.response || err.response.status !== 400) {
				return { unexpectedErrorMessage: "Ni siquiera llega a poner el response en el err." }
			}

			utilLogger.hitResultErrorHandled(err.response.data)

			switch (err.response.data.tipoError) {
				case 0:
					//Error inesperado (ya lo logueo del lado del servidor)
					return {
						unexpectedErrorMessage:
							"Error inesperado no controlado por el servidor. Ya lo logueo también del lado del servidor.",
					}
					break
				case 1: //Errores de modelo
					const serverModelErrors: IModelErrors = {}
					err.response.data.erroresDelModelo.forEach((element: any) => {
						serverModelErrors[element.key.toLowerCase()] = element.value
					})
					return {
						modelErrors: serverModelErrors,
					}
					break
				case 2: //Error con un mensaje
					return {
						errorMessage: err.response.data.mensaje,
					}
					break
				default:
					//Otro tipo de error que no estoy controlando
					return {
						unexpectedErrorMessage: "Un tipo de error no definido.",
					}
					break
			}
		}

		utilLogger.hitresult1(urlWithoutParams, res)

		//TODO: Tengo que controlar también el error 403 (unauthorized)

		const validateResObjectStringResult = validateResponseObject(res)
		if (validateResObjectStringResult) {
			return {
				unexpectedErrorMessage: validateResObjectStringResult,
			}
		}

		let resObject: any = null

		switch (res.data.tipoOk) {
			case 0: //Ok vacío. Devuelvo un objeto vacío
				resObject = {}
				break
			case 1: //Ok de mensaje. Devuelvo el mensaje en el objeto. Luego el apiCall tendrá que saber qué puede devolver ese endpoint
				resObject = res.data.mensaje
				break
			case 2: //Ok de objeto
				resObject = res.data.objeto
				break
			default:
				//Otro tipo ok que no estoy controlando
				return {
					unexpectedErrorMessage: "Un tipo ok no definido.",
				}
				break
		}

		return {
			object: resObject, // clearObjectRemovingNullValuesDeep(res.data.objeto)
		}

		// await utilOthers.sleep(2000)

		//Si viene un error de autenticación, borro el localstorage y recargo la página en la home
		// if (r.data.error === "Authentication Error") {
		// 	if (utilUser.IsLogged()) {
		// 		//Si estoy logueado, debe haber algún problema con el token (por ej: expiró), así que borro el localstorage y redirijo a la home
		// 		utilStorage.clearStorage()
		// 	} else {
		// 		//Si no estoy logueado, logueo el error porque significa que desde algún lugar sin loguearse, está pudiendo hacer alguna acción que es solo para logueados
		// 		utilLogger.error(
		// 			`Se está intentando acceder a alguna función que requiere autenticación, sin estar logueado. La llamada es a: ${url}`,
		// 			r
		// 		)
		// 	}
		// }

		// //Si no vino results ni error (alguno de los dos tiene que estar) tiro error
		// if (!r.data.results && !r.data.error) {
		// 	return {
		// 		error: `${_l.HaOcurridoUnErrorElMismoYaFueReportadoIntenteMasTardePorFavor()} ${_l.Codigo()} 10003.`,
		// 	}
		// }
	}

	// //AX = AxiosClient
	// const ax = async (
	// 	userToken: string | null,
	// 	method: Methods_Enum,
	// 	urlWithoutParams: string,
	// 	paramsObject?: any,
	// 	bodyObject?: any,
	// 	formData?: any
	// ) => {
	// 	let headers: any = {}

	// 	if (userToken) headers.Authorization = `Bearer ${userToken}`

	// 	const clienteAxios = axios.create({
	// 		headers: headers,
	// 	})

	// 	let r: any
	// 	let url = urlWithoutParams

	// 	if (paramsObject) {
	// 		const queryParams = getQueryParamsFromObjects(paramsObject)
	// 		url += queryParams
	// 	}

	// 	try {
	// 		switch (method) {
	// 			case Methods_Enum.get:
	// 				if (bodyObject)
	// 					utilLogger.error("En una llamada Get no puede haber body", {
	// 						method,
	// 						urlWithoutParams,
	// 						paramsObject,
	// 						bodyObject,
	// 					})
	// 				r = await clienteAxios.get(url)
	// 				break
	// 			case Methods_Enum.post:
	// 				r = await clienteAxios.post(url, bodyObject)
	// 				break
	// 			case Methods_Enum.delete:
	// 				if (bodyObject)
	// 					utilLogger.error("En una llamada Delete no puede haber body", {
	// 						method,
	// 						urlWithoutParams,
	// 						paramsObject,
	// 						bodyObject,
	// 					})
	// 				r = await clienteAxios.delete(url, bodyObject)
	// 				break
	// 			case Methods_Enum.put:
	// 				r = await clienteAxios.put(url, bodyObject)
	// 				break
	// 			case Methods_Enum.patch:
	// 				r = await clienteAxios.patch(url, bodyObject)
	// 				break
	// 			case Methods_Enum.postFile:
	// 				const clienteAxiosPostFile = axios.create({
	// 					headers: { ...headers, "Content-Type": "multipart/form-data" },
	// 				})

	// 				r = await clienteAxiosPostFile.post(url, formData)
	// 				break
	// 			default:
	// 				break
	// 		}
	// 	} catch (err: any) {
	// 		if (!err.response) {
	// 			return createApiCallErrorUnexpected()
	// 		}

	// 		//Este hay que cambiarlo
	// 		return createApiCallErrorUnexpected()
	// 	}

	// 	if (!r.data) {
	// 		return createApiCallErrorUnexpected()
	// 	}

	// 	//Este hay que cambiarlo
	// 	return createApiCallErrorUnexpected()

	// 	//Si viene un error de autenticación, borro el localstorage y recargo la página en la home
	// 	// if (r.data.error === "Authentication Error") {
	// 	// 	if (utilUser.IsLogged()) {
	// 	// 		//Si estoy logueado, debe haber algún problema con el token (por ej: expiró), así que borro el localstorage y redirijo a la home
	// 	// 		utilStorage.clearStorage()
	// 	// 	} else {
	// 	// 		//Si no estoy logueado, logueo el error porque significa que desde algún lugar sin loguearse, está pudiendo hacer alguna acción que es solo para logueados
	// 	// 		utilLogger.error(
	// 	// 			`Se está intentando acceder a alguna función que requiere autenticación, sin estar logueado. La llamada es a: ${url}`,
	// 	// 			r
	// 	// 		)
	// 	// 	}
	// 	// }

	// 	// //Si no vino results ni error (alguno de los dos tiene que estar) tiro error
	// 	// if (!r.data.results && !r.data.error) {
	// 	// 	return {
	// 	// 		error: `${_l.HaOcurridoUnErrorElMismoYaFueReportadoIntenteMasTardePorFavor()} ${_l.Codigo()} 10003.`,
	// 	// 	}
	// 	// }
	// }

	const getQueryParamsFromObjects = (paramsObject: any) => {
		let queryParams = ""

		for (const item in paramsObject) {
			const value = encodeURIComponent(paramsObject[item].toString()) //Le pongo un tostring porque si es 0 por ej, no me lo toma abajo

			if (value && String(value) !== "All") {
				if (queryParams === "") {
					queryParams += "?"
				} else {
					queryParams += "&"
				}

				queryParams += `${item}=${String(value)}`
			}
		}

		return queryParams
	}

	const validateResponseObject = (res: any): string | undefined => {
		if (res) {
			if (!res) {
				return "Res no definido."
			}
		}

		if (!res.data) {
			return "No viene data en la respuesta."
		}

		if (res.data.tipoOk === undefined) {
			return "No viene tipoOk en la respuesta."
		}

		if (!res.data.mensaje === undefined) {
			return "No viene mensaje en la respuesta."
		}

		if (!res.data.objeto === undefined) {
			return "No viene objeto en la respuesta."
		}
	}
}
