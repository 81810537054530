/* eslint-disable react/jsx-pascal-case*/
import { Card, CardHeader, Container } from "@mui/material"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import OrganizadoresShared_CardList from "./OrganizadoresShared_CardList"

interface IProps {}

export default function OrganizadoresShared_OrganizadoresSeguidos({}: IProps) {
	const organizadoresSeguidos = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaOrganizadoresSeguidos.organizadoresSeguidos
	)

	return (
		<Container sx={{ position: "relative" }}>
			<Card>
				<CardHeader title="Organizadores Seguidos" sx={{ mb: 3 }} />

				<OrganizadoresShared_CardList organizadores={organizadoresSeguidos} />
			</Card>
		</Container>
	)
}
