import { utilDate } from "../../utils/utilDate"

export namespace sharedSeguroPdf {
	export interface IShared {
		id: number
		url: string | null
		base64Pdf: string | null
	}

	export const objectDefault: IShared = {
		id: 0,
		url: null,
		base64Pdf: null,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}
}
