import { combineReducers } from "redux"
import { bllEscuelas_Profile } from "./bllEscuelas_Profile"
import { bllEscuelas_Create } from "./bllEscuelas_Create"
import { bllEscuelas_Settings } from "./bllEscuelas_Settings"

export default combineReducers({
	Profile: bllEscuelas_Profile.reducerFunction,
	Settings: bllEscuelas_Settings.reducerFunction,
	CreateEscuela: bllEscuelas_Create.reducerFunction,
})

export interface IEscuelas {
	Profile: bllEscuelas_Profile.IInterface
	Settings: bllEscuelas_Settings.IInterface
	CreateEscuela: bllEscuelas_Create.IInterface
}
