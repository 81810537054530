import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedEmpresa } from "../../api/shared/sharedEmpresa"
import { apiCallIG_Empresas_GetEmpresa } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_GetEmpresa"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { apiCallIG_Empresas_BloquearEmpresa } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_BloquearEmpresa"
import { apiCallIG_Empresas_DesbloquearEmpresa } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_DesbloquearEmpresa"
import { apiCallIG_Empresas_AprobarEmpresa } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_AprobarEmpresa"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_EmpresasSeguidores_SeguirEmpresa } from "../../api/calls/IG/EmpresasSeguidores/apiCallIG_EmpresasSeguidores_SeguirEmpresa"
import { bllGlobalsUser_CurrentPersonaEmpresasSeguidos } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaEmpresasSeguidos"
import { apiCallIG_EmpresasSeguidores_DejarDeSeguirEmpresa } from "../../api/calls/IG/EmpresasSeguidores/apiCallIG_EmpresasSeguidores_DejarDeSeguirEmpresa"

export namespace bllEmpresas_Profile {
	//ET = enumTypes
	enum ET {
		setInitialState = "Empresa_Profile_setInitialState",
		loadEmpresa = "Empresa_Profile_loadEmpresa",
		updateWithSharedEmpresa = "Empresas_Profile_updateWithSharedEmpresa",
		seguirEmpresa = "Empresa_Profile_seguirEmpresa",
		dejarDeSeguirEmpresa = "Empresa_Profile_dejarDeSeguirEmpresa",
	}

	export interface IInterface {
		empresa: sharedEmpresa.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			empresa: sharedEmpresa.defaultObject,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getEmpresa(apiCallParams: apiCallInterface, params: apiCallIG_Empresas_GetEmpresa.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Empresas_GetEmpresa.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "empresa">
				const updateObject: actionSubType = {
					empresa: apiCallRes.sharedEmpresa,
				}

				utilRedux.TA(D, ET.loadEmpresa, updateObject)
			}
		}
	}

	export function updateEmpresaWithSharedEmpresa(
		apiCallParams: apiCallInterface,
		sharedEmpresa: sharedEmpresa.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "empresa">
			const updateObject: actionSubType = {
				empresa: sharedEmpresa,
			}

			utilRedux.TA(D, ET.updateWithSharedEmpresa, updateObject)
		}
	}

	export function seguirEmpresa(
		apiCallParams: apiCallInterface,
		params: apiCallIG_EmpresasSeguidores_SeguirEmpresa.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_EmpresasSeguidores_SeguirEmpresa.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(bllGlobalsUser_CurrentPersonaEmpresasSeguidos.setPersonaEmpresasSeguidos(apiCallRes.sharedEmpresasSeguidos))

				type actionSubType = Pick<IInterface, "empresa">
				const updateObject: actionSubType = {
					empresa: apiCallRes.sharedEmpresa,
				}

				utilRedux.TA(D, ET.seguirEmpresa, updateObject)
			}
		}
	}

	export function dejarDeSeguirEmpresa(
		apiCallParams: apiCallInterface,
		params: apiCallIG_EmpresasSeguidores_DejarDeSeguirEmpresa.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_EmpresasSeguidores_DejarDeSeguirEmpresa.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(bllGlobalsUser_CurrentPersonaEmpresasSeguidos.setPersonaEmpresasSeguidos(apiCallRes.sharedEmpresasSeguidos))

				type actionSubType = Pick<IInterface, "empresa">
				const updateObject: actionSubType = {
					empresa: apiCallRes.sharedEmpresa,
				}

				utilRedux.TA(D, ET.dejarDeSeguirEmpresa, updateObject)
			}
		}
	}
}
