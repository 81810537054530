import produce from "immer"
import { sharedChatMessage } from "../../api/shared/sharedChatMessage"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import { utilRedux } from "../../utils/utilRedux"
import { apiCallIG_Chat_GetOrCreateAndGetRoom } from "../../api/calls/IG/Chat/apiCallIG_Chat_GetOrCreateAndGetRoom"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Chat_SetChatRoom_AsRead } from "../../api/calls/IG/Chat/apiCallIG_Chat_SetChatRoom_AsRead"
import { apiCallIG_Chat_LoadRooms_ForPerson } from "../../api/calls/IG/Chat/apiCallIG_Chat_LoadRooms_ForPerson"
import { apiCallIG_Chat_LoadMessages_ForPerson } from "../../api/calls/IG/Chat/apiCallIG_Chat_LoadMessages_ForPerson"

export namespace bllChat_All {
	//ET = enumTypes
	enum ET {
		setInitialState = "Chat_All_setInitialState",
		set_loadAllFinished_ForPerson = "Chat_All_set_loadAllFinished_ForPerson",
		loadRooms_ForPerson_Start = "Chat_All_loadRooms_ForPerson_Start",
		loadRooms_ForPerson_Finish = "Chat_All_loadRooms_ForPerson_Finish",
		loadMessages_ForPerson_Start = "Chat_All_loadMessages_ForPerson_Start",
		loadMessages_ForPerson_Finish = "Chat_All_loadMessages_ForPerson_Finish",
		setChatRooms = "Chat_All_setChatRooms",
		startChat = "Chat_All_startChat",
		addChatMessage = "Chat_Room_addChatMessage",
		addOrUpdateChatRoom = "Chat_All_addOrUpdateChatRoom",
	}

	export interface IInterface {
		loadRoomsFinished: boolean
		loadMessagesFinished: boolean
		sharedChatRooms: sharedChatRoom.IShared[]
		sharedChatMessages: sharedChatMessage.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			loadRoomsFinished: false,
			loadMessagesFinished: false,
			sharedChatRooms: [],
			sharedChatMessages: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			case ET.addChatMessage:
				return produce(state, (draft) => {
					const chatMessageIndex = draft.sharedChatMessages.findIndex(
						(chatMessage) => chatMessage.id === action.sharedChatMessage.id
					)
					if (chatMessageIndex === -1) {
						draft.sharedChatMessages.push(action.sharedChatMessage)
					}
				})
				break
			case ET.addOrUpdateChatRoom:
				return produce(state, (draft) => {
					const chatRoomIndex = draft.sharedChatRooms.findIndex((chatRoom) => chatRoom.id === action.sharedChatRoom.id)
					if (chatRoomIndex === -1) {
						draft.sharedChatRooms.push(action.sharedChatRoom)
					} else {
						draft.sharedChatRooms[chatRoomIndex] = action.sharedChatRoom
					}
				})
				break

			// case ET.User_Login_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function setChatRoom(sharedChatRoom: sharedChatRoom.IShared) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.addOrUpdateChatRoom, { sharedChatRoom: sharedChatRoom })
		}
	}

	export function setChatRooms(sharedChatRooms: sharedChatRoom.IShared[]) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "sharedChatRooms">
			const updateObject: actionSubType = {
				sharedChatRooms: sharedChatRooms,
			}

			utilRedux.TA(D, ET.setChatRooms, updateObject)
		}
	}

	export function setChatMessages(sharedChatMessages: sharedChatMessage.IShared[]) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "sharedChatMessages">
			const updateObject: actionSubType = {
				sharedChatMessages: sharedChatMessages,
			}

			utilRedux.TA(D, ET.setChatRooms, updateObject)
		}
	}

	export function addChatMessage(sharedChatMessage: sharedChatMessage.IShared) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.addChatMessage, { sharedChatMessage })
		}
	}

	export function addOrUpdateChatRoom(sharedChatRoom: sharedChatRoom.IShared) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.addOrUpdateChatRoom, { sharedChatRoom })
		}
	}

	export function GetOrCreateAndGetRoom(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Chat_GetOrCreateAndGetRoom.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Chat_GetOrCreateAndGetRoom.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(setChatRoom(apiCallRes.chatRoom))
			}
		}
	}

	export function setChatRoomAsRead(apiCallParams: apiCallInterface, params: apiCallIG_Chat_SetChatRoom_AsRead.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Chat_SetChatRoom_AsRead.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(setChatRoom(apiCallRes.chatRoom))
			}
		}
	}

	export function loadRooms_ForPerson(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Chat_LoadRooms_ForPerson.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "loadRoomsFinished">
			const updateObject: actionSubType = {
				loadRoomsFinished: false,
			}
			utilRedux.TA(D, ET.loadRooms_ForPerson_Start, updateObject)

			const apiCallRes = await apiCallIG_Chat_LoadRooms_ForPerson.apiCall(apiCallParams, params)
			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "sharedChatRooms" | "loadRoomsFinished">
				const updateObject: actionSubType = {
					sharedChatRooms: apiCallRes.chatRooms,
					loadRoomsFinished: true,
				}

				utilRedux.TA(D, ET.loadRooms_ForPerson_Finish, updateObject)
			}
		}
	}

	export function loadMessages_ForPerson(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Chat_LoadMessages_ForPerson.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "loadMessagesFinished">
			const updateObject: actionSubType = {
				loadMessagesFinished: false,
			}
			utilRedux.TA(D, ET.loadMessages_ForPerson_Start, updateObject)

			const apiCallRes = await apiCallIG_Chat_LoadMessages_ForPerson.apiCall(apiCallParams, params)
			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "sharedChatMessages" | "loadMessagesFinished">
				const updateObject: actionSubType = {
					sharedChatMessages: apiCallRes.chatMessages,
					loadMessagesFinished: true,
				}

				utilRedux.TA(D, ET.loadMessages_ForPerson_Finish, updateObject)
			}
		}
	}
}
