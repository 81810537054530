import { globalAssets } from "../../globals/globalAssets"
import { utilDate } from "../../utils/utilDate"
import { sharedDireccion } from "./sharedDireccion"

export namespace sharedNotificacion {
	export interface IShared {
		id: number
		fecha: Date | null
		asunto: string
		mensaje: string
		viewed: boolean | null
		url: string
	}

	export const defaultObject: IShared = {
		id: 0,
		fecha: null,
		asunto: "",
		mensaje: "",
		viewed: false,
		url: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...defaultObject,
			...apiObject,
			fecha: utilDate.fDateFromNet(apiObject.fecha),
		}

		return res
	}
}
