import produce from "immer"
import { utilLogger } from "../../utils/utilLogger"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllGlobalsServer_MessageList {
	//ET = enumTypes
	enum ET {
		setMessages = "GlobalsServer_MessageList_setServerMessages",
		cleanMessages = "GlobalsServer_MessageList_cleanServerMessages",
	}

	interface IServerMessage {
		serverMessage: string
	}

	export interface IInterface {
		[key: string]: IServerMessage
	}

	const getInitialState = (): IInterface => {
		return {}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			case ET.setMessages:
				return produce(state, (draft: IInterface) => {
					draft[action.key] = action.serverMessage
				})
				break
			case ET.cleanMessages:
				return produce(state, (draft: IInterface) => {
					delete draft[action.key]
				})
				break
			// case ET.Update_dialogWorkInProgressOpen:
			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})
			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setMessages(key: string, message: any) {
		//D = dispatch
		return async (D: any) => {
			const serverMessage: IServerMessage = {
				serverMessage: message,
			}

			utilRedux.TA(D, ET.setMessages, {
				key: key,
				serverMessage: serverMessage,
			})
		}
	}

	export function cleanMessages(key: string) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.cleanMessages, {
				key: key,
			})
		}
	}
}
