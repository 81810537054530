export namespace sharedDireccion {
	export interface IShared {
		id: number
		direccion: string
		pisoPuertaEtc: string
		googleJsonObject: string
		lat: string
		lng: string
		google_street_number: string
		google_route: string
		google_locality: string
		google_administrative_area_level_2: string
		google_administrative_area_level_1: string
		google_country: string
		google_postal_code: string
	}

	export const defaultObject: IShared = {
		id: 0,
		direccion: "",
		pisoPuertaEtc: "",
		googleJsonObject: "",
		lat: "",
		lng: "",
		google_street_number: "",
		google_route: "",
		google_locality: "",
		google_administrative_area_level_2: "",
		google_administrative_area_level_1: "",
		google_country: "",
		google_postal_code: "",
	}

	export const getSharedDireccionesFromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export interface ISharedDireccionGoogle {
		formatted_address: string
		jsonObject: string
		lat: string
		lng: string
		street_number: string
		route: string
		locality: string
		administrative_area_level_2: string
		administrative_area_level_1: string
		country: string
		postal_code: string
	}

	export const sharedDireccionGoogleDefault: ISharedDireccionGoogle = {
		formatted_address: "",
		jsonObject: "",
		lat: "",
		lng: "",
		street_number: "",
		route: "",
		locality: "",
		administrative_area_level_2: "",
		administrative_area_level_1: "",
		country: "",
		postal_code: "",
	}

	export const getSharedDireccionGoogle_FromDireccion = (direccion: IShared | null): ISharedDireccionGoogle => {
		if (!direccion) return sharedDireccionGoogleDefault

		return {
			formatted_address: direccion.direccion,
			jsonObject: direccion.googleJsonObject,
			lat: direccion.lat,
			lng: direccion.lng,
			street_number: direccion.google_street_number,
			route: direccion.google_route,
			locality: direccion.google_locality,
			administrative_area_level_2: direccion.google_administrative_area_level_2,
			administrative_area_level_1: direccion.google_administrative_area_level_1,
			country: direccion.google_country,
			postal_code: direccion.google_postal_code,
		}
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...sharedDireccionGoogleDefault,
			...apiObject,
		}

		return res
	}
}
