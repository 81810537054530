import { combineReducers } from "redux"
import { bllGlobalsServer_ErrorMessageList } from "./bllGlobalsServer_ErrorMessageList"
import { bllGlobalsServer_MessageList } from "./bllGlobalsServer_MessageList"
import { bllGlobalsServer_FinishedList } from "./bllGlobalsServer_FinishedList"
import { bllGlobalsServer_LoadingList } from "./bllGlobalsServer_LoadingList"
import { bllGlobalsServer_ModelErrorsList } from "./bllGlobalsServer_ModelErrorsList"
import { bllGlobalsServer_UploadLoadingList } from "./bllGlobalsServer_UploadLoadingList"

export default combineReducers({
	ErrorMessageList: bllGlobalsServer_ErrorMessageList.reducerFunction,
	MessageList: bllGlobalsServer_MessageList.reducerFunction,
	FinishedList: bllGlobalsServer_FinishedList.reducerFunction,
	LoadingList: bllGlobalsServer_LoadingList.reducerFunction,
	ModelErrorsList: bllGlobalsServer_ModelErrorsList.reducerFunction,
	UploadLoadingList: bllGlobalsServer_UploadLoadingList.reducerFunction,
})

export interface IGlobalsServer {
	ErrorMessageList: bllGlobalsServer_ErrorMessageList.IInterface
	MessageList: bllGlobalsServer_MessageList.IInterface
	FinishedList: bllGlobalsServer_FinishedList.IInterface
	LoadingList: bllGlobalsServer_LoadingList.IInterface
	ModelErrorsList: bllGlobalsServer_ModelErrorsList.IInterface
	UploadLoadingList: bllGlobalsServer_UploadLoadingList.IInterface
}
