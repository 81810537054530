import { ReactNode } from "react"
import { LazyMotion } from "framer-motion"

// ----------------------------------------------------------------------

const loadFeatures = () => import("../../globals/globalAnimate/features.js").then((res) => res.default)

type Props = {
	children: ReactNode
}

export default function AnimateMotionLazyContainer({ children }: Props) {
	return (
		<LazyMotion strict features={loadFeatures}>
			{children}
		</LazyMotion>
	)
}
