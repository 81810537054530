/* eslint-disable react/jsx-pascal-case*/
import {
	alpha,
	Avatar,
	Box,
	Card,
	Divider,
	IconButton,
	Stack,
	styled,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import { utilNumbers } from "../../../utils/utilNumbers"
import SharedBasicIconify from "../../Shared/Basic/SharedBasicIconify"
import { SharedLinkInsideAppWrapper } from "../../Shared/SharedLinkInsideAppWrapper"
import SharedSvgColor from "../../Shared/SharedSvgColor"
import SharedBasicImage from "../../Shared/Basic/SharedBasicImage"
import { sharedCircuito } from "../../../api/shared/sharedCircuito"
import SharedBasicTypo from "../../Shared/Basic/SharedBasicTypo"

interface IProps {
	circuito: sharedCircuito.IShared
}

const StyledOverlay = styled("div")(({ theme }) => ({
	top: 0,
	left: 0,
	zIndex: 8,
	width: "100%",
	height: "100%",
	position: "absolute",
	backgroundColor: alpha(theme.palette.grey[900], 0.64),
}))

export default function CircuitosShared_CardList_Card({ circuito }: IProps) {
	const theme = useTheme()

	return (
		<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.CircuitosProfile(undefined, circuito.id)}>
			<Card sx={{ textAlign: "center" }}>
				<Box sx={{ position: "relative" }}>
					<SharedSvgColor
						src="/assets/shape_avatar.svg"
						sx={{
							width: 144,
							height: 62,
							zIndex: 10,
							left: 0,
							right: 0,
							bottom: -26,
							mx: "auto",
							position: "absolute",
							color: "background.paper",
						}}
					/>

					<Avatar
						alt={circuito.nombre}
						src={circuito.avatarURL}
						sx={{
							width: 64,
							height: 64,
							zIndex: 11,
							left: 0,
							right: 0,
							bottom: -32,
							mx: "auto",
							position: "absolute",
						}}
					/>

					<StyledOverlay />

					<SharedBasicImage src={circuito.coverURL} alt={circuito.nombre} ratio="16/9" />
				</Box>

				<SharedBasicTypo variant="subtitle1" sx={{ mt: 6, mb: 0.5 }}>
					{circuito.nombre}
				</SharedBasicTypo>

				<SharedBasicTypo variant="body2" sx={{ color: theme.palette.text.secondary }}>
					{circuito.descripcionCorta}
				</SharedBasicTypo>

				<Stack direction="row" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 3 }}>
					{/* {_socials.map((social) => (
          <IconButton
            key={social.name}
            sx={{
              color: social.color,
              '&:hover': {
                bgcolor: alpha(social.color, 0.08),
              },
            }}
          >
            <Iconify icon={social.icon} />
          </IconButton>
        ))} */}
				</Stack>

				<Divider sx={{ borderStyle: "dashed" }} />

				<Box display="grid" gridTemplateColumns="repeat(3, 1fr)" sx={{ py: 3 }}>
					<div>
						<SharedBasicTypo variant="caption" component="div" sx={{ mb: 0.75, color: "text.disabled" }}>
							Seguidores
						</SharedBasicTypo>
						<SharedBasicTypo variant="subtitle1">{circuito.cantidadSeguidores}</SharedBasicTypo>
					</div>

					<div>
						<SharedBasicTypo variant="caption" component="div" sx={{ mb: 0.75, color: "text.disabled" }}>
							Precio adulto
						</SharedBasicTypo>

						<SharedBasicTypo variant="subtitle1">
							{circuito.precioAdulto ? utilNumbers.fShortenNumber(circuito.precioAdulto) : "-"}
						</SharedBasicTypo>
					</div>

					<div>
						<SharedBasicTypo variant="caption" component="div" sx={{ mb: 0.75, color: "text.disabled" }}>
							Precio niño
						</SharedBasicTypo>
						<SharedBasicTypo variant="subtitle1">
							{circuito.precioNino ? utilNumbers.fShortenNumber(circuito.precioNino) : "-"}
						</SharedBasicTypo>
					</div>
				</Box>
			</Card>
		</SharedLinkInsideAppWrapper>
	)
}
