import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { apiCallInterface } from "../api/apiCallInterface"
import useServerState from "../hooks/useServerState"
import { utilLogger } from "../utils/utilLogger"
import useGlobalsUser from "./useGlobalsUser"
import { useTheme } from "@mui/material/styles"

export default function useGeneral(key: string) {
	const { userIsLogged, userToken } = useGlobalsUser()
	const { enqueueSnackbar } = useSnackbar()
	const D = useDispatch()
	const serverState = useServerState(key)
	const theme = useTheme()

	return {
		key,
		D,
		enqueueSnackbar,
		theme,
		userIsLogged,
		userToken,
		serverState,
		apiCallParams: <apiCallInterface>{ key, userToken },
	}
}
