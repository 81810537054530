import { CssBaseline } from "@mui/material"
import {
	createTheme,
	StyledEngineProvider,
	ThemeOptions,
	ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles"
import { ReactNode } from "react"
import breakpoints from "./breakpoints"
import componentsOverride from "./overrides"
import palette from "./palette"
import shadows, { customShadows } from "./shadows"
import typography from "./typography"
import GlobalStyles from "./globalStyles"

type Props = {
	children: ReactNode
}

export default function MmStyleThemeProvider({ children }: Props) {
	const isLight = true

	const themeOptions: ThemeOptions = {
		palette: isLight ? palette.light : palette.dark,
		typography,
		breakpoints,
		shape: { borderRadius: 8 },
		direction: "ltr",
		shadows: isLight ? shadows.light : shadows.dark,
		customShadows: isLight ? customShadows.light : customShadows.dark,
	}

	const theme = createTheme(themeOptions)
	theme.components = componentsOverride(theme)

	return (
		<StyledEngineProvider injectFirst>
			<MUIThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles />
				{children}
			</MUIThemeProvider>
		</StyledEngineProvider>
	)
}
