/* eslint-disable react/jsx-pascal-case*/
import { IconifyIcon } from "@iconify/react"
import { Box, Tab, Tabs } from "@mui/material"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useRouting from "../../../hooks/useRouting"
import useGeneral from "../../../hooksData/useGeneral"
import SharedBasicIconify from "./SharedBasicIconify"
import { utilLogger } from "../../../utils/utilLogger"

export interface ISharedTabs_Tab {
	name: string
	label: string
	component: JSX.Element
	iconifyIcon?: IconifyIcon | string
	customIcon?: React.ElementType
	urlDest: string
}

export interface ISharedTabs {
	tabs: ISharedTabs_Tab[]
	hideComponent?: boolean
	tabChanged?(tab: ISharedTabs_Tab): void
}

export default function SharedTabs({ tabs, hideComponent, tabChanged }: ISharedTabs) {
	const { tabName_FromUrl, tabName_Set } = useRouting()
	const [tabMatched, setTabMatched] = useState<ISharedTabs_Tab | null>(null)

	useEffect(() => {
		if (tabName_FromUrl) {
			if (tabMatched?.name !== tabName_FromUrl) {
				const temp_tabMatched = tabs.find((tab) => tab.name === tabName_FromUrl)
				if (temp_tabMatched) {
					setTabMatched(temp_tabMatched)
				}
			}
		} else {
			setTabMatched(tabs[0])
		}
	}, [tabName_FromUrl, tabs, tabMatched])

	useEffect(() => {
		if (tabChanged && tabMatched) {
			tabChanged(tabMatched)
		}
	}, [tabMatched])

	if (!tabMatched) return null

	return (
		<>
			<Tabs
				value={tabMatched.name}
				scrollButtons="auto"
				variant="scrollable"
				allowScrollButtonsMobile
				onChange={(e, value) => tabName_Set(value)}
			>
				{tabs.map((tab) => {
					return (
						<Tab
							key={tab.name}
							disableRipple
							label={tab.label}
							icon={
								<SharedBasicIconify iconifyIcon={tab.iconifyIcon} customIcon={tab.customIcon} width={20} height={20} />
							}
							value={tab.name}
						/>
					)
				})}
			</Tabs>

			{!hideComponent && <Box sx={{ pt: 4 }}>{tabMatched.component}</Box>}
		</>
	)
}
