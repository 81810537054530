/* eslint-disable react/jsx-pascal-case*/
import EscuelasLanding from "../../Escuelas/Landing/EscuelasLanding"
import { Layout } from "../../Layout/Layout"
import SaludonnetLanding from "../../Saludonnet/Landing/SaludonnetLanding"
import SaludonnetSeguro from "../../Saludonnet/Seguro/SaludonnetSeguro"
import { SharedBasicPage } from "../../Shared/Basic/SharedBasicPage"

export default function PageSaludonnetSeguro() {
	return (
		<Layout>
			<SharedBasicPage title="Seguro" dniMustBeVerified={true}>
				<SaludonnetSeguro />
			</SharedBasicPage>
		</Layout>
	)
}
