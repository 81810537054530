import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedMotoclub } from "../../api/shared/sharedMotoclub"
import { apiCallIG_Motoclubs_GetMotoclub } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_GetMotoclub"
import { apiCallIG_Motoclubs_ActualizarAvatar } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_ActualizarAvatar"
import { apiCallIG_Motoclubs_ActualizarContact } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_ActualizarContact"
import { apiCallIG_Motoclubs_ActualizarCover } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_ActualizarCover"
import { apiCallIG_Motoclubs_ActualizarDireccionGoogle } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_ActualizarDireccionGoogle"
import { apiCallIG_Motoclubs_ActualizarDireccionNotGoogle } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_ActualizarDireccionNotGoogle"
import { apiCallIG_Motoclubs_ActualizarGeneralFields } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_ActualizarGeneralFields"
import { apiCallIG_Motoclubs_ActualizarProfileFields } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_ActualizarProfileFields"
import { apiCallIG_Motoclubs_ActualizarSocialInfo } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_ActualizarSocialInfo"
import { apiCallIG_Motoclubs_BloquearMotoclub } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_BloquearMotoclub"
import { apiCallIG_Motoclubs_AprobarMotoclub } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_AprobarMotoclub"
import { apiCallIG_Motoclubs_DesbloquearMotoclub } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_DesbloquearMotoclub"
import { apiCallIG_Motoclubs_DeleteMotoclub } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_DeleteMotoclub"
import { bllGlobalsGeneralData_AllMotoclubs } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllMotoclubs"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Motoclubs_EnviarNotificacionASeguidores } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_EnviarNotificacionASeguidores"

export namespace bllMotoclubs_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Motoclubs_Settings_setInitialState",
		loadMotoclub = "Motoclubs_Settings_loadMotoclub",
		updateWithSharedMotoclub = "Motoclubs_Settings_updateWithSharedMotoclub",
		actualizarAvatar = "Motoclubs_Settings_actualizarAvatar",
		actualizarCharacteristics = "Motoclubs_Settings_actualizarCharacteristics",
		actualizarContact = "Motoclubs_Settings_actualizarContact",
		actualizarAdminFields = "Motoclubs_Settings_actualizarAdminFields",
		actualizarCover = "Motoclubs_Settings_actualizarCover",
		actualizarDireccionGoogle = "Motoclubs_Settings_actualizarDireccionGoogle",
		actualizarDireccionNotGoogle = "Motoclubs_Settings_actualizarDireccionNotGoogle",
		actualizarGeneralFields = "Motoclubs_Settings_actualizarGeneralFields",
		actualizarProfileFields = "Motoclubs_Settings_actualizarProfileFields",
		actualizarSocialInfo = "Motoclubs_Settings_actualizarSocialInfo",
		deleteMotoclub = "Motoclubs_Settings_deleteMotoclub",
		bloquearMotoclub = "Motoclubs_Settings_bloquearMotoclub",
		desbloquearMotoclub = "Motoclubs_Settings_desbloquearMotoclub",
		aprobarMotoclub = "Motoclubs_Settings_aprobarMotoclub",

		enviarNotificacionASeguidores = "Motoclubs_Settings_enviarNotificacionASeguidores",
	}

	export interface IInterface {
		motoclub: sharedMotoclub.IShared
		isDeleted: boolean
		notificacionEnviada: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			motoclub: sharedMotoclub.defaultObject,
			isDeleted: false,
			notificacionEnviada: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadMotoclub(apiCallParams: apiCallInterface, params: apiCallIG_Motoclubs_GetMotoclub.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Motoclubs_GetMotoclub.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "motoclub">
				const updateObject: actionSubType = {
					motoclub: apiCallRes.sharedMotoclub,
				}

				utilRedux.TA(D, ET.loadMotoclub, updateObject)
			}
		}
	}

	export function updateMotoclubWithSharedMotoclub(
		apiCallParams: apiCallInterface,
		sharedMotoclub: sharedMotoclub.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "motoclub">
			const updateObject: actionSubType = {
				motoclub: sharedMotoclub,
			}

			utilRedux.TA(D, ET.updateWithSharedMotoclub, updateObject)
		}
	}

	export function actualizarAvatar(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_ActualizarAvatar.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_ActualizarAvatar.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllMotoclubs_Settings.updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.actualizarAvatar, {})
			}
		}
	}

	export function actualizarContact(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_ActualizarContact.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_ActualizarContact.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllMotoclubs_Settings.updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.actualizarContact, {})
			}
		}
	}

	export function actualizarCover(apiCallParams: apiCallInterface, params: apiCallIG_Motoclubs_ActualizarCover.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_ActualizarCover.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllMotoclubs_Settings.updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.actualizarCover, {})
			}
		}
	}

	export function actualizarDireccionGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_ActualizarDireccionGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_ActualizarDireccionGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllMotoclubs_Settings.updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.actualizarDireccionGoogle, {})
			}
		}
	}

	export function actualizarDireccionNotGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_ActualizarDireccionNotGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_ActualizarDireccionNotGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllMotoclubs_Settings.updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.actualizarDireccionNotGoogle, {})
			}
		}
	}

	export function actualizarGeneralFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_ActualizarGeneralFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_ActualizarGeneralFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllMotoclubs_Settings.updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.actualizarGeneralFields, {})
			}
		}
	}

	export function actualizarProfileFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_ActualizarProfileFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_ActualizarProfileFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllMotoclubs_Settings.updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.actualizarProfileFields, {})
			}
		}
	}

	export function actualizarSocialInfo(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_ActualizarSocialInfo.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_ActualizarSocialInfo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllMotoclubs_Settings.updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.actualizarSocialInfo, {})
			}
		}
	}

	export function deleteMotoclub(apiCallParams: apiCallInterface, params: apiCallIG_Motoclubs_DeleteMotoclub.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Motoclubs_DeleteMotoclub.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isDeleted">
				const updateObject: actionSubType = {
					isDeleted: true,
				}

				D(bllGlobalsGeneralData_AllMotoclubs.loadAllMotoclubs(apiCallParams))

				utilRedux.TA(D, ET.deleteMotoclub, updateObject)
			}
		}
	}

	export function bloquearMotoclub(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_BloquearMotoclub.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_BloquearMotoclub.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.bloquearMotoclub, {})
			}
		}
	}

	export function aprobarMotoclub(apiCallParams: apiCallInterface, params: apiCallIG_Motoclubs_AprobarMotoclub.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_AprobarMotoclub.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.aprobarMotoclub, {})
			}
		}
	}

	export function desbloquearMotoclub(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_DesbloquearMotoclub.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Motoclubs_DesbloquearMotoclub.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateMotoclubWithSharedMotoclub(apiCallParams, apiCallRes.sharedMotoclub))

				utilRedux.TA(D, ET.desbloquearMotoclub, {})
			}
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Motoclubs_EnviarNotificacionASeguidores.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_Motoclubs_EnviarNotificacionASeguidores.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}
}
