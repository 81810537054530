/* eslint-disable react/jsx-pascal-case*/
import { Alert, Container } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams, Navigate } from "react-router-dom"
import useGeneral from "../../../hooksData/useGeneral"
import { IState } from "../../../bll/bllReducers"
import useGlobalsUserRoles from "../../../hooksData/useGlobalsUserRoles"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import AdminNotificaciones_General from "./AdminNotificaciones_General"
import SharedBasicTabs, { ISharedTabs_Tab } from "../../Shared/Basic/SharedBasicTabs"
import SharedHeaderBreadcrumbs from "../../Shared/SharedHeaderBreadcrumbs"

export default function AdminNotificaciones() {
	const ug = useGeneral("AdminNotificaciones")

	var tabs: ISharedTabs_Tab[] = [
		{
			name: globalPathsApp.tabsAdminNotificationsEnum.general,
			label: "General",
			component: <AdminNotificaciones_General />,
			iconifyIcon: "iconoir:send-mail",
			urlDest: globalPathsApp.pageInsideUrl.AdminNotifications(globalPathsApp.tabsAdminNotificationsEnum.general),
		},
	]

	return (
		<Container sx={{ position: "relative" }}>
			<SharedHeaderBreadcrumbs
				heading={`Administración de Notificaciones`}
				links={[
					{ name: "Inicio", href: globalPathsApp.pageInsideUrl.Home() },
					{ name: "Admin", href: globalPathsApp.pageInsideUrl.Admin() },
					{ name: "Notificaciones", href: globalPathsApp.pageInsideUrl.AdminNotifications() },
				]}
			/>

			{ug.serverState.errorMessage ? (
				<Alert severity="error">{ug.serverState.errorMessage}</Alert>
			) : (
				<>
					<SharedBasicTabs tabs={tabs} />
				</>
			)}
		</Container>
	)
}
