/* eslint-disable react/jsx-pascal-case*/

import { lazy, Suspense } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { globalPathsApp } from "../../globals/globalPathsApp"
import GlobalFullScreenLoadingForImports from "../Globals/GlobalFullScreenLoadingForImports"
import PageSaludonnetLanding from "../Pages/Saludonnet/PageSaludonnetLanding"
import PageSaludonnetSeguro from "../Pages/Saludonnet/PageSaludonnetSeguro"
import PageSaludonnetProtocoloAccidente from "../Pages/Saludonnet/PageSaludonnetProtocoloAccidente"
import PageSaludonnetTarjetaSeguro from "../Pages/Saludonnet/PageSaludonnetTarjetaSeguro"
import PageSaludonnetTarjetaPiloto from "../Pages/Saludonnet/PageSaludonnetTarjetaPiloto"
import { ContextChatProvider } from "../Contexts/ContextChatProvider"
import PageChat from "../Pages/Chat/PageChat"

const PagePrueba1 = lazy(() => import("../Pages/Pruebas/PagePruebas1"))
const PagePrueba2 = lazy(() => import("../Pages/Pruebas/PagePruebas2"))
const PagePrueba3 = lazy(() => import("../Pages/Pruebas/PagePruebas3"))
const PagePruebaOcr = lazy(() => import("../Pages/Pruebas/PagePruebaOcr"))
const PagePruebaProtected = lazy(() => import("../Pages/Pruebas/PagePruebasProtected"))
const Page404 = lazy(() => import("../Pages/Page404"))

const PageUsersLogin = lazy(() => import("../Pages/Users/PageUsersLogin"))
//Para probar el suspense
// const PageUserLogin = lazy(async () => {
// 	await new Promise((resolve) => setTimeout(resolve, 2000))
// 	return import("../Pages/PageUserLogin")
// })

const PageAdminLanding = lazy(() => import("../Pages/Admin/PageAdminLanding"))
const PageAdminNotifications = lazy(() => import("../Pages/Admin/PageAdminNotifications"))

const PagePilotosLanding = lazy(() => import("../Pages/Pilotos/PagePilotosLanding"))

const PageEscuelasLanding = lazy(() => import("../Pages/Escuelas/PageEscuelasLanding"))
const PageEscuelasProfile = lazy(() => import("../Pages/Escuelas/PageEscuelasProfile"))
const PageEscuelasSettings = lazy(() => import("../Pages/Escuelas/PageEscuelasSettings"))
const PageEscuelasCreate = lazy(() => import("../Pages/Escuelas/PageEscuelasCreate"))

const PageOrganizadoresLanding = lazy(() => import("../Pages/Organizadores/PageOrganizadoresLanding"))
const PageOrganizadoresProfile = lazy(() => import("../Pages/Organizadores/PageOrganizadoresProfile"))
const PageOrganizadoresSettings = lazy(() => import("../Pages/Organizadores/PageOrganizadoresSettings"))
const PageOrganizadoresCreate = lazy(() => import("../Pages/Organizadores/PageOrganizadoresCreate"))

const PageMotoclubsLanding = lazy(() => import("../Pages/Motoclubs/PageMotoclubsLanding"))
const PageMotoclubsProfile = lazy(() => import("../Pages/Motoclubs/PageMotoclubsProfile"))
const PageMotoclubsSettings = lazy(() => import("../Pages/Motoclubs/PageMotoclubsSettings"))
const PageMotoclubsCreate = lazy(() => import("../Pages/Motoclubs/PageMotoclubsCreate"))

const PageMotopolizaLanding = lazy(() => import("../Pages/Motopoliza/PageMotopolizaLanding"))

const PageEmpresasLanding = lazy(() => import("../Pages/Empresas/PageEmpresasLanding"))
const PageEmpresasProfile = lazy(() => import("../Pages/Empresas/PageEmpresasProfile"))
const PageEmpresasSettings = lazy(() => import("../Pages/Empresas/PageEmpresasSettings"))
const PageEmpresasCreate = lazy(() => import("../Pages/Empresas/PageEmpresasCreate"))

const PageCircuitosLanding = lazy(() => import("../Pages/Circuitos/PageCircuitosLanding"))
const PageCircuitosProfile = lazy(() => import("../Pages/Circuitos/PageCircuitosProfile"))
const PageCircuitosSettings = lazy(() => import("../Pages/Circuitos/PageCircuitosSettings"))
const PageCircuitosCreate = lazy(() => import("../Pages/Circuitos/PageCircuitosCreate"))

const PageFederacionesLanding = lazy(() => import("../Pages/Federaciones/PageFederacionesLanding"))
const PageFederacionesProfile = lazy(() => import("../Pages/Federaciones/PageFederacionesProfile"))
const PageFederacionesSettings = lazy(() => import("../Pages/Federaciones/PageFederacionesSettings"))
const PageFederacionesCreate = lazy(() => import("../Pages/Federaciones/PageFederacionesCreate"))

const PageRodadasLanding = lazy(() => import("../Pages/Rodadas/PageRodadasLanding"))
const PageRodadasProfile = lazy(() => import("../Pages/Rodadas/PageRodadasProfile"))
const PageRodadasSettings = lazy(() => import("../Pages/Rodadas/PageRodadasSettings"))
const PageRodadasCreate = lazy(() => import("../Pages/Rodadas/PageRodadasCreate"))
const PageRodadasConfirmEmailInvitation = lazy(() => import("../Pages/Rodadas/PageRodadasConfirmEmailInvitation"))
const PageRodadasConfirmPhoneInvitation = lazy(() => import("../Pages/Rodadas/PageRodadasConfirmPhoneInvitation"))

const PageComingSoon = lazy(() => import("../Pages/PageComingSoon"))
const PageUsersNotifications = lazy(() => import("../Pages/Users/PageUsersNotifications"))

const PagePersonasProfile = lazy(() => import("../Pages/Personas/PagePersonasProfile"))
const PagePersonasSettings = lazy(() => import("../Pages/Personas/PagePersonasSettings"))
const PagePersonasSeguros = lazy(() => import("../Pages/Personas/PagePersonasSeguros"))

const PageLanding = lazy(() => import("../Pages/PageLanding"))
//Para probar el suspense
// const PageLanding = lazy(async () => {
// 	await new Promise((resolve) => setTimeout(resolve, 1000))
// 	return import("../Pages/PageLanding")
// })

const Page403 = lazy(() => import("../Pages/Page403"))

//Para probar el suspense
const PageUsersRegistration = lazy(() => import("../Pages/Users/PageUsersRegistration"))
// const PageUserRegistration = lazy(async () => {
// 	await new Promise((resolve) => setTimeout(resolve, 2000))
// 	return import("../Pages/PageUserRegistration")
// })

const PageUsersResetPassword = lazy(() => import("../Pages/Users/PageUsersResetPassword"))
const PageUsersCreationInvitation = lazy(() => import("../Pages/Users/PageUsersCreationInvitation"))
const PageUsersResendConfirmationEmail = lazy(() => import("../Pages/Users/PageUsersResendConfirmationEmail"))
const PageTermsOfService = lazy(() => import("../Pages/PageTermsOfService"))
const PagePrivacyPolicy = lazy(() => import("../Pages/PagePrivacyPolicy"))
const PageUsersSettings = lazy(() => import("../Pages/Users/PageUsersSettings"))
const PageUsersConfirmationEmailSent = lazy(() => import("../Pages/Users/PageUsersConfirmationEmailSent"))
const PageUsersConfirmEmail = lazy(() => import("../Pages/Users/PageUsersConfirmEmail"))
const PageUsersGetPasswordResetToken = lazy(() => import("../Pages/Users/PageUsersGetPasswordResetToken"))
const PageUsersGetPasswordResetTokenEmailSent = lazy(
	() => import("../Pages/Users/PageUsersGetPasswordResetTokenEmailSent")
)
const PageUsersResetPasswordCompleted = lazy(() => import("../Pages/Users/PageUsersResetPasswordCompleted"))
const PageHome = lazy(() => import("../Pages/PageHome"))

export const App_RootWrapper_Router = () => {
	// const ug = useGeneral("App_RootWrapper_Router")

	// const isLogged = useSelector((state: IState) => state.Users.Login.isLogged)

	// if (!isLogged) {
	// 	D(bllUsers_Login.loginFromStorageIfItHasLoginRes(key))
	// }

	return (
		<BrowserRouter>
			<ContextChatProvider>
				<Suspense fallback={<GlobalFullScreenLoadingForImports />}>
					<Routes>
						<Route path="" element={<PageLanding />} />

						{/* <Route path={`/${globalAppPages_BaseURL}`} element={<MmLayout />}> */}

						<Route path={`/${globalPathsApp.baseUrl}`}>
							<Route index element={<PageHome />} />
							<Route path={globalPathsApp.pagesEnum.Prueba1} element={<PagePrueba1 />} />
							<Route path={globalPathsApp.pagesEnum.Prueba2} element={<PagePrueba2 />} />
							<Route path={globalPathsApp.pagesEnum.Prueba3} element={<PagePrueba3 />} />
							<Route path={globalPathsApp.pagesEnum.PruebaOcr} element={<PagePruebaOcr />} />

							<Route path={globalPathsApp.pagesEnum.Chat} element={<PageChat />} />
							<Route path={globalPathsApp.pagesEnum.TermsOfService} element={<PageTermsOfService />} />
							<Route path={globalPathsApp.pagesEnum.PrivacyPolicy} element={<PagePrivacyPolicy />} />
							<Route path={globalPathsApp.pagesEnum.ComingSoon} element={<PageComingSoon />} />
							<Route path={globalPathsApp.pagesEnum.UsersNotifications} element={<PageUsersNotifications />} />
							<Route path={globalPathsApp.pagesEnum.UsersLogin} element={<PageUsersLogin />} />
							<Route
								path={globalPathsApp.pagesEnum.UsersResendConfirmationEmail}
								element={<PageUsersResendConfirmationEmail />}
							/>
							<Route path={globalPathsApp.pagesEnum.UsersSettings} element={<PageUsersSettings />} />
							<Route
								path={globalPathsApp.pagesEnum.UsersConfirmationEmailSent}
								element={<PageUsersConfirmationEmailSent />}
							/>
							<Route path={globalPathsApp.pagesEnum.UsersConfirmEmail} element={<PageUsersConfirmEmail />} />
							<Route path={globalPathsApp.pagesEnum.UsersRegistration} element={<PageUsersRegistration />} />
							<Route path={globalPathsApp.pagesEnum.UsersResetPassword} element={<PageUsersResetPassword />} />
							<Route
								path={globalPathsApp.pagesEnum.UsersCreationInvitation}
								element={<PageUsersCreationInvitation />}
							/>
							<Route
								path={globalPathsApp.pagesEnum.UsersResetPasswordCompleted}
								element={<PageUsersResetPasswordCompleted />}
							/>
							<Route
								path={globalPathsApp.pagesEnum.UsersGetPasswordResetToken}
								element={<PageUsersGetPasswordResetToken />}
							/>
							<Route
								path={globalPathsApp.pagesEnum.UsersGetPasswordResetTokenEmailSent}
								element={<PageUsersGetPasswordResetTokenEmailSent />}
							/>
							<Route path={globalPathsApp.pagesEnum.PersonasProfile} element={<PagePersonasProfile />} />
							<Route path={globalPathsApp.pagesEnum.PersonasSettings} element={<PagePersonasSettings />} />
							<Route path={globalPathsApp.pagesEnum.PersonasSeguros} element={<PagePersonasSeguros />} />
							<Route path={globalPathsApp.pagesEnum.Pilotos} element={<PagePilotosLanding />} />

							<Route path={globalPathsApp.pagesEnum.Circuitos} element={<PageCircuitosLanding />} />
							<Route path={globalPathsApp.pagesEnum.CircuitosProfile} element={<PageCircuitosProfile />} />
							<Route path={globalPathsApp.pagesEnum.CircuitosCreate} element={<PageCircuitosCreate />} />
							<Route path={globalPathsApp.pagesEnum.CircuitosSettings} element={<PageCircuitosSettings />} />

							<Route path={globalPathsApp.pagesEnum.Rodadas} element={<PageRodadasLanding />} />
							<Route path={globalPathsApp.pagesEnum.RodadasProfile} element={<PageRodadasProfile />} />
							<Route path={globalPathsApp.pagesEnum.RodadasSettings} element={<PageRodadasSettings />} />
							<Route path={globalPathsApp.pagesEnum.RodadasCreate} element={<PageRodadasCreate />} />
							<Route
								path={globalPathsApp.pagesEnum.RodadasConfirmEmailInvitation}
								element={<PageRodadasConfirmEmailInvitation />}
							/>
							<Route
								path={globalPathsApp.pagesEnum.RodadasConfirmPhoneNumberInvitation}
								element={<PageRodadasConfirmPhoneInvitation />}
							/>

							<Route path={globalPathsApp.pagesEnum.Escuelas} element={<PageEscuelasLanding />} />
							<Route path={globalPathsApp.pagesEnum.EscuelasProfile} element={<PageEscuelasProfile />} />
							<Route path={globalPathsApp.pagesEnum.EscuelasCreate} element={<PageEscuelasCreate />} />
							<Route path={globalPathsApp.pagesEnum.EscuelasSettings} element={<PageEscuelasSettings />} />

							<Route path={globalPathsApp.pagesEnum.Motoclubs} element={<PageMotoclubsLanding />} />
							<Route path={globalPathsApp.pagesEnum.MotoclubsProfile} element={<PageMotoclubsProfile />} />
							<Route path={globalPathsApp.pagesEnum.MotoclubsCreate} element={<PageMotoclubsCreate />} />
							<Route path={globalPathsApp.pagesEnum.MotoclubsSettings} element={<PageMotoclubsSettings />} />

							<Route path={globalPathsApp.pagesEnum.Seguros} element={<PageSaludonnetLanding />} />
							<Route path={globalPathsApp.pagesEnum.SegurosSeguro} element={<PageSaludonnetSeguro />} />
							<Route path={globalPathsApp.pagesEnum.SegurosTarjetaPiloto} element={<PageSaludonnetTarjetaPiloto />} />
							<Route path={globalPathsApp.pagesEnum.SegurosTarjetaSeguro} element={<PageSaludonnetTarjetaSeguro />} />
							<Route
								path={globalPathsApp.pagesEnum.SegurosProtocoloAccidente}
								element={<PageSaludonnetProtocoloAccidente />}
							/>

							<Route path={globalPathsApp.pagesEnum.Organizadores} element={<PageOrganizadoresLanding />} />
							<Route path={globalPathsApp.pagesEnum.OrganizadoresProfile} element={<PageOrganizadoresProfile />} />
							<Route path={globalPathsApp.pagesEnum.OrganizadoresCreate} element={<PageOrganizadoresCreate />} />
							<Route path={globalPathsApp.pagesEnum.OrganizadoresSettings} element={<PageOrganizadoresSettings />} />

							<Route path={globalPathsApp.pagesEnum.Motopoliza} element={<PageMotopolizaLanding />} />

							<Route path={globalPathsApp.pagesEnum.Empresas} element={<PageEmpresasLanding />} />
							<Route path={globalPathsApp.pagesEnum.EmpresasProfile} element={<PageEmpresasProfile />} />
							<Route path={globalPathsApp.pagesEnum.EmpresasCreate} element={<PageEmpresasCreate />} />
							<Route path={globalPathsApp.pagesEnum.EmpresasSettings} element={<PageEmpresasSettings />} />

							<Route path={globalPathsApp.pagesEnum.Federaciones} element={<PageFederacionesLanding />} />
							<Route path={globalPathsApp.pagesEnum.FederacionesProfile} element={<PageFederacionesProfile />} />
							<Route path={globalPathsApp.pagesEnum.FederacionesCreate} element={<PageFederacionesCreate />} />
							<Route path={globalPathsApp.pagesEnum.FederacionesSettings} element={<PageFederacionesSettings />} />

							<Route path={globalPathsApp.pagesEnum.Admin} element={<PageAdminLanding />} />
							<Route path={globalPathsApp.pagesEnum.AdminNotifications} element={<PageAdminNotifications />} />

							<Route path={globalPathsApp.pagesEnum.PruebaProtected} element={<PagePruebaProtected />} />
							<Route path={globalPathsApp.pagesEnum.Error403} element={<Page403 />} />
							<Route path="*" element={<Page404 />} />
						</Route>
					</Routes>
				</Suspense>
			</ContextChatProvider>
		</BrowserRouter>
	)
}
