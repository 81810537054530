import { combineReducers } from "redux"
import { bllPosteos_General } from "./bllPosteos_General"

export default combineReducers({
	General: bllPosteos_General.reducerFunction,
})

export interface IPosteos {
	General: bllPosteos_General.IInterface
}
