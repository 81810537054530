/* eslint-disable react/jsx-pascal-case*/
import { Card, CardHeader, Container } from "@mui/material"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import RodadasShared_Table from "./RodadasShared_Table"
import RodadasShared_CardList from "./RodadasShared_CardList"

interface IProps {}

export default function RodadasShared_RodadasSeguidas({}: IProps) {
	const rodadasSeguidas = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaRodadasSeguidas.rodadasSeguidas
	)

	return (
		<Container sx={{ position: "relative" }}>
			<Card>
				<CardHeader title="Rodadas Seguidas" sx={{ mb: 3 }} />

				<RodadasShared_CardList rodadas={rodadasSeguidas} />
			</Card>
		</Container>
	)
}
