import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { sharedEstado } from "./sharedEstado"
import { sharedDireccion } from "./sharedDireccion"
import { utilDate } from "../../utils/utilDate"

export namespace sharedChatRoom {
	export interface IShared {
		id: number
		dateCreation: Date | null
		dateLastMessage: Date | null
		lastMessage: string
		chatType: number
		idEntity: number
		nameEntity: string
		avatarUrlEntity: string
		idPerson1: number
		namePerson1: string
		avatarUrlPerson1: string
		readByPerson1: boolean
		idPerson2: number
		namePerson2: string
		avatarUrlPerson2: string
		readByPerson2: boolean
	}

	export const defaultObject: IShared = {
		id: 0,
		dateCreation: null,
		dateLastMessage: null,
		lastMessage: "",
		chatType: 0,
		idEntity: 0,
		nameEntity: "",
		avatarUrlEntity: globalAssets.staticPaths.circuitoAvatar1,
		idPerson1: 0,
		namePerson1: "",
		avatarUrlPerson1: globalAssets.staticPaths.personaAvatar,
		readByPerson1: false,
		idPerson2: 0,
		namePerson2: "",
		avatarUrlPerson2: globalAssets.staticPaths.personaAvatar,
		readByPerson2: false,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
			dateCreation: utilDate.fDateFromNet(apiObject.dateCreation),
			dateLastMessage: utilDate.fDateFromNet(apiObject.dateLastMessage),
			avatarUrlEntity: apiObject.avatarUrlEntity || globalAssets.staticPaths.personaAvatar,
			avatarUrlPerson1: apiObject.avatarUrlPerson1 || globalAssets.staticPaths.personaAvatar,
			avatarUrlPerson2: apiObject.avatarUrlPerson2 || globalAssets.staticPaths.personaAvatar,
		}

		return res
	}
}
