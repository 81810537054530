/* eslint-disable react/jsx-pascal-case*/
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useGlobalsUserRoles from "../../../hooksData/useGlobalsUserRoles"
import { ISharedTabs_Tab } from "../../Shared/Basic/SharedBasicTabs"
import OrganizadoresShared_AllOrganizadores from "../Shared/OrganizadoresShared_AllOrganizadores"
import OrganizadoresShared_MisOrganizadores from "../Shared/OrganizadoresShared_MisOrganizadores"
import OrganizadoresShared_OrganizadoresSeguidos from "../Shared/OrganizadoresShared_OrganizadoresSeguidos"

export const OrganizadoresLanding_Menu = (): ISharedTabs_Tab[] => {
	const { isUserCurrentRol_Admin } = useGlobalsUserRoles()

	var menu: ISharedTabs_Tab[] = []

	menu.push({
		name: globalPathsApp.tabsOrganizadoresEnum.all,
		label: "Todos los organizadores",
		component: <OrganizadoresShared_AllOrganizadores />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Organizadores(globalPathsApp.tabsOrganizadoresEnum.all),
	})

	if (isUserCurrentRol_Admin) {
		menu.push({
			name: globalPathsApp.tabsOrganizadoresEnum.own,
			label: "Mis organizadores",
			component: <OrganizadoresShared_MisOrganizadores />,
			iconifyIcon: "maki:racetrack",
			urlDest: globalPathsApp.pageInsideUrl.Organizadores(globalPathsApp.tabsOrganizadoresEnum.own),
		})
	}

	menu.push({
		name: globalPathsApp.tabsOrganizadoresEnum.following,
		label: "Organizadores seguidos",
		component: <OrganizadoresShared_OrganizadoresSeguidos />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Organizadores(globalPathsApp.tabsOrganizadoresEnum.following),
	})

	return menu
}
