import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../bll/bllReducers"
import { bllGlobalsServer_ErrorMessageList } from "../bll/GlobalsServer/bllGlobalsServer_ErrorMessageList"
import { bllGlobalsServer_ModelErrorsList } from "../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { bllGlobalsServer_LoadingList } from "../bll/GlobalsServer/bllGlobalsServer_LoadingList"
import { bllGlobalsServer_MessageList } from "../bll/GlobalsServer/bllGlobalsServer_MessageList"
import { bllGlobalsServer_FinishedList } from "../bll/GlobalsServer/bllGlobalsServer_FinishedList"

export default function useServerState(key: string) {
	const D = useDispatch()

	const serverIsLoading = useSelector((state: IState) => state.GlobalsServer.LoadingList[key]?.isLoading || false)
	const serverModelErrors = useSelector((state: IState) => state.GlobalsServer.ModelErrorsList[key] || {})
	const serverErrorMessage = useSelector(
		(state: IState) => state.GlobalsServer.ErrorMessageList[key]?.serverErrorMessage || ""
	)
	const serverMessage = useSelector((state: IState) => state.GlobalsServer.MessageList[key]?.serverMessage || "")
	const serverfinishedOk = useSelector((state: IState) => state.GlobalsServer.FinishedList[key]?.finishedOk || false)

	const clean = async () => {
		await D(bllGlobalsServer_ModelErrorsList.cleanServerModelErrors(key))
		await D(bllGlobalsServer_ErrorMessageList.cleanErrorMessages(key))
		await D(bllGlobalsServer_MessageList.cleanMessages(key))
		await D(bllGlobalsServer_FinishedList.cleanFinished(key))
	}

	const triggerLoading = async (isLoading: boolean) => {
		await D(bllGlobalsServer_LoadingList.setServerLoading(key, isLoading))
	}

	useEffect(() => {
		clean()

		return () => {
			clean()
		}
	}, [])

	useEffect(() => {}, [serverModelErrors])

	const serverData = {
		isLoading: serverIsLoading,
		modelErrors: serverModelErrors,
		errorMessage: serverErrorMessage,
		message: serverMessage,
		finishedOk: serverfinishedOk,
		clean,
		triggerLoading: triggerLoading,
	}

	return serverData
}
