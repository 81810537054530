/* eslint-disable react/jsx-pascal-case*/
import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

interface IProps {
	text: string
}

const LabelStyle = styled(Typography)(({ theme }) => ({
	...theme.typography.subtitle2,
	color: theme.palette.text.secondary,
	marginBottom: theme.spacing(1),
}))

export default function SharedLabelForForm({ text }: IProps) {
	return <LabelStyle>{text}</LabelStyle>
}
