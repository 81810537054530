import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedCircuito } from "../../../shared/sharedCircuito"
import { sharedRodada } from "../../../shared/sharedRodada"
import { apiCallInterface } from "../../../apiCallInterface"
import { sharedPosteos } from "../../../shared/sharedPosteos"

/*
  [HttpPost(ApiRoutesPosteos.DeletePosteo)]
  public async Task<ActionResult> DeletePosteo(DeletePosteoReq req)
  {
    var userId = User.GetUserId();

    var posteo = await GetPosteoWithExceptionIfNull(req.IdPosteo);

    if (posteo.IdPersonaCreadora != null)
    {
      var persona = await _personasService.GetPersonaById(posteo.IdPersonaCreadora.Value);

      if (persona == null)
        throw new Exception("No se ha encontrado la persona para el id: " + posteo.IdPersonaCreadora.Value.ToString());

      await _generalSecurityService.UsuarioDebeTenerPermisosSobrePersona(userId, persona);
    }

    if (posteo.IdCircuitoCreador != null)
    {
      var circuito = await _circuitosService.GetCircuitoById(posteo.IdCircuitoCreador.Value);

      if (circuito == null)
        throw new Exception("No se ha encontrado el circuito para el id: " + posteo.IdCircuitoCreador.Value.ToString());

      await _generalSecurityService.UsuarioDebeTenerPermisosSobreCircuito(userId, circuito);
    }

    await _posteosService.Delete(posteo);

    return Ok();
  }
		*/

export namespace apiCallIG_Posteos_DeletePosteo {
	export interface params {
		idPosteo: number
	}
	export const paramsName = {
		idPosteo: "idPosteo",
	}
	export const paramsDesc = {
		idPosteo: "IdPosteo",
	}
	export interface IRes {}
	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Posteos/DeletePosteo`
		const sharedParamsQuery: params | null = null
		const sharedParamsBody = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {}
	}
}
