import { globalConfig } from "../../../globals/globalConfig"
import { apiCallInterface } from "../../apiCallInterface"
import { apiRegularCall } from "../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../apiRegularCall_Ax"
import { sharedSN_CentroUrgencia } from "../../shared/SN/sharedSN_CentroUrgencia"

export namespace apiCallSN_GetHospitalesDeUrgencia {
	export const paramsDesc = {}

	export interface IRes {
		sharedSN_CentrosUrgencias: sharedSN_CentroUrgencia.IShared[]
	}

	// const validations = async (apiCallParams: apiCallInterface, params: bodyParams) => {
	// 	const serverModelErrors: IServerModelErrors = {}

	// 	return await dispatchServerModelErrors(key, serverModelErrors)
	// }

	export const apiCall = async (apiCallParams: apiCallInterface): Promise<IRes | null> => {
		// if (await validations(apiCallParams, params)) {
		// 	return null
		// }

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.get
		const apiCallUrl = `${globalConfig.apiPathBaseSaludOnNet}/Siniestros/GetHospitalesDeUrgencia`
		const sharedParamsQuery = null
		const sharedParamsBody = null

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedSN_CentrosUrgencias: sharedSN_CentroUrgencia.getSharedList_FromApiObject(
				apiCallRes.listHospitalesDeUrgencias
			),
		}
	}
}
