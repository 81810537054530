import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Rodadas_ActualizarDisclaimer } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_ActualizarDisclaimer"
import { apiCallIG_Rodadas_ActualizarGeneralFields } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_ActualizarGeneralFields"
import { apiCallIG_Rodadas_ActualizarProfileFields } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_ActualizarProfileFields"
import { apiCallIG_Rodadas_DeleteRodada } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_DeleteRodada"
import { apiCallIG_Rodadas_EnviarNotificacionASeguidores } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_EnviarNotificacionASeguidores"
import { apiCallIG_Rodadas_GetRodada } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_GetRodada"
import { apiCallIG_RodadasInvitations_DeleteById } from "../../api/calls/IG/RodadasInvitations_OLD/apiCallIG_RodadasInvitations_DeleteById"
import { apiCallIG_RodadasPersonas_ConfirmarPersona } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_ConfirmarPersona"
import { apiCallIG_RodadasPersonas_GetRodadasPersonasDeleteById } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_DeleteById"
import { apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada"
import { apiCallIG_RodadasPersonas_GetRodadasPersonasMapeoExcelByIdPersona } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_GetRodadasPersonasMapeoExcelByIdPersona"
import { apiCallIG_RodadasSeguidores_GetRodadasSeguidoresByIdRodada } from "../../api/calls/IG/RodadasSeguidores/apiCallIG_RodadasSeguidores_GetRodadasSeguidoresByIdRodada"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { sharedRodadaInvitation } from "../../api/shared/sharedRodadaInvitation"
import { sharedRodadaPersona } from "../../api/shared/sharedRodadaPersona"
import { sharedRodadaPersonaMapeoExcel } from "../../api/shared/sharedRodadaPersonaMapeoExcel"
import { sharedRodadaSeguidor } from "../../api/shared/sharedRodadaSeguidor"
import { utilRedux } from "../../utils/utilRedux"
import { bllGlobalsGeneralData_AllRodadas } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllRodadas"

export namespace bllRodadas_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Rodadas_Settings_setInitialState",
		loadRodada = "Rodadas_Settings_loadRodada",
		actualizarProfileFields = "Rodadas_Settings_actualizarProfileFields",
		actualizarGeneralFields = "Rodadas_Settings_actualizarGeneralFields",
		actualizarDisclaimer = "Rodadas_Settings_actualizarDisclaimer",
		loadRodadasPersonas = "Rodadas_Settings_loadRodadasPersonas",
		updateRodadasPersonas = "Rodadas_Settings_updateRodadasPersonas",
		confirmPersonInRodada = "Rodadas_Settings_confirmPersonInRodada",
		deletePersonFromRodada = "Rodadas_Settings_deletePersonFromRodada",
		loadRodadasInvitations = "Rodadas_Settings_loadRodadasInvitations",
		loadRodadasPersonasMapeoExcel = "Rodadas_Settings_loadRodadasPersonasMapeoExcel",
		// inviteEmail = "Rodadas_Settings_inviteEmail",
		// invitePhoneNumber = "Rodadas_Settings_invitePhoneNumber",
		deleteInvitation = "Rodadas_Settings_deleteInvitation",
		deleteRodada = "Rodadas_Settings_deleteRodada",
		enviarNotificacionASeguidores = "Rodadas_Settings_enviarNotificacionASeguidores",
		getSeguidores = "Rodadas_Settings_getSeguidores",
	}

	export interface IInterface {
		rodada: sharedRodada.IShared
		rodadasPersonas: sharedRodadaPersona.IShared[]
		rodadasInvitations: sharedRodadaInvitation.IShared[]
		rodadaPersonaMapeoExcel: sharedRodadaPersonaMapeoExcel.IShared
		isDeleted: boolean
		notificacionEnviada: boolean
		seguidores: sharedRodadaSeguidor.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			rodada: sharedRodada.objectDefault,
			rodadasPersonas: [],
			rodadasInvitations: [],
			rodadaPersonaMapeoExcel: sharedRodadaPersonaMapeoExcel.objectDefault,
			isDeleted: false,
			notificacionEnviada: false,
			seguidores: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Rodada_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadRodada(apiCallParams: apiCallInterface, params: apiCallIG_Rodadas_GetRodada.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Rodadas_GetRodada.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(loadRodadasPersonas(apiCallParams, { idRodada: params.idRodada }))

				D(loadRodadaPersonaMapeoExcel(apiCallParams, { idPersona: apiCallRes.sharedRodada.idPersonaCreadora }))

				// D(loadRodadasInvitations(apiCallParams, { idRodada: params.idRodada }))

				type actionSubType = Pick<IInterface, "rodada">
				const updateObject: actionSubType = {
					rodada: apiCallRes.sharedRodada,
				}

				utilRedux.TA(D, ET.loadRodada, updateObject)
			}
		}
	}

	export function getSeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasSeguidores_GetRodadasSeguidoresByIdRodada.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasSeguidores_GetRodadasSeguidoresByIdRodada.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "seguidores">
				const updateObject: actionSubType = {
					seguidores: apiCallRes.sharedRodadaSeguidores,
				}

				utilRedux.TA(D, ET.getSeguidores, updateObject)
			}
		}
	}

	export function updateRodadaWithSharedRodada(sharedRodada: sharedRodada.IShared) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "rodada">
			const updateObject: actionSubType = {
				rodada: sharedRodada,
			}

			utilRedux.TA(D, ET.loadRodada, updateObject)
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Rodadas_EnviarNotificacionASeguidores.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_Rodadas_EnviarNotificacionASeguidores.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}

	export function actualizarProfileFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Rodadas_ActualizarProfileFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Rodadas_ActualizarProfileFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateRodadaWithSharedRodada(apiCallRes.sharedRodada))

				utilRedux.TA(D, ET.actualizarProfileFields, {})
			}
		}
	}

	export function actualizarGeneralFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Rodadas_ActualizarGeneralFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Rodadas_ActualizarGeneralFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateRodadaWithSharedRodada(apiCallRes.sharedRodada))

				utilRedux.TA(D, ET.actualizarGeneralFields, {})
			}
		}
	}

	export function actualizarDisclaimer(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Rodadas_ActualizarDisclaimer.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Rodadas_ActualizarDisclaimer.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllRodadas_Settings.updateRodadaWithSharedRodada(apiCallRes.sharedRodada))

				utilRedux.TA(D, ET.actualizarDisclaimer, {})
			}
		}
	}

	export function loadRodadasPersonas(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada.params
	) {
		//D = dispatch
		return async (D: any) => {
			// await D(setInitialState())

			const apiCallRes = await apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "rodadasPersonas">
				const updateObject: actionSubType = {
					rodadasPersonas: apiCallRes.sharedRodadasPersonas,
				}

				utilRedux.TA(D, ET.loadRodadasPersonas, updateObject)
			}
		}
	}

	export function loadRodadaPersonaMapeoExcel(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_GetRodadasPersonasMapeoExcelByIdPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			// await D(setInitialState())

			const apiCallRes = await apiCallIG_RodadasPersonas_GetRodadasPersonasMapeoExcelByIdPersona.apiCall(
				apiCallParams,
				params
			)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "rodadaPersonaMapeoExcel">
				const updateObject: actionSubType = {
					rodadaPersonaMapeoExcel: apiCallRes.sharedRodadasPersonasMapeoExcel,
				}

				utilRedux.TA(D, ET.loadRodadasPersonasMapeoExcel, updateObject)
			}
		}
	}

	export function updateRodadasPersonas(
		apiCallParams: apiCallInterface,
		rodadasPersonas: sharedRodadaPersona.IShared[]
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "rodadasPersonas">
			const updateObject: actionSubType = {
				rodadasPersonas: rodadasPersonas,
			}

			utilRedux.TA(D, ET.updateRodadasPersonas, updateObject)
		}
	}

	export function confirmPersonInRodada(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_ConfirmarPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasPersonas_ConfirmarPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "rodadasPersonas">
				const updateObject: actionSubType = {
					rodadasPersonas: apiCallRes.sharedRodadasPersonas,
				}

				utilRedux.TA(D, ET.confirmPersonInRodada, updateObject)
			}
		}
	}

	export function deletePersonFromRodada(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_GetRodadasPersonasDeleteById.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasPersonas_GetRodadasPersonasDeleteById.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "rodadasPersonas">
				const updateObject: actionSubType = {
					rodadasPersonas: apiCallRes.sharedRodadasPersonas,
				}

				utilRedux.TA(D, ET.deletePersonFromRodada, updateObject)
			}
		}
	}

	// export function loadRodadasInvitations(
	// 	apiCallParams: apiCallInterface,
	// 	params: apiCallIG_RodadasInvitations_GetRodadasInvitationsByIdRodada.params
	// ) {
	// 	//D = dispatch
	// 	return async (D: any) => {
	// 		await D(setInitialState())

	// 		const apiCallRes = await apiCallIG_RodadasInvitations_GetRodadasInvitationsByIdRodada.apiCall(apiCallParams, params)

	// 		if (apiCallRes) {
	// 			type actionSubType = Pick<IInterface, "rodadasInvitations">
	// 			const updateObject: actionSubType = {
	// 				rodadasInvitations: apiCallRes.sharedRodadasInvitations,
	// 			}

	// 			utilRedux.TA(D, ET.loadRodadasInvitations, updateObject)
	// 		}
	// 	}
	// }

	// export function inviteEmail(apiCallParams: apiCallInterface, params: apiCallIG_RodadasInvitations_InvitarEmail.params) {
	// 	//D = dispatch
	// 	return async (D: any) => {
	// 		const apiCallRes = await apiCallIG_RodadasInvitations_InvitarEmail.apiCall(apiCallParams, params)

	// 		if (apiCallRes) {
	// 			await bllDispatchs_GlobalsServer.setMessage(apiCallParams, "Invitación realizada correctamente.")

	// 			type actionSubType = Pick<IInterface, "rodadasInvitations">
	// 			const updateObject: actionSubType = {
	// 				rodadasInvitations: apiCallRes.sharedRodadasInvitations,
	// 			}

	// 			utilRedux.TA(D, ET.inviteEmail, updateObject)
	// 		}
	// 	}
	// }

	// export function invitePhoneNumber(apiCallParams: apiCallInterface, params: apiCallIG_RodadasInvitations_InvitarPhoneNumber.params) {
	// 	//D = dispatch
	// 	return async (D: any) => {
	// 		const apiCallRes = await apiCallIG_RodadasInvitations_InvitarPhoneNumber.apiCall(apiCallParams, params)

	// 		if (apiCallRes) {
	// 			await bllDispatchs_GlobalsServer.setMessage(apiCallParams, "Invitación realizada correctamente.")

	// 			type actionSubType = Pick<IInterface, "rodadasInvitations">
	// 			const updateObject: actionSubType = {
	// 				rodadasInvitations: apiCallRes.sharedRodadasInvitations,
	// 			}

	// 			utilRedux.TA(D, ET.invitePhoneNumber, updateObject)
	// 		}
	// 	}
	// }

	export function deleteInvitation(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasInvitations_DeleteById.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasInvitations_DeleteById.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "rodadasInvitations">
				const updateObject: actionSubType = {
					rodadasInvitations: apiCallRes.sharedRodadasInvitations,
				}

				utilRedux.TA(D, ET.deleteInvitation, updateObject)
			}
		}
	}

	export function deleteRodada(apiCallParams: apiCallInterface, params: apiCallIG_Rodadas_DeleteRodada.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Rodadas_DeleteRodada.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isDeleted">
				const updateObject: actionSubType = {
					isDeleted: true,
				}

				D(bllGlobalsGeneralData_AllRodadas.loadAllRodadas(apiCallParams))

				utilRedux.TA(D, ET.deleteRodada, updateObject)
			}
		}
	}
}
