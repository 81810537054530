/* eslint-disable react/jsx-pascal-case*/
import { InfoWindow, Marker } from "@react-google-maps/api"

type Props = {
	id: string | number
	centerLat: number
	centerLng: number
	showInfoWindow?: boolean
	selectMarker: (id: string | number | null) => void
	selectedMarker?: string | number | null
	infoWindowContent?: React.ReactNode
}

export default function SharedGoogleMapMarker2({
	id,
	centerLat,
	centerLng,
	showInfoWindow,
	selectMarker,
	selectedMarker,
	infoWindowContent,
}: Props) {
	const center = { lat: centerLat, lng: centerLng }

	return (
		<Marker position={center} onClick={() => selectMarker(id)}>
			{selectedMarker === id && (
				<InfoWindow
					position={center}
					onCloseClick={() => {
						selectMarker(null)
					}}
				>
					{infoWindowContent}
				</InfoWindow>
			)}
		</Marker>
	)
}
