import { number } from "yup"

export namespace sharedRodadaPersona {
	export interface IShared {
		id: number
		idRodada: number
		idPersona: number
		confirmada: boolean
		personaAcepto: boolean
		nombrePersona: string
		apellido1Persona: string
		avatarURLPersona: string
		emailInvitado: string
		emailInvitadoToken: string
		emailInvitadoFecha: Date | null
		phoneNumberInvitado: string
		phoneNumberInvitadoToken: string
		phoneNumberInvitadoFecha: Date | null
		firmaDisclaimerURL: string
		disclaimerFirmadoHtml: string
	}

	export const objectDefault: IShared = {
		id: 0,
		idRodada: 0,
		idPersona: 0,
		confirmada: false,
		personaAcepto: false,
		nombrePersona: "",
		apellido1Persona: "",
		avatarURLPersona: "",
		emailInvitado: "",
		emailInvitadoToken: "",
		emailInvitadoFecha: null,
		phoneNumberInvitado: "",
		phoneNumberInvitadoToken: "",
		phoneNumberInvitadoFecha: null,
		firmaDisclaimerURL: "",
		disclaimerFirmadoHtml: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}
}
