import { bllGlobalsServer_ModelErrorsList } from "../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { utilFormatValidations } from "../utils/utilFormatValidations"

export namespace apiValidations2 {
	export const validateMaxNumberOfChars = (
		fieldName: string,
		fieldDesc: string,
		value: string | null,
		maxNumberOfChars: number,
		serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors
	) => {
		if (serverModelErrors[fieldName.toLowerCase()]) return

		if (!value) return

		if (value.length > maxNumberOfChars) {
			serverModelErrors[
				fieldName.toLowerCase()
			] = `El campo "${fieldDesc}" no puede contener más de ${maxNumberOfChars} caracteres.`
		}
	}

	export const validateRequired = (
		fieldName: string,
		fieldDesc: string,
		value: any,
		serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors
	) => {
		if (serverModelErrors[fieldName.toLowerCase()]) return

		if (!value) {
			serverModelErrors[fieldName.toLowerCase()] = `El campo "${fieldDesc}" es requerido.`
		}
	}

	export const validateMustBeEmail = (
		fieldName: string,
		fieldDesc: string,
		value: any,
		serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors
	) => {
		if (serverModelErrors[fieldName.toLowerCase()]) return

		if (!utilFormatValidations.isEmail(value))
			serverModelErrors[fieldName.toLowerCase()] = `El campo "${fieldDesc}" debe ser una dirección de email válida.`
	}

	export const validateMustBeUrl = (
		fieldName: string,
		fieldDesc: string,
		value: any,
		serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors
	) => {
		if (serverModelErrors[fieldName.toLowerCase()]) return

		if (!utilFormatValidations.isUrl(value))
			serverModelErrors[fieldName.toLowerCase()] = `El campo "${fieldDesc}" debe ser una dirección web válida.`
	}

	export const validateDateMinDateMax = (
		dateMinFieldName: string,
		dateMaxFieldName: string,
		dateMinFieldDesc: string,
		dateMaxFieldDesc: string,
		dateMinValue: Date,
		dateMaxValue: Date,
		serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors
	) => {
		if (serverModelErrors[dateMinFieldName.toLowerCase()]) return
		if (serverModelErrors[dateMaxFieldName.toLowerCase()]) return

		if (dateMinValue > dateMaxValue) {
			serverModelErrors[
				dateMinFieldName.toLowerCase()
			] = `La "${dateMinFieldDesc}" no puede ser mayor a la "${dateMaxFieldDesc}".`
			serverModelErrors[
				dateMaxFieldName.toLowerCase()
			] = `La "${dateMinFieldDesc}" no puede ser mayor a la "${dateMaxFieldDesc}".`
		}
	}
}
