import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { utilDate } from "../../utils/utilDate"
import { sharedCircuitoEstado } from "./sharedCircuitoEstado"
import { sharedCircuitoPista } from "./sharedCircuitoPista"
import { sharedDireccion } from "./sharedDireccion"

export namespace sharedPersonasPosteos {
	export interface IShared {
		id: number
		fechaCreacion: Date | null
		idPersona: number
		texto: string
	}

	export const defaultObject: IShared = {
		id: 0,
		fechaCreacion: null,
		idPersona: 0,
		texto: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
			fechaCreacion: utilDate.fDateFromNet(apiObject.fechaCreacion),
		}

		return res
	}
}
