import { bllDispatchs_GlobalsServer } from "../bll/bllDispatchs_GlobalsServer"
import { bllDispatchs_GlobalsUI } from "../bll/bllDispatchs_GlobalsUI"
import { utilFunctions } from "../utils/utilFunctions"
import { utilLogger } from "../utils/utilLogger"
import store from "../utils/utilReduxStore"
import { apiCallInterface } from "./apiCallInterface"
import { apiRegularCall_Ax_v2 } from "./apiRegularCall_Ax"
import _ from "lodash"

export namespace apiRegularCall {
	export const call_v2 = async (
		apiCallParams: apiCallInterface,
		method: apiRegularCall_Ax_v2.Methods_Enum,
		urlWithoutParams: string,
		queryParamsObject?: any,
		bodyParamsObject?: any,
		formData?: any
	): Promise<any | null> => {
		await bllDispatchs_GlobalsServer.cleanAll(apiCallParams.key)
		await bllDispatchs_GlobalsServer.setLoading(apiCallParams.key, true)

		utilLogger.hit(
			apiRegularCall_Ax_v2.Methods_Enum[method].toString(),
			apiCallParams.key,
			urlWithoutParams,
			queryParamsObject,
			bodyParamsObject
		)

		// await utilFunctions.sleep(1000) //TODO: Comentar esta línea, está solo para probar los loadings

		//------------------LLAMADA
		const startTime = new Date().getTime()

		const apiAxCallRes = await apiRegularCall_Ax_v2.callv2(
			method,
			urlWithoutParams,
			queryParamsObject,
			bodyParamsObject,
			formData,
			apiCallParams.userToken
		)

		const elapsedTime = new Date().getTime() - startTime

		utilLogger.hitresultElapsedTime(urlWithoutParams, elapsedTime)

		utilLogger.hitresult2(urlWithoutParams, apiAxCallRes)

		await bllDispatchs_GlobalsServer.setLoading(apiCallParams.key, false)

		if (apiAxCallRes.forbiddenError) {
			utilLogger.error("forbiddenError", {
				method,
				urlWithoutParams,
				paramsObject: queryParamsObject,
				bodyObject: bodyParamsObject,
			})

			await bllDispatchs_GlobalsUI.openDialogErrorForbidden(
				"Acceso denegado",
				"No tienes permisos para realizar esta acción.",
				""
			)

			await bllDispatchs_GlobalsServer.stopAllLoading()

			return null
		}

		if (apiAxCallRes.networkError) {
			utilLogger.error("networkError", {
				method,
				urlWithoutParams,
				paramsObject: queryParamsObject,
				bodyObject: bodyParamsObject,
			})

			await bllDispatchs_GlobalsUI.openDialogErrorUnexpected("Error de red", "Verifique su conexión a internet", "")

			await bllDispatchs_GlobalsServer.stopAllLoading()

			return null
		}

		if (apiAxCallRes.unexpectedErrorMessage) {
			utilLogger.error(apiAxCallRes.unexpectedErrorMessage, {
				method,
				urlWithoutParams,
				paramsObject: queryParamsObject,
				bodyObject: bodyParamsObject,
			})

			await bllDispatchs_GlobalsUI.openDialogErrorUnexpected(
				"Error inesperado",
				"El mismo ya fue reportado, intenta nuevamente más tarde.",
				""
			)

			await bllDispatchs_GlobalsServer.stopAllLoading()

			return null
		}

		if (apiAxCallRes.errorMessage || apiAxCallRes.modelErrors) {
			if (apiAxCallRes.errorMessage) {
				await bllDispatchs_GlobalsServer.setErrorMessage(apiCallParams.key, apiAxCallRes.errorMessage)
			}

			if (apiAxCallRes.modelErrors) {
				await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, apiAxCallRes.modelErrors)
			}

			return null
		}

		utilLogger.hitresult3(urlWithoutParams, apiAxCallRes.object)

		await bllDispatchs_GlobalsServer.setFinished(apiCallParams.key, true)

		return apiAxCallRes.object
	}
}
