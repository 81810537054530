import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedMotoclub } from "../../../shared/sharedMotoclub"

export namespace apiCallIG_Motoclubs_ActualizarProfileFields {
	export interface params {
		idMotoclub: number
		descripcionCorta: string | null
		descripcionLarga: string | null
	}
	export const paramsName = {
		idMotoclub: "idMotoclub",
		descripcionCorta: "descripcionCorta",
		descripcionLarga: "descripcionLarga",
	}
	export const paramsDesc = {
		idMotoclub: "Id Motoclub",
		descripcionCorta: "Descripción Corta",
		descripcionLarga: "Descripción Larga",
	}

	export interface IRes {
		sharedMotoclub: sharedMotoclub.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//descripcionCorta
		if (params.descripcionCorta) {
			apiValidations2.validateMaxNumberOfChars(
				paramsName.descripcionCorta,
				paramsDesc.descripcionCorta,
				params.descripcionCorta,
				250,
				serverModelErrors
			)
		}

		//descripcionLarga
		if (params.descripcionLarga) {
			apiValidations2.validateMaxNumberOfChars(
				paramsName.descripcionLarga,
				paramsDesc.descripcionLarga,
				params.descripcionLarga,
				7000,
				serverModelErrors
			)
		}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Motoclubs/ActualizarProfileFields`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedMotoclub: sharedMotoclub.getSharedOne_FromApiObject(apiCallRes) }
	}
}
