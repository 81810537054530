/* eslint-disable react/jsx-pascal-case*/
import { Box } from "@mui/material"
import { sharedRodada } from "../../../api/shared/sharedRodada"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import RodadasShared_CardList_Card from "./RodadasShared_CardList_Card"

interface IProps {
	rodadas: sharedRodada.IShared[]
}

export default function RodadasShared_CardList({ rodadas }: IProps) {
	return (
		<>
			<Box
				gap={3}
				display="grid"
				sx={{ p: 3 }}
				gridTemplateColumns={{
					xs: "repeat(1, 1fr)",
					sm: "repeat(2, 1fr)",
					md: "repeat(3, 1fr)",
				}}
			>
				{rodadas.map((rodada, i) => (
					<RodadasShared_CardList_Card key={i} rodada={rodada} />
				))}

				{rodadas.length === 0 && (
					<Box
						sx={{
							color: "text.secondary",
						}}
					>
						-Aún no hay rodadas en este listado-
					</Box>
				)}
			</Box>
		</>
	)
}
