/* eslint-disable react/jsx-pascal-case*/
import { Alert, Card, Container } from "@mui/material"
import { useEffect } from "react"
import { bllSaludonnet_CentrosUrgencias } from "../../../bll/Saludonnet/bllSaludonnet_CentrosUrgencias"
import useGeneral from "../../../hooksData/useGeneral"
import useSntCentrosUrgencias from "../../../hooksData/useSntCentrosUrgencias"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import { globalAssets } from "../../../globals/globalAssets"

export default function SaludonnetHeTenidoUnAccidente() {
	const ug = useGeneral("SaludonnetHeTenidoUnAccidente")

	return (
		<Container sx={{ position: "relative" }}>
			<Card sx={{ p: 4 }}>En desarrollo...</Card>
		</Container>
	)
}
