import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedEscuela } from "../../../shared/sharedEscuela"
import { apiCallInterface } from "../../../apiCallInterface"

export namespace apiCallIG_Escuelas_ActualizarAvatar {
	export interface params {
		idEscuela: number
		avatarUrl: string
	}
	export const paramsName = {
		idEscuela: "idEscuela",
		avatarUrl: "avatarUrl",
	}
	export const paramsDesc = {
		idEscuela: "Id Escuela",
		avatarUrl: "URL del avatar",
	}

	export interface IRes {
		sharedEscuela: sharedEscuela.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Escuelas/ActualizarAvatar`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedEscuela: sharedEscuela.getSharedOne_FromApiObject(apiCallRes) }
	}
}
