import {
	format,
	getTime,
	formatDistanceToNow,
	addDays,
	addHours,
	addMinutes,
	differenceInMinutes,
	Locale,
	formatDistanceToNowStrict,
	differenceInDays,
} from "date-fns"
import { es } from "date-fns/locale"
// import { toZonedTime } from "date-fns-tz"
import { utilLogger } from "./utilLogger"

export namespace utilDate {
	export function now() {
		return new Date()
	}

	export function getDateWithTimezoneAdded(date: Date | null | undefined) {
		if (!date) return null
		if (!date.getTimezoneOffset) return date
		const r = new Date(date.valueOf() - date.getTimezoneOffset() * 60 * 1000)

		return r
	}

	export function getNowDateNoTime() {
		return fDateNoTime(new Date(Date.now()))
	}

	export function fDate(date: Date | string | number) {
		return format(new Date(date), "dd MMMM yyyy", { locale: es })
	}

	export function fDateNoTime(date: Date) {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate())
	}

	export function fDateTime(date: Date | string | number) {
		return format(new Date(date), "dd MMM yyyy p", { locale: es })
	}

	export function fTimestamp(date: Date | string | number) {
		return getTime(new Date(date))
	}

	export function fDateTimeSuffix(date: Date | string | number) {
		return format(new Date(date), "dd/MM/yyyy hh:mm p")
	}

	export function fToNow(date: Date | string | number) {
		return formatDistanceToNow(new Date(date), {
			addSuffix: true,
		})
	}

	export function fDateFromNet(date: string | null) {
		if (!date) return null

		if (date.slice(-1) === "Z") {
			date = date.slice(0, -1)
		}

		const dateParsed = new Date(date)
		const dateTimezoned = toMadridZonedTime(dateParsed)
		return dateTimezoned
	}

	export function getDifferenceInDays(dateSmaller: Date, dateBigger: Date) {
		return differenceInDays(dateBigger, dateSmaller)
	}

	export function getDifferenceInMinutes(dateSmaller: Date, dateBigger: Date) {
		return differenceInMinutes(dateBigger, dateSmaller)
	}

	export function getDateFormatted(date: Date | null, dateFormat: string) {
		var r = ""
		if (!date) return r

		r = format(new Date(date), dateFormat, { locale: es })

		return r
	}

	export function getDateDDMMYYYY(date: Date | null) {
		var r = ""
		if (!date) return r

		r = format(new Date(date), "dd/MM/yyyy", { locale: es })

		return r
	}

	export function getDate_ForChat(date: Date | null) {
		var dateToShow = ""
		if (!date) return dateToShow

		const now = utilDate.now()

		const differenceInDays = utilDate.getDifferenceInDays(date, now)

		if (differenceInDays === 0) {
			dateToShow = utilDate.getDateFormatted(date, "p")
		} else {
			if (differenceInDays <= 6) {
				dateToShow = utilDate.getDateFormatted(date, "eeee")
			} else {
				dateToShow = utilDate.getDateFormatted(date, "d/M/yy")
			}
		}

		return dateToShow
	}

	export function getWeekDayList(startDate: Date, endDate: Date) {
		let days: number[] = []
		let end = new Date(endDate)

		for (let start = new Date(startDate); start <= end; start.setDate(start.getDate() + 1)) {
			if (days.length >= 7) {
				return days
			}

			days.push(start.getDay())
		}
		return days
	}

	export function getHoursAndMinutes(date: Date | null) {
		var dateToShow = ""
		if (!date) return dateToShow

		dateToShow = utilDate.getDateFormatted(date, "h:mm a")

		return dateToShow
	}

	export function toMadridZonedTime(date: Date) {
		var date_timeZoned = new Date(date.getTime() + 2 * 60 * 60 * 1000)

		return date_timeZoned
	}

	export function getDate_ForChat_Full(date: Date | null) {
		var dateToShow = ""
		if (!date) return dateToShow

		const now = utilDate.now()

		const differenceInDays = utilDate.getDifferenceInDays(date, now)

		if (differenceInDays === 0) {
			dateToShow = utilDate.getDateFormatted(date, "p")
		} else {
			if (differenceInDays <= 6) {
				dateToShow += utilDate.getDateFormatted(date, "eeee")
				dateToShow += " "
				dateToShow += utilDate.getDateFormatted(date, "p")
			} else {
				dateToShow += utilDate.getDateFormatted(date, "d/M/yy")
				dateToShow += " "
				dateToShow += utilDate.getDateFormatted(date, "p")
			}
		}

		return dateToShow
	}

	export function getDiaDeLaSemana(nroDia: number) {
		switch (nroDia) {
			case 0:
				return "Domingo"
			case 1:
				return "Lunes"
			case 2:
				return "Martes"
			case 3:
				return "Miércoles"
			case 4:
				return "Jueves"
			case 5:
				return "Viernes"
			case 6:
				return "Sábado"
			default:
				return ""
		}
	}
}
