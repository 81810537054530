/* eslint-disable react/jsx-pascal-case*/
import {
	FormControlLabel,
	FormHelperText,
	Radio,
	RadioGroup,
	RadioGroupProps,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	SelectProps,
	SxProps,
	TextField,
	Autocomplete,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { globalInterfaces } from "../../../globals/globalInterfaces"
import SharedLabelForForm from "../SharedLabelForForm"
import useServerStateField from "../../../hooks/useServerStateField"

interface IProps {
	callerKey: string
	fieldName: string
	label: string
	value: number | null
	showNotDefined?: boolean
	options: globalInterfaces.IValueNumberLabelString[]
	handleOnChangeProp(newValue: number | null): void
	sx?: SxProps
}

export default function SharedFormAutocomplete({
	callerKey,
	fieldName,
	label,
	options,
	value,
	showNotDefined = true,
	handleOnChangeProp,
	sx,
}: IProps) {
	const serverStateField = useServerStateField(callerKey, fieldName)

	const handleOnChange = (
		event: React.SyntheticEvent<Element, Event>,
		value: globalInterfaces.IValueNumberLabelString | null
	) => {
		if (value === null) {
			handleOnChangeProp(null)
		} else {
			handleOnChangeProp(value.value)
		}
	}

	var optionSelected: globalInterfaces.IValueNumberLabelString | null = null

	if (value !== null) {
		const optionFound = options.find((m) => m.value === value)
		if (!optionFound) {
			return null
		} else {
			optionSelected = optionFound
		}
	}

	return (
		<div>
			{value === null && showNotDefined && (
				<Typography sx={{ color: "warning.dark", display: "inline-block" }} variant="body2">
					-Aún no definido-
				</Typography>
			)}

			<FormControl fullWidth>
				{/* <InputLabel id="demo-simple-select-label">{label}</InputLabel> */}
				<Autocomplete
					disabled={serverStateField.isLoading}
					// value={value || ""}
					value={optionSelected}
					onChange={handleOnChange}
					// disablePortal
					options={options}
					sx={sx}
					renderInput={(params) => <TextField {...params} label={label} />}
				/>
				{/* 
				<Select
					disabled={serverStateField.isLoading}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={value || ""}
					label={label}
					onChange={handleOnChange}
					sx={sx}
				>
					{options.map((option, index) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select> */}
			</FormControl>

			<FormHelperText error sx={{ px: 2, mt: "0px !important" }}>
				{serverStateField.error}
			</FormHelperText>
		</div>
	)
}
