import { utilRedux } from "../../utils/utilRedux"
import produce from "immer"

export namespace bllGlobalsUI_FullScreenLoading {
	//ET = enumTypes
	enum ET {
		start = "GlobalsUI_FullScreenLoading_start",
		stop = "GlobalsUI_FullScreenLoading_stop",
	}

	export interface IInterface {
		isLoading: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			isLoading: true,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Update_FullScreenLoadingOpen:
			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.FullScreenLoadingOpen = action.FullScreenLoadingOpen
			// 	})
			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	FullScreenLoadingOpen: action.FullScreenLoadingOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function start() {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "isLoading">
			const updateObject: actionSubType = {
				isLoading: true,
			}

			utilRedux.TA(D, ET.start, updateObject)
		}
	}

	export function stop() {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "isLoading">
			const updateObject: actionSubType = {
				isLoading: false,
			}

			utilRedux.TA(D, ET.stop, updateObject)
		}
	}
}
