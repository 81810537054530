import { combineReducers } from "redux"
import { bllChat_All } from "./bllChat_All"
import { bllChat_Start } from "./bllChat_Start"

export default combineReducers({
	All: bllChat_All.reducerFunction,
	Start: bllChat_Start.reducerFunction,
})

export interface IChat {
	All: bllChat_All.IInterface
	Start: bllChat_Start.IInterface
}
