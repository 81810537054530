import { apiCallIG_Personas_GetPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersona"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { apiCallInterface } from "../../api/apiCallInterface"
import { sharedSN_CentroUrgencia } from "../../api/shared/SN/sharedSN_CentroUrgencia"
import { apiCallSN_GetHospitalesDeUrgencia } from "../../api/calls/SN/apiCallSN_GetHospitalesDeUrgencia"
import { sharedSeguroPdf } from "../../api/shared/sharedSeguroPdf"
import { apiCallMP_Seguros_GetSegurosParaDni } from "../../api/calls/MP/Seguros/apiCallMP_Seguros_GetSegurosParaDni"
import { apiCallMP_Seguros_GetSegurosVigentes } from "../../api/calls/MP/Seguros/apiCallMP_Seguros_GetSegurosVigentes"
import { sharedSeguroAccidentesCircuito } from "../../api/shared/sharedSeguroAccidentesCircuito"
import { apiCallMP_Seguros_GetSeguro } from "../../api/calls/MP/Seguros/apiCallMP_Seguros_GetSeguro"
import { apiCallMP_Seguros_GetTarjetaSeguro } from "../../api/calls/MP/Seguros/apiCallMP_Seguros_GetTarjetaSeguro"
import { apiCallMP_Seguros_GetTarjetaPiloto } from "../../api/calls/MP/Seguros/apiCallMP_Seguros_GetTarjetaPiloto"

export namespace bllSeguros_GetTarjetaSeguro {
	//ET = enumTypes
	enum ET {
		setInitialState = "Seguros_GetTarjetaSeguro_setInitialState",
		load = "Seguros_GetTarjetaSeguro_load",
	}

	export interface IInterface {
		tarjetaImagenUrl: string
	}

	const getInitialState = (): IInterface => {
		return {
			tarjetaImagenUrl: "",
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function load(apiCallParams: apiCallInterface, params: apiCallMP_Seguros_GetTarjetaSeguro.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallMP_Seguros_GetTarjetaSeguro.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "tarjetaImagenUrl">
				const updateObject: actionSubType = {
					tarjetaImagenUrl: apiCallRes.tarjetaImagenUrl,
				}

				utilRedux.TA(D, ET.load, updateObject)
			}
		}
	}
}
