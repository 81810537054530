import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Personas_ActualizarAvatar } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarAvatar"
import { apiCallIG_Personas_ActualizarContact } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarContact"
import { apiCallIG_Personas_ActualizarCover } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarCover"
import { apiCallIG_Personas_ActualizarDireccionGoogle } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarDireccionGoogle"
import { apiCallIG_Personas_ActualizarDireccionNotGoogle } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarDireccionNotGoogle"
import { apiCallIG_Personas_ActualizarGeneralFields } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarGeneralFields"
import { apiCallIG_Personas_ActualizarPosteoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarPosteoDePersona"
import { apiCallIG_Personas_ActualizarProfileFields } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarProfileFields"
import { apiCallIG_Personas_ActualizarSocialInfo } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarSocialInfo"
import { apiCallIG_Personas_ActualizarVehiculoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarVehiculoDePersona"
import { apiCallIG_Personas_AgregarPosteoParaPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_AgregarPosteoParaPersona"
import { apiCallIG_Personas_AgregarVehiculoParaPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_AgregarVehiculoParaPersona"
import { apiCallIG_Personas_EliminarPosteoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_EliminarPosteoDePersona"
import { apiCallIG_Personas_EliminarVehiculoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_EliminarVehiculoDePersona"
import { apiCallIG_Personas_EnviarNotificacionASeguidores } from "../../api/calls/IG/Personas/apiCallIG_Personas_EnviarNotificacionASeguidores"
import { apiCallIG_Personas_GetPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersona"
import { apiCallIG_Personas_GetPersonas_ByName } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersonas_ByName"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { bllGlobalsUser_CurrentPersona } from "../GlobalsUser/bllGlobalsUser_CurrentPersona"
import { bllGlobalsUser_Personas } from "../GlobalsUser/bllGlobalsUser_Personas"

export namespace bllPersonas_Search {
	//ET = enumTypes
	enum ET {
		setInitialState = "Personas_Search_setInitialState",
		searchPersonasByName = "Personas_Search_searchPersonasByName",
	}

	export interface IInterface {
		personasByName: sharedPersona.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			personasByName: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function searchPersonasByName(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Personas_GetPersonas_ByName.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Personas_GetPersonas_ByName.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "personasByName">
				const updateObject: actionSubType = {
					personasByName: apiCallRes.sharedPersonas,
				}

				utilRedux.TA(D, ET.searchPersonasByName, updateObject)
			}
		}
	}
}
