// @ts-ignore
import { getPlayerId, setExternalUserId } from "webtonative/OneSignal"

export namespace utilWebtonative {
	export const getPlayerId2 = async () => {
		try {
			const playerId = await getPlayerId()
			if (playerId) {
				return playerId
			}
		} catch (error) {
			console.log(error)
		}
	}

	export const setExternalUserId2 = async () => {
		alert(4)
		setExternalUserId("jaspfdoijfsdpiojpwfeojknvñlk3394820743")
	}
}

// utilWebtonative.getPlayerId2().then(function (playerId: any) {
// 	if (playerId) {
// 		// handle for playerId
// 		console.log(playerId)
// 	}
// })
