import { apiCallIG_Registrarse_ResendConfirmationEmail } from "../../api/calls/IG/Registrarse/apiCallIG_Registrarse_ResendConfirmationEmail"
import { utilRedux } from "../../utils/utilRedux"
import { globalPathsApp } from "../../globals/globalPathsApp"
import { apiCallInterface } from "../../api/apiCallInterface"

export namespace bllUsers_ResendConfirmationEmail {
	//ET = enumTypes
	enum ET {
		setInitialState = "Users_ResendConfirmationEmail_setInitialState",
		resendConfirmationEmail = "Users_resendConfirmationEmail",
	}

	export interface IInterface {
		isEmailSent: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			isEmailSent: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.User_ResendConfirmationEmail_ResendConfirmationEmail:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function resendConfirmationEmail(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Registrarse_ResendConfirmationEmail.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Registrarse_ResendConfirmationEmail.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isEmailSent">
				const updateObject: actionSubType = {
					isEmailSent: true,
				}

				utilRedux.TA(D, ET.resendConfirmationEmail, updateObject)
			}
		}
	}
}
