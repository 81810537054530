import { bllGlobalsServer_ErrorMessageList } from "./GlobalsServer/bllGlobalsServer_ErrorMessageList"
import { bllGlobalsServer_FinishedList } from "./GlobalsServer/bllGlobalsServer_FinishedList"
import { bllGlobalsServer_LoadingList } from "./GlobalsServer/bllGlobalsServer_LoadingList"
import { bllGlobalsServer_MessageList } from "./GlobalsServer/bllGlobalsServer_MessageList"
import { bllGlobalsServer_ModelErrorsList } from "./GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { dispatchStore } from "../utils/utilReduxStore"
import { bllGlobalsUI_FullScreenLoading } from "./GlobalsUI/bllGlobalsUI_FullScreenLoading"

export namespace bllDispatchs_GlobalsServer {
	export const setModelErrors = async (
		key: string,
		modelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors
	) => {
		await dispatchStore(bllGlobalsServer_ModelErrorsList.cleanServerModelErrors(key))

		var flagHasAtLeastOneError = Object.entries(modelErrors).some((item) => {
			return item[1] !== ""
		})

		if (flagHasAtLeastOneError) {
			dispatchStore(bllGlobalsServer_ModelErrorsList.setServerModelErrors(key, modelErrors))
		}

		return flagHasAtLeastOneError
	}

	export const setErrorMessage = async (key: string, message: string) => {
		await dispatchStore(bllGlobalsServer_ErrorMessageList.cleanErrorMessages(key))

		await dispatchStore(bllGlobalsServer_ErrorMessageList.setErrorMessages(key, message))
	}

	export const setMessage = async (key: string, message: string) => {
		await dispatchStore(bllGlobalsServer_MessageList.cleanMessages(key))

		await dispatchStore(bllGlobalsServer_MessageList.setMessages(key, message))
	}

	export const setFinished = async (key: string, isFinished: boolean) => {
		await dispatchStore(bllGlobalsServer_FinishedList.setFinished(key, isFinished))
	}

	export const cleanAll = async (key: string) => {
		await dispatchStore(bllGlobalsServer_ModelErrorsList.cleanServerModelErrors(key))
		await dispatchStore(bllGlobalsServer_LoadingList.clean(key))
		await dispatchStore(bllGlobalsServer_ErrorMessageList.cleanErrorMessages(key))
		await dispatchStore(bllGlobalsServer_MessageList.cleanMessages(key))
		await dispatchStore(bllGlobalsServer_FinishedList.cleanFinished(key))
	}

	export const setLoading = async (key: string, isLoading: boolean) => {
		await dispatchStore(bllGlobalsServer_LoadingList.setServerLoading(key, isLoading))
	}

	export const stopAllLoading = async () => {
		await dispatchStore(bllGlobalsServer_LoadingList.stopAllServerLoading())
		await dispatchStore(bllGlobalsUI_FullScreenLoading.stop())
	}
}
