import { globalAssets } from "../../globals/globalAssets"
import { sharedDireccion } from "./sharedDireccion"
import { utilDate } from "../../utils/utilDate"
import { sharedPersonasVehiculos } from "./sharedPersonasVehiculos"
import { sharedPersonasPosteos } from "./sharedPersonasPosteos"

export namespace sharedPersonaSeguidor {
	export interface IShared {
		id: number
		nombre: string
		apellido1: string
		apellido2: string
		avatarURL: string
		coverURL: string
		descripcionCorta: string
	}

	export const objectDefault: IShared = {
		id: 0,
		nombre: "",
		apellido1: "",
		apellido2: "",
		avatarURL: globalAssets.staticPaths.personaAvatar,
		coverURL: globalAssets.staticPaths.personaCover1,
		descripcionCorta: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...objectDefault,
			...apiObject,
			avatarURL: apiObject.avatarURL || globalAssets.staticPaths.personaAvatar,
			coverURL: apiObject.coverURL || globalAssets.staticPaths.personaCover1,
		}

		return res
	}
}
