import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedCircuito } from "../../api/shared/sharedCircuito"
import { apiCallIG_Circuitos_GetCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_GetCircuito"
import { apiCallIG_Circuitos_ActualizarAvatar } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarAvatar"
import { apiCallIG_Circuitos_ActualizarCharacteristics } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarCharacteristics"
import { apiCallIG_Circuitos_ActualizarContact } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarContact"
import { apiCallIG_Circuitos_ActualizarCover } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarCover"
import { apiCallIG_Circuitos_ActualizarDireccionGoogle } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarDireccionGoogle"
import { apiCallIG_Circuitos_ActualizarDireccionNotGoogle } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarDireccionNotGoogle"
import { apiCallIG_Circuitos_ActualizarGeneralFields } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarGeneralFields"
import { apiCallIG_Circuitos_ActualizarProfileFields } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarProfileFields"
import { apiCallIG_Circuitos_ActualizarSocialInfo } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarSocialInfo"
import { apiCallIG_Circuitos_BloquearCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_BloquearCircuito"
import { apiCallIG_Circuitos_AprobarCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_AprobarCircuito"
import { apiCallIG_Circuitos_DesbloquearCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_DesbloquearCircuito"
import { apiCallIG_Circuitos_ActualizarAdminFields } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_ActualizarAdminFields"
import { apiCallIG_Circuitos_DeleteCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_DeleteCircuito"
import { bllGlobalsGeneralData_AllCircuitos } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllCircuitos"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Circuitos_EnviarNotificacionASeguidores } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_EnviarNotificacionASeguidores"
import { apiCallIG_CircuitosPistas_CreateCircuitoPista } from "../../api/calls/IG/CircuitosPistas/apiCallIG_CircuitosPistas_CreateCircuitoPista"
import { apiCallIG_CircuitosPistas_DeleteCircuitoPista } from "../../api/calls/IG/CircuitosPistas/apiCallIG_CircuitosPistas_DeleteCircuitoPista"
import { apiCallIG_CircuitosPistas_UpdateCircuitoPista } from "../../api/calls/IG/CircuitosPistas/apiCallIG_CircuitosPistas_UpdateCircuitoPista"
import { sharedCircuitoSeguidor } from "../../api/shared/sharedCircuitoSeguidor"
import { apiCallIG_CircuitosSeguidores_GetCircuitosSeguidoresByIdCircuito } from "../../api/calls/IG/CircuitosSeguidores/apiCallIG_CircuitosSeguidores_GetCircuitosSeguidoresByIdCircuito"

export namespace bllCircuitos_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Circuitos_Settings_setInitialState",
		loadCircuito = "Circuitos_Settings_loadCircuito",
		updateWithSharedCircuito = "Circuitos_Settings_updateWithSharedCircuito",
		actualizarAvatar = "Circuitos_Settings_actualizarAvatar",
		actualizarCharacteristics = "Circuitos_Settings_actualizarCharacteristics",
		actualizarContact = "Circuitos_Settings_actualizarContact",
		actualizarAdminFields = "Circuitos_Settings_actualizarAdminFields",
		actualizarCover = "Circuitos_Settings_actualizarCover",
		actualizarDireccionGoogle = "Circuitos_Settings_actualizarDireccionGoogle",
		actualizarDireccionNotGoogle = "Circuitos_Settings_actualizarDireccionNotGoogle",
		actualizarGeneralFields = "Circuitos_Settings_actualizarGeneralFields",
		actualizarProfileFields = "Circuitos_Settings_actualizarProfileFields",
		actualizarSocialInfo = "Circuitos_Settings_actualizarSocialInfo",
		deleteCircuito = "Circuitos_Settings_deleteCircuito",
		bloquearCircuito = "Circuitos_Settings_bloquearCircuito",
		desbloquearCircuito = "Circuitos_Settings_desbloquearCircuito",
		aprobarCircuito = "Circuitos_Settings_aprobarCircuito",
		enviarNotificacionASeguidores = "Circuitos_Settings_enviarNotificacionASeguidores",

		getSeguidores = "Circuitos_Settings_getSeguidores",

		createCircuitoPista = "Circuitos_Settings_createCircuitoPista",
		updateCircuitoPista = "Circuitos_Settings_updateCircuitoPista",
		deleteCircuitoPista = "Circuitos_Settings_deleteCircuitoPista",
	}

	export interface IInterface {
		circuito: sharedCircuito.IShared
		isDeleted: boolean
		notificacionEnviada: boolean
		seguidores: sharedCircuitoSeguidor.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			circuito: sharedCircuito.defaultObject,
			isDeleted: false,
			notificacionEnviada: false,
			seguidores: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadCircuito(apiCallParams: apiCallInterface, params: apiCallIG_Circuitos_GetCircuito.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Circuitos_GetCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))
				utilRedux.TA(D, ET.loadCircuito, {})
			}
		}
	}

	export function setCircuitoWithSharedCircuito(sharedCircuito: sharedCircuito.IShared) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "circuito">
			const updateObject: actionSubType = {
				circuito: sharedCircuito,
			}

			utilRedux.TA(D, ET.updateWithSharedCircuito, updateObject)
		}
	}

	export function getSeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosSeguidores_GetCircuitosSeguidoresByIdCircuito.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_CircuitosSeguidores_GetCircuitosSeguidoresByIdCircuito.apiCall(
				apiCallParams,
				params
			)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "seguidores">
				const updateObject: actionSubType = {
					seguidores: apiCallRes.sharedCircuitoSeguidores,
				}

				utilRedux.TA(D, ET.getSeguidores, updateObject)
			}
		}
	}

	export function actualizarAvatar(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_ActualizarAvatar.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarAvatar.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarAvatar, {})
			}
		}
	}

	export function actualizarCharacteristics(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_ActualizarCharacteristics.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarCharacteristics.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarCharacteristics, {})
			}
		}
	}

	export function actualizarContact(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_ActualizarContact.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarContact.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarContact, {})
			}
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_EnviarNotificacionASeguidores.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_Circuitos_EnviarNotificacionASeguidores.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}

	export function actualizarAdminFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_ActualizarAdminFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarAdminFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarAdminFields, {})
			}
		}
	}

	export function actualizarCover(apiCallParams: apiCallInterface, params: apiCallIG_Circuitos_ActualizarCover.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarCover.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarCover, {})
			}
		}
	}

	export function actualizarDireccionGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_ActualizarDireccionGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarDireccionGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarDireccionGoogle, {})
			}
		}
	}

	export function actualizarDireccionNotGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_ActualizarDireccionNotGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarDireccionNotGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarDireccionNotGoogle, {})
			}
		}
	}

	export function actualizarGeneralFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_ActualizarGeneralFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarGeneralFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarGeneralFields, {})
			}
		}
	}

	export function actualizarProfileFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_ActualizarProfileFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarProfileFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarProfileFields, {})
			}
		}
	}

	export function actualizarSocialInfo(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_ActualizarSocialInfo.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_ActualizarSocialInfo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllCircuitos_Settings.setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.actualizarSocialInfo, {})
			}
		}
	}

	export function deleteCircuito(apiCallParams: apiCallInterface, params: apiCallIG_Circuitos_DeleteCircuito.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Circuitos_DeleteCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isDeleted">
				const updateObject: actionSubType = {
					isDeleted: true,
				}

				D(bllGlobalsGeneralData_AllCircuitos.loadAllCircuitos(apiCallParams))

				utilRedux.TA(D, ET.deleteCircuito, updateObject)
			}
		}
	}

	export function bloquearCircuito(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_BloquearCircuito.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_BloquearCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.bloquearCircuito, {})
			}
		}
	}

	export function aprobarCircuito(apiCallParams: apiCallInterface, params: apiCallIG_Circuitos_AprobarCircuito.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_AprobarCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.aprobarCircuito, {})
			}
		}
	}

	export function desbloquearCircuito(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Circuitos_DesbloquearCircuito.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Circuitos_DesbloquearCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.desbloquearCircuito, {})
			}
		}
	}

	export function createCircuitoPista(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosPistas_CreateCircuitoPista.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_CircuitosPistas_CreateCircuitoPista.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.createCircuitoPista, {})
			}
		}
	}

	export function deleteCircuitoPista(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosPistas_DeleteCircuitoPista.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_CircuitosPistas_DeleteCircuitoPista.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.deleteCircuitoPista, {})
			}
		}
	}

	export function updateCircuitoPista(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosPistas_UpdateCircuitoPista.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_CircuitosPistas_UpdateCircuitoPista.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(setCircuitoWithSharedCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.updateCircuitoPista, {})
			}
		}
	}
}
