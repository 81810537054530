import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../bll/bllReducers"
import useGlobalsUser from "./useGlobalsUser"
import { utilLogger } from "../utils/utilLogger"

export default function useSeguros() {
	const seguro = useSelector((state: IState) => state.Seguros.GetSeguro.sharedSeguroAccidentesCircuito)
	const seguros = useSelector((state: IState) => state.Seguros.GetSegurosParaDni.seguros)
	const segurosVigentes = useSelector(
		(state: IState) => state.Seguros.GetSegurosVigentes.sharedSeguroAccidentesCircuitoList
	)
	const protocoloAccidenteUrlPdf = useSelector((state: IState) => state.Seguros.GetPdfProtocoloAccidente.urlPdf)
	const protocoloAccidenteUrlPdfSaved = useSelector(
		(state: IState) => state.Seguros.SaveProtocoloAccidenteConCampos.urlPdf
	)
	const tarjetaPilotoImagenUrl = useSelector((state: IState) => state.Seguros.GetTarjetaPiloto.tarjetaImagenUrl)
	const tarjetaSeguroImagenUrl = useSelector((state: IState) => state.Seguros.GetTarjetaSeguro.tarjetaImagenUrl)

	return {
		seguro,
		seguros,
		segurosVigentes,
		protocoloAccidenteUrlPdf,
		protocoloAccidenteUrlPdfSaved,
		tarjetaPilotoImagenUrl,
		tarjetaSeguroImagenUrl,
	}
}
