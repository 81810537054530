// @mui
import { Breakpoint } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

// ----------------------------------------------------------------------

export default function useResponsive() {
	const theme = useTheme()

	const screen_up_xs = useMediaQuery(theme.breakpoints.up("xs"))
	const screen_up_sm = useMediaQuery(theme.breakpoints.up("sm"))
	const screen_up_md = useMediaQuery(theme.breakpoints.up("md"))
	const screen_up_lg = useMediaQuery(theme.breakpoints.up("lg"))
	const screen_up_xl = useMediaQuery(theme.breakpoints.up("xl"))
	const isDesktop = screen_up_lg || false

	return {
		screen_up_xs,
		screen_up_sm,
		screen_up_md,
		screen_up_lg,
		screen_up_xl,
		isDesktop,
	}
}
