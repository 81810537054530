import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Notificaciones_GetLast20Notificaciones_ForUserLogged } from "../../api/calls/IG/Notificaciones/apiCallIG_Notificaciones_GetLast20Notificaciones_ForUserLogged"
import { apiCallIG_Notificaciones_SetAllAsViewedForUserLogged } from "../../api/calls/IG/Notificaciones/apiCallIG_Notificaciones_SetAllAsViewedForUserLogged"
import { sharedNotificacion } from "../../api/shared/sharedNotificacion"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllNotificaciones_All {
	//ET = enumTypes
	enum ET {
		setInitialState = "Notificaciones_All_setInitialState",
		loadLast20 = "Notificaciones_All_loadLast20",
		setAllAsViewedForUserLogged = "Notificaciones_All_setAllAsViewedForUserLogged",
	}

	export interface IInterface {
		last20: sharedNotificacion.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			last20: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadLast20(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Notificaciones_GetLast20Notificaciones_ForUserLogged.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Notificaciones_GetLast20Notificaciones_ForUserLogged.apiCall(
				apiCallParams,
				params
			)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "last20">
				const updateObject: actionSubType = {
					last20: apiCallRes.sharedNotificacionesList,
				}

				utilRedux.TA(D, ET.loadLast20, updateObject)
			}
		}
	}

	export function setAllAsViewedForUserLogged(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Notificaciones_SetAllAsViewedForUserLogged.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Notificaciones_SetAllAsViewedForUserLogged.apiCall(apiCallParams, {})

			if (apiCallRes) {
				utilRedux.TA(D, ET.setAllAsViewedForUserLogged, {})
			}
		}
	}
}
