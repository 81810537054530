import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_AdminNotificaciones_SendNotificationToAllAppCircuitosUsers } from "../../api/calls/IG/AdminNotificaciones/apiCallIG_AdminNotificaciones_SendNotificationToAllAppCircuitosUsers"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllAdminNotificaciones_General {
	//ET = enumTypes
	enum ET {
		setInitialState = "AdminNotificaciones_General_setInitialState",
		enviarNotificacionASeguidores = "AdminNotificaciones_General_enviarNotificacionASeguidores",
	}

	export interface IInterface {
		notificacionEnviada: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			notificacionEnviada: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_AdminNotificaciones_SendNotificationToAllAppCircuitosUsers.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_AdminNotificaciones_SendNotificationToAllAppCircuitosUsers.apiCall(
				apiCallParams,
				params
			)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}
}
