/* eslint-disable react/jsx-pascal-case*/
import { List, ListSubheader, styled, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import { globalConfig } from "../../globals/globalConfig"
import { globalPathsApp } from "../../globals/globalPathsApp"
import useGlobalsUserRoles from "../../hooksData/useGlobalsUserRoles"
import { AdminMenu } from "../Admin/Menu/AdminMenu"
import { CircuitosLanding_Menu } from "../Circuitos/Landing/CircuitosLanding_Menu"
import { OrganizadoresLanding_Menu } from "../Organizadores/Landing/OrganizadoresLanding_Menu"
import { PilotosLanding_Menu } from "../Pilotos/Landing/PilotosLanding_Menu"
import { RodadasLanding_Menu } from "../Rodadas/Landing/RodadasLanding_Menu"
import { SaludonnetLanding_Menu } from "../Saludonnet/Landing/SaludonnetLanding_Menu"
import { Layout_LeftMenu_Navbar_Item } from "./Layout_LeftMenu_Navbar_Item"
import { Layout_LeftMenu_Navbar_TabsCollapsable } from "./Layout_LeftMenu_Navbar_TabsCollapsable"

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
	({ theme }) => ({
		...theme.typography.overline,
		paddingTop: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(1),
		color: theme.palette.text.primary,
		transition: theme.transitions.create("opacity", {
			duration: theme.transitions.duration.shorter,
		}),
	})
)

export const Layout_LeftMenu_Navbar = () => {
	const { isUserCurrentRol_Admin } = useGlobalsUserRoles()
	const theme = useTheme()

	return (
		<Box>
			<List disablePadding sx={{ px: 0 }}>
				{globalConfig.environment !== "production" && (
					<ListSubheaderStyle sx={{ color: theme.palette.error.main, fontSize: "20px" }}>
						{globalConfig.environment}
					</ListSubheaderStyle>
				)}

				<ListSubheaderStyle>General</ListSubheaderStyle>

				<Layout_LeftMenu_Navbar_Item
					iconifyIcon="entypo:home"
					pageDest={globalPathsApp.pagesEnum.Home}
					urlDest={globalPathsApp.pageInsideUrl.Home()}
					title="Inicio"
				/>

				<Layout_LeftMenu_Navbar_TabsCollapsable
					iconifyIcon="mdi:racing-helmet"
					appPagePath={globalPathsApp.pagesEnum.Pilotos}
					tabs={PilotosLanding_Menu()}
					title="Pilotos"
				/>

				{/* <Layout_LeftMenu_Navbar_Item
					iconifyIcon="maki:racetrack"
					pageDest={globalPathsApp.pagesEnum.Circuitos}
					urlDest={globalPathsApp.pageInsideUrl.Circuitos()}
					title="Circuitos"
				/> */}

				<Layout_LeftMenu_Navbar_TabsCollapsable
					iconifyIcon="maki:racetrack"
					appPagePath={globalPathsApp.pagesEnum.Circuitos}
					tabs={CircuitosLanding_Menu()}
					title="Circuitos"
				/>

				<Layout_LeftMenu_Navbar_TabsCollapsable
					iconifyIcon="mdi:calendar-month"
					appPagePath={globalPathsApp.pagesEnum.Rodadas}
					tabs={RodadasLanding_Menu()}
					title="Rodadas"
				/>

				{/* <Layout_LeftMenu_Navbar_TabsCollapsable
					iconifyIcon="carbon:building"
					appPagePath={globalPathsApp.pagesEnum.Empresas}
					tabs={EmpresasLanding_Menu()}
					title="Empresas"
				/>

				<Layout_LeftMenu_Navbar_TabsCollapsable
					iconifyIcon="material-symbols:school"
					appPagePath={globalPathsApp.pagesEnum.Escuelas}
					tabs={EscuelasLanding_Menu()}
					title="Escuelas"
				/>

				<Layout_LeftMenu_Navbar_TabsCollapsable
					iconifyIcon="fontisto:motorcycle"
					appPagePath={globalPathsApp.pagesEnum.Motoclubs}
					tabs={MotoclubsLanding_Menu()}
					title="Motoclubs"
				/> */}

				<Layout_LeftMenu_Navbar_TabsCollapsable
					iconifyIcon="mdi:car-insurance"
					// customIcon={AssetIconSaludonnet}
					appPagePath={globalPathsApp.pagesEnum.Seguros}
					tabs={SaludonnetLanding_Menu()}
					title="Seguros"
				/>

				<Layout_LeftMenu_Navbar_TabsCollapsable
					iconifyIcon="ic:baseline-calendar-month"
					appPagePath={globalPathsApp.pagesEnum.Organizadores}
					tabs={OrganizadoresLanding_Menu()}
					title="Organizadores"
				/>

				{/* 
				<Layout_LeftMenu_Navbar_TabsCollapsable
					iconifyIcon="ic:baseline-calendar-month"
					appPagePath={globalPathsApp.pagesEnum.Federaciones}
					tabs={FederacionesLanding_Menu()}
					title="Federaciones"
				/>

				<Layout_LeftMenu_Navbar_Item
					customIcon={AssetIconMotopoliza}
					pageDest={globalPathsApp.pagesEnum.Motopoliza}
					urlDest={globalPathsApp.pageInsideUrl.Motopoliza()}
					title="Motopoliza"
				/> */}

				{isUserCurrentRol_Admin && (
					<>
						<Layout_LeftMenu_Navbar_TabsCollapsable
							iconifyIcon="eos-icons:admin"
							appPagePath={globalPathsApp.pagesEnum.Admin}
							tabs={AdminMenu()}
							title="Admin"
						/>
					</>
				)}
			</List>
		</Box>
	)
}
