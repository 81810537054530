import { globalRegex } from "../globals/globalRegex"

export namespace utilFormatValidations {
	export function isEmail(email: string) {
		return email.match(globalRegex.email)
	}

	export function isUrl(url: string) {
		return url.match(globalRegex.url)
	}
}
