/* eslint-disable react/jsx-pascal-case*/
import { Alert, Card, Container } from "@mui/material"
import { useEffect } from "react"
import { bllSaludonnet_CentrosUrgencias } from "../../../bll/Saludonnet/bllSaludonnet_CentrosUrgencias"
import useGeneral from "../../../hooksData/useGeneral"
import useSntCentrosUrgencias from "../../../hooksData/useSntCentrosUrgencias"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import { globalAssets } from "../../../globals/globalAssets"
import useSeguros from "../../../hooksData/useSeguros"
import { bllSeguros_GetPdfProtocoloAccidente } from "../../../bll/Seguros/bllSeguros_GetPdfProtocoloAccidente"
import SaludonnetProtocoloAccidente_Form from "./SaludonnetProtocoloAccidente_Form"
import { useSearchParams } from "react-router-dom"
import useGlobalsUser from "../../../hooksData/useGlobalsUser"
import { bllSeguros_GetSeguro } from "../../../bll/Seguros/bllSeguros_GetSeguro"

export default function SaludonnetProtocoloAccidente() {
	const ug = useGeneral("SaludonnetProtocoloAccidente")
	const { protocoloAccidenteUrlPdf } = useSeguros()
	const { currentPersona, userIsLogged } = useGlobalsUser()

	useEffect(() => {
		return () => {
			ug.D(bllSeguros_GetPdfProtocoloAccidente.setInitialState())
		}
	}, [])

	const [searchParams, setSearchParams] = useSearchParams({})

	useEffect(() => {
		if (userIsLogged && currentPersona.id !== 0) {
			const currentIdSeguro = Number(searchParams.get("idSeguro")) || 0
			const currentTipo = Number(searchParams.get("tipo")) || 0

			// if (seguro.id !== currentIdSeguro) {
			ug.D(bllSeguros_GetPdfProtocoloAccidente.load(ug.apiCallParams, { idSeguro: currentIdSeguro, tipo: currentTipo }))
			ug.D(bllSeguros_GetSeguro.load(ug.apiCallParams, { idSeguro: currentIdSeguro, tipo: currentTipo }))
			// }
		}
	}, [userIsLogged, currentPersona, searchParams])

	return (
		<Container sx={{ position: "relative" }}>
			{ug.serverState.isLoading && <SharedLoadingComponent />}

			{/* <SharedHeaderBreadcrumbs
				heading={`Perfil de ${persona.nombre}`}
				links={[
					{ name: "Inicio", href: globalPathsApp.pageInsideUrl.Home() },
					{
						name: "Perfil",
						href: globalPathsApp.pageInsideUrl.PersonasProfile(undefined, persona.id),
					},
				]}
				action={
					userIsOwner && (
						<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.PersonasSettings(undefined, persona.id)}>
							<Button
								variant="contained"
								color="warning"
								startIcon={<SharedBasicIconify iconifyIcon={"bytesize:settings"} />}
							>
								Ajustes
							</Button>
						</SharedLinkInsideAppWrapper>
					)
				}
			/> */}

			{ug.serverState.errorMessage && <Alert severity="error">{ug.serverState.errorMessage}</Alert>}

			<>
				<Card>
					<iframe src={protocoloAccidenteUrlPdf} width="100%" height="600px" />
				</Card>

				<Card sx={{ mt: 1 }}>
					<SaludonnetProtocoloAccidente_Form />
				</Card>
			</>
		</Container>
	)
}
