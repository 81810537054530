import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedLoginRes } from "../../../shared/sharedLoginRes"
import { apiCallInterface } from "../../../apiCallInterface"
import { sharedInitialCallRes } from "../../../shared/sharedInitialCallRes"

export namespace apiCallIG_General_InitialCall {
	export const paramsDesc = {}

	export interface IRes {
		sharedInitialCallRes: sharedInitialCallRes.IShared
	}

	// const validations = async (apiCallParams: apiCallInterface, params: bodyParams) => {
	// 	const serverModelErrors: bllGlobals_ServerModelErrorsList.IServerModelErrors = {}

	// 	return await dispatchServerModelErrors(key, serverModelErrors)
	// }

	export const apiCall = async (apiCallParams: apiCallInterface): Promise<IRes | null> => {
		// if (await validations(apiCallParams, params)) {
		// 	return null
		// }

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.get
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/General/InitialCall`
		const sharedParamsQuery = null
		const sharedParamsBody = null

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedInitialCallRes: sharedInitialCallRes.getSharedOne_FromApiObject(apiCallRes),
		}
	}
}
