import { combineReducers } from "redux"
import { bllMotoclubs_Profile } from "./bllMotoclubs_Profile"
import { bllMotoclubs_Create } from "./bllMotoclubs_Create"
import { bllMotoclubs_Settings } from "./bllMotoclubs_Settings"

export default combineReducers({
	Profile: bllMotoclubs_Profile.reducerFunction,
	Settings: bllMotoclubs_Settings.reducerFunction,
	CreateMotoclub: bllMotoclubs_Create.reducerFunction,
})

export interface IMotoclubs {
	Profile: bllMotoclubs_Profile.IInterface
	Settings: bllMotoclubs_Settings.IInterface
	CreateMotoclub: bllMotoclubs_Create.IInterface
}
