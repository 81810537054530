/* eslint-disable react/jsx-pascal-case*/
import orderBy from "lodash/orderBy"
import { alpha, styled } from "@mui/material/styles"
import { Box, Stack, Card, Avatar, CardHeader, Typography, Grid, Alert, Container } from "@mui/material"
import SharedBasicIconify from "../../Shared/Basic/SharedBasicIconify"
import { utilNumbers } from "../../../utils/utilNumbers"
import useGeneral from "../../../hooksData/useGeneral"
import { useEffect, useState } from "react"
import { bllPersonas_Search } from "../../../bll/Personas/bllPersonas_Search"
import useRouting from "../../../hooks/useRouting"
import { bllGlobalsServer_ModelErrorsList } from "../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { bllDispatchs_GlobalsServer } from "../../../bll/bllDispatchs_GlobalsServer"
import { utilLogger } from "../../../utils/utilLogger"
import { LoadingButton } from "@mui/lab"
import SharedFormTextFieldString from "../../Shared/Form/SharedFormTextFieldString"
import { apiCallIG_Personas_GetPersonas_ByName } from "../../../api/calls/IG/Personas/apiCallIG_Personas_GetPersonas_ByName"
import { apiValidations2 } from "../../../api/apiValidations2"
import PilotosShared_Personas_CardList from "../Shared/PilotosShared_Personas_CardList"
import usePersonasLanding from "../../../hooksData/usePilotosLanding"

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
	width: 40,
	height: 40,
	display: "flex",
	borderRadius: "50%",
	alignItems: "center",
	justifyContent: "center",
	color: theme.palette.primary.main,
	backgroundColor: alpha(theme.palette.primary.main, 0.08),
}))

// ----------------------------------------------------------------------

export default function PilotosSearch() {
	const ug = useGeneral("PilotosSearch")

	const { personasByName } = usePersonasLanding()

	const [nombre, set_nombre] = useState<string | null>("")
	const [searchedAtLeastOnce, set_searchedAtLeastOnce] = useState(false)

	useEffect(() => {
		return () => {
			ug.D(bllPersonas_Search.setInitialState())
		}
	}, [])

	const validations = async () => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//nombre
		apiValidations2.validateRequired(
			apiCallIG_Personas_GetPersonas_ByName.paramsName.name,
			apiCallIG_Personas_GetPersonas_ByName.paramsDesc.name,
			nombre,
			serverModelErrors
		)

		return await bllDispatchs_GlobalsServer.setModelErrors(ug.key, serverModelErrors)
	}

	const onSubmit = async (e: any) => {
		e.preventDefault()

		if (await validations()) return

		if (!nombre) {
			utilLogger.error("No debería haber llegado hasta acá un parámetro requerido como NULL.", { nombre })
			return
		}

		await ug.D(
			bllPersonas_Search.searchPersonasByName(ug.apiCallParams, {
				name: nombre,
			})
		)

		set_searchedAtLeastOnce(true)
	}

	return (
		<>
			<Grid item xs={12}>
				<Card sx={{ p: 3, mt: 2 }}>
					<Box
						sx={{
							display: "grid",
							rowGap: 3,
							columnGap: 2,
						}}
					>
						<form onSubmit={(e) => onSubmit(e)}>
							<Stack spacing={3}>
								{ug.serverState.errorMessage !== "" && <Alert severity="error">{ug.serverState.errorMessage}</Alert>}

								<Stack spacing={2}>
									<SharedFormTextFieldString
										callerKey={ug.key}
										fieldName={apiCallIG_Personas_GetPersonas_ByName.paramsName.name}
										label={apiCallIG_Personas_GetPersonas_ByName.paramsDesc.name}
										value={nombre}
										handleOnChangeProp={set_nombre}
									/>
								</Stack>
							</Stack>

							<Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
								<LoadingButton type="submit" variant="contained" loading={ug.serverState.isLoading}>
									Buscar
								</LoadingButton>
							</Stack>
						</form>
					</Box>
				</Card>
			</Grid>

			<Card sx={{ mt: 2 }}>
				<PilotosShared_Personas_CardList
					personas={personasByName}
					emptyListMessage={
						searchedAtLeastOnce ? "-No hay resultados para la búsqueda-" : "-Ingresa un nombre para buscar pilotos-"
					}
				/>
			</Card>
		</>
	)
}
