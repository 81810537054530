/* eslint-disable react/jsx-pascal-case*/
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useGlobalsUserRoles from "../../../hooksData/useGlobalsUserRoles"
import { ISharedTabs_Tab } from "../../Shared/Basic/SharedBasicTabs"
import SaludonnetCentrosUrgencias from "../CentrosUrgencias/SaludonnetCentrosUrgencias"
import SaludonnetHeTenidoUnAccidente from "../HeTenidoUnAccidente/SaludonnetHeTenidoUnAccidente"
import SaludonnetProtocoloAccidente from "../ProtocoloAccidente/SaludonnetProtocoloAccidente"
import SaludonnetProtocoloChubb from "../ProtocoloChubb/SaludonnetProtocoloChubb"
import SaludonnetProtocoloHubener from "../ProtocoloHubener/SaludonnetProtocoloHubener"
import SaludonnetSeguroActual from "../SeguroActual/SaludonnetSeguroActual"
import SaludonnetSegurosVigentes from "../SegurosVigentes/SaludonnetSegurosVigentes"

export const SaludonnetLanding_Menu = (): ISharedTabs_Tab[] => {
	const { isUserCurrentRol_Admin } = useGlobalsUserRoles()

	var menu: ISharedTabs_Tab[] = []

	menu.push({
		name: globalPathsApp.tabsSaludonnetEnum.seguroActual,
		label: "Seguro actual",
		component: <SaludonnetSeguroActual />,
		iconifyIcon: "mdi:car-insurance",
		urlDest: globalPathsApp.pageInsideUrl.Seguros(globalPathsApp.tabsSaludonnetEnum.seguroActual),
	})

	menu.push({
		name: globalPathsApp.tabsSaludonnetEnum.segurosVigentes,
		label: "Seguros vigentes",
		component: <SaludonnetSegurosVigentes />,
		iconifyIcon: "mdi:car-insurance",
		urlDest: globalPathsApp.pageInsideUrl.Seguros(globalPathsApp.tabsSaludonnetEnum.segurosVigentes),
	})

	menu.push({
		name: globalPathsApp.tabsSaludonnetEnum.protocoloHubener,
		label: "Protocolo Hübener",
		component: <SaludonnetProtocoloHubener />,
		iconifyIcon: "fa-solid:car-crash",
		urlDest: globalPathsApp.pageInsideUrl.Seguros(globalPathsApp.tabsSaludonnetEnum.protocoloHubener),
	})

	menu.push({
		name: globalPathsApp.tabsSaludonnetEnum.protocoloChubb,
		label: "Protocolo Chubb",
		component: <SaludonnetProtocoloChubb />,
		iconifyIcon: "fa-solid:car-crash",
		urlDest: globalPathsApp.pageInsideUrl.Seguros(globalPathsApp.tabsSaludonnetEnum.protocoloChubb),
	})

	menu.push({
		name: globalPathsApp.tabsSaludonnetEnum.heTenidoUnAccidente,
		label: "He tenido un accidente",
		component: <SaludonnetHeTenidoUnAccidente />,
		iconifyIcon: "fa-solid:car-crash",
		urlDest: globalPathsApp.pageInsideUrl.Seguros(globalPathsApp.tabsSaludonnetEnum.heTenidoUnAccidente),
	})

	menu.push({
		name: globalPathsApp.tabsSaludonnetEnum.centrosurgencia,
		label: "Centros de urgencia",
		component: <SaludonnetCentrosUrgencias />,
		iconifyIcon: "fa:hospital-o",
		urlDest: globalPathsApp.pageInsideUrl.Seguros(globalPathsApp.tabsSaludonnetEnum.centrosurgencia),
	})

	menu.push({
		name: globalPathsApp.tabsSaludonnetEnum.historicoSeguros,
		label: "Histórico de seguros",
		component: <SaludonnetCentrosUrgencias />,
		iconifyIcon: "mdi:car-insurance",
		urlDest: globalPathsApp.pageInsideUrl.Seguros(globalPathsApp.tabsSaludonnetEnum.historicoSeguros),
	})

	// menu.push({
	// 	name: globalPathsApp.tabsSaludonnetEnum.protocoloAccidente,
	// 	label: "Protocolo accidente",
	// 	component: <SaludonnetProtocoloAccidente />,
	// 	iconifyIcon: "fa-solid:car-crash",
	// 	urlDest: globalPathsApp.pageInsideUrl.Saludonnet(globalPathsApp.tabsSaludonnetEnum.protocoloAccidente),
	// })

	return menu
}
