/* eslint-disable react/jsx-pascal-case*/
import { Container, styled } from "@mui/material"
import Box from "@mui/material/Box"
import { useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import { styleConfig } from "../../Style/styleConfig"
import { Layout_Header } from "./Layout_Header"
import { Layout_LeftMenu } from "./Layout_LeftMenu"

const RightSideHeaderStyle = styled("div")(({ theme }) => ({
	flex: 1,
}))

interface IProps {
	children: React.ReactNode
}

export const Layout = ({ children }: IProps) => {
	const leftMenuOpen = useSelector((state: IState) => state.GlobalsUI.LeftMenu.open)

	return (
		<>
			<Layout_LeftMenu />
			<Box
				sx={{
					marginLeft: 0,
					transition: (theme) =>
						theme.transitions.create("margin-left", {
							duration: theme.transitions.duration.standard,
						}),
					...(leftMenuOpen && {
						marginLeft: `${styleConfig.leftMenuWidth}px`,
					}),
				}}
			>
				<RightSideHeaderStyle>
					<Layout_Header />

					<Container sx={{ mt: 1, pb: 1 }}>{children}</Container>
				</RightSideHeaderStyle>
			</Box>
		</>
	)
}
