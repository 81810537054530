import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import ReactCookieFirst from "react-cookiefirst"
import { globalConfig } from "../../globals/globalConfig"
import { utilLogger } from "../../utils/utilLogger"

interface IProps {
	children: React.ReactNode
}

function isBrave() {
	const navigator: any = window.navigator
	if ((navigator as any).brave != undefined) {
		if (navigator.brave.isBrave.name == "isBrave") {
			return true
		} else {
			return false
		}
	} else {
		return false
	}
}

export default function GlobalCookiesConsent({ children }: IProps) {
	// utilLogger.two(isBrave(), "window.navigator")

	if (isBrave()) return <>{children}</>

	if (globalConfig.environment === "development") return <>{children}</>

	return <ReactCookieFirst apiKey={globalConfig.cookieFirstKey}>{children}</ReactCookieFirst>
}
