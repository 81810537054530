import produce from "immer"

export namespace utilRedux {
	export const isActionInEnum = (action: any, ET: any): boolean => {
		if (ET) {
			for (let item in ET) {
				if (ET[item] === action.type) {
					return true
				}
			}
		}
		return false
	}

	export const actionToState = (action: any, state: any): any => {
		return produce(state, (draft: any) => {
			for (let key of Object.keys(action)) {
				//console.log(key, action[key])
				if (key !== "type") {
					draft[key] = action[key]
				}
			}
		})
		// for (let key of Object.keys(action)) {
		// 	//console.log(key, action[key])
		// 	if (key !== "type") {
		// 		state[key] = action[key]
		// 	}
		// }
		// for (let key of Object.keys(state)) {
		// 	 if (action[key] !== undefined) {//Pongo que es distinto de undefined porque puede venir null o false y son valores válidos
		// 	state[key] = action[key]
		// 	 }
		// }

		return state
	}

	//TA = triggerAction
	export const TA = (dispatch: any, type: string, obj: any) => {
		dispatch(standardAction(type, obj))
	}

	const standardAction = (type: string, obj: any) => {
		//Le agrego el tipo
		obj.type = type

		return obj

		//En caso de que esté trabajando con clases, lo tengo que convertir a un objeto plano (POJO)
		//const plainObj = JSON.parse(JSON.stringify(obj))
		//return plainObj
	}
}
