import produce from "immer"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllGlobalsServer_ModelErrorsList {
	//ET = enumTypes
	enum ET {
		setModelErrors = "GlobalsServer_ModelErrorsList_setServerModelErrors",
		cleanModelErrors = "GlobalsServer_ModelErrorsList_cleanServerModelErrors",
	}

	export interface IServerModelErrors {
		[key: string]: string
	}

	export interface IInterface {
		[key: string]: IServerModelErrors
	}

	const getInitialState = (): IInterface => {
		return {}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			case ET.setModelErrors:
				return produce(state, (draft: IInterface) => {
					draft[action.key] = action.serverModelErrors
				})
				break
			case ET.cleanModelErrors:
				return produce(state, (draft: IInterface) => {
					delete draft[action.key]
				})
				break
			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setServerModelErrors(key: string, serverModelErrors: IServerModelErrors) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.setModelErrors, {
				key: key,
				serverModelErrors: serverModelErrors,
			})
		}
	}

	export function cleanServerModelErrors(key: string) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.cleanModelErrors, {
				key: key,
			})
		}
	}
}
