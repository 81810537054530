import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { sharedPersona } from "../../../shared/sharedPersona"

export namespace apiCallIG_Personas_ActualizarPosteoDePersona {
	export interface params {
		idPersonaPosteo: number
		texto: string
	}
	export const paramsName = {
		idPersonaPosteo: "idPersonaPosteo",
		texto: "texto",
	}
	export const paramsDesc = {
		idPersonaPosteo: "Id PersonaPosteo",
		texto: "Texto del posteo",
	}

	export interface IRes {
		sharedPersona: sharedPersona.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Personas/ActualizarPosteoDePersona`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedPersona: sharedPersona.getSharedOne_FromApiObject(apiCallRes) }
	}
}
