/* eslint-disable react/jsx-pascal-case*/
import { Link, ListItemButton, ListItemText, Typography } from "@mui/material"
import { sharedNotificacion } from "../../api/shared/sharedNotificacion"
import { globalPathsApp } from "../../globals/globalPathsApp"
import { utilDate } from "../../utils/utilDate"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"

type IProps = {
	notificacion: sharedNotificacion.IShared
	notificationClicked(): void
}

export const Layout_Header_Notifications_Item = ({ notificacion, notificationClicked }: IProps) => {
	const dateToNow = notificacion.fecha ? utilDate.fToNow(notificacion.fecha) : ""

	return (
		<ListItemButton
			sx={{
				py: 1.5,
				px: 2.5,
				mt: "1px",
				...(!notificacion.viewed && {
					bgcolor: "action.selected",
				}),
			}}
			onClick={() => notificationClicked()}
		>
			<Link variant="body2" color="text.primary" href={notificacion.url}>
				{/* <ListItemAvatar>
					<SharedAvatarImg src={avatarSrc} />
				</ListItemAvatar> */}
				<ListItemText
					primary={
						<>
							<Typography
								variant="caption"
								sx={{
									mt: 0.5,
									display: "flex",
									alignItems: "center",
								}}
							>
								{notificacion.asunto}
							</Typography>

							<Typography
								sx={{
									mt: 0.5,
									display: "flex",
									alignItems: "center",
								}}
							>
								{notificacion.mensaje}
							</Typography>
						</>
					}
					secondary={
						<Typography
							variant="caption"
							sx={{
								mt: 0.5,
								display: "flex",
								alignItems: "center",
								color: "text.disabled",
							}}
						>
							<SharedBasicIconify iconifyIcon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
							{dateToNow}
						</Typography>
					}
				/>
			</Link>
		</ListItemButton>
	)
}
