import { dispatchStore } from "../utils/utilReduxStore"
import { bllGlobalsUI_DialogErrorForbidden } from "./GlobalsUI/bllGlobalsUI_DialogErrorForbidden"
import { bllGlobalsUI_DialogErrorUnexpected } from "./GlobalsUI/bllGlobalsUI_DialogErrorUnexpected"

export namespace bllDispatchs_GlobalsUI {
	export const openDialogErrorUnexpected = async (title: string, subtitle: string, excepcion: string) => {
		await dispatchStore(bllGlobalsUI_DialogErrorUnexpected.open(title, subtitle, excepcion))
	}

	export const openDialogErrorForbidden = async (title: string, subtitle: string, excepcion: string) => {
		await dispatchStore(bllGlobalsUI_DialogErrorForbidden.open(title, subtitle, excepcion))
	}
}
