import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { sharedCircuito } from "../../../shared/sharedCircuito"

export namespace apiCallIG_CircuitosPistas_UpdateCircuitoPista {
	export interface params {
		idCircuitoPista: number
		nombre: string
		longitudEnMetros: number | null
		nivelPilotaje: number | null
		vehiculos: number[]
		dibujoPistaURL: string | null
		longitudRectaPrincipalEnMetros: number | null
		cantidadCurvasDerecha: number | null
		cantidadCurvasIzquierda: number | null
		anchoMaximoPistaEnMetros: number | null
		pendienteMaximaEnPorcentaje: number | null
		recordCircuito: string | null
		cantidadBoxes: number | null
	}
	export const paramsName = {
		idCircuitoPista: "idCircuitoPista",
		nombre: "Nombre",
		longitudEnMetros: "longitudEnMetros",
		nivelPilotaje: "nivelPilotaje",
		vehiculos: "vehiculos",
		dibujoPistaURL: "dibujoPistaURL",
		longitudRectaPrincipalEnMetros: "longitudRectaPrincipalEnMetros",
		cantidadCurvasDerecha: "cantidadCurvasDerecha",
		cantidadCurvasIzquierda: "cantidadCurvasIzquierda",
		anchoMaximoPistaEnMetros: "anchoMaximoPistaEnMetros",
		pendienteMaximaEnPorcentaje: "pendienteMaximaEnPorcentaje",
		recordCircuito: "recordCircuito",
		cantidadBoxes: "cantidadBoxes",
	}
	export const paramsDesc = {
		nombre: "Nombre",
		longitudEnMetros: "Longitud en metros",
		nivelPilotaje: "Nivel pilotaje (1-5)",
		vehiculos: "Vehículos",
		dibujoPistaURL: "Dibujo pista",
		longitudRectaPrincipalEnMetros: "Longitud recta principal en metros",
		cantidadCurvasDerecha: "Cantidad curvas derecha",
		cantidadCurvasIzquierda: "Cantidad curvas izquierda",
		anchoMaximoPistaEnMetros: "Ancho máximo pista en metros",
		pendienteMaximaEnPorcentaje: "Pendiente máxima en porcentaje",
		recordCircuito: "Record circuito",
		cantidadBoxes: "Cantidad boxes",
	}

	export interface IRes {
		sharedCircuito: sharedCircuito.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/CircuitosPistas/UpdateCircuitoPista`
		const sharedParamsQuery: params | null = null
		const sharedParamsBody = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedCircuito: sharedCircuito.getSharedOne_FromApiObject(apiCallRes.circuito),
		}
	}
}
