/* eslint-disable react/jsx-pascal-case*/
import { Box, Container } from "@mui/material"

export default function AdminGeneral() {
	return (
		<Container>
			<Box>General</Box>
		</Container>
	)
}
