import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Personas_GetPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersona"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { bllChat_All } from "../Chat/bllChat_All"
import { bllGlobalsUI_FullScreenLoading } from "../GlobalsUI/bllGlobalsUI_FullScreenLoading"
import { bllGlobalsUser_CurrentPersonaCircuitos } from "./bllGlobalsUser_CurrentPersonaCircuitos"
import { bllGlobalsUser_CurrentPersonaRodadas } from "./bllGlobalsUser_CurrentPersonaRodadas"
import { bllGlobalsUser_CurrentPersona_InitialLoad } from "./bllGlobalsUser_CurrentPersona_InitialLoad"

export namespace bllGlobalsUser_CurrentPersona {
	//ET = enumTypes
	enum ET {
		setInitialState = "GlobalsUser_CurrentPersona_setInitialState",
		change = "GlobalsUser_CurrentPersona_change",
		updateWithSharedPersona = "GlobalsUser_CurrentPersona_updateWithSharedPersona",
	}

	export interface IInterface {
		currentPersona: sharedPersona.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			currentPersona: sharedPersona.objectDefault,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Current_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function change(apiCallParams: apiCallInterface, sharedPersona: sharedPersona.IShared) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())
			type actionSubType = Pick<IInterface, "currentPersona">
			const updateObject: actionSubType = {
				currentPersona: sharedPersona,
			}

			utilRedux.TA(D, ET.change, updateObject)

			D(bllGlobalsUser_CurrentPersona_InitialLoad.initialCallForPersona(apiCallParams, sharedPersona.id))
			D(bllChat_All.loadRooms_ForPerson(apiCallParams, { idPerson: sharedPersona.id }))
			D(bllChat_All.loadMessages_ForPerson(apiCallParams, { idPerson: sharedPersona.id }))

			// D(bllGlobalsUser_CurrentPersonaCircuitos.loadCircuitosDePersona(apiCallParams, { idPersona: sharedPersona.id }))
			// D(bllGlobalsUser_CurrentPersonaRodadas.loadRodadasDePersona(apiCallParams, { idPersona: sharedPersona.id }))
		}
	}

	export function updateOnlyPersonaInfoWithSharedPersona(sharedPersona: sharedPersona.IShared) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "currentPersona">
			const updateObject: actionSubType = {
				currentPersona: sharedPersona,
			}

			utilRedux.TA(D, ET.updateWithSharedPersona, updateObject)
		}
	}
}
