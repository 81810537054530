/* eslint-disable react/jsx-pascal-case*/
import { List, SxProps, ListItem, ListItemText } from "@mui/material"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import useResponsive from "../../hooks/useResponsive"
import useRouting from "../../hooks/useRouting"
import useChat from "../../hooksData/useChat"
import useGeneral from "../../hooksData/useGeneral"
import { utilString } from "../../utils/utilString"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"
import Chat_Rooms_List_Item from "./Chat_Rooms_List_Item"
import orderBy from "lodash/orderBy"
import { utilLogger } from "../../utils/utilLogger"
import { sharedChatType } from "../../api/shared/sharedChatType"
import useGlobalsUser from "../../hooksData/useGlobalsUser"

export interface IProps {
	chatType: sharedChatType.IShared
	hideRoomType?: boolean
	openNav: boolean
	onCloseNav: VoidFunction
	sx?: SxProps
}

export default function Chat_Rooms_List({ chatType, openNav, onCloseNav, sx, hideRoomType, ...other }: IProps) {
	const ug = useGeneral("Chat_Rooms_List")
	const { currentPersona } = useGlobalsUser()

	const { sharedChatRooms } = useChat()
	const { nombre_FromUrl } = useRouting()
	const { isDesktop } = useResponsive()

	const nameFromUrlForFilter = utilString.normalizeToSearch(nombre_FromUrl)

	var sharedChatRoomsFiltered = sharedChatRooms.filter(
		(m) =>
			// m.chatType.id === chatType.id &&
			(utilString.normalizeToSearch(m.namePerson1).includes(nameFromUrlForFilter) &&
				m.namePerson1 !== currentPersona.nombre) ||
			(utilString.normalizeToSearch(m.namePerson2).includes(nameFromUrlForFilter) &&
				m.namePerson2 !== currentPersona.nombre)
	)

	const rooms: sharedChatRoom.IShared[] = orderBy(sharedChatRoomsFiltered, ["dateLastMessage"], ["desc"])

	return (
		<List disablePadding sx={sx} {...other}>
			{rooms.map((room) => (
				<Chat_Rooms_List_Item
					key={room.id}
					openNav={openNav}
					room={room}
					hideRoomType={hideRoomType}
					onSelect={() => {
						if (!isDesktop) {
							onCloseNav()
						}
					}}
				/>
			))}

			{sharedChatRoomsFiltered.length === 0 && ( //Puede que haya rooms pero no mensajes
				<ListItem>
					<SharedBasicTypo
						color={ug.theme.palette.grey[600]}
						variant="subtitle2"
						sx={{ mt: 2, fontStyle: "italic", mx: "auto" }}
					>{`-No hay chats aún-`}</SharedBasicTypo>
				</ListItem>
			)}
		</List>
	)
}
