import numeral from "numeral"

export namespace utilString {
	export const isNumber = (str: string): boolean => {
		if (typeof str !== "string") {
			return false
		}

		if (str.trim() === "") {
			return false
		}

		return !Number.isNaN(Number(str))
	}

	export const convertLetterToNumber = (str: string) => {
		if (isNumber(str)) {
			return Number(str)
		}

		if (/^[a-zA-Z]+$/.test(str)) {
			str = str.toUpperCase()
			let out = 0,
				len = str.length
			for (var pos: number = 0; pos < len; pos++) {
				out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1)
			}
			return out
		} else {
			return undefined
		}
	}

	export const normalizeToSearch = (str: string | undefined | null) => {
		if (!str) return ""

		return str
			.toLowerCase()
			.trim()
			.normalize("NFD")
			.replace(/\p{Diacritic}/gu, "")
	}
}
