import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"

export namespace apiCallIG_Registrarse_ResendConfirmationEmail {
	export interface params {
		emailConfirmationFrontendUrl: string
		email: string
		// recaptchaToken: recaptchaToken, //TODO: poner recaptchaToken
	}
	export const paramsName = {
		emailConfirmationFrontendUrl: "emailConfirmationFrontendUrl",
		email: "email",
	}
	export const paramsDesc = { email: "Dirección de email" }

	export interface IRes {}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		apiValidations2.validateMustBeEmail(paramsName.email, paramsDesc.email, params.email, serverModelErrors)
		apiValidations2.validateMaxNumberOfChars(paramsName.email, paramsDesc.email, params.email, 320, serverModelErrors)

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Registrarse/ResendConfirmationEmail`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {}
	}
}
