import { sharedCircuito } from "./sharedCircuito"
import { sharedEmpresa } from "./sharedEmpresa"
import { sharedEscuela } from "./sharedEscuela"
import { sharedFederacion } from "./sharedFederacion"
import { sharedMotoclub } from "./sharedMotoclub"
import { sharedOrganizador } from "./sharedOrganizador"
import { sharedPersonaSeguidor } from "./sharedPersonaSeguidor"
import { sharedRodada } from "./sharedRodada"

export namespace sharedInitialCallForPersonaRes {
	export interface IShared {
		personaCircuitos: sharedCircuito.IShared[]
		personaEmpresas: sharedEmpresa.IShared[]
		personaEscuelas: sharedEscuela.IShared[]
		personaFederaciones: sharedFederacion.IShared[]
		personaMotoclubs: sharedMotoclub.IShared[]
		personaOrganizadores: sharedOrganizador.IShared[]
		personaRodadas: sharedRodada.IShared[]
		rodadasSeguidas: sharedRodada.IShared[]
		circuitosSeguidos: sharedCircuito.IShared[]
		empresasSeguidas: sharedEmpresa.IShared[]
		escuelasSeguidas: sharedEscuela.IShared[]
		federacionesSeguidas: sharedFederacion.IShared[]
		motoclubsSeguidos: sharedMotoclub.IShared[]
		organizadoresSeguidos: sharedOrganizador.IShared[]
		personasSeguidas: sharedPersonaSeguidor.IShared[]
	}

	export const objectDefault: IShared = {
		personaCircuitos: [],
		personaEmpresas: [],
		personaEscuelas: [],
		personaFederaciones: [],
		personaMotoclubs: [],
		personaOrganizadores: [],
		personaRodadas: [],
		rodadasSeguidas: [],
		circuitosSeguidos: [],
		empresasSeguidas: [],
		escuelasSeguidas: [],
		federacionesSeguidas: [],
		motoclubsSeguidos: [],
		organizadoresSeguidos: [],
		personasSeguidas: [],
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...objectDefault,
			...apiObject,
			personaCircuitos:
				apiObject.personaCircuitos && sharedCircuito.getSharedList_FromApiObject(apiObject.personaCircuitos),
			personaEmpresas:
				apiObject.personaEmpresas && sharedEmpresa.getSharedList_FromApiObject(apiObject.personaEmpresas),
			personaEscuelas:
				apiObject.personaEscuelas && sharedEscuela.getSharedList_FromApiObject(apiObject.personaEscuelas),
			personaFederaciones:
				apiObject.personaFederaciones && sharedFederacion.getSharedList_FromApiObject(apiObject.personaFederaciones),
			personaMotoclubs:
				apiObject.personaMotoclubs && sharedMotoclub.getSharedList_FromApiObject(apiObject.personaMotoclubs),
			personaOrganizadores:
				apiObject.personaOrganizadores && sharedOrganizador.getSharedList_FromApiObject(apiObject.personaOrganizadores),
			personaRodadas: apiObject.personaRodadas && sharedRodada.getSharedList_FromApiObject(apiObject.personaRodadas),
			rodadasSeguidas: apiObject.rodadasSeguidas && sharedRodada.getSharedList_FromApiObject(apiObject.rodadasSeguidas),
			circuitosSeguidos:
				apiObject.circuitosSeguidos && sharedCircuito.getSharedList_FromApiObject(apiObject.circuitosSeguidos),
			empresasSeguidas:
				apiObject.empresasSeguidas && sharedEmpresa.getSharedList_FromApiObject(apiObject.empresasSeguidas),
			escuelasSeguidas:
				apiObject.escuelasSeguidas && sharedEscuela.getSharedList_FromApiObject(apiObject.escuelasSeguidas),
			federacionesSeguidas:
				apiObject.federacionesSeguidas && sharedFederacion.getSharedList_FromApiObject(apiObject.federacionesSeguidas),
			motoclubsSeguidos:
				apiObject.motoclubsSeguidos && sharedMotoclub.getSharedList_FromApiObject(apiObject.motoclubsSeguidos),
			organizadoresSeguidos:
				apiObject.organizadoresSeguidos &&
				sharedOrganizador.getSharedList_FromApiObject(apiObject.organizadoresSeguidos),
			personasSeguidas:
				apiObject.personasSeguidas && sharedPersonaSeguidor.getSharedList_FromApiObject(apiObject.personasSeguidas),
		}

		return res
	}
}
