import { globalAssets } from "../../globals/globalAssets"

export namespace sharedOrganizadorSeguidor {
	export interface IShared {
		id: number
		idOrganizador: number
		idPersona: number
		nombrePersona: string
		apellido1Persona: string
		apellido2Persona: string
		avatarURLPersona: string
	}

	export const defaultObject: IShared = {
		id: 0,
		idOrganizador: 0,
		idPersona: 0,
		nombrePersona: "",
		apellido1Persona: "",
		apellido2Persona: "",
		avatarURLPersona: globalAssets.staticPaths.personaAvatar,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
			avatarURL: apiObject.avatarURL || globalAssets.staticPaths.personaAvatar,
		}

		return res
	}
}
