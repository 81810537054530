import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedCircuito } from "../../api/shared/sharedCircuito"
import { apiCallIG_Circuitos_GetAllCircuitos } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_GetAllCircuitos"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_General_InitialCall } from "../../api/calls/IG/General/apiCallIG_General_InitialCall"
import { bllGlobalsGeneralData_AllCircuitos } from "./bllGlobalsGeneralData_AllCircuitos"
import { bllGlobalsGeneralData_AllEmpresas } from "./bllGlobalsGeneralData_AllEmpresas"
import { bllGlobalsGeneralData_AllEscuelas } from "./bllGlobalsGeneralData_AllEscuelas"
import { bllGlobalsGeneralData_AllFederaciones } from "./bllGlobalsGeneralData_AllFederaciones"
import { bllGlobalsGeneralData_AllMotoclubs } from "./bllGlobalsGeneralData_AllMotoclubs"
import { bllGlobalsGeneralData_AllOrganizadores } from "./bllGlobalsGeneralData_AllOrganizadores"
import { bllGlobalsGeneralData_AllRodadas } from "./bllGlobalsGeneralData_AllRodadas"
import { bllGlobalsGeneralData_AllVehiculos } from "./bllGlobalsGeneralData_AllVehiculos"

export namespace bllGlobalsGeneralData_InitialLoad {
	//ET = enumTypes
	enum ET {
		setInitialState = "GlobalsGeneralData_InitialLoad_setInitialState",
		load = "GlobalsGeneralData_InitialLoad_load",
	}

	export interface IInterface {
		loaded: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			loaded: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function initialCall(apiCallParams: apiCallInterface) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_General_InitialCall.apiCall(apiCallParams)

			if (apiCallRes) {
				await D(bllGlobalsGeneralData_AllCircuitos.setAllCircuitos(apiCallRes.sharedInitialCallRes.allCircuitos))
				await D(bllGlobalsGeneralData_AllEmpresas.setAllEmpresas(apiCallRes.sharedInitialCallRes.allEmpresas))
				await D(bllGlobalsGeneralData_AllEscuelas.setAllEscuelas(apiCallRes.sharedInitialCallRes.allEscuelas))
				await D(
					bllGlobalsGeneralData_AllFederaciones.setAllFederaciones(apiCallRes.sharedInitialCallRes.allFederaciones)
				)
				await D(bllGlobalsGeneralData_AllMotoclubs.setAllMotoclubs(apiCallRes.sharedInitialCallRes.allMotoclubs))
				await D(
					bllGlobalsGeneralData_AllOrganizadores.setAllOrganizadores(apiCallRes.sharedInitialCallRes.allOrganizadores)
				)
				await D(bllGlobalsGeneralData_AllRodadas.setAllRodadas(apiCallRes.sharedInitialCallRes.allRodadas))
				await D(
					bllGlobalsGeneralData_AllVehiculos.setAllVehiculos(
						apiCallRes.sharedInitialCallRes.allVehiculos,
						apiCallRes.sharedInitialCallRes.allAgrupacionesVehiculos
					)
				)

				type actionSubType = Pick<IInterface, "loaded">
				const updateObject: actionSubType = {
					loaded: true,
				}

				utilRedux.TA(D, ET.load, updateObject)
			}
		}
	}
}
