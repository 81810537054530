/* eslint-disable react/jsx-pascal-case*/
import { alpha, Avatar, Box, Card, Divider, Stack, styled } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { sharedRodada } from "../../../api/shared/sharedRodada"
import { globalAssets } from "../../../globals/globalAssets"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import { utilDate } from "../../../utils/utilDate"
import SharedBasicImage from "../../Shared/Basic/SharedBasicImage"
import SharedBasicTypo from "../../Shared/Basic/SharedBasicTypo"
import { SharedLinkInsideAppWrapper } from "../../Shared/SharedLinkInsideAppWrapper"
import SharedSvgColor from "../../Shared/SharedSvgColor"

interface IProps {
	rodada: sharedRodada.IShared
}

const StyledOverlay = styled("div")(({ theme }) => ({
	top: 0,
	left: 0,
	zIndex: 8,
	width: "100%",
	height: "100%",
	position: "absolute",
	backgroundColor: alpha(theme.palette.grey[900], 0.64),
}))

export default function RodadasShared_CardList_Card({ rodada }: IProps) {
	const theme = useTheme()

	return (
		<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.RodadasProfile(undefined, rodada.id)}>
			<Card sx={{ textAlign: "center" }}>
				<Box sx={{ position: "relative" }}>
					<SharedSvgColor
						src="/assets/shape_avatar.svg"
						sx={{
							width: 144,
							height: 62,
							zIndex: 10,
							left: 0,
							right: 0,
							bottom: -26,
							mx: "auto",
							position: "absolute",
							color: "background.paper",
						}}
					/>

					<Avatar
						alt={rodada.nombre}
						src={globalAssets.staticPaths.rodadasAvatar}
						sx={{
							width: 64,
							height: 64,
							zIndex: 11,
							left: 0,
							right: 0,
							bottom: -32,
							mx: "auto",
							position: "absolute",
						}}
					/>

					<StyledOverlay />

					<SharedBasicImage src={globalAssets.staticPaths.rodadasHero} alt={rodada.nombre} ratio="16/9" />
				</Box>

				<SharedBasicTypo variant="subtitle1" sx={{ mt: 6, mb: 0.5 }}>
					{rodada.nombre}
				</SharedBasicTypo>

				<SharedBasicTypo variant="body2" sx={{ color: theme.palette.text.secondary }}>
					{rodada.descripcionCorta}
				</SharedBasicTypo>

				<Stack direction="row" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 3 }}>
					{/* {_socials.map((social) => (
          <IconButton
            key={social.name}
            sx={{
              color: social.color,
              '&:hover': {
                bgcolor: alpha(social.color, 0.08),
              },
            }}
          >
            <Iconify icon={social.icon} />
          </IconButton>
        ))} */}
				</Stack>

				<Divider sx={{ borderStyle: "dashed" }} />

				<Box display="grid" gridTemplateColumns="repeat(3, 1fr)" sx={{ py: 3 }}>
					<div>
						<SharedBasicTypo variant="caption" component="div" sx={{ mb: 0.75, color: "text.disabled" }}>
							Inscriptos
						</SharedBasicTypo>
						<SharedBasicTypo variant="subtitle1">{rodada.cantidadInscriptosConfirmados}</SharedBasicTypo>
					</div>

					<div>
						<SharedBasicTypo variant="caption" component="div" sx={{ mb: 0.75, color: "text.disabled" }}>
							Fecha desde
						</SharedBasicTypo>

						<SharedBasicTypo variant="body2">
							{rodada.fechaDesde ? utilDate.fDate(rodada.fechaDesde) : "-"}
						</SharedBasicTypo>
					</div>

					<div>
						<SharedBasicTypo variant="caption" component="div" sx={{ mb: 0.75, color: "text.disabled" }}>
							Fecha hasta
						</SharedBasicTypo>
						<SharedBasicTypo variant="body2">
							{rodada.fechaHasta ? utilDate.fDate(rodada.fechaHasta) : "-"}
						</SharedBasicTypo>
					</div>
				</Box>
			</Card>
		</SharedLinkInsideAppWrapper>
	)
}
