/* eslint-disable react/jsx-pascal-case*/
import SimpleBarReact, { Props as ScrollbarProps } from "simplebar-react"
import { alpha, styled } from "@mui/material/styles"
import { Box, SxProps } from "@mui/material"
import useWindowSize from "../../../hooks/useWindowSize"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(() => ({
	flexGrow: 1,
	height: "100%",
	overflow: "hidden",
}))

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
	maxHeight: "100%",
	"& .simplebar-scrollbar": {
		"&:before": {
			backgroundColor: alpha(theme.palette.grey[600], 0.48),
		},
		"&.simplebar-visible:before": {
			opacity: 1,
		},
	},
	"& .simplebar-track.simplebar-vertical": {
		width: 10,
	},
	"& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
		height: 6,
	},
	"& .simplebar-mask": {
		zIndex: "inherit",
	},
}))

// ----------------------------------------------------------------------

interface Props extends ScrollbarProps {
	lessToWindowSizeHeight?: number
	sx?: SxProps
}

export default function SharedBasicScrollbar({ lessToWindowSizeHeight, children, sx, ...other }: Props) {
	const userAgent = typeof navigator === "undefined" ? "SSR" : navigator.userAgent

	const windowSize = useWindowSize()

	const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

	const scrollbarHeight = lessToWindowSizeHeight && windowSize.height - lessToWindowSizeHeight

	if (isMobile) {
		return (
			<Box sx={{ overflowX: "auto", maxHeight: scrollbarHeight, ...sx }} {...other}>
				{children}
			</Box>
		)
	}

	return (
		<RootStyle>
			<SimpleBarStyle timeout={500} clickOnTrack={false} sx={{ maxHeight: scrollbarHeight, ...sx }} {...other}>
				{children}
			</SimpleBarStyle>
		</RootStyle>
	)
}
