/* eslint-disable react/jsx-pascal-case*/
import { Box } from "@mui/material"
import { sharedOrganizador } from "../../../api/shared/sharedOrganizador"
import OrganizadoresGlobals_CardList_Card from "./OrganizadoresShared_CardList_Card"

interface IProps {
	organizadores: sharedOrganizador.IShared[]
}

export default function OrganizadoresShared_CardList({ organizadores }: IProps) {
	return (
		<>
			<Box
				gap={3}
				display="grid"
				sx={{ p: 3 }}
				gridTemplateColumns={{
					xs: "repeat(1, 1fr)",
					sm: "repeat(2, 1fr)",
					md: "repeat(3, 1fr)",
				}}
			>
				{organizadores.map((organizador, i) => (
					<OrganizadoresGlobals_CardList_Card key={i} organizador={organizador} />
				))}

				{organizadores.length === 0 && (
					<Box
						sx={{
							color: "text.secondary",
						}}
					>
						-Aún no hay organizadores en este listado-
					</Box>
				)}
			</Box>
		</>
	)
}
