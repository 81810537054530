/* eslint-disable react/jsx-pascal-case*/
import { IconifyIcon } from "@iconify/react"
import { alpha, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, styled } from "@mui/material"
import { globalPathsApp } from "../../globals/globalPathsApp"
import useRouting from "../../hooks/useRouting"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"

interface ListItemStyleProps extends ListItemButtonProps {
	active?: boolean
}

const ListItemButtonStyled = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== "active",
})<ListItemStyleProps>(({ active, theme }) => ({
	...theme.typography.body2,
	position: "relative",
	height: 40,
	textTransform: "capitalize",
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(1.5),
	marginBottom: theme.spacing(0.5),
	fontSize: "14px",
	color: theme.palette.text.secondary,
	borderRadius: theme.shape.borderRadius,
	// active
	...(active && {
		...theme.typography.subtitle2,
		color: theme.palette.primary.main,
		backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
	}),
}))

const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
	whiteSpace: "nowrap",
}))

interface IProps {
	iconifyIcon?: IconifyIcon | string
	customIcon?: React.ElementType
	pageDest: globalPathsApp.pagesEnum
	urlDest: string
	title: string
}

export function Layout_LeftMenu_Navbar_Item({ iconifyIcon, customIcon, pageDest, urlDest, title }: IProps) {
	const { page } = useRouting()

	return (
		<SharedLinkInsideAppWrapper urlDest={urlDest}>
			<ListItemButtonStyled active={pageDest === page}>
				<ListItemIcon>
					<SharedBasicIconify iconifyIcon={iconifyIcon} customIcon={customIcon} width={20} height={20} />
				</ListItemIcon>
				<ListItemTextStyled /*disableTypography*/ primary={title} />
			</ListItemButtonStyled>
		</SharedLinkInsideAppWrapper>
	)
}
