import { apiCallIG_Registrarse_RegistrarseConEmail } from "../../api/calls/IG/Registrarse/apiCallIG_Registrarse_RegistrarseConEmail"
import { utilRedux } from "../../utils/utilRedux"
import { globalPathsApp } from "../../globals/globalPathsApp"
import { utilLogger } from "../../utils/utilLogger"
import { bllUsers_Login } from "./bllUsers_Login"
import { apiCallInterface } from "../../api/apiCallInterface"

export namespace bllUsers_Registrarse {
	//ET = enumTypes
	enum ET {
		setInitialState = "Users_Registrarse_setInitialState",
		registrarseConEmail = "Users_Registrarse_registrarseConEmail",
	}

	export interface IInterface {
		isUserRegistered: boolean
		isUserRegisteredAndLogged: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			isUserRegistered: false,
			isUserRegisteredAndLogged: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.User_Registrarse_Registrarse:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function registrarseConEmail(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Registrarse_RegistrarseConEmail.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Registrarse_RegistrarseConEmail.apiCall(apiCallParams, params)

			if (apiCallRes) {
				var isUserRegisteredAndLogged = false

				if (apiCallRes.sharedLoginRes) {
					await D(bllUsers_Login.setLoginRes(apiCallParams, apiCallRes.sharedLoginRes))
					isUserRegisteredAndLogged = true
				}

				type actionSubType = Pick<IInterface, "isUserRegistered" | "isUserRegisteredAndLogged">
				const updateObject: actionSubType = {
					isUserRegistered: true,
					isUserRegisteredAndLogged: isUserRegisteredAndLogged,
				}

				utilRedux.TA(D, ET.registrarseConEmail, updateObject)
			}
		}
	}
}
