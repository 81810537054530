import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedCircuito } from "../../api/shared/sharedCircuito"
import { apiCallIG_Circuitos_GetCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_GetCircuito"
import { apiCallIG_Rodadas_GetRodadasDeCircuito } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_GetRodadasDeCircuito"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { apiCallIG_Circuitos_BloquearCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_BloquearCircuito"
import { apiCallIG_Circuitos_DesbloquearCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_DesbloquearCircuito"
import { apiCallIG_Circuitos_AprobarCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_AprobarCircuito"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_CircuitosSeguidores_SeguirCircuito } from "../../api/calls/IG/CircuitosSeguidores/apiCallIG_CircuitosSeguidores_SeguirCircuito"
import { apiCallIG_CircuitosSeguidores_DejarDeSeguirCircuito } from "../../api/calls/IG/CircuitosSeguidores/apiCallIG_CircuitosSeguidores_DejarDeSeguirCircuito"
import { bllGlobalsUser_CurrentPersonaCircuitosSeguidos } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaCircuitosSeguidos"
import { apiCallIG_CircuitosValoraciones_ValorarCircuito } from "../../api/calls/IG/CircuitosValoraciones/apiCallIG_CircuitosValoraciones_ValorarCircuito"

export namespace bllCircuitos_Profile {
	//ET = enumTypes
	enum ET {
		setInitialState = "Circuito_Profile_setInitialState",
		loadCircuito = "Circuito_Profile_loadCircuito",
		setCircuito = "Circuito_Profile_setCircuito",
		seguirCircuito = "Circuito_Profile_seguirCircuito",
		dejarDeSeguirCircuito = "Circuito_Profile_dejarDeSeguirCircuito",
		loadRodadasDelCircuito = "Circuito_Profile_loadRodadasDelCircuito",
		updateWithSharedCircuito = "Circuitos_Profile_updateWithSharedCircuito",
		valorarCircuito = "Circuitos_Profile_valorarCircuito",
	}

	export interface IInterface {
		circuito: sharedCircuito.IShared
		rodadasDelCircuito: sharedRodada.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			circuito: sharedCircuito.defaultObject,
			rodadasDelCircuito: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getCircuito(apiCallParams: apiCallInterface, params: apiCallIG_Circuitos_GetCircuito.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Circuitos_GetCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(loadRodadasDelCircuito(apiCallParams, { idCircuito: params.idCircuito }))

				type actionSubType = Pick<IInterface, "circuito">
				const updateObject: actionSubType = {
					circuito: apiCallRes.sharedCircuito,
				}

				utilRedux.TA(D, ET.loadCircuito, updateObject)
			}
		}
	}

	export function setCircuito(sharedCircuito: sharedCircuito.IShared) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "circuito">
			const updateObject: actionSubType = {
				circuito: sharedCircuito,
			}

			utilRedux.TA(D, ET.setCircuito, updateObject)
		}
	}

	export function loadRodadasDelCircuito(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Rodadas_GetRodadasDeCircuito.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Rodadas_GetRodadasDeCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "rodadasDelCircuito">
				const updateObject: actionSubType = {
					rodadasDelCircuito: apiCallRes.sharedRodadas,
				}

				utilRedux.TA(D, ET.loadRodadasDelCircuito, updateObject)
			}
		}
	}

	export function seguirCircuito(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosSeguidores_SeguirCircuito.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_CircuitosSeguidores_SeguirCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(
					bllGlobalsUser_CurrentPersonaCircuitosSeguidos.setPersonaCircuitosSeguidos(apiCallRes.sharedCircuitosSeguidos)
				)
				D(setCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.seguirCircuito, {})
			}
		}
	}

	export function dejarDeSeguirCircuito(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosSeguidores_DejarDeSeguirCircuito.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_CircuitosSeguidores_DejarDeSeguirCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(
					bllGlobalsUser_CurrentPersonaCircuitosSeguidos.setPersonaCircuitosSeguidos(apiCallRes.sharedCircuitosSeguidos)
				)
				D(setCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.dejarDeSeguirCircuito, {})
			}
		}
	}

	export function valorarCircuito(
		apiCallParams: apiCallInterface,
		params: apiCallIG_CircuitosValoraciones_ValorarCircuito.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_CircuitosValoraciones_ValorarCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(setCircuito(apiCallRes.sharedCircuito))

				utilRedux.TA(D, ET.valorarCircuito, {})
			}
		}
	}
}
