/* eslint-disable react/jsx-pascal-case*/
import { useLoadScript } from "@react-google-maps/api"
import { ReactNode } from "react"
import { globalConfig } from "../../globals/globalConfig"
import SharedLoadingComponent from "./Loading/SharedLoadingComponent"
import SharedGoogleMap2_Sub from "./SharedGoogleMap2_Sub"

type Props = {
	zoom: number
	centerLat: number
	centerLng: number
	children: ReactNode
}

export default function SharedGoogleMap2({ zoom, centerLat, centerLng, children }: Props) {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: globalConfig.googlePlacesApiKey,
	})

	if (!isLoaded) {
		return <SharedLoadingComponent />
	}

	return (
		<SharedGoogleMap2_Sub zoom={zoom} centerLat={centerLat} centerLng={centerLng}>
			{children}
		</SharedGoogleMap2_Sub>
	)
}
