import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { sharedPersona } from "../../../shared/sharedPersona"
import { sharedRodadaInvitation } from "../../../shared/sharedRodadaInvitation"
import { sharedRodadaPersona } from "../../../shared/sharedRodadaPersona"

export namespace apiCallIG_RodadasPersonas_GetEmailInvitationInfo {
	export interface params {
		email: string
		token: string
	}
	export const paramsName = {
		email: "email",
		token: "token",
	}
	export const paramsDesc = {}

	export interface IRes {
		sharedPersona: sharedPersona.IShared
		sharedRodadaPersona: sharedRodadaPersona.IShared
		existeUsuarioParaElEmail: boolean
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.get
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/RodadasPersonas/GetEmailInvitationInfo`
		const sharedParamsQuery: params | null = params
		const sharedParamsBody = null

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedPersona: sharedPersona.getSharedOne_FromApiObject(apiCallRes.persona),
			sharedRodadaPersona: sharedRodadaPersona.getSharedOne_FromApiObject(apiCallRes.rodadaPersona),
			existeUsuarioParaElEmail: apiCallRes.existeUsuarioParaElEmail,
		}
	}
}
