import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedEmpresa } from "../../api/shared/sharedEmpresa"
import { apiCallIG_Empresas_GetAllEmpresas } from "../../api/calls/IG/Empresas/apiCallIG_Empresas_GetAllEmpresas"
import { apiCallInterface } from "../../api/apiCallInterface"

export namespace bllGlobalsGeneralData_AllEmpresas {
	//ET = enumTypes
	enum ET {
		setInitialState = "GlobalsGeneralData_AllEmpresas_setInitialState",
		load = "GlobalsGeneralData_AllEmpresas_load",
	}

	export interface IInterface {
		empresas: sharedEmpresa.IShared[]
		loaded: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			empresas: [],
			loaded: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadAllEmpresas(apiCallParams: apiCallInterface) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Empresas_GetAllEmpresas.apiCall(apiCallParams)

			if (apiCallRes) {
				await D(setAllEmpresas(apiCallRes.sharedEmpresas))
			}
		}
	}

	export function setAllEmpresas(allEmpresas: sharedEmpresa.IShared[]) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "empresas" | "loaded">
			const updateObject: actionSubType = {
				empresas: allEmpresas,
				loaded: true,
			}

			utilRedux.TA(D, ET.load, updateObject)
		}
	}
}
