/* eslint-disable react/jsx-pascal-case*/
import { Box, Card, Container, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { bllChat_All } from "../../bll/Chat/bllChat_All"
import { IState } from "../../bll/bllReducers"
import useRouting from "../../hooks/useRouting"
import useChat from "../../hooksData/useChat"
import useGeneral from "../../hooksData/useGeneral"
import SharedLoadingComponent from "../Shared/Loading/SharedLoadingComponent"
import Chat_Room from "./Chat_Room"
import Chat_Rooms from "./Chat_Rooms"
import { GlobalDialogErrorUnexpected } from "../Globals/GlobalDialogErrorUnexpected"
import { bllGlobalsUI_DialogErrorUnexpected } from "../../bll/GlobalsUI/bllGlobalsUI_DialogErrorUnexpected"
import { Navigate } from "react-router-dom"
import { globalPathsApp } from "../../globals/globalPathsApp"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import { utilLogger } from "../../utils/utilLogger"
import { sharedChatType } from "../../api/shared/sharedChatType"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"
import useGlobalsUser from "../../hooksData/useGlobalsUser"

export interface IProps {
	chatType: sharedChatType.IShared
	hideRooms?: boolean
	hideRoomType?: boolean
	isUserEntityOwner?: boolean
}

export default function Chat({ chatType, hideRooms, hideRoomType, isUserEntityOwner }: IProps) {
	const ug = useGeneral("Chat")
	const { currentPersona } = useGlobalsUser()

	const { sharedChatRooms, currentRoom_sharedChatRoom, loadRoomsFinished, loadMessagesFinished } = useChat()
	const { idChatRoom_FromUrl, idChatRoom_Set } = useRouting()
	const sharedChatRoomCreated = useSelector((state: IState) => state.Chat.Start.sharedChatRoomCreated)

	// useEffect(() => {
	// 	return () => {
	// 		//Si pongo en inicio esto, cuando me voy de un lugar donde esté el chat, como por ej el QR, a la página de chat, me hace el inicialState y con eso me hace una petición, y luego me hace la petición normal
	// 		// ug.D(bllChat_All.setInitialState())
	// 		// ug.D(bllChat_Start.setInitialState())
	// 	}
	// }, [])

	useEffect(() => {
		if (ug.userIsLogged === false) {
			return
		}

		if (idChatRoom_FromUrl) {
			const sharedChatRoomFound: sharedChatRoom.IShared | undefined = sharedChatRooms.find(
				(x) => x.id === idChatRoom_FromUrl
			)

			if (!sharedChatRoomFound) {
				alert("Sala de chat no encontrada")
				const pageHome = globalPathsApp.pageInsideUrl.Home()
				location.href = pageHome
				return
			}

			return
		} else {
			// if (chatType.id === sharedChatType.sharedEnum.chatWithPerson.id) {
			// 	if (!idPerson_FromUrl) {
			// 		return
			// 	}
			// 	if (currentPersona.id === idPerson_FromUrl) {
			// 		return
			// 	}
			// 	const room1 = sharedChatRooms.find(
			// 		(x) =>
			// 			x.chatType === sharedChatType.sharedEnum.chatWithPerson &&
			// 			x.idPerson1 === idPerson_FromUrl &&
			// 			x.idPerson2 === currentPersona.id
			// 	)
			// 	const room2 = sharedChatRooms.find(
			// 		(x) =>
			// 			x.chatType === sharedChatType.sharedEnum.chatWithPerson &&
			// 			x.idPerson2 === idPerson_FromUrl &&
			// 			x.idPerson1 === currentPersona.id
			// 	)
			// 	if (room1) {
			// 		ug.D(bllChat_Start.setSharedChatRoom(room1))
			// 		return
			// 	}
			// 	if (room2) {
			// 		ug.D(bllChat_Start.setSharedChatRoom(room2))
			// 		return
			// 	}
			// 	ug.D(
			// 		bllChat_Start.startChat_For2Persons(ug.apiCallParams, {
			// 			idPerson1: currentPersona.id,
			// 			idPerson2: idPerson_FromUrl,
			// 		})
			// 	)
			// 	return
			// }
			// if (chatType.id === sharedChatType.sharedEnum.chatWithQr.id) {
			// 	if (!idQr_FromUrl) {
			// 		return
			// 	}
			// 	if (isUserQrOwner === undefined || isUserQrOwner === true) {
			// 		return
			// 	}
			// 	const room1 = sharedChatRooms.find(
			// 		(x) =>
			// 			x.chatType === sharedChatType.sharedEnum.chatWithQr &&
			// 			x.idQr === idQr_FromUrl &&
			// 			x.idPerson1 === currentPersona.id
			// 	)
			// 	const room2 = sharedChatRooms.find(
			// 		(x) =>
			// 			x.chatType === sharedChatType.sharedEnum.chatWithQr &&
			// 			x.idQr === idQr_FromUrl &&
			// 			x.idPerson2 === currentPersona.id
			// 	)
			// 	if (room1) {
			// 		ug.D(bllChat_Start.setSharedChatRoom(room1))
			// 		return
			// 	}
			// 	if (room2) {
			// 		ug.D(bllChat_Start.setSharedChatRoom(room2))
			// 		return
			// 	}
			// 	ug.D(
			// 		bllChat_Start.startChat_ForPersonAndQr(ug.apiCallParams, {
			// 			idPerson1: currentPersona.id,
			// 			idQr: idQr_FromUrl,
			// 		})
			// 	)
			// 	return
			// }
		}
	}, [
		chatType,
		loadRoomsFinished,
		loadMessagesFinished,
		// idPerson_FromUrl,
		idChatRoom_FromUrl,
		sharedChatRooms,
		currentRoom_sharedChatRoom,
		isUserEntityOwner,
	])

	// useEffect(() => {
	// 	if (sharedChatRoomCreated && sharedChatRoomCreated.id !== idChatRoom_FromUrl) {
	// 		if (sharedChatRoomCreated.idPerson1 !== sharedChatRoomCreated.idPerson2) {
	// 			idChatRoom_Set(sharedChatRoomCreated.id)
	// 		}
	// 	}
	// }, [sharedChatRoomCreated])

	useEffect(() => {
		if (currentRoom_sharedChatRoom) {
			if (
				(currentRoom_sharedChatRoom.idPerson1 === currentPersona.id &&
					currentRoom_sharedChatRoom.readByPerson1 !== true) ||
				(currentRoom_sharedChatRoom.idPerson2 === currentPersona.id &&
					currentRoom_sharedChatRoom.readByPerson2 !== true)
			) {
				ug.D(
					bllChat_All.setChatRoomAsRead(ug.apiCallParams, {
						idPerson: currentPersona.id,
						idRoom: currentRoom_sharedChatRoom.id,
					})
				)
			}
		}
	}, [currentRoom_sharedChatRoom])

	// var chatHeight = "72vh"
	// switch (chatType) {
	// 	case sharedChatType.sharedEnum.chatWithPerson:
	// 		chatHeight = "72vh"
	// 		break
	// 	case sharedChatType.sharedEnum.chatWithQr:
	// 		chatHeight = "65vh"
	// 		break
	// 	default:
	// 		chatHeight = "72vh"
	// 		break
	// }

	return (
		<Container disableGutters>
			{ug.serverState.isLoading && <SharedLoadingComponent />}

			<SharedBasicTypo sx={{ fontWeight: 600, fontSize: 16, mb: 1, textAlign: "center" }}>{"Mensajes"}</SharedBasicTypo>

			<Card sx={{ height: "78vh", display: "flex" }}>
				{!hideRooms && <Chat_Rooms chatType={chatType} hideRoomType={hideRoomType} />}

				<Stack flexGrow={1}>
					<Chat_Room hideRoomType={hideRoomType} />
				</Stack>
			</Card>

			{/*Box con un sharedbasictypo que diga acá recibirás mensajes*/}
			<Box sx={{ textAlign: "center", pt: 2 }}>
				<SharedBasicTypo variant="body2" sx={{ color: "text.secondary", fontStyle: "italic" }}>
					{"Aquí recibirás los mensajes que te envíen"}
				</SharedBasicTypo>
				<SharedBasicTypo variant="body2" sx={{ color: "text.secondary", fontStyle: "italic" }}>
					{
						"Toma tus precauciones. El sitio no se hace responsable por los mensajes enviados a través de esta plataforma."
					}
				</SharedBasicTypo>
			</Box>
		</Container>
	)
}
