/* eslint-disable react/jsx-pascal-case*/
import { TextField, TextFieldProps } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import useServerStateField from "../../../hooks/useServerStateField"

interface IProps {
	callerKey: string
	fieldName: string
	label: string
	value: string | null
	triggerOnEveryKeyPress?: boolean
	handleOnChangeProp(newValue: string | null): void
}

export default function SharedFormTextFieldString({
	callerKey,
	fieldName,
	label,
	value,
	triggerOnEveryKeyPress = false,
	handleOnChangeProp,
	...other
}: IProps & TextFieldProps) {
	const serverStateField = useServerStateField(callerKey, fieldName)

	const [textboxValue, set_textboxValue] = useState("")

	useEffect(() => {
		var tempTextboxValue = ""
		if (value) {
			tempTextboxValue = value || ""
		}

		set_textboxValue(tempTextboxValue)
	}, [value])

	const handleOnBlur = () => {
		triggerValueChanged()
	}

	const handleOnKeyPress = (e: any) => {
		if (e.key === "Enter") {
			triggerValueChanged()
		}
	}

	const triggerValueChanged = () => {
		const newValue = textboxValue.trim()
		if (newValue === "") {
			handleOnChangeProp(null)
		} else {
			handleOnChangeProp(newValue)
		}
	}

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const tempTextboxValue = e.target.value
		set_textboxValue(tempTextboxValue)

		if (triggerOnEveryKeyPress) {
			handleOnChangeProp(tempTextboxValue)
		}
	}

	return (
		<TextField
			disabled={serverStateField.isLoading}
			label={label}
			fullWidth
			error={!!serverStateField.error}
			helperText={serverStateField.error}
			value={textboxValue}
			onBlur={() => handleOnBlur()}
			onKeyPress={(e) => handleOnKeyPress(e)}
			onChange={(e) => handleOnChange(e)}
			{...other}
		/>
	)
}
