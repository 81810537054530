export namespace sharedLoginRes {
	export interface IShared {
		token: string
		refreshToken: string
		email: string
		roles: string[]
	}

	export const objectDefault: IShared = {
		token: "",
		refreshToken: "",
		email: "",
		roles: [],
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}
}
