/* eslint-disable react/jsx-pascal-case*/
import EscuelasLanding from "../../Escuelas/Landing/EscuelasLanding"
import { Layout } from "../../Layout/Layout"
import SaludonnetLanding from "../../Saludonnet/Landing/SaludonnetLanding"
import SaludonnetSeguro from "../../Saludonnet/Seguro/SaludonnetSeguro"
import SaludonnetSeguroTarjetaSeguro from "../../Saludonnet/Seguro/SaludonnetSeguroTarjetaSeguro"
import SaludonnetSeguroTarjetaPiloto from "../../Saludonnet/Seguro/SaludonnetSeguroTarjetaPiloto"
import { SharedBasicPage } from "../../Shared/Basic/SharedBasicPage"

export default function PageSaludonnetTarjetaPiloto() {
	return (
		<Layout>
			<SharedBasicPage title="Tarjeta Piloto" dniMustBeVerified={true}>
				<SaludonnetSeguroTarjetaPiloto />
			</SharedBasicPage>
		</Layout>
	)
}
