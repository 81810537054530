import { createContext } from "react"

type ContextChatType = {
	isChatConnected: boolean
	sendMessage: (message: string) => void
	sendMultipleMessagesFromEntity: (idEntity: number, idPerson: number[], idChatType: number, message: string) => void
}

export const ContextChat = createContext<ContextChatType>({
	isChatConnected: false,
	sendMessage: () => {},
	sendMultipleMessagesFromEntity: () => {},
})
