/* eslint-disable react/jsx-pascal-case*/
import { DatePicker, DatePickerProps } from "@mui/lab"
import { TextField } from "@mui/material"
import { useState } from "react"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { utilLogger } from "../../../utils/utilLogger"
import useServerStateField from "../../../hooks/useServerStateField"

interface IProps extends Omit<DatePickerProps, "renderInput" | "value" | "onChange"> {
	callerKey: string
	fieldName: string
	label: string
	value: Date | null
	handleOnChangeProp(newValue: Date | null): void
}

export default function SharedFormDatepicker({
	callerKey,
	fieldName,
	label,
	value,
	handleOnChangeProp,
	...other
}: IProps) {
	const serverStateField = useServerStateField(callerKey, fieldName)

	const [formatError, set_formatError] = useState("")

	const handleOnChange = (date: any) => {
		if (!date) {
			handleOnChangeProp(null)
			return
		}

		const newValue = new Date(date)
		handleOnChangeProp(newValue)
	}

	const onError = (val: any) => {
		console.log(val)
		switch (val) {
			case "invalidDate":
				set_formatError("La fecha ingresada es inválida")
				break
			default:
				set_formatError("")
				break
		}
	}

	return (
		<DatePicker
			value={value}
			onError={onError}
			label={label}
			onChange={handleOnChange}
			inputFormat="dd/MM/yyyy"
			renderInput={(params) => (
				<TextField
					disabled={serverStateField.isLoading}
					helperText={serverStateField.error || formatError}
					fullWidth
					{...params}
					error={!!serverStateField.error || formatError !== ""}
				/>
			)}
			{...other}
		/>
	)
}
