import numeral from "numeral"

export namespace utilNumbers {
	numeral.register("locale", "es", {
		delimiters: {
			thousands: ".",
			decimal: ",",
		},
		abbreviations: {
			thousand: "k",
			million: "m",
			billion: "b",
			trillion: "t",
		},
		ordinal: function (number) {
			return number === 2 ? "do" : "er"
		},
		currency: {
			symbol: "€",
		},
	})
	numeral.locale("es")

	// ----------------------------------------------------------------------

	export function fCurrency(number: string | number) {
		return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00")
	}

	export function fPercent(number: number) {
		return numeral(number / 100).format("0.0%")
	}

	export function fNumberFormatted(number: string | number) {
		return numeral(number).format()
	}

	export function fGetNumber(number: string | number) {
		return numeral(number).value()
	}

	export function fShortenNumber(number: string | number) {
		return numeral(number).format("0.00a").replace(".00", "")
	}

	export function fData(number: string | number) {
		return numeral(number).format("0.0 b")
	}
}
