import { combineReducers } from "redux"
import { bllFederaciones_Profile } from "./bllFederaciones_Profile"
import { bllFederaciones_Create } from "./bllFederaciones_Create"
import { bllFederaciones_Settings } from "./bllFederaciones_Settings"

export default combineReducers({
	Profile: bllFederaciones_Profile.reducerFunction,
	Settings: bllFederaciones_Settings.reducerFunction,
	CreateFederacion: bllFederaciones_Create.reducerFunction,
})

export interface IFederaciones {
	Profile: bllFederaciones_Profile.IInterface
	Settings: bllFederaciones_Settings.IInterface
	CreateFederacion: bllFederaciones_Create.IInterface
}
