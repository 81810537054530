import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Organizadores_GetOrganizadoresDePersona } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_GetOrganizadoresDePersona"
import { GetOrganizadores_DeOrganizadoresSeguidores_ByIdPersona } from "../../api/calls/IG/OrganizadoresSeguidores/GetOrganizadores_DeOrganizadoresSeguidores_ByIdPersona"
import { sharedOrganizador } from "../../api/shared/sharedOrganizador"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos {
	//ET = enumTypes
	enum ET {
		setInitialState = "bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos_setInitialState",
		load = "bllGlobalsUser_CurrentPersonaOrganizadoresSeguidos_load",
	}

	export interface IInterface {
		organizadoresSeguidos: sharedOrganizador.IShared[]
		loaded: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			organizadoresSeguidos: [],
			loaded: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Current_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadOrganizadoresSeguidosDePersona(
		apiCallParams: apiCallInterface,
		params: GetOrganizadores_DeOrganizadoresSeguidores_ByIdPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await GetOrganizadores_DeOrganizadoresSeguidores_ByIdPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(setPersonaOrganizadoresSeguidos(apiCallRes.sharedOrganizadoresSeguidos))
			}
		}
	}

	export function setPersonaOrganizadoresSeguidos(organizadoresSeguidos: sharedOrganizador.IShared[]) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "organizadoresSeguidos" | "loaded">
			const updateObject: actionSubType = {
				organizadoresSeguidos: organizadoresSeguidos,
				loaded: true,
			}

			utilRedux.TA(D, ET.load, updateObject)
		}
	}
}
