import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { sharedCircuitoEstado } from "./sharedCircuitoEstado"
import { sharedCircuitoPista } from "./sharedCircuitoPista"
import { sharedDireccion } from "./sharedDireccion"

export namespace sharedPersonasModalidades {
	export interface IShared {
		id: number
		idModalidad: number
		idNivelModalidad: number
		compite: boolean
		federado: boolean
	}

	export const defaultObject: IShared = {
		id: 0,
		idModalidad: 0,
		idNivelModalidad: 0,
		compite: false,
		federado: false,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
		}

		return res
	}
}
