/* eslint-disable react/jsx-pascal-case*/
import { ReactNode } from "react"
import isString from "lodash/isString"
import { Box, Typography, Link } from "@mui/material"
import SharedBreadcrumbs, { Props as SharedBreadcrumbsProps } from "./SharedBreadcrumbs"

// ----------------------------------------------------------------------

interface Props extends SharedBreadcrumbsProps {
	action?: ReactNode
	heading: string
}

export default function SharedHeaderBreadcrumbs({ links, action, heading, sx, ...other }: Props) {
	return (
		<Box sx={{ mb: 2, ...sx }}>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Box sx={{ flexGrow: 1 }}>
					<Typography variant="h4">{heading}</Typography>
					<SharedBreadcrumbs links={links} {...other} />
				</Box>

				{action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
			</Box>
		</Box>
	)
}
