import { utilDate } from "../../utils/utilDate"

export namespace sharedSeguroAccidentesCircuito {
	export interface IShared {
		id: number
		tipo: string
		idAseguradora: number | null
		nombreAseguradora: string
		tipoProductoEmision: number
		estado: number
		fechaEfecto: Date
		fechaFin: Date
		aseguradoDocumento: string
		nombrePiloto: string
		tutorDocumento: string
		tutor: string
		domicilioCompleto: string
		fechaRealizacion: Date | null
		numeroPoliza: string
		numeroPolizaLimpio: string
		asistenciaSanitaria: number | null
		invalidezPermanente: number | null
		fallecimiento: number | null
		duracion: string
		nombreModalidad: string
		idModalidad: number | null
		modalidadActiva: boolean | null
		idModalidadEquivalenteActual: number | null
		idsVehiculos: string
		idsUsos: string
		idsRestricciones: string
		vehiculosCubiertos: any[]
		usosPermitidos: any[]
		restricciones: any[]
	}

	export const objectDefault: IShared = {
		id: 0,
		tipo: "",
		idAseguradora: null,
		nombreAseguradora: "",
		tipoProductoEmision: 0,
		estado: 0,
		fechaEfecto: new Date(),
		fechaFin: new Date(),
		aseguradoDocumento: "",
		nombrePiloto: "",
		tutorDocumento: "",
		tutor: "",
		domicilioCompleto: "",
		fechaRealizacion: null,
		numeroPoliza: "",
		numeroPolizaLimpio: "",
		asistenciaSanitaria: null,
		invalidezPermanente: null,
		fallecimiento: null,
		duracion: "",
		nombreModalidad: "",
		idModalidad: null,
		modalidadActiva: null,
		idModalidadEquivalenteActual: null,
		idsVehiculos: "",
		idsUsos: "",
		idsRestricciones: "",
		vehiculosCubiertos: [],
		usosPermitidos: [],
		restricciones: [],
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
			fechaEfecto: utilDate.fDateFromNet(apiObject.fechaEfecto),
			fechaFin: utilDate.fDateFromNet(apiObject.fechaFin),
			fechaRealizacion: utilDate.fDateFromNet(apiObject.fechaRealizacion),
			vehiculosCubiertos: apiObject.vehiculosCubiertos || [],
			usosPermitidos: apiObject.usosPermitidos || [],
			restricciones: apiObject.restricciones || [],
		}

		return res
	}
}
