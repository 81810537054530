import { Container, useTheme } from "@mui/material"
import { styled } from "@mui/material/styles"
import { m } from "framer-motion"
import { varFade } from "../../../globals/globalAnimate/variants/fade"
import { globalAssets } from "../../../globals/globalAssets"
import useGlobalsUser from "../../../hooksData/useGlobalsUser"
import AnimateMotionContainer from "../../Animate/AnimateMotionContainer"
import AnimateText from "../../Animate/AnimateText"
import SharedBasicTypo from "../../Shared/Basic/SharedBasicTypo"

const StyledRoot = styled("div")(({ theme }) => ({
	position: "relative",
	backgroundSize: "cover",
	backgroundPosition: "center",
	borderRadius: "15px",
	backgroundImage: `url(${globalAssets.staticPaths.minimalOverlay}), url(${globalAssets.staticPaths.saludonnetHero})`,
	padding: theme.spacing(10, 0),
	[theme.breakpoints.up("md")]: {
		height: 560,
		padding: 0,
	},
}))

const StyledContent = styled("div")(({ theme }) => ({
	textAlign: "center",
	[theme.breakpoints.up("md")]: {
		bottom: 80,
		textAlign: "left",
		position: "absolute",
	},
}))

// ----------------------------------------------------------------------

export default function SaludonnetLanding_Hero() {
	const theme = useTheme()
	const { userIsLogged } = useGlobalsUser()

	return (
		<StyledRoot>
			<Container component={AnimateMotionContainer}>
				<StyledContent>
					<AnimateText
						text={"Seguros"}
						sx={{
							color: theme.palette.primary.main,
						}}
						variants={varFade().inRight}
					/>

					<m.div variants={varFade().inRight}>
						<SharedBasicTypo
							variant="h4"
							sx={{
								color: theme.palette.common.white,
								fontWeight: "fontWeightMedium",
								pl: 1,
							}}
						>
							Conoce todo sobre tus seguros
						</SharedBasicTypo>
					</m.div>
				</StyledContent>
			</Container>
		</StyledRoot>
	)
}
