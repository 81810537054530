import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { sharedRodada } from "../../../shared/sharedRodada"
import { sharedRodadaPersona } from "../../../shared/sharedRodadaPersona"
import { apiCallInterface } from "../../../apiCallInterface"
import { sharedPersona } from "../../../shared/sharedPersona"

export namespace apiCallIG_PersonasSeguidores_SeguirPersona {
	export interface params {
		idPersonaSeguidor: number
		idPersona: number
	}
	export const paramsName = {
		idPersonaSeguidor: "idPersonaSeguidor",
		idPersona: "idPersona",
	}
	export const paramsDesc = {}

	export interface IRes {
		sharedPersonasSeguidas: sharedPersona.IShared[]
		sharedPersona: sharedPersona.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/PersonasSeguidores/SeguirPersona`
		const sharedParamsQuery: params | null = null
		const sharedParamsBody = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedPersonasSeguidas: sharedPersona.getSharedList_FromApiObject(apiCallRes.personasSeguidas),
			sharedPersona: sharedPersona.getSharedOne_FromApiObject(apiCallRes.persona),
		}
	}
}
