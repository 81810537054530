import { combineReducers } from "redux"
import { bllSaludonnet_CentrosUrgencias } from "./bllSaludonnet_CentrosUrgencias"

export default combineReducers({
	CentrosUrgencias: bllSaludonnet_CentrosUrgencias.reducerFunction,
})

export interface ISaludonnet {
	CentrosUrgencias: bllSaludonnet_CentrosUrgencias.IInterface
}
