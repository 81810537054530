/* eslint-disable react/jsx-pascal-case*/
import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import { alpha, styled } from "@mui/material/styles"
import { useDispatch, useSelector } from "react-redux"
import { sharedPersona } from "../../../api/shared/sharedPersona"
import { IState } from "../../../bll/bllReducers"
import { bllGlobalsUser_CurrentPersona } from "../../../bll/GlobalsUser/bllGlobalsUser_CurrentPersona"
import useServerState from "../../../hooks/useServerState"
import useGeneral from "../../../hooksData/useGeneral"
import AnimateIconButton from "../../Animate/AnimateIconButton"
import { SharedBasicAvatarImg } from "../../Shared/Basic/SharedBasicAvatarImg"
import SharedBasicIconify from "../../Shared/Basic/SharedBasicIconify"

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
	width: 40,
	height: 40,
	display: "flex",
	borderRadius: "50%",
	alignItems: "center",
	justifyContent: "center",
	color: theme.palette.primary.main,
	backgroundColor: alpha(theme.palette.primary.main, 0.08),
}))

// ----------------------------------------------------------------------

interface IProps {
	persona: sharedPersona.IShared
	isCurrentPersona: boolean
}

export default function PersonasDelegadasDialog_List_Item({ persona, isCurrentPersona }: IProps) {
	const ug = useGeneral(`PersonasDelegadasDialog_List_Item_${persona.id}`)

	const personasUsuarios = useSelector((state: IState) => state.GlobalsUser.Personas.personasUsuarios)

	const selectPersona = () => {
		ug.D(bllGlobalsUser_CurrentPersona.change(ug.apiCallParams, persona))
	}

	const rol =
		personasUsuarios.find((item) => {
			return item.idPersona === persona.id
		})?.rol || ""

	return (
		<Stack direction="row" alignItems="center" spacing={2}>
			<SharedBasicAvatarImg src={persona.avatarURL} alt={`${persona.nombre} ${persona.apellido1}`} />
			<Box sx={{ flexGrow: 1 }}>
				<Typography variant="subtitle2">{`${persona.nombre} ${persona.apellido1}`}</Typography>

				{rol && (
					<Typography variant="body2" sx={{ color: "text.secondary" }}>
						{`Mi rol: ${rol}`}
					</Typography>
				)}
			</Box>

			{ug.serverState.isLoading ? (
				<CircularProgress />
			) : (
				<AnimateIconButton color="primary" onClick={selectPersona}>
					<SharedBasicIconify iconifyIcon={isCurrentPersona ? "bx:radio-circle-marked" : "bx:radio-circle"} />
				</AnimateIconButton>
			)}
		</Stack>
	)
}
