/* eslint-disable react/jsx-pascal-case*/
import { LogLevel } from "@microsoft/signalr"
import { Stack } from "@mui/material"
import { useContext } from "react"
import { globalConfig } from "../../globals/globalConfig"
import useGeneral from "../../hooksData/useGeneral"
import { ContextChat } from "../Contexts/ContextChat"
import SharedLoadingComponent from "../Shared/Loading/SharedLoadingComponent"
import Chat_Room_Header from "./Chat_Room_Header"
import Chat_Room_Input from "./Chat_Room_Input"
import Chat_Room_MessagesList from "./Chat_Room_MessagesList"

const connectionLoggingLevel = globalConfig.environment !== "production" ? LogLevel.Information : LogLevel.None

export interface IProps {
	hideRoomType?: boolean
}

export default function Chat_Room({ hideRoomType }: IProps) {
	const ug = useGeneral("Chat_Room")

	const { isChatConnected, sendMessage } = useContext(ContextChat)

	const handleSendMessage = async (message: string) => {
		sendMessage(message)
	}

	return (
		<>
			{ug.serverState.isLoading && <SharedLoadingComponent />}

			<Chat_Room_Header connected={isChatConnected} hideRoomType={hideRoomType} />

			<Stack
				direction="row"
				flexGrow={1}
				sx={{
					overflow: "hidden",
					borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
				}}
			>
				<Stack flexGrow={1}>
					<Chat_Room_MessagesList />

					<Chat_Room_Input onSend={handleSendMessage} />
				</Stack>
			</Stack>
		</>
	)
}
