/* eslint-disable react/jsx-pascal-case*/
import { LoadingButton } from "@mui/lab"
import { Alert, Box, Card, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { apiValidations2 } from "../../../api/apiValidations2"
import { apiCallIG_Personas_ActualizarGeneralFields } from "../../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarGeneralFields"
import { sharedTipoSexo } from "../../../api/shared/sharedTipoSexo"
import { bllDispatchs_GlobalsServer } from "../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { bllPersonas_Settings } from "../../../bll/Personas/bllPersonas_Settings"
import useGeneral from "../../../hooksData/useGeneral"
import usePersonasSettings from "../../../hooksData/usePersonasSettings"
import { utilLogger } from "../../../utils/utilLogger"
import SharedFormDatepicker from "../../Shared/Form/SharedFormDatepicker"
import SharedFormRadio from "../../Shared/Form/SharedFormRadio"
import SharedFormTextFieldString from "../../Shared/Form/SharedFormTextFieldString"
import useSeguros from "../../../hooksData/useSeguros"
import { apiCallMP_Seguros_SaveProtocoloAccidenteConCampos } from "../../../api/calls/MP/Seguros/apiCallMP_Seguros_SaveProtocoloAccidenteConCampos"
import useGlobalsUser from "../../../hooksData/useGlobalsUser"
import { bllSeguros_SaveProtocoloAccidenteConCampos } from "../../../bll/Seguros/bllSeguros_SaveProtocoloAccidenteConCampos"
import { sharedSegurosTipoDoc } from "../../../api/shared/sharedSegurosTipoDoc"
import SharedFormDropdown from "../../Shared/Form/SharedFormDropdown"
import { useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { sharedCircuito } from "../../../api/shared/sharedCircuito"
import SharedFormAutocomplete from "../../Shared/Form/SharedFormAutocomplete"
import SharedBasicHtml from "../../Shared/Basic/SharedBasicHtml"
import SharedFormSignature from "../../Shared/Form/SharedFormSignature"
import { utilAws } from "../../../utils/utilAws"
import { globalAssets } from "../../../globals/globalAssets"
import { v4 as uuidv4 } from "uuid"

export default function SaludonnetProtocoloAccidente_Form() {
	const ug = useGeneral("SaludonnetProtocoloAccidente_Form")
	const { protocoloAccidenteUrlPdfSaved, seguro } = useSeguros()
	const { currentPersona } = useGlobalsUser()

	const circuitos = useSelector((state: IState) => state.GlobalsGeneralData.AllCircuitos.circuitos)

	const [searchParams, setSearchParams] = useSearchParams({})
	const [firmaAseguradoOTutorData, set_firmaAseguradoOTutorData] = useState<string | null>(null)
	const [firmaResponsableCircuitoData, set_firmaResponsableCircuitoData] = useState<string | null>(null)
	// const [idTipoDoc, set_idTipoDoc] = useState<number | null>(1)

	/*
		idPersona: number
		tipoDocumento: string
		nroDocumento: string
		apellidosNombre: string
		domicilio: string
		poblacionProvincia: string
		telefonoContacto: string
		email: string
		circuitoLugarOcurrencia: string
		detalleClaramenteComoOcurrioElSiniestro: string
		fechaAccidente: Date
		nombresTestigos: string
		descripcionLesiones: string
		*/

	const [nroDocumento, set_nroDocumento] = useState<string | null>(null)
	const [telefonoContacto, set_telefonoContacto] = useState<string | null>(null)
	const [email, set_email] = useState<string | null>(null)
	const [circuitoLugarOcurrencia, set_circuitoLugarOcurrencia] = useState<string | null>(null)
	const [idCircuito, set_idCircuito] = useState<number | null>(null)
	const [detalleClaramenteComoOcurrioElSiniestro, set_detalleClaramenteComoOcurrioElSiniestro] = useState<
		string | null
	>(null)
	const [fechaAccidente, set_fechaAccidente] = useState<Date | null>(null)
	const [nombresTestigos, set_nombresTestigos] = useState<string | null>(null)
	const [descripcionLesiones, set_descripcionLesiones] = useState<string | null>(null)

	// const [apellidosNombre, set_apellidosNombre] = useState<string | null>(null)
	// const [domicilio, set_domicilio] = useState<string | null>(null)
	// const [poblacionProvincia, set_poblacionProvincia] = useState<string | null>(null)

	useEffect(() => {
		if (seguro) {
			set_nroDocumento(seguro.aseguradoDocumento)
		}
	}, [seguro])

	const validations = async () => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//nroDocumento
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.nroDocumento,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.nroDocumento,
			nroDocumento,
			serverModelErrors
		)

		//telefonoContacto
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.telefonoContacto,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.telefonoContacto,
			telefonoContacto,
			serverModelErrors
		)

		//email
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.email,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.email,
			email,
			serverModelErrors
		)

		apiValidations2.validateMustBeEmail(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.email,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.email,
			email,
			serverModelErrors
		)

		//circuitoLugarOcurrencia
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.circuitoLugarOcurrencia,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.circuitoLugarOcurrencia,
			circuitoLugarOcurrencia,
			serverModelErrors
		)

		//detalleClaramenteComoOcurrioElSiniestro
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.detalleClaramenteComoOcurrioElSiniestro,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.detalleClaramenteComoOcurrioElSiniestro,
			detalleClaramenteComoOcurrioElSiniestro,
			serverModelErrors
		)

		//fechaAccidente
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.fechaAccidente,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.fechaAccidente,
			fechaAccidente,
			serverModelErrors
		)

		//nombresTestigos
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.nombresTestigos,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.nombresTestigos,
			nombresTestigos,
			serverModelErrors
		)

		//descripcionLesiones
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.descripcionLesiones,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.descripcionLesiones,
			descripcionLesiones,
			serverModelErrors
		)

		// //apellidosNombre
		// apiValidations2.validateRequired(
		// 	apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.apellidosNombre,
		// 	apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.apellidosNombre,
		// 	apellidosNombre,
		// 	serverModelErrors
		// )

		// //domicilio
		// apiValidations2.validateRequired(
		// 	apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.domicilio,
		// 	apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.domicilio,
		// 	domicilio,
		// 	serverModelErrors
		// )

		// //poblacionProvincia
		// apiValidations2.validateRequired(
		// 	apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.poblacionProvincia,
		// 	apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.poblacionProvincia,
		// 	poblacionProvincia,
		// 	serverModelErrors
		// )

		//firmaAseguradoOTutorUrl
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.firmaAseguradoOTutorUrl,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.firmaAseguradoOTutorUrl,
			firmaAseguradoOTutorData,
			serverModelErrors
		)

		//firmaResponsableCircuitoUrl
		apiValidations2.validateRequired(
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.firmaResponsableCircuitoUrl,
			apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.firmaResponsableCircuitoUrl,
			firmaResponsableCircuitoData,
			serverModelErrors
		)

		return await bllDispatchs_GlobalsServer.setModelErrors(ug.key, serverModelErrors)
	}

	const currentIdSeguro = Number(searchParams.get("idSeguro")) || 0
	const currentIdTipoSeguro = Number(searchParams.get("tipo")) || 0

	const onSubmit = async (e: any) => {
		e.preventDefault()

		if (await validations()) return

		// const tipoDocumento = sharedSegurosTipoDoc.getShared_Desc_FromId(idTipoDoc)

		if (
			!nroDocumento ||
			!telefonoContacto ||
			!email ||
			!circuitoLugarOcurrencia ||
			!detalleClaramenteComoOcurrioElSiniestro ||
			!fechaAccidente ||
			!nombresTestigos ||
			!descripcionLesiones ||
			!firmaAseguradoOTutorData ||
			!firmaResponsableCircuitoData
			// !tipoDocumento ||
			// !apellidosNombre ||
			// !domicilio ||
			// !poblacionProvincia ||
		) {
			utilLogger.error("No debería haber llegado hasta acá un parámetro requerido como NULL.", {
				nroDocumento,
				telefonoContacto,
				email,
				circuitoLugarOcurrencia,
				detalleClaramenteComoOcurrioElSiniestro,
				fechaAccidente,
				nombresTestigos,
				descripcionLesiones,
				firmaAseguradoOTutorUrl: firmaAseguradoOTutorData,
				firmaResponsableCircuitoUrl: firmaResponsableCircuitoData,
				// tipoDocumento,
				// apellidosNombre,
				// domicilio,
				// poblacionProvincia,
			})
			return
		}

		const firmaAseguradoOTutorUrl = await utilAws.uploadFile_WithData(
			ug.key,
			firmaAseguradoOTutorData,
			globalAssets.awsFolders.rodadaPersonasDisclaimer,
			uuidv4(),
			"jpg"
		)

		const firmaResponsableCircuitoUrl = await utilAws.uploadFile_WithData(
			ug.key,
			firmaResponsableCircuitoData,
			globalAssets.awsFolders.rodadaPersonasDisclaimer,
			uuidv4(),
			"jpg"
		)

		await ug.D(
			bllSeguros_SaveProtocoloAccidenteConCampos.save(ug.apiCallParams, {
				idPersona: currentPersona.id,
				firmaResponsableCircuitoUrl,
				firmaAseguradoOTutorUrl,
				nroDocumento,
				telefonoContacto,
				email,
				circuitoLugarOcurrencia,
				idCircuito,
				detalleClaramenteComoOcurrioElSiniestro,
				fechaAccidente,
				nombresTestigos,
				descripcionLesiones,
				idSeguro: currentIdSeguro,
				idTipoSeguro: currentIdTipoSeguro,
				// tipoDocumento,
				// apellidosNombre,
				// domicilio,
				// poblacionProvincia,
			})
		)
	}

	if (protocoloAccidenteUrlPdfSaved) {
		return (
			<Card sx={{ p: 3 }}>
				<Box
					sx={{
						display: "grid",
						rowGap: 3,
						columnGap: 2,
					}}
				>
					<Stack spacing={3}>
						{ug.serverState.errorMessage !== "" && <Alert severity="error">{ug.serverState.errorMessage}</Alert>}

						<Stack spacing={2}>
							<Alert severity="success">Protocolo de accidente guardado correctamente.</Alert>
							<Alert severity="info">
								Se ha enviado un email con el protocolo de accidente a su casilla de correo.
							</Alert>
							<Alert severity="info">
								Para descargar el protocolo de accidente, haga click{" "}
								<a href={protocoloAccidenteUrlPdfSaved} target="_blank" rel="noopener noreferrer">
									acá
								</a>
								.
							</Alert>
						</Stack>
					</Stack>
				</Box>
			</Card>
		)
	}

	return (
		<Card sx={{ p: 3 }}>
			<Box
				sx={{
					display: "grid",
					rowGap: 3,
					columnGap: 2,
				}}
			>
				<form onSubmit={(e) => onSubmit(e)}>
					<Stack spacing={3}>
						{ug.serverState.errorMessage !== "" && <Alert severity="error">{ug.serverState.errorMessage}</Alert>}

						<Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
							{/* <div>
								<SharedFormDropdown
									callerKey={ug.key}
									fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.tipoDocumento}
									label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.tipoDocumento}
									value={idTipoDoc}
									options={sharedSegurosTipoDoc.sharedList_ForRadioGroup()}
									handleOnChangeProp={set_idTipoDoc}
									sx={{
										minWidth: "200px",
										"& .MuiFormControlLabel-root": { mr: 4 },
									}}
								/>
							</div> */}

							<SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.nroDocumento}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.nroDocumento}
								value={nroDocumento}
								handleOnChangeProp={set_nroDocumento}
							/>

							<SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.telefonoContacto}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.telefonoContacto}
								value={telefonoContacto}
								handleOnChangeProp={set_telefonoContacto}
							/>
						</Stack>

						{/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
							<SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.apellidosNombre}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.apellidosNombre}
								value={apellidosNombre}
								handleOnChangeProp={set_apellidosNombre}
							/>
							<SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.domicilio}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.domicilio}
								value={domicilio}
								handleOnChangeProp={set_domicilio}
							/>
						</Stack> */}

						{/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
							 <SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.poblacionProvincia}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.poblacionProvincia}
								value={poblacionProvincia}
								handleOnChangeProp={set_poblacionProvincia}
							/> 
							 <SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.telefonoContacto}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.telefonoContacto}
								value={telefonoContacto}
								handleOnChangeProp={set_telefonoContacto}
							/> 
						</Stack> */}

						<Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
							<SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.email}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.email}
								value={email}
								handleOnChangeProp={set_email}
							/>
							<SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.circuitoLugarOcurrencia}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.circuitoLugarOcurrencia}
								value={circuitoLugarOcurrencia}
								handleOnChangeProp={set_circuitoLugarOcurrencia}
							/>
						</Stack>

						<Box>
							<SharedFormAutocomplete
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.idCircuito}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.idCircuito}
								value={idCircuito}
								options={sharedCircuito.listForRadioGroup(circuitos)}
								showNotDefined={false}
								handleOnChangeProp={set_idCircuito}
								sx={{
									"& .MuiFormControlLabel-root": { mr: 4 },
								}}
							/>
						</Box>

						<Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
							<SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={
									apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.detalleClaramenteComoOcurrioElSiniestro
								}
								label={
									apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.detalleClaramenteComoOcurrioElSiniestro
								}
								value={detalleClaramenteComoOcurrioElSiniestro}
								handleOnChangeProp={set_detalleClaramenteComoOcurrioElSiniestro}
								multiline
							/>
							<SharedFormDatepicker
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.fechaAccidente}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.fechaAccidente}
								value={fechaAccidente}
								handleOnChangeProp={set_fechaAccidente}
								disableFuture
								minDate={new Date("1900-01-01")}
							/>
						</Stack>

						<Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
							<SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.nombresTestigos}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.nombresTestigos}
								value={nombresTestigos}
								handleOnChangeProp={set_nombresTestigos}
							/>
							<SharedFormTextFieldString
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.descripcionLesiones}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.descripcionLesiones}
								value={descripcionLesiones}
								handleOnChangeProp={set_descripcionLesiones}
								multiline
							/>
						</Stack>

						<Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
							<SharedFormSignature
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.firmaAseguradoOTutorUrl}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.firmaAseguradoOTutorUrl}
								disabled={false}
								initialImageUrl={null}
								handleOnChangeProp={set_firmaAseguradoOTutorData}
							/>

							<SharedFormSignature
								callerKey={ug.key}
								fieldName={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsName.firmaResponsableCircuitoUrl}
								label={apiCallMP_Seguros_SaveProtocoloAccidenteConCampos.paramsDesc.firmaResponsableCircuitoUrl}
								disabled={false}
								initialImageUrl={null}
								handleOnChangeProp={set_firmaResponsableCircuitoData}
							/>
						</Stack>
					</Stack>

					<Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
						<LoadingButton type="submit" variant="contained" loading={ug.serverState.isLoading}>
							Guardar
						</LoadingButton>
					</Stack>
				</form>
			</Box>
		</Card>
	)
}
