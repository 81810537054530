import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedOrganizador } from "../../../shared/sharedOrganizador"

export namespace apiCallIG_Organizadores_ActualizarSocialInfo {
	export interface params {
		idOrganizador: number
		linkFacebook: string | null
		linkTwitter: string | null
		linkInstagram: string | null
		linkLinkedIn: string | null
	}
	export const paramsName = {
		idOrganizador: "idOrganizador",
		linkFacebook: "linkFacebook",
		linkTwitter: "linkTwitter",
		linkInstagram: "linkInstagram",
		linkLinkedIn: "telefonoMovil",
	}
	export const paramsDesc = {
		idOrganizador: "Id Organizador",
		linkFacebook: "Link de Facebook",
		linkTwitter: "Link de Twitter",
		linkInstagram: "Link de Instagram",
		linkLinkedIn: "Link de LinkedIn",
	}

	export interface IRes {
		sharedOrganizador: sharedOrganizador.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//linkFacebook
		if (params.linkFacebook) {
			apiValidations2.validateMustBeUrl(
				paramsName.linkFacebook,
				paramsDesc.linkFacebook,
				params.linkFacebook,
				serverModelErrors
			)
		}

		//linkTwitter
		if (params.linkTwitter) {
			apiValidations2.validateMustBeUrl(
				paramsName.linkTwitter,
				paramsDesc.linkTwitter,
				params.linkTwitter,
				serverModelErrors
			)
		}

		//linkInstagram
		if (params.linkInstagram) {
			apiValidations2.validateMustBeUrl(
				paramsName.linkInstagram,
				paramsDesc.linkInstagram,
				params.linkInstagram,
				serverModelErrors
			)
		}

		//linkLinkedIn
		if (params.linkLinkedIn) {
			apiValidations2.validateMustBeUrl(
				paramsName.linkLinkedIn,
				paramsDesc.linkLinkedIn,
				params.linkLinkedIn,
				serverModelErrors
			)
		}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Organizadores/ActualizarSocialInfo`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedOrganizador: sharedOrganizador.getSharedOne_FromApiObject(apiCallRes) }
	}
}
