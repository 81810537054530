import { apiCallIG_Registrarse_ChangePassword } from "../../api/calls/IG/Registrarse/apiCallIG_Registrarse_ChangePassword"
import { bllDispatchs_GlobalsServer } from "../bllDispatchs_GlobalsServer"
import { utilRedux } from "../../utils/utilRedux"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Registrarse_DeleteUser } from "../../api/calls/IG/Registrarse/apiCallIG_Registrarse_DeleteUser"

export namespace bllUsers_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Users_Settings_setInitialState",
		changePassword = "Users_Settings_changePassword",
		deleteUser = "Users_Settings_deleteUser",
	}

	export interface IInterface {}

	const getInitialState = (): IInterface => {
		return {}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.User_Registrarse_Registrarse:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function changePassword(apiCallParams: apiCallInterface, params: apiCallIG_Registrarse_ChangePassword.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Registrarse_ChangePassword.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.changePassword, {})
			}
		}
	}

	export function deleteUser(apiCallParams: apiCallInterface, params: apiCallIG_Registrarse_DeleteUser.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Registrarse_DeleteUser.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.deleteUser, {})
			}
		}
	}
}
