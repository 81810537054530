import { number } from "yup"

export namespace sharedRodadaPersonaMapeoExcel {
	export interface IShared {
		id: number
		idPersona: number
		mapeoNombre: string
		mapeoApellido1: string
		mapeoEmail: string
		mapeoPhoneNumber: string
	}

	export const objectDefault: IShared = {
		id: 0,
		idPersona: 0,
		mapeoNombre: "",
		mapeoApellido1: "",
		mapeoEmail: "",
		mapeoPhoneNumber: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}
}
