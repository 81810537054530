import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { sharedCircuitoEstado } from "./sharedCircuitoEstado"
import { sharedDireccion } from "./sharedDireccion"

export namespace sharedCircuitoValoracion {
	export interface IShared {
		id: number
		idCircuito: number
		idPersona: number
		valoracionInstalaciones: number | null
		valoracionTerreno: number | null
		valoracionRiegos: number | null
		valoracionSaltos: number | null
		valoracionSeguridad: number | null
	}

	export const defaultObject: IShared = {
		id: 0,
		idCircuito: 0,
		idPersona: 0,
		valoracionInstalaciones: null,
		valoracionTerreno: null,
		valoracionRiegos: null,
		valoracionSaltos: null,
		valoracionSeguridad: null,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
		}

		return res
	}
}
