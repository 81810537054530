import { utilRedux } from "../../utils/utilRedux"
import { utilWebtonative } from "../../utils/utilWebtonative"

export namespace bllGlobalsUser_General {
	//ET = enumTypes
	enum ET {
		setInitialState = "GlobalsUser_General_setInitialState",
		getClientInfo = "GlobalsUser_General_getClientInfo",
	}

	export interface IInterface {
		latitud: number
		longitud: number
	}

	const getInitialState = (): IInterface => {
		return {
			latitud: 40.4378693, //Latitud Madrid
			longitud: -3.8199624, //Longitud Madrid
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_PersonasDeUsuarioLogueado_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getClientInfo() {
		//D = dispatch
		return async (D: any) => {
			navigator.geolocation.getCurrentPosition(function (position) {
				// type actionSubType = Pick<IInterface, "latitud" | "longitud">
				// const updateObject: actionSubType = {
				// 	latitud: position.coords.latitude,
				// 	longitud: position.coords.longitude,
				// }
				// utilRedux.TA(D, ET.getClientInfo, updateObject)
			})
		}
	}
}
