/* eslint-disable react/jsx-pascal-case*/
import { Box } from "@mui/material"
import { sharedPersonaSeguidor } from "../../../api/shared/sharedPersonaSeguidor"
import PersonasShared_Seguidores_CardList_Card from "./PilotosShared_Personas_CardList_Card"
import { sharedPersona } from "../../../api/shared/sharedPersona"

interface IProps {
	personas: sharedPersona.IShared[]
	emptyListMessage?: string
}

export default function PilotosShared_Personas_CardList({ personas, emptyListMessage }: IProps) {
	return (
		<>
			<Box
				gap={3}
				display="grid"
				sx={{ p: 3 }}
				gridTemplateColumns={{
					xs: "repeat(1, 1fr)",
					sm: "repeat(2, 1fr)",
					md: "repeat(3, 1fr)",
				}}
			>
				{personas.map((persona, i) => (
					<PersonasShared_Seguidores_CardList_Card key={i} persona={persona} />
				))}

				{personas.length === 0 && (
					<Box
						sx={{
							color: "text.secondary",
							fontStyle: "italic",
						}}
					>
						{emptyListMessage ? emptyListMessage : "-Aún no hay elementos en este listado-"}
					</Box>
				)}
			</Box>
		</>
	)
}
