import { utilDate } from "../../utils/utilDate"
import { sharedPosteosComentarios } from "./sharedPosteosComentarios"
import { sharedPosteosEtiquetas } from "./sharedPosteosEtiquetas"
import { sharedPosteosFotos } from "./sharedPosteosFotos"
import { sharedPosteosLikes } from "./sharedPosteosLikes"
import { sharedPosteosUbicacion } from "./sharedPosteosUbicacion"

export namespace sharedPosteos {
	export type typeTipoPosteo = "persona" | "circuito"

	export interface IShared {
		id: number
		idCircuitoCreador: number | null
		nombreCircuitoCreador: string | null
		avatarUrlCircuitoCreador: string | null
		idPersonaCreadora: number | null
		nombrePersonaCreadora: string | null
		avatarUrlPersonaCreadora: string | null
		texto: string | null
		fechaCreacion: Date | null
		fotos: sharedPosteosFotos.IShared[]
		comentarios: sharedPosteosComentarios.IShared[]
		likes: sharedPosteosLikes.IShared[]
		tipoPosteo: typeTipoPosteo
		etiquetas: sharedPosteosEtiquetas.IShared[]
		ubicacion: sharedPosteosUbicacion.IShared
	}

	export const objectDefault: IShared = {
		id: 0,
		idCircuitoCreador: null,
		nombreCircuitoCreador: null,
		avatarUrlCircuitoCreador: null,
		idPersonaCreadora: null,
		nombrePersonaCreadora: null,
		avatarUrlPersonaCreadora: null,
		texto: null,
		fechaCreacion: null,
		fotos: [],
		comentarios: [],
		likes: [],
		tipoPosteo: "persona",
		etiquetas: [],
		ubicacion: sharedPosteosUbicacion.objectDefault,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
			fechaCreacion: utilDate.fDateFromNet(apiObject.fechaCreacion),
			fotos: sharedPosteosFotos.getSharedList_FromApiObject(apiObject.fotos),
			comentarios: sharedPosteosComentarios.getSharedList_FromApiObject(apiObject.comentarios),
			likes: sharedPosteosLikes.getSharedList_FromApiObject(apiObject.likes),
			etiquetas: sharedPosteosEtiquetas.getSharedList_FromApiObject(apiObject.etiquetas),
			ubicacion: sharedPosteosUbicacion.getSharedOne_FromApiObject(apiObject.ubicacion),
		}

		if (res.idCircuitoCreador !== null) res.tipoPosteo = "circuito"
		if (res.idPersonaCreadora !== null) res.tipoPosteo = "persona"

		return res
	}
}
