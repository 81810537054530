import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedCircuito } from "../../../shared/sharedCircuito"

export namespace apiCallIG_Circuitos_ActualizarCharacteristics {
	export interface params {
		idCircuito: number
		nivelPilotaje?: number | null
		longitudEnMetros?: number | null
		tieneBanos?: boolean | null
		tieneBar?: boolean | null
		tieneFotografo?: number | null
		tieneCronos?: boolean | null
		precioAdulto?: number | null
		precioNino?: number | null
		horario?: string | null
		diasParaEntrenar?: string | null

		tieneWifi: boolean | null
		tieneGasolinera: boolean | null
		tieneSalaPrensa: boolean | null
		tieneSalaBriefing: boolean | null
		tieneZona4x4: boolean | null
		tieneHospital: boolean | null
		tieneCircuitoCerradoTV: boolean | null
	}

	export const paramsName = {
		idCircuito: "idCircuito",
		nivelPilotaje: "nivelPilotaje",
		longitudEnMetros: "longitudEnMetros",
		tieneBanos: "tieneBanos",
		tieneBar: "tieneBar",
		tieneFotografo: "tieneFotografo",
		tieneCronos: "tieneCronos",
		precioAdulto: "precioAdulto",
		precioNino: "precioNino",
		horario: "horario",
		diasParaEntrenar: "diasParaEntrenar",

		tieneWifi: "TieneWifi",
		tieneGasolinera: "TieneGasolinera",
		tieneSalaPrensa: "TieneSalaPrensa",
		tieneSalaBriefing: "TieneSalaBriefing",
		tieneZona4x4: "TieneZona4x4",
		tieneHospital: "TieneHospital",
		tieneCircuitoCerradoTV: "TieneCircuitoCerradoTV",
	}

	export const paramsDesc = {
		idCircuito: "Id Circuito",
		nivelPilotaje: "Nivel de pilotaje",
		longitudEnMetros: "Longitud (en metros)",
		tieneBanos: "¿Tiene baños?",
		tieneBar: "¿Tiene bar?",
		tieneFotografo: "¿Tiene Fotógrafo?",
		tieneCronos: "¿Tiene cronos?",
		precioAdulto: "Precio Adulto",
		precioNino: "Precio niño",
		horario: "Horario (ej: de 10am a 3pm)",
		diasParaEntrenar: "Días para entrenar (ej: Lunes, Martes, Miércoles)",

		tieneWifi: "¿Tiene wifi?",
		tieneGasolinera: "¿Tiene gasolinera?",
		tieneSalaPrensa: "¿Tiene sala de prensa?",
		tieneSalaBriefing: "¿Tiene sala de briefing?",
		tieneZona4x4: "¿Tiene zona 4x4?",
		tieneHospital: "¿Tiene hospital?",
		tieneCircuitoCerradoTV: "¿Tiene circuito cerrado de TV?",
	}

	export interface IRes {
		sharedCircuito: sharedCircuito.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//horario
		if (params.horario) {
			apiValidations2.validateMaxNumberOfChars(
				paramsName.horario,
				paramsDesc.horario,
				params.horario,
				250,
				serverModelErrors
			)
		}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Circuitos/ActualizarCharacteristics`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedCircuito: sharedCircuito.getSharedOne_FromApiObject(apiCallRes) }
	}
}
