export namespace utilStorage {
	// /* ---------------------------------- Token --------------------------------- */
	// export const getUserTokenFromStorage = () => {
	// 	return getItem("userToken")
	// }

	// export const setUserTokenInStorage = (token: string) => {
	// 	setItem("userToken", token)
	// }

	/* ---------------------------------- LoginRes ---------------------------------- */
	export const getLoginResFromStorage = () => {
		return getItem("loginRes")
	}

	export const setLoginResInStorage = (loginResJson: string) => {
		setItem("loginRes", loginResJson)
	}

	/* ---------- LAS FUNCIONES QUE GUARDAN U OBTIENEN INFO DEL STORAGE --------- */
	const getItem = (name: string) => {
		return localStorage.getItem(name)
	}

	const setItem = (name: string, value: any) => {
		localStorage.setItem(name, value)
	}

	export const clearStorage = () => {
		localStorage.clear()
	}
}
