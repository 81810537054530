import { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { globalPathsApp } from "../globals/globalPathsApp"

//Este hook tiene que estar sí o sí dentro de <Router>, o sea, en cualquier componente pero siempre que no esté fuera de <Router>
export default function useRouting() {
	const { pathname } = useLocation()
	const [searchParams, setSearchParams] = useSearchParams({})

	const [isOnLandingPage, set_isOnLandingPage] = useState(false)
	const [page, set_page] = useState<globalPathsApp.pagesEnum | undefined>(undefined)

	useEffect(() => {
		set_isOnLandingPage(pathname === "/")
		set_page(globalPathsApp.pageEnum_byPathname(pathname))
	}, [pathname])

	//idCircuito
	var idCircuito_FromUrl: number | undefined
	const t_idCircuito_FromUrl = searchParams.get("idCircuito")
	if (t_idCircuito_FromUrl) {
		idCircuito_FromUrl = Number(t_idCircuito_FromUrl)
	}
	//idCircuitoPista
	var idCircuitoPista_FromUrl: number | undefined
	const t_idCircuitoPista_FromUrl = searchParams.get("idCircuitoPista")
	if (t_idCircuitoPista_FromUrl) {
		idCircuitoPista_FromUrl = Number(t_idCircuitoPista_FromUrl)
	}
	const idCircuitoPista_Set = (value: number | undefined | null) => {
		if (!value) {
			searchParams.delete("idCircuitoPista")
		} else {
			searchParams.set("idCircuitoPista", value.toString())
		}
		setSearchParams(searchParams)
	}
	//idPersonaVehiculo
	var idPersonaVehiculo_FromUrl: number | undefined
	const t_idPersonaVehiculo_FromUrl = searchParams.get("idPersonaVehiculo")
	if (t_idPersonaVehiculo_FromUrl) {
		idPersonaVehiculo_FromUrl = Number(t_idPersonaVehiculo_FromUrl)
	}
	const idPersonaVehiculo_Set = (value: number | undefined | null) => {
		if (!value) {
			searchParams.delete("idPersonaVehiculo")
		} else {
			searchParams.set("idPersonaVehiculo", value.toString())
		}
		setSearchParams(searchParams)
	}

	//idPersonaModalidad
	var idPersonaModalidad_FromUrl: number | undefined
	const t_idPersonaModalidad_FromUrl = searchParams.get("idPersonaModalidad")
	if (t_idPersonaModalidad_FromUrl) {
		idPersonaModalidad_FromUrl = Number(t_idPersonaModalidad_FromUrl)
	}
	const idPersonaModalidad_Set = (value: number | undefined | null) => {
		if (!value) {
			searchParams.delete("idPersonaModalidad")
		} else {
			searchParams.set("idPersonaModalidad", value.toString())
		}
		setSearchParams(searchParams)
	}

	//idEmpresa
	var idEmpresa_FromUrl: number | undefined
	const t_idEmpresa_FromUrl = searchParams.get("idEmpresa")
	if (t_idEmpresa_FromUrl) {
		idEmpresa_FromUrl = Number(t_idEmpresa_FromUrl)
	}
	//idEscuela
	var idEscuela_FromUrl: number | undefined
	const t_idEscuela_FromUrl = searchParams.get("idEscuela")
	if (t_idEscuela_FromUrl) {
		idEscuela_FromUrl = Number(t_idEscuela_FromUrl)
	}
	//idMotoclub
	var idMotoclub_FromUrl: number | undefined
	const t_idMotoclub_FromUrl = searchParams.get("idMotoclub")
	if (t_idMotoclub_FromUrl) {
		idMotoclub_FromUrl = Number(t_idMotoclub_FromUrl)
	}
	//idFederacion
	var idFederacion_FromUrl: number | undefined
	const t_idFederacion_FromUrl = searchParams.get("idFederacion")
	if (t_idFederacion_FromUrl) {
		idFederacion_FromUrl = Number(t_idFederacion_FromUrl)
	}
	//idOrganizador
	var idOrganizador_FromUrl: number | undefined
	const t_idOrganizador_FromUrl = searchParams.get("idOrganizador")
	if (t_idOrganizador_FromUrl) {
		idOrganizador_FromUrl = Number(t_idOrganizador_FromUrl)
	}
	//idPersona
	var idPersona_FromUrl: number | undefined
	const t_idPersona_FromUrl = searchParams.get("idPersona")
	if (t_idPersona_FromUrl) {
		idPersona_FromUrl = Number(t_idPersona_FromUrl)
	}
	//idRodada
	var idRodada_FromUrl: number | undefined
	const t_idRodada_FromUrl = searchParams.get("idRodada")
	if (t_idRodada_FromUrl) {
		idRodada_FromUrl = Number(t_idRodada_FromUrl)
	}
	//idChatRoom
	var idChatRoom_FromUrl: number | undefined
	const t_idChatRoom_FromUrl = searchParams.get("idChatRoom")
	if (t_idChatRoom_FromUrl) {
		idChatRoom_FromUrl = Number(t_idChatRoom_FromUrl)
	}
	//idChatRoom_Set
	const idChatRoom_Set = (value: number | undefined | null) => {
		if (!value) {
			searchParams.delete("idChatRoom")
		} else {
			searchParams.set("idChatRoom", value.toString())
		}
		setSearchParams(searchParams)
	}
	//email
	const email_FromUrl: string | undefined = searchParams.get("email")?.toString()
	//tabName
	const tabName_FromUrl: string | undefined = searchParams.get("tabName")?.toString()
	const tabName_Set = (value: string | undefined) => {
		if (!value) {
			searchParams.delete("tabName")
		} else {
			searchParams.set("tabName", value)
		}

		setSearchParams(searchParams)
	}
	//token
	const token_FromUrl: string | undefined = searchParams.get("token")?.toString()
	//phoneNumber
	const phoneNumber_FromUrl: string | undefined = searchParams.get("phoneNumber")?.toString()
	//redirectUrl
	const redirectUrl_FromUrl: string | undefined = searchParams.get("redirectUrl")?.toString()
	//nombre
	const nombre_FromUrl: string | undefined = searchParams.get("nombre")?.toString()
	const nombre_Set = (value: string | undefined) => {
		if (!value) {
			searchParams.delete("nombre")
		} else {
			searchParams.set("nombre", value)
		}

		setSearchParams(searchParams)
	}
	//apellido1
	const apellido1_FromUrl: string | undefined = searchParams.get("apellido1")?.toString()
	//emailInvitationToken
	const emailInvitationToken_FromUrl: string | undefined = searchParams.get("emailInvitationToken")?.toString()
	//phoneNumberInvitationToken
	const phoneNumberInvitationToken_FromUrl: string | undefined = searchParams
		.get("phoneNumberInvitationToken")
		?.toString()

	return {
		isOnLandingPage,
		page,
		idCircuito_FromUrl,
		idCircuitoPista_FromUrl,
		idCircuitoPista_Set,
		idPersonaVehiculo_FromUrl,
		idPersonaVehiculo_Set,
		idPersonaModalidad_FromUrl,
		idPersonaModalidad_Set,
		idEmpresa_FromUrl,
		idEscuela_FromUrl,
		idMotoclub_FromUrl,
		idFederacion_FromUrl,
		idOrganizador_FromUrl,
		idPersona_FromUrl,
		idRodada_FromUrl,
		idChatRoom_FromUrl,
		idChatRoom_Set,
		email_FromUrl,
		tabName_FromUrl,
		tabName_Set,
		token_FromUrl,
		phoneNumber_FromUrl,
		redirectUrl_FromUrl,
		nombre_FromUrl,
		nombre_Set,
		apellido1_FromUrl,
		emailInvitationToken_FromUrl,
		phoneNumberInvitationToken_FromUrl,
	}
}
