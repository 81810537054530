import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedRodada } from "../../../shared/sharedRodada"
import { apiCallInterface } from "../../../apiCallInterface"

export namespace apiCallIG_Rodadas_GetAllRodadas {
	export const paramsDesc = {}

	export interface IRes {
		sharedRodadas: sharedRodada.IShared[]
	}

	// const validations = async (apiCallParams: apiCallInterface, params: bodyParams) => {
	// 	const serverModelErrors: IServerModelErrors = {}

	// 	return await dispatchServerModelErrors(key, serverModelErrors)
	// }

	export const apiCall = async (apiCallParams: apiCallInterface): Promise<IRes | null> => {
		// if (await validations(apiCallParams, params)) {
		// 	return null
		// }

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.get
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Rodadas/GetAllRodadas`
		const sharedParamsQuery = null
		const sharedParamsBody = null

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedRodadas: sharedRodada.getSharedList_FromApiObject(apiCallRes),
		}
	}
}
