/* eslint-disable react/jsx-pascal-case*/
import { Alert, Card, Container } from "@mui/material"
import { useEffect } from "react"
import { bllSaludonnet_CentrosUrgencias } from "../../../bll/Saludonnet/bllSaludonnet_CentrosUrgencias"
import useGeneral from "../../../hooksData/useGeneral"
import useSntCentrosUrgencias from "../../../hooksData/useSntCentrosUrgencias"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import SaludonnetCentrosUrgencias_Map from "./SaludonnetCentrosUrgencias_Map"

export default function SaludonnetCentrosUrgencias() {
	const ug = useGeneral("SaludonnetCentrosUrgencias")
	// const { centrosUrgencias } = useSntCentrosUrgencias()

	// useEffect(() => {
	// 	ug.D(bllSaludonnet_CentrosUrgencias.loadCentrosUrgencias(ug.apiCallParams))

	// 	return () => {
	// 		ug.D(bllSaludonnet_CentrosUrgencias.setInitialState())
	// 	}
	// }, [])

	return (
		<Container sx={{ position: "relative" }}>
			<Card sx={{ p: 4 }}>En desarrollo...</Card>
		</Container>
	)

	// return (
	// 	<Container sx={{ position: "relative" }}>
	// 		{ug.serverState.isLoading && <SharedLoadingComponent />}

	// 		{ug.serverState.errorMessage ? (
	// 			<Alert severity="error">{ug.serverState.errorMessage}</Alert>
	// 		) : (
	// 			<SaludonnetCentrosUrgencias_Map />
	// 		)}
	// 	</Container>
	// )
}
