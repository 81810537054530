/* eslint-disable react/jsx-pascal-case*/
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useGlobalsUserRoles from "../../../hooksData/useGlobalsUserRoles"
import { ISharedTabs_Tab } from "../../Shared/Basic/SharedBasicTabs"
import RodadasShared_AllRodadas from "../Shared/RodadasShared_AllRodadas"
import RodadasShared_MisRodadas from "../Shared/RodadasShared_MisRodadas"
import RodadasShared_RodadasSeguidas from "../Shared/RodadasShared_RodadasSeguidas"

export const RodadasLanding_Menu = (): ISharedTabs_Tab[] => {
	const { isUserCurrentRol_OrganizadorDeCircuito } = useGlobalsUserRoles()

	var menu: ISharedTabs_Tab[] = []

	menu.push({
		name: globalPathsApp.tabsRodadasEnum.all,
		label: "Todos las rodadas",
		component: <RodadasShared_AllRodadas />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Rodadas(globalPathsApp.tabsRodadasEnum.all),
	})

	if (isUserCurrentRol_OrganizadorDeCircuito) {
		menu.push({
			name: globalPathsApp.tabsRodadasEnum.own,
			label: "Mis rodadas",
			component: <RodadasShared_MisRodadas />,
			iconifyIcon: "maki:racetrack",
			urlDest: globalPathsApp.pageInsideUrl.Rodadas(globalPathsApp.tabsRodadasEnum.own),
		})
	}

	menu.push({
		name: globalPathsApp.tabsRodadasEnum.following,
		label: "Rodadas seguidas",
		component: <RodadasShared_RodadasSeguidas />,
		iconifyIcon: "maki:racetrack",
		urlDest: globalPathsApp.pageInsideUrl.Rodadas(globalPathsApp.tabsRodadasEnum.following),
	})

	return menu
}
