/* eslint-disable react/jsx-pascal-case*/
import { Card, CardHeader, Container } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useServerState from "../../../hooks/useServerState"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import CircuitosShared_Table from "./CircuitosShared_Table"
import CircuitosShared_CardList from "./CircuitosShared_CardList"

interface IProps {}

export default function CircuitosShared_AllCircuitos({}: IProps) {
	const circuitos = useSelector((state: IState) => state.GlobalsGeneralData.AllCircuitos.circuitos)

	return (
		<Container sx={{ position: "relative" }}>
			<Card>
				{/* <CardHeader title="Circuitos" sx={{ mb: 3 }} />
				<CircuitosGlobals_Table
					circuitos={circuitos}
					showAll={showAll}
					showAllAppPathPage={globalPathsApp.pagesEnum.CircuitosLists}
					showAllPathParameters={globalPathsApp.pageParameters.CircuitosLists("allCircuits")}
				/> */}

				<CardHeader title="Circuitos" sx={{ mb: 3 }} />

				<CircuitosShared_CardList circuitos={circuitos} />
			</Card>
		</Container>
	)
}
