/* eslint-disable react/jsx-pascal-case*/
import { useEffect, useState } from "react"
import useDisplayAfter500 from "../../hooks/useDisplayAfter500"
import useIsMounted from "../../hooks/useIsMounted"
import SharedLoadingScreen from "../Shared/Loading/SharedLoadingScreen"

export default function GlobalFullScreenLoadingForImports() {
	const { displayAfter500 } = useDisplayAfter500()

	return <>{displayAfter500 && <SharedLoadingScreen />}</>
}
