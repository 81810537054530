import { combineReducers } from "redux"
import { bllSeguros_GetSegurosParaDni } from "./bllSeguros_GetSegurosParaDni"
import { bllSeguros_GetPdfProtocoloAccidente } from "./bllSeguros_GetPdfProtocoloAccidente"
import { bllSeguros_SaveProtocoloAccidenteConCampos } from "./bllSeguros_SaveProtocoloAccidenteConCampos"
import { bllSeguros_GetSegurosVigentes } from "./bllSeguros_GetSegurosVigentes"
import { bllSeguros_GetSeguro } from "./bllSeguros_GetSeguro"
import { bllSeguros_GetTarjetaSeguro } from "./bllSeguros_GetTarjetaSeguro"
import { bllSeguros_GetTarjetaPiloto } from "./bllSeguros_GetTarjetaPiloto"

export default combineReducers({
	GetSegurosParaDni: bllSeguros_GetSegurosParaDni.reducerFunction,
	GetPdfProtocoloAccidente: bllSeguros_GetPdfProtocoloAccidente.reducerFunction,
	SaveProtocoloAccidenteConCampos: bllSeguros_SaveProtocoloAccidenteConCampos.reducerFunction,
	GetSegurosVigentes: bllSeguros_GetSegurosVigentes.reducerFunction,
	GetSeguro: bllSeguros_GetSeguro.reducerFunction,
	GetTarjetaSeguro: bllSeguros_GetTarjetaSeguro.reducerFunction,
	GetTarjetaPiloto: bllSeguros_GetTarjetaPiloto.reducerFunction,
})

export interface ISeguros {
	GetSegurosParaDni: bllSeguros_GetSegurosParaDni.IInterface
	GetPdfProtocoloAccidente: bllSeguros_GetPdfProtocoloAccidente.IInterface
	SaveProtocoloAccidenteConCampos: bllSeguros_SaveProtocoloAccidenteConCampos.IInterface
	GetSegurosVigentes: bllSeguros_GetSegurosVigentes.IInterface
	GetSeguro: bllSeguros_GetSeguro.IInterface
	GetTarjetaSeguro: bllSeguros_GetTarjetaSeguro.IInterface
	GetTarjetaPiloto: bllSeguros_GetTarjetaPiloto.IInterface
}
