import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedEmpresa } from "../../../shared/sharedEmpresa"
import { apiCallInterface } from "../../../apiCallInterface"

export namespace apiCallIG_Empresas_ActualizarCover {
	export interface params {
		idEmpresa: number
		coverUrl: string
	}
	export const paramsName = {
		idEmpresa: "idEmpresa",
		coverUrl: "coverUrl",
	}
	export const paramsDesc = {
		idEmpresa: "Id Empresa",
		coverUrl: "URL del cover",
	}

	export interface IRes {
		sharedEmpresa: sharedEmpresa.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Empresas/ActualizarCover`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedEmpresa: sharedEmpresa.getSharedOne_FromApiObject(apiCallRes) }
	}
}
