import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { sharedUserRoles } from "../api/shared/sharedUserRoles"
import { IState } from "../bll/bllReducers"
import { utilFunctions } from "../utils/utilFunctions"
import { utilLogger } from "../utils/utilLogger"

export default function useGlobalsUser() {
	const circuitosDeLaPersona = useSelector((state: IState) => state.GlobalsUser.CurrentPersonaCircuitos.circuitos)
	const organizadoresDeLaPersona = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaOrganizadores.organizadores
	)

	const currentPersona = useSelector((state: IState) => state.GlobalsUser.CurrentPersona.currentPersona)
	const rodadasSeguidas = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaRodadasSeguidas.rodadasSeguidas
	)
	const circuitosSeguidos = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaCircuitosSeguidos.circuitosSeguidos
	)
	const empresasSeguidas = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaEmpresasSeguidas.empresasSeguidos
	)
	const escuelasSeguidas = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaEscuelasSeguidas.escuelasSeguidos
	)
	const motoclubsSeguidos = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaMotoclubsSeguidos.motoclubsSeguidos
	)
	const federacionesSeguidas = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaFederacionesSeguidas.federacionesSeguidos
	)
	const organizadoresSeguidos = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaOrganizadoresSeguidos.organizadoresSeguidos
	)
	const personasSeguidas = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaPersonasSeguidas.personasSeguidas
	)

	const userIsLogged = useSelector((state: IState) => state.Users.Login.isLogged)
	const userToken = useSelector((state: IState) => state.Users.Login.loginRes.token)
	const refreshToken = useSelector((state: IState) => state.Users.Login.loginRes.refreshToken)

	// const isUserTokenExpired = utilFunctions.isJwtTokenExpired(userToken)
	// const isRefreshTokenExpired = utilFunctions.isJwtTokenExpired(refreshToken)
	// utilLogger.two(isUserTokenExpired, "isUserTokenExpired")
	// utilLogger.two(isRefreshTokenExpired, "isRefreshTokenExpired")

	return {
		circuitosDeLaPersona,
		organizadoresDeLaPersona,
		rodadasSeguidas,
		circuitosSeguidos,
		empresasSeguidas,
		escuelasSeguidas,
		motoclubsSeguidos,
		federacionesSeguidas,
		organizadoresSeguidos,
		personasSeguidas,
		currentPersona,
		userIsLogged,
		userToken,
	}
}
