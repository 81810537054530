import { utilDate } from "../../utils/utilDate"

export namespace sharedPosteosEtiquetas {
	export interface IShared {
		id: number
		idPosteo: number
		idPersonaEtiquetada: number
		nombrePersonaEtiquetada: string
	}

	export const objectDefault: IShared = {
		id: 0,
		idPosteo: 0,
		idPersonaEtiquetada: 0,
		nombrePersonaEtiquetada: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}
}
