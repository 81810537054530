/* eslint-disable react/jsx-pascal-case*/
import { Box } from "@mui/material"
import { sharedCircuito } from "../../../api/shared/sharedCircuito"
import CircuitosGlobals_CardList_Card from "./CircuitosShared_CardList_Card"

interface IProps {
	circuitos: sharedCircuito.IShared[]
}

export default function CircuitosShared_CardList({ circuitos }: IProps) {
	return (
		<>
			<Box
				gap={3}
				display="grid"
				sx={{ p: 3 }}
				gridTemplateColumns={{
					xs: "repeat(1, 1fr)",
					sm: "repeat(2, 1fr)",
					md: "repeat(3, 1fr)",
				}}
			>
				{circuitos.map((circuito, i) => (
					<CircuitosGlobals_CardList_Card key={i} circuito={circuito} />
				))}

				{circuitos.length === 0 && (
					<Box
						sx={{
							color: "text.secondary",
						}}
					>
						-Aún no hay circuitos en este listado-
					</Box>
				)}
			</Box>
		</>
	)
}
