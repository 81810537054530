import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedOrganizador } from "../../api/shared/sharedOrganizador"
import { apiCallIG_Organizadores_GetOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_GetOrganizador"
import { apiCallIG_Organizadores_ActualizarAvatar } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_ActualizarAvatar"
import { apiCallIG_Organizadores_ActualizarContact } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_ActualizarContact"
import { apiCallIG_Organizadores_ActualizarCover } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_ActualizarCover"
import { apiCallIG_Organizadores_ActualizarGeneralFields } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_ActualizarGeneralFields"
import { apiCallIG_Organizadores_ActualizarProfileFields } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_ActualizarProfileFields"
import { apiCallIG_Organizadores_ActualizarSocialInfo } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_ActualizarSocialInfo"
import { apiCallIG_Organizadores_BloquearOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_BloquearOrganizador"
import { apiCallIG_Organizadores_AprobarOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_AprobarOrganizador"
import { apiCallIG_Organizadores_DesbloquearOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_DesbloquearOrganizador"
import { apiCallIG_Organizadores_DeleteOrganizador } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_DeleteOrganizador"
import { bllGlobalsGeneralData_AllFederaciones } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllFederaciones"
import { apiCallInterface } from "../../api/apiCallInterface"
import { bllGlobalsGeneralData_AllOrganizadores } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllOrganizadores"
import { apiCallIG_Organizadores_EnviarNotificacionASeguidores } from "../../api/calls/IG/Organizadores/apiCallIG_Organizadores_EnviarNotificacionASeguidores"
import { sharedOrganizadorSeguidor } from "../../api/shared/sharedOrganizadorSeguidor"
import { GetOrganizadores_DeOrganizadoresSeguidores_ByIdPersona } from "../../api/calls/IG/OrganizadoresSeguidores/GetOrganizadores_DeOrganizadoresSeguidores_ByIdPersona"
import { apiCallIG_OrganizadoresSeguidores_GetOrganizadoresSeguidoresByIdOrganizador } from "../../api/calls/IG/OrganizadoresSeguidores/apiCallIG_OrganizadoresSeguidores_GetOrganizadoresSeguidoresByIdOrganizador"

export namespace bllOrganizadores_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Organizadores_Settings_setInitialState",
		loadOrganizador = "Organizadores_Settings_loadOrganizador",
		updateWithSharedOrganizador = "Organizadores_Settings_updateWithSharedOrganizador",
		actualizarAvatar = "Organizadores_Settings_actualizarAvatar",
		actualizarCharacteristics = "Organizadores_Settings_actualizarCharacteristics",
		actualizarContact = "Organizadores_Settings_actualizarContact",
		actualizarAdminFields = "Organizadores_Settings_actualizarAdminFields",
		actualizarCover = "Organizadores_Settings_actualizarCover",
		actualizarGeneralFields = "Organizadores_Settings_actualizarGeneralFields",
		actualizarProfileFields = "Organizadores_Settings_actualizarProfileFields",
		actualizarSocialInfo = "Organizadores_Settings_actualizarSocialInfo",
		deleteOrganizador = "Organizadores_Settings_deleteOrganizador",
		bloquearOrganizador = "Organizadores_Settings_bloquearOrganizador",
		desbloquearOrganizador = "Organizadores_Settings_desbloquearOrganizador",
		aprobarOrganizador = "Organizadores_Settings_aprobarOrganizador",

		enviarNotificacionASeguidores = "Organizadores_Settings_enviarNotificacionASeguidores",

		getSeguidores = "Organizadores_Settings_getSeguidores",
	}

	export interface IInterface {
		organizador: sharedOrganizador.IShared
		isDeleted: boolean
		notificacionEnviada: boolean
		seguidores: sharedOrganizadorSeguidor.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			organizador: sharedOrganizador.defaultObject,
			isDeleted: false,
			notificacionEnviada: false,
			seguidores: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_GetOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Organizadores_GetOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "organizador">
				const updateObject: actionSubType = {
					organizador: apiCallRes.sharedOrganizador,
				}

				utilRedux.TA(D, ET.loadOrganizador, updateObject)
			}
		}
	}

	export function getSeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_OrganizadoresSeguidores_GetOrganizadoresSeguidoresByIdOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_OrganizadoresSeguidores_GetOrganizadoresSeguidoresByIdOrganizador.apiCall(
				apiCallParams,
				params
			)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "seguidores">
				const updateObject: actionSubType = {
					seguidores: apiCallRes.sharedOrganizadorSeguidores,
				}

				utilRedux.TA(D, ET.getSeguidores, updateObject)
			}
		}
	}

	export function updateOrganizadorWithSharedOrganizador(
		apiCallParams: apiCallInterface,
		sharedOrganizador: sharedOrganizador.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "organizador">
			const updateObject: actionSubType = {
				organizador: sharedOrganizador,
			}

			utilRedux.TA(D, ET.updateWithSharedOrganizador, updateObject)
		}
	}

	export function actualizarAvatar(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_ActualizarAvatar.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_ActualizarAvatar.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllOrganizadores_Settings.updateOrganizadorWithSharedOrganizador(apiCallParams, apiCallRes.sharedOrganizador)
				)

				utilRedux.TA(D, ET.actualizarAvatar, {})
			}
		}
	}

	export function actualizarContact(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_ActualizarContact.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_ActualizarContact.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllOrganizadores_Settings.updateOrganizadorWithSharedOrganizador(apiCallParams, apiCallRes.sharedOrganizador)
				)

				utilRedux.TA(D, ET.actualizarContact, {})
			}
		}
	}

	export function actualizarCover(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_ActualizarCover.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_ActualizarCover.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllOrganizadores_Settings.updateOrganizadorWithSharedOrganizador(apiCallParams, apiCallRes.sharedOrganizador)
				)

				utilRedux.TA(D, ET.actualizarCover, {})
			}
		}
	}

	export function actualizarGeneralFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_ActualizarGeneralFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_ActualizarGeneralFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllOrganizadores_Settings.updateOrganizadorWithSharedOrganizador(apiCallParams, apiCallRes.sharedOrganizador)
				)

				utilRedux.TA(D, ET.actualizarGeneralFields, {})
			}
		}
	}

	export function actualizarProfileFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_ActualizarProfileFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_ActualizarProfileFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllOrganizadores_Settings.updateOrganizadorWithSharedOrganizador(apiCallParams, apiCallRes.sharedOrganizador)
				)

				utilRedux.TA(D, ET.actualizarProfileFields, {})
			}
		}
	}

	export function actualizarSocialInfo(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_ActualizarSocialInfo.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_ActualizarSocialInfo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(
					bllOrganizadores_Settings.updateOrganizadorWithSharedOrganizador(apiCallParams, apiCallRes.sharedOrganizador)
				)

				utilRedux.TA(D, ET.actualizarSocialInfo, {})
			}
		}
	}

	export function deleteOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_DeleteOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Organizadores_DeleteOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isDeleted">
				const updateObject: actionSubType = {
					isDeleted: true,
				}

				D(bllGlobalsGeneralData_AllOrganizadores.loadAllOrganizadores(apiCallParams))

				utilRedux.TA(D, ET.deleteOrganizador, updateObject)
			}
		}
	}

	export function bloquearOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_BloquearOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_BloquearOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateOrganizadorWithSharedOrganizador(apiCallParams, apiCallRes.sharedOrganizador))

				utilRedux.TA(D, ET.bloquearOrganizador, {})
			}
		}
	}

	export function aprobarOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_AprobarOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_AprobarOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateOrganizadorWithSharedOrganizador(apiCallParams, apiCallRes.sharedOrganizador))

				utilRedux.TA(D, ET.aprobarOrganizador, {})
			}
		}
	}

	export function desbloquearOrganizador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_DesbloquearOrganizador.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Organizadores_DesbloquearOrganizador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateOrganizadorWithSharedOrganizador(apiCallParams, apiCallRes.sharedOrganizador))

				utilRedux.TA(D, ET.desbloquearOrganizador, {})
			}
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Organizadores_EnviarNotificacionASeguidores.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_Organizadores_EnviarNotificacionASeguidores.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}
}
