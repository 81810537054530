import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { apiRegularCall } from "../../../apiRegularCall"
import { sharedCircuito } from "../../../shared/sharedCircuito"
import { sharedRodada } from "../../../shared/sharedRodada"
import { apiCallInterface } from "../../../apiCallInterface"
import { sharedPosteos } from "../../../shared/sharedPosteos"

/*
[HttpPost(ApiRoutesPosteos.CreatePosteo)]
  public async Task<ActionResult> CreatePosteo(CreatePosteoReq req)
  {
    var userId = User.GetUserId();

    InxurCircuitos? circuito = null;
    if (req.IdCircuitoCreador != null)
    {
      circuito = await _circuitosService.GetCircuitoById(req.IdCircuitoCreador.Value);

      if (circuito == null)
        throw new Exception("No se ha encontrado el circuito para el id: " + req.IdCircuitoCreador.Value.ToString());

      await _generalSecurityService.UsuarioDebeTenerPermisosSobreCircuito(userId, circuito);
    }

    InxurPersonas? persona = null;
    if (req.IdPersonaCreadora != null)
    {
      persona = await _personasService.GetPersonaById(req.IdPersonaCreadora.Value);

      if (persona == null)
        throw new Exception("No se ha encontrado la persona para el id: " + req.IdPersonaCreadora.Value.ToString());

      await _generalSecurityService.UsuarioDebeTenerPermisosSobrePersona(userId, persona);
    }

    var posteo = new InxurPosteos
    {
      IdPersonaCreadora = req.IdPersonaCreadora,
      IdCircuitoCreador = req.IdCircuitoCreador,
      Texto = req.Texto,
      FechaCreacion = DateTime.Now
    };

    await _posteosService.CreatePosteo(posteo);



    if (persona != null)
    {
      var urlPosteo = _appCircuitosUrlsConfig.GetPersonaProfileUrl(persona.Id);
      await _notificacionesService.EnviarNotificacion_PorTema(NombresDeTemas.SeguidoresDePersonas(persona.Id), $"Nuevo posteo de: {persona.Nombre}", req.Texto, urlPosteo, true, true, true);
    }

    if (circuito != null)
    {
      var urlPosteo = _appCircuitosUrlsConfig.GetCircuitoProfileUrl(circuito.Id);
      await _notificacionesService.EnviarNotificacion_PorTema(NombresDeTemas.SeguidoresDeCircuitos(circuito.Id), $"Nuevo posteo en: {circuito.Nombre}", req.Texto, urlPosteo, true, true, true);
    }

    var posteoForClient = InxurPosteosForClient.Create(posteo);
    return Ok(posteoForClient);
  }
		*/

export namespace apiCallIG_Posteos_CreatePosteo {
	export interface params {
		idCircuitoCreador: number | null
		idPersonaCreadora: number | null
		texto: string | null
	}
	export const paramsName = {
		idCircuitoCreador: "idCircuitoCreador",
		idPersonaCreadora: "idPersonaCreadora",
		texto: "texto",
	}
	export const paramsDesc = {
		idCircuitoCreador: "idCircuitoCreador",
		idPersonaCreadora: "idPersonaCreadora",
		texto: "Texto",
	}

	export interface IRes {
		sharedPosteo: sharedPosteos.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Posteos/CreatePosteo`
		const sharedParamsQuery: params | null = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedPosteo: sharedPosteos.getSharedOne_FromApiObject(apiCallRes),
		}
	}
}
