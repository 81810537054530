import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../bll/bllReducers"
import useGlobalsUser from "./useGlobalsUser"
import { utilLogger } from "../utils/utilLogger"

export default function usePersonasLanding() {
	const personasByName = useSelector((state: IState) => state.Personas.Landing.personasByName)

	return {
		personasByName,
	}
}
