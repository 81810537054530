import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { sharedCircuitoEstado } from "./sharedCircuitoEstado"
import { sharedCircuitoPista } from "./sharedCircuitoPista"
import { sharedDireccion } from "./sharedDireccion"

export namespace sharedPersonasVehiculos {
	export interface IShared {
		id: number
		idTipoVehiculo: number
		idPersona: number
		avatarURL: string
		descripcion: string
		marca: string
		modelo: string
		anio: number
		fotoUrl: string
	}

	export const defaultObject: IShared = {
		id: 0,
		idTipoVehiculo: 0,
		idPersona: 0,
		avatarURL: globalAssets.staticPaths.circuitoAvatar1,
		descripcion: "",
		marca: "",
		modelo: "",
		anio: 0,
		fotoUrl: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
			avatarURL: apiObject.avatarURL || globalAssets.staticPaths.circuitoAvatar1,
		}

		return res
	}
}
