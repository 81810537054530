import { useSelector } from "react-redux"
import { sharedChatRoom } from "../api/shared/sharedChatRoom"
import { IState } from "../bll/bllReducers"
import useRouting from "../hooks/useRouting"
import useGeneral from "./useGeneral"
import { globalPathsApp } from "../globals/globalPathsApp"
import { sharedChatType } from "../api/shared/sharedChatType"
import { utilLogger } from "../utils/utilLogger"
import useGlobalsUser from "./useGlobalsUser"

export interface IRoomInfo {
	chatType: number
	// idOtherPerson: number
	// nameOtherPerson: string
	// avatarUrlOtherPerson: string | null
	// idEntity?: number
	// nameEntity?: string
	// avatarUrlEntity?: string

	// var mainName: string | undefined = undefined
	// var mainAvatarUrl: string | undefined = undefined
	// var secondaryName: string | undefined = undefined
	// var secondaryAvatarUrl: string | undefined = undefined

	mainNameToShow: string | undefined
	mainAvatarUrlToShow: string | undefined
	mainLink: string | undefined

	secondaryNameToShow: string | undefined
	secondaryAvatarUrlToShow: string | undefined
	secondaryLink: string | undefined
}

export default function useChat() {
	const { ...ug } = useGeneral("useChat")
	const { currentPersona } = useGlobalsUser()

	const loadRoomsFinished = useSelector((state: IState) => state.Chat.All.loadRoomsFinished)
	const loadMessagesFinished = useSelector((state: IState) => state.Chat.All.loadMessagesFinished)
	const sharedChatRooms = useSelector((state: IState) => state.Chat.All.sharedChatRooms)
	const sharedChatMessages = useSelector((state: IState) => state.Chat.All.sharedChatMessages)

	// utilLogger.two(sharedChatRooms, "sharedChatRooms")
	// utilLogger.two(sharedChatMessages, "sharedChatMessages")

	const { idChatRoom_FromUrl } = useRouting()

	const currentRoom_sharedChatRoom = sharedChatRooms.find((x) => x.id === idChatRoom_FromUrl)
	const currentRoom_sharedChatMessages = sharedChatMessages.filter((x) => x.idRoom === idChatRoom_FromUrl)

	// utilLogger.two(currentRoom_sharedChatMessages, "currentRoom_sharedChatMessages")
	// utilLogger.two(currentRoom_sharedChatRoom, "currentRoom_sharedChatRoom")

	const getRoomInfo = (chatRoom: sharedChatRoom.IShared, ownIdPerson: number): IRoomInfo => {
		var roomInfo: IRoomInfo = {
			chatType: chatRoom.chatType,

			mainNameToShow: undefined,
			mainAvatarUrlToShow: undefined,
			mainLink: undefined,

			secondaryNameToShow: undefined,
			secondaryAvatarUrlToShow: undefined,
			secondaryLink: undefined,
		}

		//Si estoy chateando con una persona, entonces la otra persona es la principal
		if (chatRoom.chatType === sharedChatType.sharedEnum.personAndPerson.id) {
			if (chatRoom.idPerson1 === ownIdPerson) {
				roomInfo.mainNameToShow = chatRoom.namePerson2
				roomInfo.mainAvatarUrlToShow = chatRoom.avatarUrlPerson2
				roomInfo.mainLink = globalPathsApp.pageInsideUrl.PersonasProfile(undefined, chatRoom.idPerson2)
			} else {
				roomInfo.mainNameToShow = chatRoom.namePerson1
				roomInfo.mainAvatarUrlToShow = chatRoom.avatarUrlPerson1
				roomInfo.mainLink = globalPathsApp.pageInsideUrl.PersonasProfile(undefined, chatRoom.idPerson1)
			}
		} else {
			//Si la persona1 soy yo, significa que no soy el dueño de la entidad, por lo cual, muestro el nombre de la entidad como nombre principal
			if (chatRoom.idPerson1 === ownIdPerson) {
				roomInfo.mainNameToShow = chatRoom.nameEntity
				roomInfo.mainAvatarUrlToShow = chatRoom.avatarUrlEntity

				switch (chatRoom.chatType) {
					case sharedChatType.sharedEnum.personAndCircuito.id:
						roomInfo.mainLink = globalPathsApp.pageInsideUrl.CircuitosProfile(undefined, chatRoom.idEntity)
						break
					case sharedChatType.sharedEnum.personAndRodada.id:
						roomInfo.mainLink = globalPathsApp.pageInsideUrl.RodadasProfile(undefined, chatRoom.idEntity)
						break
					case sharedChatType.sharedEnum.personAndOrganizador.id:
						roomInfo.mainLink = globalPathsApp.pageInsideUrl.OrganizadoresProfile(undefined, chatRoom.idEntity)
						break
					default:
						break
				}
			} else {
				roomInfo.mainNameToShow = chatRoom.namePerson1
				roomInfo.mainAvatarUrlToShow = chatRoom.avatarUrlPerson1
				roomInfo.mainLink = globalPathsApp.pageInsideUrl.PersonasProfile(undefined, chatRoom.idPerson1)

				roomInfo.secondaryNameToShow = chatRoom.nameEntity
				roomInfo.secondaryAvatarUrlToShow = chatRoom.avatarUrlEntity

				switch (chatRoom.chatType) {
					case sharedChatType.sharedEnum.personAndCircuito.id:
						roomInfo.secondaryLink = globalPathsApp.pageInsideUrl.CircuitosProfile(undefined, chatRoom.idEntity)
						break
					case sharedChatType.sharedEnum.personAndRodada.id:
						roomInfo.secondaryLink = globalPathsApp.pageInsideUrl.RodadasProfile(undefined, chatRoom.idEntity)
						break
					case sharedChatType.sharedEnum.personAndOrganizador.id:
						roomInfo.secondaryLink = globalPathsApp.pageInsideUrl.OrganizadoresProfile(undefined, chatRoom.idEntity)
						break
					default:
						break
				}
			}
		}

		return roomInfo
	}

	function chatsUnreadCount(rooms: sharedChatRoom.IShared[]) {
		var count = 0
		rooms.forEach((room) => {
			if (isChatRoomUnread(room, currentPersona.id) && sharedChatMessages.some((x) => x.idRoom === room.id)) {
				count++
			}
		})
		return count
	}

	function isChatRoomUnread(room: sharedChatRoom.IShared, idPerson: number) {
		if (room.idPerson1 === room.idPerson2) {
			return false
		}

		if (room.idPerson1 === idPerson && room.readByPerson1 !== true) {
			return true
		}

		if (room.idPerson2 === idPerson && room.readByPerson2 !== true) {
			return true
		}

		return false
	}

	// function getRoomsUnreadForQr(rooms: sharedChatRoom.IShared[], idQr: string, idPerson: string) {
	// 	const roomsFiltered = rooms.filter((x) => x.idQr === idQr)

	// 	const roomsUnread = roomsFiltered.filter((x) => isChatRoomUnread(x, idPerson))

	// 	return roomsUnread.length
	// }

	return {
		loadRoomsFinished,
		loadMessagesFinished,
		sharedChatRooms,
		sharedChatMessages,
		//CurrentRoom
		currentRoom_sharedChatRoom,
		currentRoom_sharedChatMessages,

		idChatRoom_FromUrl,

		isChatRoomUnread,
		chatsUnreadCount,
		getRoomInfo,
		// getRoomsUnreadForQr,
	}
}
