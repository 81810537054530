/* eslint-disable react/jsx-pascal-case*/

import { Button } from "@mui/material"
import { globalPathsApp } from "../../globals/globalPathsApp"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"

export const Layout_Header_LoginRegistrarse = () => {
	return (
		<>
			<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.UsersLogin()}>
				<Button size="medium" variant="contained">
					Login
				</Button>
			</SharedLinkInsideAppWrapper>

			<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.UsersRegistration()} sx={{ ml: 1 }}>
				<Button size="medium" variant="contained">
					Registrarse
				</Button>
			</SharedLinkInsideAppWrapper>
		</>
	)
}
