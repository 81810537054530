import _ from "lodash"
import { utilLogger } from "./utilLogger"

export namespace utilFunctionsFiles {
	export function dataUrl_FromImage(img: any) {
		//Usage example: var base64 = getBase64_FromImage(document.getElementById("imageid"));
		var canvas = document.createElement("canvas")
		canvas.width = img.width
		canvas.height = img.height
		var ctx = canvas.getContext("2d")
		if (!ctx) return null
		ctx.drawImage(img, 0, 0)
		var dataURL = canvas.toDataURL("image/png")
		return dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
	}

	export function getUrlFromBase64(base64String: string): string {
		const byteCharacters = atob(base64String)
		const byteNumbers = new Array(byteCharacters.length)
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i)
		}
		const byteArray = new Uint8Array(byteNumbers)
		const blob = new Blob([byteArray], { type: "application/pdf" })
		const url = URL.createObjectURL(blob)
		return url
	}

	export async function getBase64FromPdf(url: string): Promise<string> {
		const response = await fetch(url)
		const buffer = await response.arrayBuffer()
		const pdfBytes = new Uint8Array(buffer)
		const base64String = bufferToBase64(pdfBytes)
		return base64String
	}

	function bufferToBase64(buffer: Uint8Array): string {
		let binary = ""
		const length = buffer.byteLength
		for (let i = 0; i < length; i++) {
			binary += String.fromCharCode(buffer[i])
		}
		return btoa(binary)
	}

	export async function file_FromUrl(url: string, filename: string, mimeType: string) {
		//Usage example:
		// File_FromUrl('data:text/plain;base64,aGVsbG8gd29ybGQ=', 'hello.txt','text/plain')
		// .then(function(file){ console.log(file);});
		const res = await fetch(url)
		const buf = await res.arrayBuffer()
		return new File([buf], filename, { type: mimeType })
	}

	export function file_FromDataUrl(dataurl: string, filename: string) {
		//Usage example:
		// var file = dataURLtoFile("data:text/plain;base64,aGVsbG8gd29ybGQ=", "hello.txt")
		// console.log(file)
		const arr = dataurl.split(",")
		if (!arr) return null
		const arr0 = arr[0]
		const arr1 = arr[1]
		if (!arr0 || !arr1) return null
		const arr0Matched = arr0.match(/:(.*?);/)
		if (!arr0Matched) return null
		const arr0Matched1 = arr0Matched[1]
		if (!arr0Matched1) return null
		const mime = arr0Matched1
		const bstr = atob(arr1)
		var n = bstr.length
		const u8arr = new Uint8Array(n)

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n)
		}

		return new File([u8arr], filename, { type: mime })
	}

	export const dataUrl_FromFileUrl = (url: string) => {
		// dataUrl_FromUrl('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0')
		// .then(dataUrl => {
		//   console.log('RESULT:', dataUrl)
		// })

		fetch(url)
			.then((response) => response.blob())
			.then(
				(blob) =>
					new Promise((resolve, reject) => {
						const reader = new FileReader()
						reader.onloadend = () => resolve(reader.result)
						reader.onerror = reject
						reader.readAsDataURL(blob)
					})
			)
	}

	export const dataUrl_FromImageUrl = (url: string, callback: any) => {
		var img = new Image()
		img.crossOrigin = "Anonymous"

		img.onload = function () {
			var canvas = document.createElement("CANVAS") as HTMLCanvasElement
			var ctx = canvas.getContext("2d") as CanvasRenderingContext2D
			canvas.height = img.naturalHeight
			canvas.width = img.naturalWidth
			var dataURL
			ctx.drawImage(img, 0, 0)
			dataURL = canvas.toDataURL()
			callback(dataURL)
		}

		img.src = url
	}

	export const getFileName_FromUrl = (url: string) => {
		const arr = url.split("/")
		if (!arr) return null
		const arrLast = _.last(arr)
		if (!arrLast) return null
		return arrLast
	}

	// return new Promise((resolve, reject) => {
	// 	utilLogger.two(img, "img")
	// 	var canvas = document.createElement("CANVAS") as HTMLCanvasElement
	// 	var ctx = canvas.getContext("2d") as CanvasRenderingContext2D
	// 	// ctx.drawImage(img, 0, 0)
	// 	utilLogger.two(ctx, "ctx")
	// 	var dataURL = canvas.toDataURL("image/jpg")

	// 	utilLogger.two(dataURL, "dataURL")
	// })

	// img.src = url

	// // dataUrl_FromUrl('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0')
	// // .then(dataUrl => {
	// //   console.log('RESULT:', dataUrl)
	// // })

	// fetch(url)
	// 	.then((response) => response.blob())
	// 	.then(
	// 		(blob) =>
	// 			new Promise((resolve, reject) => {
	// 				const reader = new FileReader()
	// 				reader.onloadend = () => resolve(reader.result)
	// 				reader.onerror = reject
	// 				reader.readAsDataURL(blob)
	// 			})
	// 	)
}
