import { combineReducers } from "redux"
import { bllPersonas_Profile } from "./bllPersonas_Profile"
import { bllPersonas_Settings } from "./bllPersonas_Settings"
import { bllPersonas_Search } from "./bllPersonas_Search"

export default combineReducers({
	Profile: bllPersonas_Profile.reducerFunction,
	Settings: bllPersonas_Settings.reducerFunction,
	Landing: bllPersonas_Search.reducerFunction,
})

export interface IPersonas {
	Profile: bllPersonas_Profile.IInterface
	Settings: bllPersonas_Settings.IInterface
	Landing: bllPersonas_Search.IInterface
}
