/* eslint-disable react/jsx-pascal-case*/
import { Avatar, Box, styled, SxProps, Theme, Typography } from "@mui/material"
import { add } from "date-fns"
import useCountdown from "../../hooks/useCountdown"

interface IProps {
	comingInDays: number
	sx?: SxProps<Theme>
}

const CountdownStyle = styled("div")({
	display: "flex",
	justifyContent: "center",
})

const SeparatorStyle = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(0, 1),
	[theme.breakpoints.up("sm")]: {
		margin: theme.spacing(0, 2.5),
	},
}))

export const SharedCountdown = ({ comingInDays, sx }: IProps) => {
	const comingDate = add(Date.now(), { days: comingInDays })
	const countdown = useCountdown(comingDate)

	return (
		<Box sx={{ ...sx }}>
			<CountdownStyle>
				<div>
					<Typography variant="h2">{countdown.days}</Typography>
					<Typography sx={{ color: "text.secondary" }}>Días</Typography>
				</div>

				<SeparatorStyle variant="h2">:</SeparatorStyle>

				<div>
					<Typography variant="h2">{countdown.hours}</Typography>
					<Typography sx={{ color: "text.secondary" }}>Horas</Typography>
				</div>

				<SeparatorStyle variant="h2">:</SeparatorStyle>

				<div>
					<Typography variant="h2">{countdown.minutes}</Typography>
					<Typography sx={{ color: "text.secondary" }}>Minutos</Typography>
				</div>

				<SeparatorStyle variant="h2">:</SeparatorStyle>

				<div>
					<Typography variant="h2">{countdown.seconds}</Typography>
					<Typography sx={{ color: "text.secondary" }}>Segundos</Typography>
				</div>
			</CountdownStyle>
		</Box>
	)
}
