/* eslint-disable react/jsx-pascal-case*/
import { Box, Container } from "@mui/material"
import SharedBasicTabs from "../../Shared/Basic/SharedBasicTabs"
import SaludonnetLanding_Hero from "./SaludonnetLanding_Hero"
import { SaludonnetLanding_Menu } from "./SaludonnetLanding_Menu"

export default function SaludonnetLanding() {
	return (
		<Container>
			<SaludonnetLanding_Hero />

			<Box sx={{ mt: 2 }}>
				<SharedBasicTabs tabs={SaludonnetLanding_Menu()} />
			</Box>
		</Container>
	)
}
