import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_PersonasSeguidores_GetPersonas_DePersonasSeguidores_ByIdPersonaSeguidor } from "../../api/calls/IG/PersonasSeguidores/apiCallIG_PersonasSeguidores_GetPersonas_DePersonasSeguidores_ByIdPersonaSeguidor"
import { apiCallIG_Rodadas_GetRodadasDePersona } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_GetRodadasDePersona"
import { apiCallIG_RodadasSeguidores_GetRodadas_DeRodadasSeguidores_ByIdPersona } from "../../api/calls/IG/RodadasSeguidores/apiCallIG_RodadasSeguidores_GetRodadas_DeRodadasSeguidores_ByIdPersona"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { sharedPersonaSeguidor } from "../../api/shared/sharedPersonaSeguidor"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllGlobalsUser_CurrentPersonaPersonasSeguidas {
	//ET = enumTypes
	enum ET {
		setInitialState = "bllGlobalsUser_CurrentPersonaPersonasSeguidas_setInitialState",
		load = "bllGlobalsUser_CurrentPersonaPersonasSeguidas_load",
	}

	export interface IInterface {
		personasSeguidas: sharedPersonaSeguidor.IShared[]
		loaded: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			personasSeguidas: [],
			loaded: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Current_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadPersonasSeguidasDePersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_PersonasSeguidores_GetPersonas_DePersonasSeguidores_ByIdPersonaSeguidor.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes =
				await apiCallIG_PersonasSeguidores_GetPersonas_DePersonasSeguidores_ByIdPersonaSeguidor.apiCall(
					apiCallParams,
					params
				)

			if (apiCallRes) {
				await D(setPersonaPersonasSeguidas(apiCallRes.sharedPersonasSeguidas))
			}
		}
	}

	export function setPersonaPersonasSeguidas(personasSeguidas: sharedPersonaSeguidor.IShared[]) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "personasSeguidas" | "loaded">
			const updateObject: actionSubType = {
				personasSeguidas: personasSeguidas,
				loaded: true,
			}

			utilRedux.TA(D, ET.load, updateObject)
		}
	}
}
