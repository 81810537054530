import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { sharedChatMessage } from "../../../shared/sharedChatMessage"
import { sharedChatRoom } from "../../../shared/sharedChatRoom"

export namespace apiCallIG_Chat_LoadMessages_ForPerson {
	export interface params {
		idPerson: number
	}

	export interface IRes {
		chatMessages: sharedChatMessage.IShared[]
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.get
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Chat/LoadMessages_ForPerson`
		const sharedParamsQuery: params | null = params
		const sharedParamsBody: params | null = null

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			chatMessages: sharedChatMessage.getSharedList_FromApiObject(apiCallRes),
		}
	}
}
