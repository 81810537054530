import { useCallback, useEffect, useRef, useState } from "react"
import useIsMounted from "./useIsMounted"

export default function useDisplayAfter500() {
	const isMounted = useIsMounted()

	const [display, set_display] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			if (isMounted()) {
				set_display(true)
			}
		}, 500)
	}, [isMounted])

	return {
		displayAfter500: display,
	}
}
