import AWS from "aws-sdk"
import TextractClient from "aws-sdk/clients/textract"
import { sharedTipoSexo } from "../api/shared/sharedTipoSexo"
import { bllDispatchs_GlobalsServer } from "../bll/bllDispatchs_GlobalsServer"
import { globalAssets } from "../globals/globalAssets"
import { globalConfig } from "../globals/globalConfig"
import { utilFunctionsFiles } from "./utilFunctionsFiles"
import { utilLogger } from "./utilLogger"

export namespace utilAws {
	const config = new AWS.Config({
		accessKeyId: globalConfig.aws.accessKeyId,
		secretAccessKey: globalConfig.aws.secretAccessKey,
		region: globalConfig.aws.region,
	})

	AWS.config.update(config)

	//--------------------------------------------------------------------------------------------
	//Upload file
	//--------------------------------------------------------------------------------------------

	export const uploadFile_WithData = async (
		key: string,
		dataURL: string,
		folder: globalAssets.awsFolders,
		fileNameWithoutExtension: string,
		fileExtension: string
	) => {
		try {
			const file = utilFunctionsFiles.file_FromDataUrl(dataURL, `${fileNameWithoutExtension}.${fileExtension}`)
			if (!file) throw Error
			return await uploadFile_WithFile(key, file, folder, fileNameWithoutExtension, fileExtension)
		} catch (err) {
			const errMensajeAmistoso = "Ha ocurrido un error al subir la imagen a AWS.."
			utilLogger.error(errMensajeAmistoso, err)
			bllDispatchs_GlobalsServer.setErrorMessage(key, errMensajeAmistoso)
			return null
		}
	}

	export const uploadFile_WithFile = (
		key: string,
		file: File,
		folder: globalAssets.awsFolders,
		fileNameWithoutExtension: string,
		fileExtension: string
	) => {
		//Inicio el loading. Se supone que el servidor lo termina
		bllDispatchs_GlobalsServer.setLoading(key, true)

		const folderAndFile = globalAssets.aws_GetFolderAndFile(folder, fileNameWithoutExtension, fileExtension)

		const upload = new AWS.S3.ManagedUpload({
			params: {
				Bucket: globalConfig.aws.bucket,
				Key: folderAndFile,
				Body: file,
			},
		})

		const uploadPromise = upload.promise()

		return uploadPromise.then(
			function (data) {
				const fileUploadedUrl = globalAssets.aws_GetUrl(folderAndFile)
				bllDispatchs_GlobalsServer.setLoading(key, false)
				return fileUploadedUrl
			},
			function (err: any) {
				const errMensajeAmistoso = "Ha ocurrido un error al subir la imagen a AWS."
				utilLogger.error(errMensajeAmistoso, err)
				bllDispatchs_GlobalsServer.setErrorMessage(key, errMensajeAmistoso)
				return null
			}
		)
	}
	//--------------------------------------------------------------------------------------------

	//--------------------------------------------------------------------------------------------
	//AnalisisDniFrente
	//--------------------------------------------------------------------------------------------
	export interface IAnalisisDniFrente {
		dni: string
	}

	export const analisisDniFrenteDefault: IAnalisisDniFrente = {
		dni: "",
	}

	export const getInfoFromDniFrente = (key: string, fileWithExtension: string, folder: globalAssets.awsFolders) => {
		bllDispatchs_GlobalsServer.setLoading(key, true)
		const textractClient = new TextractClient()

		var flagParsed = false
		const twoDigitCurrentYear = Number(new Date().getFullYear().toString().substring(2, 2))

		// Definir el objeto de solicitud de análisis de documento
		const params = {
			Document: {
				S3Object: {
					Bucket: globalConfig.aws.bucket,
					Name: `${folder}/${fileWithExtension}`,
				},
			},
			FeatureTypes: ["FORMS"],
		}

		const textractPromise = textractClient.analyzeDocument(params).promise()

		return textractPromise.then(
			function (data) {
				try {
					var analisisDni: IAnalisisDniFrente = analisisDniFrenteDefault
					// Procesar los resultados del análisis
					if (data.Blocks) {
						data.Blocks.forEach((block, i) => {
							if (!flagParsed && block.BlockType === "LINE" && data.Blocks) {
								if (block.Text) {
									if (block.Text?.toLowerCase().includes("dni")) {
										const dni = data.Blocks[i + 1].Text || ""
										analisisDni.dni = dni
										flagParsed = true
									}
								}

								console.log("\n==========================")
								console.log(block.Text)
								console.log(block.EntityTypes)
								console.log(block.Confidence)
								console.log("==========================")
							}
						})
					}

					if (flagParsed) {
						bllDispatchs_GlobalsServer.setLoading(key, false)
						bllDispatchs_GlobalsServer.setErrorMessage(key, "")

						return analisisDni
					} else {
						throw new Error("No se pudo parsear el DNI.")
					}
				} catch (err) {
					const errMensajeAmistoso = "No se pudo obtener la información del DNI, intente con una imagen más nítida."
					utilLogger.error(errMensajeAmistoso, err)
					bllDispatchs_GlobalsServer.setLoading(key, false)
					bllDispatchs_GlobalsServer.setErrorMessage(key, errMensajeAmistoso)
				}
			},
			function (err: any) {
				const errMensajeAmistoso = "Ha ocurrido un error al analizar el DNI."
				utilLogger.error(errMensajeAmistoso, err)
				bllDispatchs_GlobalsServer.setLoading(key, false)
				bllDispatchs_GlobalsServer.setErrorMessage(key, errMensajeAmistoso)
				return null
			}
		)
	}
	//--------------------------------------------------------------------------------------------

	//--------------------------------------------------------------------------------------------
	//AnalisisDniDorso
	//--------------------------------------------------------------------------------------------
	export interface IAnalisisDniDorso {
		fechaNacimiento: Date | null
		sexo: sharedTipoSexo.IShared
		nombre: string
		apellido1: string
		apellido2: string
		dni: string
		domicilio: string
	}

	export const analisisDniDorsoDefault: IAnalisisDniDorso = {
		fechaNacimiento: null,
		sexo: sharedTipoSexo.sharedEnum.noBinario,
		nombre: "",
		apellido1: "",
		apellido2: "",
		dni: "",
		domicilio: "",
	}

	export const getInfoFromDniDorso = (key: string, fileWithExtension: string, folder: globalAssets.awsFolders) => {
		bllDispatchs_GlobalsServer.setLoading(key, true)
		const textractClient = new TextractClient()

		var flagParsed = false
		const twoDigitCurrentYear = Number(new Date().getFullYear().toString().substring(2, 2))

		// Definir el objeto de solicitud de análisis de documento
		const params = {
			Document: {
				S3Object: {
					Bucket: globalConfig.aws.bucket,
					Name: `${folder}/${fileWithExtension}`,
				},
			},
			FeatureTypes: ["FORMS"],
		}

		const textractPromise = textractClient.analyzeDocument(params).promise()

		return textractPromise.then(
			function (data) {
				try {
					var analisisDni: IAnalisisDniDorso = analisisDniDorsoDefault
					// Procesar los resultados del análisis
					if (data.Blocks) {
						data.Blocks.forEach((block, i) => {
							if (!flagParsed && block.BlockType === "LINE" && data.Blocks) {
								if (block.Text) {
									if (block.Text?.toLowerCase().includes("domicilio")) {
										const domicilio1 = data.Blocks[i + 1].Text || ""
										const domicilio2 = data.Blocks[i + 2].Text || ""
										analisisDni.domicilio = domicilio1 + ", " + domicilio2
									}
								}

								//Solo hago esto una vez y ya seteo el flagParsed en true
								if ((data.Blocks[i].Text?.length || 0) >= 28) {
									if ((data.Blocks[i + 1].Text?.length || 0) >= 28) {
										if ((data.Blocks[i + 2].Text?.length || 0) >= 28) {
											const ocrLine1 = data.Blocks[i].Text || ""
											const ocrLine2 = data.Blocks[i + 1].Text || ""
											const ocrLine3 = data.Blocks[i + 2].Text || ""

											//-------------------------------------------------------
											analisisDni.dni = ocrLine1.substring(15, 24)
											//-------------------------------------------------------
											const fechaNacimientoAAMMDD = ocrLine2.substring(0, 6)

											// Extraer los componentes de la fecha del string
											const yearNumber = Number(fechaNacimientoAAMMDD.substring(0, 2))
											var year = Number(`19${yearNumber}`)
											if (yearNumber < twoDigitCurrentYear) {
												year = Number(`20${yearNumber}`)
											}
											const month = Number(fechaNacimientoAAMMDD.substring(2, 4)) - 1 // Restar 1 porque los meses en Date empiezan en 0 (enero = 0, febrero = 1, etc.)
											const day = Number(fechaNacimientoAAMMDD.substring(4, 6))

											analisisDni.fechaNacimiento = new Date(year, month, day)
											//-------------------------------------------------------
											const sexoLetra = ocrLine2.substring(7, 8)
											if (sexoLetra === "F") analisisDni.sexo = sharedTipoSexo.sharedEnum.mujer
											if (sexoLetra === "M") analisisDni.sexo = sharedTipoSexo.sharedEnum.hombre
											//-------------------------------------------------------
											const nameParts = ocrLine3.split("<")
											// console.log("nameParts", nameParts)
											analisisDni.apellido1 = nameParts[0]
											analisisDni.apellido2 = nameParts[1]
											analisisDni.nombre = nameParts[3]
											//-------------------------------------------------------

											flagParsed = true
										}
									}
								}

								console.log("\n==========================")
								console.log(block.Text)
								console.log(block.EntityTypes)
								console.log(block.Confidence)
								console.log("==========================")
							}
						})
					}

					if (flagParsed) {
						bllDispatchs_GlobalsServer.setLoading(key, false)
						bllDispatchs_GlobalsServer.setErrorMessage(key, "")

						return analisisDni
					} else {
						throw new Error("No se pudo parsear el DNI.")
					}
				} catch (err) {
					const errMensajeAmistoso = "No se pudo obtener la información del DNI, intente con una imagen más nítida."
					utilLogger.error(errMensajeAmistoso, err)
					bllDispatchs_GlobalsServer.setLoading(key, false)
					bllDispatchs_GlobalsServer.setErrorMessage(key, errMensajeAmistoso)
				}
			},
			function (err: any) {
				const errMensajeAmistoso = "Ha ocurrido un error al analizar el DNI."
				utilLogger.error(errMensajeAmistoso, err)
				bllDispatchs_GlobalsServer.setLoading(key, false)
				bllDispatchs_GlobalsServer.setErrorMessage(key, errMensajeAmistoso)
				return null
			}
		)
	}
	//--------------------------------------------------------------------------------------------

	// export const uploadDni_AndGetInfo2 = () => {
	// 	const textractClient = new TextractClient()

	// 	var flagParsed = false
	// 	const twoDigitCurrentYear = Number(new Date().getFullYear().toString().substring(2, 2))

	// 	// Definir el objeto de solicitud de análisis de documento
	// 	const params = {
	// 		Document: {
	// 			S3Object: {
	// 				Bucket: globalConfig.aws.bucket,
	// 				Name: "analisisdn/dnid.png",
	// 			},
	// 		},
	// 		FeatureTypes: ["FORMS"],
	// 	}

	// 	// Realizar la solicitud de análisis de documento
	// 	textractClient.analyzeDocument(params, (err, data) => {
	// 		if (err) {
	// 			console.log(err, err.stack)
	// 		} else {
	// 			var analisisDni: IAnalisisDni = analisisDniDefault
	// 			// Procesar los resultados del análisis
	// 			if (data.Blocks) {
	// 				data.Blocks.forEach((block, i) => {
	// 					if (!flagParsed && block.BlockType === "LINE" && data.Blocks) {
	// 						if (block.Text) {
	// 							if (block.Text?.toLowerCase().includes("domicilio")) {
	// 								const domicilio1 = data.Blocks[i + 1].Text || ""
	// 								const domicilio2 = data.Blocks[i + 2].Text || ""
	// 								analisisDni.domicilio = domicilio1 + ", " + domicilio2
	// 							}
	// 						}

	// 						//Solo hago esto una vez y ya seteo el flagParsed en true
	// 						if (data.Blocks[i].Text?.length === 30) {
	// 							if (data.Blocks[i + 1].Text?.length === 30) {
	// 								if (data.Blocks[i + 2].Text?.length === 30) {
	// 									const ocrLine1 = data.Blocks[i].Text || ""
	// 									const ocrLine2 = data.Blocks[i + 1].Text || ""
	// 									const ocrLine3 = data.Blocks[i + 2].Text || ""

	// 									//-------------------------------------------------------
	// 									analisisDni.dni = ocrLine1.substring(15, 24)
	// 									//-------------------------------------------------------
	// 									const fechaNacimientoAAMMDD = ocrLine2.substring(0, 6)

	// 									// Extraer los componentes de la fecha del string
	// 									const yearNumber = Number(fechaNacimientoAAMMDD.substring(0, 2))
	// 									var year = Number(`19${yearNumber}`)
	// 									if (yearNumber < twoDigitCurrentYear) {
	// 										year = Number(`20${yearNumber}`)
	// 									}
	// 									const month = Number(fechaNacimientoAAMMDD.substring(2, 4)) - 1 // Restar 1 porque los meses en Date empiezan en 0 (enero = 0, febrero = 1, etc.)
	// 									const day = Number(fechaNacimientoAAMMDD.substring(4, 6))

	// 									console.log(year)
	// 									console.log(month)
	// 									console.log(day)

	// 									analisisDni.fechaNacimiento = new Date(year, month, day)
	// 									//-------------------------------------------------------
	// 									const sexoLetra = ocrLine2.substring(7, 8)
	// 									if (sexoLetra === "F") analisisDni.sexo = sharedTipoSexo.sharedEnum.mujer
	// 									if (sexoLetra === "M") analisisDni.sexo = sharedTipoSexo.sharedEnum.hombre
	// 									//-------------------------------------------------------
	// 									const nameParts = ocrLine3.split("<")
	// 									console.log("nameParts", nameParts)
	// 									analisisDni.apellido1 = nameParts[0]
	// 									analisisDni.apellido2 = nameParts[1]
	// 									analisisDni.nombre = nameParts[3]
	// 									//-------------------------------------------------------

	// 									flagParsed = true
	// 								}
	// 							}
	// 						}

	// 						// console.log("\n==========================")
	// 						// console.log(block.Text)
	// 						// console.log(block.EntityTypes)
	// 						// console.log(block.Confidence)
	// 						// console.log("==========================")
	// 					}
	// 				})
	// 			}

	// 			console.log("analisisDni", analisisDni)
	// 		}
	// 	})
	// }
}
