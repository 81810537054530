/* eslint-disable react/jsx-pascal-case*/
import { IconButton, InputAdornment, InputBase, InputBaseProps, Stack } from "@mui/material"
import { useState } from "react"
import useChat from "../../hooksData/useChat"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import useGeneral from "../../hooksData/useGeneral"
import useGlobalsUser from "../../hooksData/useGlobalsUser"

interface Props extends InputBaseProps {
	onSend: (message: string) => void
}

export default function Chat_Room_Input({ disabled, onSend, sx, ...other }: Props) {
	const ug = useGeneral("Chat_Room_Input")
	const { currentPersona } = useGlobalsUser()

	const { currentRoom_sharedChatRoom } = useChat()

	const [message, set_message] = useState("")

	const inputDisabled = disabled || !currentRoom_sharedChatRoom

	const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			sendMessage()
		}
	}

	const sendMessage = () => {
		if (currentRoom_sharedChatRoom && message) {
			onSend(message)
		}
		set_message("")
	}

	var inputDescText = "Escribe un mensaje"
	if (inputDisabled && ug.userIsLogged) {
		inputDescText = "Selecciona una conversación para enviar mensajes"
	}

	return (
		<>
			<InputBase
				value={message}
				disabled={inputDisabled}
				onKeyUp={handleSend}
				onChange={(event) => set_message(event.target.value)}
				placeholder={inputDescText}
				startAdornment={
					<InputAdornment position="start">
						<SharedBasicIconify iconifyIcon="material-symbols:short-text" />
					</InputAdornment>
				}
				endAdornment={
					<Stack direction="row" spacing={1} sx={{ flexShrink: 0, mr: 1.5 }}>
						<IconButton disabled={inputDisabled} size="small" onClick={sendMessage}>
							<SharedBasicIconify iconifyIcon="material-symbols:send-outline" />
						</IconButton>
					</Stack>
				}
				sx={{
					pl: 1,
					height: 56,
					flexShrink: 0,
					borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
					...sx,
				}}
				{...other}
			/>
		</>
	)
}
