/* eslint-disable react/jsx-pascal-case*/
import { IconifyIcon } from "@iconify/react"
import {
	alpha,
	Collapse,
	List,
	ListItemButton,
	ListItemButtonProps,
	ListItemIcon,
	ListItemText,
	styled,
} from "@mui/material"
import { useEffect, useState } from "react"
import { globalPathsApp } from "../../globals/globalPathsApp"
import useRouting from "../../hooks/useRouting"
import { styleConfig } from "../../Style/styleConfig"
import SharedBasicIconify from "../Shared/Basic/SharedBasicIconify"
import { ISharedTabs_Tab } from "../Shared/Basic/SharedBasicTabs"
import { Layout_LeftMenu_Navbar_TabsCollapsable_Item } from "./Layout_LeftMenu_Navbar_TabsCollapsable_Item"

interface ListItemStyleProps extends ListItemButtonProps {
	active?: boolean
}

const ListItemButtonStyled = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== "active",
})<ListItemStyleProps>(({ active, theme }) => ({
	...theme.typography.body2,
	position: "relative",
	height: 40,
	textTransform: "capitalize",
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(1.5),
	marginBottom: theme.spacing(0.5),
	fontSize: "14px",
	color: theme.palette.text.secondary,
	borderRadius: theme.shape.borderRadius,
	width: styleConfig.leftMenuWidth - 25,
	// active
	...(active && {
		...theme.typography.subtitle2,
		color: theme.palette.primary.main,
		backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
	}),
}))

const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
	whiteSpace: "nowrap",
	overflow: "hidden",
}))

interface IProps {
	iconifyIcon?: IconifyIcon | string
	customIcon?: React.ElementType
	appPagePath: globalPathsApp.pagesEnum
	title: string
	tabs: ISharedTabs_Tab[]
}

export function Layout_LeftMenu_Navbar_TabsCollapsable({ iconifyIcon, customIcon, appPagePath, title, tabs }: IProps) {
	const { page } = useRouting()

	const [open, setOpen] = useState(false)

	const handleClick = () => {
		setOpen(!open)
	}

	const active = appPagePath === page

	useEffect(() => {
		setOpen(active)
	}, [page])

	return (
		<>
			{/* <SharedLinkInsideAppWrapper appPagePath={appPagePath} parameters={appPathParameters}   */}
			<ListItemButtonStyled active={active} onClick={handleClick}>
				<ListItemIcon>
					<SharedBasicIconify iconifyIcon={iconifyIcon} customIcon={customIcon} width={20} height={20} />
				</ListItemIcon>
				<ListItemTextStyled /*disableTypography*/ primary={title} />
				<SharedBasicIconify
					iconifyIcon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
					width={20}
					height={20}
				/>
			</ListItemButtonStyled>
			{/* </SharedLinkInsideAppWrapper> */}

			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{tabs.map((tab) => {
						return (
							<Layout_LeftMenu_Navbar_TabsCollapsable_Item
								key={tab.name}
								appPagePath={appPagePath}
								iconifyIcon={tab.iconifyIcon}
								customIcon={tab.customIcon}
								tabName={tab.name}
								title={tab.label}
								urlDest={tab.urlDest}
							/>
						)
					})}
				</List>
			</Collapse>
		</>
	)
}
