import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { sharedChatMessage } from "../../api/shared/sharedChatMessage"
import { sharedChatRoom } from "../../api/shared/sharedChatRoom"
import { bllChat_All } from "../../bll/Chat/bllChat_All"
import { IState } from "../../bll/bllReducers"
import { globalConfig } from "../../globals/globalConfig"
import useGeneral from "../../hooksData/useGeneral"
import useGlobalsUser from "../../hooksData/useGlobalsUser"
import { utilLogger } from "../../utils/utilLogger"
import { ContextChat } from "./ContextChat"
import useChat from "../../hooksData/useChat"
import { utilApiCalls } from "../../utils/utilApiCalls"

const connectionLoggingLevel = globalConfig.environment !== "production" ? LogLevel.Information : LogLevel.None

export const ContextChatProvider: React.FC = ({ children }) => {
	const { apiCallParams, D, userToken } = useGeneral("useChatSocket")
	const { currentPersona } = useGlobalsUser()
	// const secondLoadFinished = useSelector((state: IState) => state.GlobalsGeneral.InitialLoad.secondLoadFinished)

	// const { user_token, user_bdCode } = useGlobalsUser()

	const { sharedChatRooms, currentRoom_sharedChatRoom, idChatRoom_FromUrl, loadRoomsFinished, loadMessagesFinished } =
		useChat()

	const [connection, set_connection] = useState<HubConnection | null>(null)
	const [isChatConnected, set_isChatConnected] = useState(false)
	const [headersAsQueryParameters, set_headersAsQueryParameters] = useState("")

	useEffect(() => {
		if (connection && connection.state === "Connected") {
			utilLogger.two("useChatSocket", "connection already connected")

			connection.off("ReceiveMessage")

			connection.on("ReceiveMessage", (user, socketMessage) => {
				const chatMessage = sharedChatMessage.getSharedOne_FromApiObject(socketMessage.chatMessage)
				const chatRoom = sharedChatRoom.getSharedOne_FromApiObject(socketMessage.chatRoom)

				D(bllChat_All.addChatMessage(chatMessage))
				D(bllChat_All.addOrUpdateChatRoom(chatRoom))
			})
		}
	}, [sharedChatRooms, connection, apiCallParams, isChatConnected, D])

	useEffect(() => {
		// if (user_token) {
		set_headersAsQueryParameters(
			utilApiCalls.getHeadersAsQueryParameters({
				// userToken: user_token,
				// bdCode: user_bdCode,
			})
		)
		// }
	}, [])

	const sendMessage = (message: string) => {
		if (connection && connection.state === "Connected" && currentRoom_sharedChatRoom) {
			connection.invoke("SendMessage", {
				idPerson1: currentPersona.id,
				idRoom: currentRoom_sharedChatRoom.id,
				message,
			})
		}
	}

	const sendMultipleMessagesFromEntity = (
		idEntity: number,
		idPerson: number[],
		idChatType: number,
		message: string
	) => {
		if (connection && connection.state === "Connected") {
			connection.invoke("SendMultipleMessagesFromEntity", {
				idEntity,
				idPerson,
				idChatType,
				message,
			})
		}
	}

	const createConnection = (headersAsQueryParameters: string, _userToken: string) => {
		if (!_userToken) return
		const newConnection = new HubConnectionBuilder()
			// .withUrl(`${globalConfig.apiPathBaseGeneral}/ChatHub${headersAsQueryParameters}`)
			// .withUrl(`${globalConfig.apiPathBaseGeneral}/ChatHub`)
			//with url and add token
			.withUrl(`${globalConfig.apiPathBaseGeneral}/ChatHub`, {
				accessTokenFactory: () => {
					return _userToken
				},
			})
			.configureLogging(connectionLoggingLevel)
			.build()

		// hubConnection = new HubConnectionBuilder()
		//   .WithUrl(NavigationManager.ToAbsoluteUri("/broadcaster"), options =>
		//   {
		//       options.AccessTokenProvider = async () =>
		//       {
		//           var accessTokenResult = await AccessTokenProvider.RequestAccessToken();
		//           accessTokenResult.TryGetToken(out var accessToken);
		//           return accessToken.Value;
		//       };
		//   })
		//   .Build();

		set_connection(newConnection)
	}

	const connect_ifItIsDisconnected = (connection: HubConnection | null, idUserPerson: number) => {
		utilLogger.two(connection, "connection")
		if (connection && connection.state === "Disconnected") {
			connection
				.start()
				.then(() => {
					utilLogger.two("", "Connected!")

					set_isChatConnected(true)
					connection.invoke("Join", { idPerson: idUserPerson })

					connection.onclose((e) => {
						utilLogger.two(e, "Connection closed!")
						set_isChatConnected(false)
					})
				})
				.catch((e) => {
					utilLogger.two(e, "Error al conectar con el socket")
				})
		}
	}

	const disconnect = () => {
		if (connection && connection.state === "Connected") {
			connection
				.stop()
				.then(() => {
					utilLogger.two("", "Disconnected!")
					set_isChatConnected(false)
				})
				.catch((e) => {
					utilLogger.two(e, "Error al desconectar con el socket")
				})
		}
	}

	useEffect(() => {
		if (loadRoomsFinished && loadMessagesFinished) {
			connect_ifItIsDisconnected(connection, currentPersona.id)
		}
	}, [connection, currentPersona, loadRoomsFinished, loadMessagesFinished])

	useEffect(() => {
		if (!isChatConnected /*&& secondLoadFinished*/ && headersAsQueryParameters !== "f") {
			createConnection(headersAsQueryParameters, userToken)
		}

		return () => {
			disconnect()
		}
	}, [isChatConnected, /*secondLoadFinished,*/ headersAsQueryParameters, userToken])

	return (
		<ContextChat.Provider value={{ isChatConnected, sendMessage, sendMultipleMessagesFromEntity }}>
			{children}
		</ContextChat.Provider>
	)
}
