import { useSelector } from "react-redux"
import { IState } from "../bll/bllReducers"

export default function useNotificacionesAll() {
	const last20Notificaciones = useSelector((state: IState) => state.Notificaciones.All.last20)

	return {
		last20Notificaciones,
	}
}
