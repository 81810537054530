import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedOrganizador } from "../../../shared/sharedOrganizador"

export namespace apiCallIG_Organizadores_ActualizarGeneralFields {
	export interface params {
		idOrganizador: number
		nombre: string
	}
	export const paramsName = {
		idOrganizador: "idOrganizador",
		nombre: "nombre",
	}
	export const paramsDesc = {
		idOrganizador: "Id Organizador",
		nombre: "Nombre",
	}

	export interface IRes {
		sharedOrganizador: sharedOrganizador.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//nombre
		apiValidations2.validateMaxNumberOfChars(
			paramsName.nombre,
			paramsDesc.nombre,
			params.nombre,
			100,
			serverModelErrors
		)

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Organizadores/ActualizarGeneralFields`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedOrganizador: sharedOrganizador.getSharedOne_FromApiObject(apiCallRes) }
	}
}
