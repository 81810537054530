/* eslint-disable react/jsx-pascal-case*/
import { Card, CardHeader, Container, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import useServerState from "../../../hooks/useServerState"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import OrganizadoresShared_Table from "./OrganizadoresShared_Table"
import OrganizadoresShared_CardList from "./OrganizadoresShared_CardList"
import useGeneral from "../../../hooksData/useGeneral"
import SharedFormTextFieldString from "../../Shared/Form/SharedFormTextFieldString"

interface IProps {}

export default function OrganizadoresShared_AllOrganizadores({}: IProps) {
	const ug = useGeneral("OrganizadoresShared_AllOrganizadores")

	const organizadores = useSelector((state: IState) => state.GlobalsGeneralData.AllOrganizadores.organizadores)

	const [filtro, set_filtro] = useState<string | null>(null)

	const organizadoresFiltered = filtro
		? organizadores.filter((organizador) => organizador.nombre.toLowerCase().includes(filtro.toLowerCase()))
		: organizadores

	return (
		<Container sx={{ position: "relative" }}>
			<Card>
				{/* <CardHeader title="Organizadores" sx={{ mb: 3 }} />
				<OrganizadoresGlobals_Table
					organizadores={organizadores}
					showAll={showAll}
					showAllAppPathPage={globalPathsApp.pagesEnum.OrganizadoresLists}
					showAllPathParameters={globalPathsApp.pageParameters.OrganizadoresLists("allCircuits")}
				/> */}

				<Stack sx={{ m: 2 }}>
					<SharedFormTextFieldString
						callerKey={ug.key}
						fieldName={"Filtro"}
						label={"Filtro"}
						value={filtro}
						handleOnChangeProp={set_filtro}
						triggerOnEveryKeyPress={true}
					/>
				</Stack>

				<OrganizadoresShared_CardList organizadores={organizadoresFiltered} />
			</Card>
		</Container>
	)
}
