import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { sharedCircuitoEstado } from "./sharedCircuitoEstado"
import { sharedCircuitoPista } from "./sharedCircuitoPista"
import { sharedCircuitoValoracion } from "./sharedCircuitoValoracion"
import { sharedDireccion } from "./sharedDireccion"

export namespace sharedCircuitoFecha {
	export interface IShared {
		id: number
		idCircuito: number
		nroOrden: number | null
		tipoAcontecimiento: string
		fechaInicio: Date | null
		fechaFin: Date | null
		lunesHoraInicio1: Date | null
		lunesHoraFin1: Date | null
		lunesHoraInicio2: Date | null
		lunesHoraFin2: Date | null
		martesHoraInicio1: Date | null
		martesHoraFin1: Date | null
		martesHoraInicio2: Date | null
		martesHoraFin2: Date | null
		miercolesHoraInicio1: Date | null
		miercolesHoraFin1: Date | null
		miercolesHoraInicio2: Date | null
		miercolesHoraFin2: Date | null
		juevesHoraInicio1: Date | null
		juevesHoraFin1: Date | null
		juevesHoraInicio2: Date | null
		juevesHoraFin2: Date | null
		viernesHoraInicio1: Date | null
		viernesHoraFin1: Date | null
		viernesHoraInicio2: Date | null
		viernesHoraFin2: Date | null
		sabadoHoraInicio1: Date | null
		sabadoHoraFin1: Date | null
		sabadoHoraInicio2: Date | null
		sabadoHoraFin2: Date | null
		domingoHoraInicio1: Date | null
		domingoHoraFin1: Date | null
		domingoHoraInicio2: Date | null
		domingoHoraFin2: Date | null
		descripcion: string | null
	}

	export const defaultObject: IShared = {
		id: 0,
		idCircuito: 0,
		nroOrden: null,
		tipoAcontecimiento: "",
		fechaInicio: null,
		fechaFin: null,
		lunesHoraInicio1: null,
		lunesHoraFin1: null,
		lunesHoraInicio2: null,
		lunesHoraFin2: null,
		martesHoraInicio1: null,
		martesHoraFin1: null,
		martesHoraInicio2: null,
		martesHoraFin2: null,
		miercolesHoraInicio1: null,
		miercolesHoraFin1: null,
		miercolesHoraInicio2: null,
		miercolesHoraFin2: null,
		juevesHoraInicio1: null,
		juevesHoraFin1: null,
		juevesHoraInicio2: null,
		juevesHoraFin2: null,
		viernesHoraInicio1: null,
		viernesHoraFin1: null,
		viernesHoraInicio2: null,
		viernesHoraFin2: null,
		sabadoHoraInicio1: null,
		sabadoHoraFin1: null,
		sabadoHoraInicio2: null,
		sabadoHoraFin2: null,
		domingoHoraInicio1: null,
		domingoHoraFin1: null,
		domingoHoraInicio2: null,
		domingoHoraFin2: null,
		descripcion: null,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
		}

		return res
	}
}
