/* eslint-disable react/jsx-pascal-case*/
import { Container, Grid } from "@mui/material"
import AdminNotificaciones_General_Form from "./AdminNotificaciones_General_Form"

export default function AdminNotificaciones_General() {
	return (
		<>
			<Container maxWidth="md" disableGutters sx={{ mx: 0 }}>
				<Grid container spacing={2} direction="row">
					<AdminNotificaciones_General_Form />
				</Grid>
			</Container>
		</>
	)
}
