import { globalAssets } from "../../globals/globalAssets"
import { globalInterfaces } from "../../globals/globalInterfaces"
import { sharedEstado } from "./sharedEstado"
import { sharedDireccion } from "./sharedDireccion"
import { sharedOrganizadoresPosteos } from "./sharedOrganizadoresPosteos"

export namespace sharedOrganizador {
	export interface IShared {
		id: number
		idPersonaCreadora: number
		nombre: string
		avatarURL: string
		coverURL: string
		descripcionCorta: string
		descripcionLarga: string
		email: string
		telefonoMovil: string
		linkInstagram: string
		linkFacebook: string
		linkLinkedIn: string
		linkTwitter: string
		posteos: sharedOrganizadoresPosteos.IShared[]
		cantidadSeguidores: number
		idEstado: number
	}

	export const defaultObject: IShared = {
		id: 0,
		idPersonaCreadora: 0,
		nombre: "",
		avatarURL: globalAssets.staticPaths.organizadorAvatar,
		coverURL: globalAssets.staticPaths.organizadorCover1,
		descripcionCorta: "",
		descripcionLarga: "",
		email: "",
		telefonoMovil: "",
		linkInstagram: "",
		linkFacebook: "",
		linkLinkedIn: "",
		linkTwitter: "",
		posteos: [],
		cantidadSeguidores: 0,
		idEstado: sharedEstado.sharedEnum.creado.id,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...defaultObject,
			...apiObject,
			avatarURL: apiObject.avatarURL || globalAssets.staticPaths.organizadorAvatar,
			coverURL: apiObject.coverURL || globalAssets.staticPaths.organizadorCover1,
			posteos: apiObject.posteos && sharedOrganizadoresPosteos.getSharedList_FromApiObject(apiObject.posteos),
			cantidadSeguidores: apiObject.cantidadSeguidores || 0,
		}

		return res
	}

	export const listForRadioGroup = (sharedOrganizadorList: IShared[]): globalInterfaces.IValueNumberLabelString[] => {
		return sharedOrganizadorList.map((sharedOrganizador) => {
			return {
				value: sharedOrganizador.id,
				label: sharedOrganizador.nombre,
			}
		})
	}
}
