import { utilDate } from "../../utils/utilDate"

export namespace sharedPosteosComentarios {
	export interface IShared {
		id: number
		idPosteo: number
		idPersonaComenta: number
		textoComentario: string
		fechaComentario: Date | null
		nombrePersonaComenta: string | null
		avatarUrlPersonaComenta: string | null
	}

	export const objectDefault: IShared = {
		id: 0,
		idPosteo: 0,
		idPersonaComenta: 0,
		textoComentario: "",
		fechaComentario: null,
		nombrePersonaComenta: "",
		avatarUrlPersonaComenta: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
			fechaComentario: utilDate.fDateFromNet(apiObject.fechaComentario),
		}

		return res
	}
}
