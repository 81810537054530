import { globalConfig } from "./globalConfig"

export namespace globalAssets {
	//TODO: en desa, estos assets ponerlos locales

	export const staticPaths = {
		//background
		backgroundOverlay2: globalConfig.staticsPathsBaseUrl + "backgroundOverlay2.jpg",
		backgroundOverlay3: globalConfig.staticsPathsBaseUrl + "backgroundOverlay3.jpg",
		backgroundOverlay4: globalConfig.staticsPathsBaseUrl + "backgroundOverlay4.jpg",

		//circuitos
		circuitoHero: globalConfig.staticsPathsBaseUrl + "circuitosHero.jpg",
		circuitoIcono: globalConfig.staticsPathsBaseUrl + "circuitoIcono.svg",
		circuitoAvatar1: globalConfig.staticsPathsBaseUrl + "circuitoAvatar1.jpg",
		circuitoCover1: globalConfig.staticsPathsBaseUrl + "circuitoCover1.jpg",

		//escuelas
		escuelaHero: globalConfig.staticsPathsBaseUrl + "escuelaHero.jpg",
		escuelaAvatar1: globalConfig.staticsPathsBaseUrl + "escuelaAvatar1.jpg",
		escuelaCover1: globalConfig.staticsPathsBaseUrl + "escuelaCover1.jpg",

		//empresas
		empresaHero: globalConfig.staticsPathsBaseUrl + "empresaHero.jpg",
		empresaAvatar1: globalConfig.staticsPathsBaseUrl + "empresaAvatar1.jpg",
		empresaCover1: globalConfig.staticsPathsBaseUrl + "empresaCover1.jpg",

		//motoclubs
		motoclubHero: globalConfig.staticsPathsBaseUrl + "motoclubHero.jpg",
		motoclubAvatar1: globalConfig.staticsPathsBaseUrl + "motoclubAvatar1.jpg",
		motoclubCover1: globalConfig.staticsPathsBaseUrl + "motoclubCover1.jpg",

		//federaciones
		federacionHero: globalConfig.staticsPathsBaseUrl + "federacionHero.jpg",
		federacionAvatar1: globalConfig.staticsPathsBaseUrl + "federacionAvatar1.jpg",
		federacionCover1: globalConfig.staticsPathsBaseUrl + "federacionCover1.jpg",

		//organizadores
		organizadorHero: globalConfig.staticsPathsBaseUrl + "organizadorHero.jpg",
		organizadorAvatar: globalConfig.staticsPathsBaseUrl + "organizadorAvatar.jpg",
		organizadorCover1: globalConfig.staticsPathsBaseUrl + "organizadorCover1.jpg",

		//logo
		logo: globalConfig.staticsPathsBaseUrl + "logo.png",
		logoA: globalConfig.staticsPathsBaseUrl + "logoA.png",
		logomotopoliza2: globalConfig.staticsPathsBaseUrl + "logomotopoliza2.png",
		//minimal
		minimalLogin: globalConfig.staticsPathsBaseUrl + "minimalLogin.png",
		minimalRegister: globalConfig.staticsPathsBaseUrl + "minimalRegister.png",
		minimalOverlay: globalConfig.staticsPathsBaseUrl + "backgroundOverlay1.svg",
		minimalHero: globalConfig.staticsPathsBaseUrl + "minimalHero.png",
		minimalGrid: globalConfig.staticsPathsBaseUrl + "minimalGrid.png",
		minimalScreenDefault: globalConfig.staticsPathsBaseUrl + "minimalScreenDefault.png",
		minimalBlock1Default: globalConfig.staticsPathsBaseUrl + "minimalBlock1Default.png",
		minimalBlock2Default: globalConfig.staticsPathsBaseUrl + "minimalBlock2Default.png",
		minimalSidebarDefault: globalConfig.staticsPathsBaseUrl + "minimalSidebarDefault.png",
		minimalShape: globalConfig.staticsPathsBaseUrl + "minimalShape.svg",
		minimalLightMode: globalConfig.staticsPathsBaseUrl + "minimalLightMode.png",
		minimalDarkMode: globalConfig.staticsPathsBaseUrl + "minimalDarkMode.png",
		minimalRocket: globalConfig.staticsPathsBaseUrl + "minimalRocket.png",

		//motopoliza
		motopolizaHero: globalConfig.staticsPathsBaseUrl + "motopolizaHero.jpg",

		//personas
		personaAvatar: globalConfig.staticsPathsBaseUrl + "personaAvatar.jpg",
		personaCover1: globalConfig.staticsPathsBaseUrl + "personaCover1.jpg",

		//pilotos
		pilotosHero: globalConfig.staticsPathsBaseUrl + "pilotoHero.jpg",

		//rodadas
		rodadasHero: globalConfig.staticsPathsBaseUrl + "rodadaHero.jpg",
		rodadasAvatar: globalConfig.staticsPathsBaseUrl + "rodadaAvatar.jpg",

		//saludonnet
		saludonnetHero: globalConfig.staticsPathsBaseUrl + "saludonnetHero.jpg",

		//varios
		imagePlaceholder: globalConfig.staticsPathsBaseUrl + "imagePlaceholder.svg",

		//otros
		pilotapPdf: globalConfig.staticsPathsBaseUrl + "pilotap.pdf",
	}

	export enum awsFolders {
		personaAvatar = "PersonaAvatar",
		personaCover = "PersonaCover",
		circuitoAvatar = "CircuitoAvatar",
		circuitoCover = "CircuitoCover",
		empresaAvatar = "EmpresaAvatar",
		empresaCover = "EmpresaCover",
		escuelaAvatar = "EscuelaAvatar",
		escuelaCover = "EscuelaCover",
		motoclubAvatar = "MotoclubAvatar",
		motoclubCover = "MotoclubCover",
		federacionAvatar = "FederacionAvatar",
		federacionCover = "FederacionCover",
		organizadorAvatar = "OrganizadorAvatar",
		organizadorCover = "OrganizadorCover",
		rodadaDisclaimer = "RodadaDisclaimer",
		circuitoPistasDibujo = "CircuitoPistasDibujo",
		rodadaPersonasDisclaimer = "RodadaPersonasDisclaimer",
		analisisDni = "analisisdn",
		firmaAseguradoProtocoloAccidente = "FirmaAseguradoProtocoloAccidente",
		firmaResponsableCircuitoProtocoloAccidente = "FirmaResponsableCircuitoProtocoloAccidente",
		personaVehiculo = "PersonaVehiculo",
		fotosPosteo = "FotosPosteo",
	}

	export const aws_GetFolderAndFile = (folder: awsFolders, fileNameWithoutExtension: string, fileExtension: string) => {
		return `${folder}/${fileNameWithoutExtension}.${fileExtension}`
	}

	export const aws_GetUrl = (folderAndFileName: string) => {
		return `https://${globalConfig.aws.bucket}.s3.${globalConfig.aws.region}.amazonaws.com/${folderAndFileName}`

		//Ejemplo de URL: 	"https://pruebamp.s3.eu-west-3.amazonaws.com/PersonalAvatar/foto1.png",
	}
}
