import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { sharedUserRoles } from "../api/shared/sharedUserRoles"
import { IState } from "../bll/bllReducers"

export default function useGlobalsUserRoles() {
	const userCurrentRol = useSelector((state: IState) => state.GlobalsUser.Roles.currentRol)
	const userRoles = useSelector((state: IState) => state.GlobalsUser.Roles.roles)

	const isUserCurrentRol_Piloto = userCurrentRol.id === sharedUserRoles.sharedEnum.piloto.id
	const isUserCurrentRol_OrganizadorDeCircuito =
		userCurrentRol.id === sharedUserRoles.sharedEnum.organizadorDeCircuito.id
	const isUserCurrentRol_Colaborador = userCurrentRol.id === sharedUserRoles.sharedEnum.colaborador.id
	const isUserCurrentRol_Admin = userCurrentRol.id === sharedUserRoles.sharedEnum.admin.id

	const userHasAdminRol = userRoles.some((userRol) => userRol.id === sharedUserRoles.sharedEnum.admin.id)

	return {
		userCurrentRol,
		userRoles,
		isUserCurrentRol_Piloto,
		isUserCurrentRol_OrganizadorDeCircuito,
		isUserCurrentRol_Colaborador,
		isUserCurrentRol_Admin,
		userHasAdminRol,
	}
}
