import { utilRedux } from "../../utils/utilRedux"
import { bllGlobalsServer_LoadingList } from "../GlobalsServer/bllGlobalsServer_LoadingList"

export namespace bllGlobalsUI_DialogErrorUnexpected {
	//ET = enumTypes
	enum ET {
		open = "GlobalsUI_DialogErrorUnexpected_open",
		close = "GlobalsUI_DialogErrorUnexpected_close",
	}

	export interface IInterface {
		open: boolean
		title: string
		subtitle: string
		excepcion: string
	}

	const getInitialState = (): IInterface => {
		return {
			open: false,
			title: "",
			subtitle: "",
			excepcion: "",
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Update_dialogWorkInProgressOpen:
			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})
			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function open(title: string, subtitle: string, excepcion: string) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "open" | "title" | "subtitle" | "excepcion">
			const updateObject: actionSubType = {
				open: true,
				title: title,
				subtitle: subtitle,
				excepcion: excepcion,
			}

			utilRedux.TA(D, ET.open, updateObject)

			//Detengo todos los loadings del servidor
			D(bllGlobalsServer_LoadingList.stopAllServerLoading())
		}
	}

	export function close() {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "open">
			const updateObject: actionSubType = {
				open: false,
			}

			utilRedux.TA(D, ET.close, updateObject)
		}
	}
}
