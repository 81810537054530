import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedEmpresa } from "../../../shared/sharedEmpresa"

export namespace apiCallIG_Empresas_ActualizarContact {
	export interface params {
		idEmpresa: number
		email: string | null
		telefonoMovil: string | null
	}
	export const paramsName = {
		idEmpresa: "idEmpresa",
		email: "email",
		telefonoMovil: "telefonoMovil",
	}
	export const paramsDesc = {
		idEmpresa: "Id Empresa",
		email: "Dirección de email",
		telefonoMovil: "Teléfono móvil",
	}

	export interface IRes {
		sharedEmpresa: sharedEmpresa.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		if (params.email) {
			apiValidations2.validateMustBeEmail(paramsName.email, paramsDesc.email, params.email, serverModelErrors)
		}

		//telefonoMovil
		if (params.telefonoMovil) {
			apiValidations2.validateMaxNumberOfChars(
				paramsName.telefonoMovil,
				paramsDesc.telefonoMovil,
				params.telefonoMovil,
				250,
				serverModelErrors
			)
		}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Empresas/ActualizarContact`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return { sharedEmpresa: sharedEmpresa.getSharedOne_FromApiObject(apiCallRes) }
	}
}
