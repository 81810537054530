import { apiCallIG_Personas_GetPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersona"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { apiCallInterface } from "../../api/apiCallInterface"
import { sharedSN_CentroUrgencia } from "../../api/shared/SN/sharedSN_CentroUrgencia"
import { apiCallSN_GetHospitalesDeUrgencia } from "../../api/calls/SN/apiCallSN_GetHospitalesDeUrgencia"

export namespace bllSaludonnet_CentrosUrgencias {
	//ET = enumTypes
	enum ET {
		setInitialState = "Saludonnet_CentrosUrgencias_setInitialState",
		load = "Saludonnet_CentrosUrgencias_load",
	}

	export interface IInterface {
		centrosUrgencias: sharedSN_CentroUrgencia.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			centrosUrgencias: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadCentrosUrgencias(apiCallParams: apiCallInterface) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallSN_GetHospitalesDeUrgencia.apiCall(apiCallParams)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "centrosUrgencias">
				const updateObject: actionSubType = {
					centrosUrgencias: apiCallRes.sharedSN_CentrosUrgencias,
				}

				utilRedux.TA(D, ET.load, updateObject)
			}
		}
	}
}
