import { bllDispatchs_GlobalsServer } from "../../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../../globals/globalConfig"
import { apiCallInterface } from "../../../apiCallInterface"
import { apiRegularCall } from "../../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../../apiRegularCall_Ax"
import { apiValidations2 } from "../../../apiValidations2"
import { sharedRodada } from "../../../shared/sharedRodada"

export namespace apiCallIG_Rodadas_CreateRodada {
	export interface params {
		idPersona: number
		idCircuito: number
		idVehiculo: number
		nombre: string
		fechaDesde: Date
		fechaHasta: Date
	}
	export const paramsName = {
		idPersona: "idPersona",
		idCircuito: "idCircuito",
		idVehiculo: "idVehiculo",
		nombre: "nombre",
		fechaDesde: "fechaDesde",
		fechaHasta: "fechaHasta",
	}
	export const paramsDesc = {
		idCircuito: "Circuito",
		idVehiculo: "Vehículo",
		nombre: "Nombre",
		fechaDesde: "Fecha desde",
		fechaHasta: "Fecha hasta",
	}

	export interface IRes {
		sharedRodada: sharedRodada.IShared
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		//nombre
		apiValidations2.validateMaxNumberOfChars(
			paramsName.nombre,
			paramsDesc.nombre,
			params.nombre,
			100,
			serverModelErrors
		)

		apiValidations2.validateDateMinDateMax(
			paramsName.fechaDesde,
			paramsName.fechaHasta,
			paramsDesc.fechaDesde,
			paramsDesc.fechaHasta,
			params.fechaDesde,
			params.fechaHasta,
			serverModelErrors
		)

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.post
		const apiCallUrl = `${globalConfig.apiPathBaseGeneral}/Rodadas/CreateRodada`
		const sharedParamsQuery = null
		const sharedParamsBody: params | null = params

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			sharedRodada: sharedRodada.getSharedOne_FromApiObject(apiCallRes),
		}
	}
}
