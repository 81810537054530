import { sharedUserRoles } from "../../api/shared/sharedUserRoles"
import { utilRedux } from "../../utils/utilRedux"

export namespace bllGlobalsUser_Roles {
	//ET = enumTypes
	enum ET {
		setInitialState = "GlobalsUser_Roles_setInitialState",
		setCurrentRol = "GlobalsUser_Roles_setCurrentRol",
		setRoles = "GlobalsUser_Roles_setRoles",
	}

	export interface IInterface {
		roles: sharedUserRoles.IShared[]
		currentRol: sharedUserRoles.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			roles: [],
			currentRol: sharedUserRoles.sharedEnum.piloto,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_PersonasDeUsuarioLogueado_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function setCurrentRol(currentRol: sharedUserRoles.IShared) {
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "currentRol">
			const updateObject: actionSubType = {
				currentRol: currentRol,
			}

			utilRedux.TA(D, ET.setCurrentRol, updateObject)
		}
	}

	export function setRoles(loginResRoles: string[]) {
		return async (D: any) => {
			var roles: sharedUserRoles.IShared[] = loginResRoles.map((rol) => {
				const sharedUserRol = sharedUserRoles.getShared_FromDbDescription(rol)
				return sharedUserRol
			})

			roles.push(sharedUserRoles.sharedEnum.piloto)

			type actionSubType = Pick<IInterface, "roles">
			const updateObject: actionSubType = {
				roles: roles,
			}

			utilRedux.TA(D, ET.setRoles, updateObject)
		}
	}
}
