import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import SignatureCanvas from "react-signature-canvas"
import { FormHelperText, makeStyles, styled } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { Box } from "@mui/system"
import SharedLabelForForm from "../SharedLabelForForm"
import useServerStateField from "../../../hooks/useServerStateField"
import SharedBasicImage from "../Basic/SharedBasicImage"
import { utilLogger } from "../../../utils/utilLogger"

interface IProps {
	callerKey: string
	fieldName: string
	label: string
	initialImageUrl: string | null
	disabled?: boolean
	handleOnChangeProp(newValue: string | null): void
}

const StyledSignatureCanvas = styled("div")(({ theme }) => {
	return {
		"& .sigCanvas": {
			backgroundColor: " #EEEEEE",
			borderRadius: 10,
		},
	}
})

export default function SharedFormSignature({
	callerKey,
	fieldName,
	label,
	initialImageUrl: initialImageUrlProp,
	disabled = false,
	handleOnChangeProp,
	...other
}: IProps) {
	const ref: any = useRef()
	const serverStateField = useServerStateField(callerKey, fieldName)

	const [initialImageUrl, set_initialImageUrl] = useState<string | null>(null)

	useEffect(() => {
		set_initialImageUrl(initialImageUrlProp)
	}, [initialImageUrlProp])

	useEffect(() => {
		if (ref.current) {
			if (disabled) {
				ref.current.off()
			} else {
				ref.current.on()
			}
		}
	}, [disabled])

	const handleClear = () => {
		handleOnChangeProp(null)
		ref.current.clear()
	}

	const handleOnEnd = () => {
		const newValue = ref.current.toDataURL()

		setTimeout(() => {
			handleOnChangeProp(newValue)
		}, 1)
	}

	return (
		<div>
			{initialImageUrl && (
				<>
					<SharedLabelForForm text={"Firma"} />
					<Box sx={{ maxWidth: "500px", mx: "auto" }}>
						<SharedBasicImage alt="Firma" src={initialImageUrl} sx={{}} />
					</Box>
				</>
			)}

			{!initialImageUrl && (
				<>
					<SharedLabelForForm text={label} />

					<StyledSignatureCanvas>
						<div>
							<SignatureCanvas
								ref={ref}
								penColor="black"
								canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
								onEnd={handleOnEnd}
							/>
						</div>
						<Box>
							<Button onClick={handleClear}>Borrar</Button>
						</Box>

						<FormHelperText error sx={{ px: 2, mt: "0px !important", textAlign: "center" }}>
							{serverStateField.error}
						</FormHelperText>
					</StyledSignatureCanvas>
				</>
			)}
		</div>
	)
}
