/* eslint-disable react/jsx-pascal-case*/

import store from "../../utils/utilReduxStore" //Debe ser el primer import
import { App_RootWrapper_Router } from "./App_RootWrapper_Router"
import { HelmetProvider } from "react-helmet-async"
import MmStyleThemeProvider from "../../MmTheme/MmStyleThemeProvider"
import AnimateMotionLazyContainer from "../Animate/AnimateMotionLazyContainer"
import { Provider, useDispatch, useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import SharedNotistackProvider from "../Shared/SharedNotistackProvider"
import "simplebar/src/simplebar.css"
// editor
import "react-quill/dist/quill.snow.css"
// slick-carousel
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "react-image-gallery/styles/css/image-gallery.css"

import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import esLocale from "date-fns/locale/es"
import { GlobalDialogWorkInProgress } from "../Globals/GlobalDialogWorkInProgress"
import { GlobalDialogErrorUnexpected } from "../Globals/GlobalDialogErrorUnexpected"
import { App_RootWrapper } from "./App_RootWrapper"
import { GlobalFullScreenLoading } from "../Globals/GlobalFullScreenLoading"
import GlobalCookiesConsent from "../Globals/GlobalCookiesConsent"
import { GlobalDialogErrorForbidden } from "../Globals/GlobalDialogErrorForbidden"

export const App = () => {
	return (
		<div>
			<Provider store={store}>
				<HelmetProvider>
					<MmStyleThemeProvider>
						<SharedNotistackProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
								<AnimateMotionLazyContainer>
									<GlobalCookiesConsent>
										<GlobalDialogWorkInProgress />
										<GlobalDialogErrorUnexpected />
										<GlobalDialogErrorForbidden />
										<GlobalFullScreenLoading />
										<App_RootWrapper />
									</GlobalCookiesConsent>
								</AnimateMotionLazyContainer>
							</LocalizationProvider>
						</SharedNotistackProvider>
					</MmStyleThemeProvider>
				</HelmetProvider>
			</Provider>
		</div>
	)
}

//TODO: Ver contextos de la aplicación, es decir, si estoy dentro de la página de motopóliza, o si estoy usando la app de circuitos, etc.

//TODO: Circuito:
//Organizador envía un link único al móvil (o email) del piloto.
//El piloto ingresa en ese link y ya tiene la posibilidad de registrarse en la competición (si tiene todo el perfil relleno).
//Enviar justificante.
