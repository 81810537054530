/* eslint-disable react/jsx-pascal-case*/
import { Alert, Box, Button, Card, Container } from "@mui/material"
import { useEffect } from "react"
import { bllSaludonnet_CentrosUrgencias } from "../../../bll/Saludonnet/bllSaludonnet_CentrosUrgencias"
import useGeneral from "../../../hooksData/useGeneral"
import useSntCentrosUrgencias from "../../../hooksData/useSntCentrosUrgencias"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import { globalAssets } from "../../../globals/globalAssets"
import useSeguros from "../../../hooksData/useSeguros"
import { bllSeguros_GetSegurosVigentes } from "../../../bll/Seguros/bllSeguros_GetSegurosVigentes"
import useGlobalsUser from "../../../hooksData/useGlobalsUser"
import { utilLogger } from "../../../utils/utilLogger"
import SharedBasicImage from "../../Shared/Basic/SharedBasicImage"
import SharedBasicTypo from "../../Shared/Basic/SharedBasicTypo"
import { sharedSeguroAccidentesCircuito } from "../../../api/shared/sharedSeguroAccidentesCircuito"
import { SharedLinkInsideAppWrapper } from "../../Shared/SharedLinkInsideAppWrapper"
import { globalPathsApp } from "../../../globals/globalPathsApp"

type props = {
	seguro: sharedSeguroAccidentesCircuito.IShared
}

export default function SaludonnetShared_SeguroCard({ seguro }: props) {
	const ug = useGeneral("SaludonnetShared_SeguroCard")

	return (
		<Card sx={{ height: "300px", p: 3 }}>
			<SharedBasicTypo sx={{ fontWeight: 600, fontSize: "15px" }}>{seguro.nombreAseguradora}</SharedBasicTypo>

			<SharedBasicTypo sx={{ fontWeight: 400, fontSize: "14px", color: "#606060" }}>
				Domicilio: {seguro.domicilioCompleto || "-No definido-"}
			</SharedBasicTypo>

			<SharedBasicTypo sx={{ fontWeight: 400, fontSize: "14px", color: "#606060" }}>
				Duración: {seguro.duracion || "-No definido-"}
			</SharedBasicTypo>

			<SharedBasicTypo sx={{ fontWeight: 400, fontSize: "14px", color: "#606060" }}>
				Nombre piloto: {seguro.nombrePiloto || "-No definido-"}
			</SharedBasicTypo>

			<SharedBasicTypo sx={{ fontWeight: 400, fontSize: "14px", color: "#606060" }}>
				Número póliza: {seguro.numeroPoliza || "-No definido-"}
			</SharedBasicTypo>

			<Box>
				<SharedLinkInsideAppWrapper
					urlDest={globalPathsApp.pageInsideUrl.SegurosSeguro(seguro.id, seguro.tipoProductoEmision)}
				>
					<Button variant="contained" sx={{ m: 1 }}>
						Ver seguro
					</Button>
				</SharedLinkInsideAppWrapper>

				<SharedLinkInsideAppWrapper
					urlDest={globalPathsApp.pageInsideUrl.SegurosProtocoloAccidente(seguro.id, seguro.tipoProductoEmision)}
				>
					<Button variant="contained" sx={{ m: 1 }}>
						Protocolo accidente
					</Button>
				</SharedLinkInsideAppWrapper>

				<SharedLinkInsideAppWrapper
					urlDest={globalPathsApp.pageInsideUrl.SegurosTarjetaSeguro(seguro.id, seguro.tipoProductoEmision)}
				>
					<Button variant="contained" sx={{ m: 1 }}>
						Tarjeta
					</Button>
				</SharedLinkInsideAppWrapper>
			</Box>
		</Card>
	)
}
