import { combineReducers } from "redux"
import { bllAdminNotificaciones_General } from "./bllAdminNotificaciones_General"

export default combineReducers({
	General: bllAdminNotificaciones_General.reducerFunction,
})

export interface IAdminNotificaciones {
	General: bllAdminNotificaciones_General.IInterface
}
