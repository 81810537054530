/* eslint-disable react/jsx-pascal-case*/
import { Box, Button, Container, Dialog, DialogTitle, useTheme } from "@mui/material"
import { m } from "framer-motion"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../bll/bllReducers"
import { bllGlobalsUI_DialogErrorUnexpected } from "../../bll/GlobalsUI/bllGlobalsUI_DialogErrorUnexpected"
import { varBounce } from "../../globals/globalAnimate/variants/bounce"
import AnimateMotionContainer from "../Animate/AnimateMotionContainer"
import AssetSeverErrorIllustration from "../Assets/AssetSeverErrorIllustration"
import SharedBasicScrollbar from "../Shared/Basic/SharedBasicScrollbar"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"

export const GlobalDialogErrorUnexpected = () => {
	const theme = useTheme()
	const D = useDispatch()

	const isOpenState = useSelector((state: IState) => state.GlobalsUI.DialogErrorUnexpected.open)
	const title = useSelector((state: IState) => state.GlobalsUI.DialogErrorUnexpected.title)
	const subtitle = useSelector((state: IState) => state.GlobalsUI.DialogErrorUnexpected.subtitle)
	const excepcion = useSelector((state: IState) => state.GlobalsUI.DialogErrorUnexpected.excepcion)

	const handleClose = () => {
		D(bllGlobalsUI_DialogErrorUnexpected.close())
	}

	return (
		<>
			<Dialog fullWidth maxWidth="xs" open={isOpenState} onClose={handleClose}>
				<SharedBasicScrollbar lessToWindowSizeHeight={120}>
					<DialogTitle>{title !== "" ? title : "Ha ocurrido un error inesperado"}</DialogTitle>

					<Container component={AnimateMotionContainer} sx={{ mx: "auto", pb: 2 }}>
						<Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
							<SharedBasicTypo color={theme.palette.text.secondary} sx={{ textAlign: "left" }}>
								{subtitle !== "" ? subtitle : "El mismo ya fue reportado, intenta nuevamente más tarde"}
							</SharedBasicTypo>

							<m.div variants={varBounce().in}>
								<AssetSeverErrorIllustration sx={{ height: 260, my: 3 }} />
							</m.div>

							<Button size="large" variant="contained" onClick={handleClose}>
								Cerrar
							</Button>
						</Box>
					</Container>
				</SharedBasicScrollbar>
			</Dialog>
		</>
	)
}
