/* eslint-disable react/jsx-pascal-case*/
import { Stack } from "@mui/material"
import { alpha, styled } from "@mui/material/styles"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { utilLogger } from "../../../utils/utilLogger"
import PersonasDelegadasDialog_List_Item from "./PersonasDelegadasDialog_List_Item"

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
	width: 40,
	height: 40,
	display: "flex",
	borderRadius: "50%",
	alignItems: "center",
	justifyContent: "center",
	color: theme.palette.primary.main,
	backgroundColor: alpha(theme.palette.primary.main, 0.08),
}))

// ----------------------------------------------------------------------

export default function PersonasDelegadasDialog_List() {
	const userPersona = useSelector((state: IState) => state.GlobalsUser.Personas.persona)
	const personasDelegadas = useSelector((state: IState) => state.GlobalsUser.Personas.personasDelegadas)
	const currentPersona = useSelector((state: IState) => state.GlobalsUser.CurrentPersona.currentPersona)

	return (
		<Stack spacing={3} sx={{ p: 3 }}>
			<PersonasDelegadasDialog_List_Item
				key={0}
				persona={userPersona}
				isCurrentPersona={userPersona.id === currentPersona.id}
			/>
			{personasDelegadas.map((persona, i) => (
				<PersonasDelegadasDialog_List_Item
					key={persona.id}
					persona={persona}
					isCurrentPersona={persona.id === currentPersona.id}
				/>
			))}
		</Stack>
	)
}
