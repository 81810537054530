import { utilRedux } from "../../utils/utilRedux"
import produce from "immer"

export namespace bllGlobalsServer_LoadingList {
	//ET = enumTypes
	enum ET {
		setLoading = "GlobalsServer_LoadingList_setServerLoading",
		stopAllLoading = "GlobalsServer_LoadingList_stopAllServerLoading",
		clean = "GlobalsServer_LoadingList_clean",
	}

	interface IServerLoading {
		isLoading: boolean
	}

	export interface IInterface {
		[key: string]: IServerLoading
	}

	const getInitialState = (): IInterface => {
		return {}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			case ET.setLoading:
				return produce(state, (draft: IInterface) => {
					draft[action.key] = action.serverLoading
				})
				break
			case ET.stopAllLoading:
				return produce(state, (draft: IInterface) => {
					draft = getInitialState()
				})
				break
			case ET.clean:
				return produce(state, (draft: IInterface) => {
					delete draft[action.key]
				})
				break
			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setServerLoading(key: string, isLoading: boolean) {
		//D = dispatch
		return async (D: any) => {
			const serverLoading: IServerLoading = {
				isLoading: isLoading,
			}

			utilRedux.TA(D, ET.setLoading, {
				key: key,
				serverLoading: serverLoading,
			})
		}
	}

	export function stopAllServerLoading() {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.stopAllLoading, {})
		}
	}

	export function clean(key: string) {
		//D = dispatch
		return async (D: any) => {
			utilRedux.TA(D, ET.clean, {
				key: key,
			})
		}
	}
}
