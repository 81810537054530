/* eslint-disable react/jsx-pascal-case*/
import { Box, SxProps, Theme } from "@mui/material"
import { globalPathsApp } from "../../globals/globalPathsApp"
import SharedBasicImage from "./Basic/SharedBasicImage"
import { SharedLinkInsideAppWrapper } from "./SharedLinkInsideAppWrapper"
import { globalAssets } from "../../globals/globalAssets"

interface IProps {
	sx?: SxProps<Theme>
	disabledLink?: boolean
}

export const SharedLogo = ({ sx, disabledLink }: IProps) => {
	return (
		<>
			{disabledLink ? (
				<Box sx={{ width: 40, height: 40, m: 1, ...sx }}>
					<SharedBasicImage src={globalAssets.staticPaths.logoA} alt="Logo" />
				</Box>
			) : (
				<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.Home()}>
					<Box sx={{ width: 40, height: 40, m: 1, ...sx }}>
						<SharedBasicImage src={globalAssets.staticPaths.logoA} alt="Logo" />
					</Box>
				</SharedLinkInsideAppWrapper>
			)}
		</>
	)
}
