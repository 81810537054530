/* eslint-disable react/jsx-pascal-case*/
// @mui
import { alpha, styled } from "@mui/material/styles"
import { Popover, PopoverOrigin, PopoverProps } from "@mui/material"

// ----------------------------------------------------------------------

function getPosition(arrow: string) {
	let props

	switch (arrow) {
		case "top-left":
			props = {
				style: { ml: -0.75 },
				anchorOrigin: { vertical: "bottom", horizontal: "left" },
				transformOrigin: { vertical: "top", horizontal: "left" },
			}
			break
		case "top-center":
			props = {
				style: {},
				anchorOrigin: { vertical: "bottom", horizontal: "center" },
				transformOrigin: { vertical: "top", horizontal: "center" },
			}
			break
		case "top-right":
			props = {
				style: { ml: 0.75 },
				anchorOrigin: { vertical: "bottom", horizontal: "right" },
				transformOrigin: { vertical: "top", horizontal: "right" },
			}
			break
		case "bottom-left":
			props = {
				style: { ml: -0.75 },
				anchorOrigin: { vertical: "top", horizontal: "left" },
				transformOrigin: { vertical: "bottom", horizontal: "left" },
			}
			break
		case "bottom-center":
			props = {
				style: {},
				anchorOrigin: { vertical: "top", horizontal: "center" },
				transformOrigin: { vertical: "bottom", horizontal: "center" },
			}
			break
		case "bottom-right":
			props = {
				style: { ml: 0.75 },
				anchorOrigin: { vertical: "top", horizontal: "right" },
				transformOrigin: { vertical: "bottom", horizontal: "right" },
			}
			break
		case "left-top":
			props = {
				style: { mt: -0.75 },
				anchorOrigin: { vertical: "top", horizontal: "right" },
				transformOrigin: { vertical: "top", horizontal: "left" },
			}
			break
		case "left-center":
			props = {
				anchorOrigin: { vertical: "center", horizontal: "right" },
				transformOrigin: { vertical: "center", horizontal: "left" },
			}
			break
		case "left-bottom":
			props = {
				style: { mt: 0.75 },
				anchorOrigin: { vertical: "bottom", horizontal: "right" },
				transformOrigin: { vertical: "bottom", horizontal: "left" },
			}
			break
		case "right-top":
			props = {
				style: { mt: -0.75 },
				anchorOrigin: { vertical: "top", horizontal: "left" },
				transformOrigin: { vertical: "top", horizontal: "right" },
			}
			break
		case "right-center":
			props = {
				anchorOrigin: { vertical: "center", horizontal: "left" },
				transformOrigin: { vertical: "center", horizontal: "right" },
			}
			break
		case "right-bottom":
			props = {
				style: { mt: 0.75 },
				anchorOrigin: { vertical: "bottom", horizontal: "left" },
				transformOrigin: { vertical: "bottom", horizontal: "right" },
			}
			break

		// top-right
		default:
			props = {
				style: { ml: 0.75 },
				anchorOrigin: { vertical: "bottom", horizontal: "right" },
				transformOrigin: { vertical: "top", horizontal: "right" },
			}
	}

	return props
}

type MenuPopoverArrowValue =
	| "top-left"
	| "top-center"
	| "top-right"
	| "bottom-left"
	| "bottom-center"
	| "bottom-right"
	| "left-top"
	| "left-center"
	| "left-bottom"
	| "right-top"
	| "right-center"
	| "right-bottom"

interface MenuPopoverProps extends Omit<PopoverProps, "open"> {
	open: HTMLElement | null
	arrow?: MenuPopoverArrowValue
	disabledArrow?: boolean
}

const StyledArrow = styled("span")<{ arrow: MenuPopoverArrowValue }>(({ arrow, theme }) => {
	const SIZE = 12

	const POSITION = -(SIZE / 2)

	const borderStyle = `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`

	const topStyle = {
		borderRadius: "0 0 3px 0",
		top: POSITION,
		borderBottom: borderStyle,
		borderRight: borderStyle,
	}

	const bottomStyle = {
		borderRadius: "3px 0 0 0",
		bottom: POSITION,
		borderTop: borderStyle,
		borderLeft: borderStyle,
	}

	const leftStyle = {
		borderRadius: "0 3px 0 0",
		left: POSITION,
		borderTop: borderStyle,
		borderRight: borderStyle,
	}

	const rightStyle = {
		borderRadius: "0 0 0 3px",
		right: POSITION,
		borderBottom: borderStyle,
		borderLeft: borderStyle,
	}

	return {
		display: "none",
		[theme.breakpoints.up("sm")]: {
			zIndex: 1,
			width: SIZE,
			height: SIZE,
			content: "''",
			display: "block",
			position: "absolute",
			transform: "rotate(-135deg)",
			background: theme.palette.background.paper,
		},
		// Top
		...(arrow === "top-left" && { ...topStyle, left: 20 }),
		...(arrow === "top-center" && { ...topStyle, left: 0, right: 0, margin: "auto" }),
		...(arrow === "top-right" && { ...topStyle, right: 20 }),
		// Bottom
		...(arrow === "bottom-left" && { ...bottomStyle, left: 20 }),
		...(arrow === "bottom-center" && { ...bottomStyle, left: 0, right: 0, margin: "auto" }),
		...(arrow === "bottom-right" && { ...bottomStyle, right: 20 }),
		// Left
		...(arrow === "left-top" && { ...leftStyle, top: 20 }),
		...(arrow === "left-center" && { ...leftStyle, top: 0, bottom: 0, margin: "auto" }),
		...(arrow === "left-bottom" && { ...leftStyle, bottom: 20 }),
		// Right
		...(arrow === "right-top" && { ...rightStyle, top: 20 }),
		...(arrow === "right-center" && { ...rightStyle, top: 0, bottom: 0, margin: "auto" }),
		...(arrow === "right-bottom" && { ...rightStyle, bottom: 20 }),
	}
})

export default function SharedMenuPopover({
	open,
	children,
	arrow = "top-right",
	disabledArrow,
	sx,
	...other
}: MenuPopoverProps) {
	const { style, anchorOrigin, transformOrigin } = getPosition(arrow)

	return (
		<Popover
			open={Boolean(open)}
			anchorEl={open}
			anchorOrigin={anchorOrigin as PopoverOrigin}
			transformOrigin={transformOrigin as PopoverOrigin}
			PaperProps={{
				sx: {
					p: 1,
					width: "auto",
					overflow: "inherit",
					...style,
					"& .MuiMenuItem-root": {
						px: 1,
						typography: "body2",
						borderRadius: 0.75,
						"& svg": { mr: 2, width: 20, height: 20, flexShrink: 0 },
					},
					...sx,
				},
			}}
			{...other}
		>
			{!disabledArrow && <StyledArrow arrow={arrow} />}

			{children}
		</Popover>
	)
}
