/* eslint-disable react/jsx-pascal-case*/
import { Card, CardHeader, Container } from "@mui/material"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import PersonasShared_Seguidores_CardList from "../../Personas/Shared/PersonasShared_Seguidores_CardList"

interface IProps {}

export default function PilotosShared_PilotosSeguidos({}: IProps) {
	const personasSeguidas = useSelector(
		(state: IState) => state.GlobalsUser.CurrentPersonaPersonasSeguidas.personasSeguidas
	)

	return (
		<Container sx={{ position: "relative" }}>
			<Card>
				<CardHeader title="Pilotos Seguidos" sx={{ mb: 3 }} />

				<PersonasShared_Seguidores_CardList personasSeguidores={personasSeguidas} />
			</Card>
		</Container>
	)
}
