import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_RodadasInvitations_ConfirmEmailInvitationAndCreateUserAndLogin } from "../../api/calls/IG/RodadasInvitations_OLD/apiCallIG_RodadasInvitations_ConfirmEmailInvitationAndCreateUserAndLogin"
import { apiCallIG_RodadasInvitations_ConfirmEmailInvitationPre } from "../../api/calls/IG/RodadasInvitations_OLD/apiCallIG_RodadasInvitations_ConfirmEmailInvitationPre"
import { apiCallIG_RodadasPersonas_ConfirmEmailInvitationAsUserLogged } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_ConfirmEmailInvitationAsUserLogged"
import { apiCallIG_RodadasPersonas_GetEmailInvitationInfo } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_GetEmailInvitationInfo"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { sharedRodadaInvitation } from "../../api/shared/sharedRodadaInvitation"
import { sharedRodadaPersona } from "../../api/shared/sharedRodadaPersona"
import { utilLogger } from "../../utils/utilLogger"
import { utilRedux } from "../../utils/utilRedux"
import { bllUsers_Login } from "../Users/bllUsers_Login"

export namespace bllRodadas_ConfirmEmailInvitation {
	//ET = enumTypes
	enum ET {
		setInitialState = "Rodadas_ConfirmEmailInvitation_setInitialState",
		getEmailInvitationInfo = "Rodadas_ConfirmEmailInvitation_getEmailInvitationInfo",
		confirmEmailInvitationAsUserLogged = "Rodadas_ConfirmEmailInvitation_confirmEmailInvitationAsUserLogged",
	}

	export interface IInterface {
		sharedPersona: sharedPersona.IShared
		sharedRodadaPersona: sharedRodadaPersona.IShared
		existeUsuarioParaElEmail: boolean
		emailInvitationInfoObteined: boolean

		invitationConfirmedAsUserLogged: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			sharedPersona: sharedPersona.objectDefault,
			sharedRodadaPersona: sharedRodadaPersona.objectDefault,
			existeUsuarioParaElEmail: false,
			emailInvitationInfoObteined: false,
			invitationConfirmedAsUserLogged: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.User_ConfirmEmail_ConfirmEmail:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getEmailInvitationInfo(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_GetEmailInvitationInfo.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_RodadasPersonas_GetEmailInvitationInfo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<
					IInterface,
					"sharedPersona" | "sharedRodadaPersona" | "existeUsuarioParaElEmail" | "emailInvitationInfoObteined"
				>
				const updateObject: actionSubType = {
					sharedPersona: apiCallRes.sharedPersona,
					sharedRodadaPersona: apiCallRes.sharedRodadaPersona,
					existeUsuarioParaElEmail: apiCallRes.existeUsuarioParaElEmail,
					emailInvitationInfoObteined: true,
				}

				utilRedux.TA(D, ET.getEmailInvitationInfo, updateObject)
			}
		}
	}

	export function confirmEmailInvitationAsUserLogged(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_ConfirmEmailInvitationAsUserLogged.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasPersonas_ConfirmEmailInvitationAsUserLogged.apiCall(
				apiCallParams,
				params
			)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "invitationConfirmedAsUserLogged">
				const updateObject: actionSubType = {
					invitationConfirmedAsUserLogged: true,
				}

				utilRedux.TA(D, ET.getEmailInvitationInfo, updateObject)
			}
		}
	}
}
