export namespace sharedPersonasUsuarios {
	export interface IShared {
		idPersona: number
		rol: string
		//El userId por ahora no lo pongo porque por ahora estoy trayendo PersonasUsuarios solo para el usuario logueado
	}

	export const objectDefault: IShared = {
		idPersona: 0,
		rol: "",
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		const res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}
}
