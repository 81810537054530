import { utilDate } from "../../utils/utilDate"

export namespace sharedNotifSuscripciones {
	export interface IShared {
		id: number
		nombreTema: string
		userId: string
		// email: string
		// phoneNumberSMS: string
		// appEndpoint: string
		enabled: boolean
	}

	export const objectDefault: IShared = {
		id: 0,
		nombreTema: "",
		userId: "",
		// email: "",
		// phoneNumberSMS: "",
		// appEndpoint: "",
		enabled: false,
	}

	export const getSharedList_FromApiObject = (apiObject: any) => {
		const res: IShared[] = []

		apiObject.forEach((item: any) => {
			res.push(getSharedOne_FromApiObject(item))
		})

		return res
	}

	export const getSharedOne_FromApiObject = (apiObject: any) => {
		var res: IShared = {
			...objectDefault,
			...apiObject,
		}

		return res
	}
}
