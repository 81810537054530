/* eslint-disable react/jsx-pascal-case*/
import { GoogleMap } from "@react-google-maps/api"
import { ReactNode } from "react"

type Props = {
	zoom: number
	centerLat: number
	centerLng: number
	children: ReactNode
}

export default function SharedGoogleMap2_Sub({ zoom, centerLat, centerLng, children }: Props) {
	const center = { lat: centerLat, lng: centerLng }

	return (
		<GoogleMap
			zoom={zoom}
			center={center}
			mapContainerStyle={{ height: "600px" }}
			mapContainerClassName="map-container"
		>
			{children}
		</GoogleMap>
	)
}
