import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../bll/bllReducers"
import { bllGlobalsServer_ErrorMessageList } from "../bll/GlobalsServer/bllGlobalsServer_ErrorMessageList"
import { bllGlobalsServer_ModelErrorsList } from "../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { bllGlobalsServer_LoadingList } from "../bll/GlobalsServer/bllGlobalsServer_LoadingList"

export default function useServerStateField(callerKey: string, fieldName: string) {
	const serverIsLoading = useSelector((state: IState) => state.GlobalsServer.LoadingList[callerKey]?.isLoading || false)
	const serverModelError = useSelector((state: IState) => state.GlobalsServer.ModelErrorsList[callerKey])
	const error = serverModelError ? serverModelError[fieldName.toLowerCase()] || "" : ""
	const uploadIsLoading = useSelector(
		(state: IState) => state.GlobalsServer.UploadLoadingList[callerKey]?.isLoading || false
	)

	return {
		isLoading: serverIsLoading,
		error,
		uploadIsLoading,
	}
}
