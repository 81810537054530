import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Rodadas_CreateRodada } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_CreateRodada"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { utilRedux } from "../../utils/utilRedux"
import { bllGlobalsGeneralData_AllRodadas } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllRodadas"
import { bllGlobalsUser_CurrentPersonaRodadas } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaRodadas"

export namespace bllRodadas_Create {
	//ET = enumTypes
	enum ET {
		setInitialState = "bllRodadas_Create_setInitialState",
		set = "bllRodadas_Create_Set",
	}

	export interface IInterface {
		rodada: sharedRodada.IShared
		isCreated: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			rodada: sharedRodada.objectDefault,
			isCreated: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function createRodada(apiCallParams: apiCallInterface, params: apiCallIG_Rodadas_CreateRodada.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Rodadas_CreateRodada.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllGlobalsGeneralData_AllRodadas.loadAllRodadas(apiCallParams))
				await D(
					bllGlobalsUser_CurrentPersonaRodadas.loadRodadasDePersona(apiCallParams, { idPersona: params.idPersona })
				)

				type actionSubType = Pick<IInterface, "rodada" | "isCreated">
				const updateObject: actionSubType = {
					rodada: apiCallRes.sharedRodada,
					isCreated: true,
				}

				utilRedux.TA(D, ET.set, updateObject)
			}
		}
	}
}
