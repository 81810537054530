import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedMotoclub } from "../../api/shared/sharedMotoclub"
import { apiCallIG_Motoclubs_GetMotoclub } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_GetMotoclub"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { apiCallIG_Motoclubs_BloquearMotoclub } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_BloquearMotoclub"
import { apiCallIG_Motoclubs_DesbloquearMotoclub } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_DesbloquearMotoclub"
import { apiCallIG_Motoclubs_AprobarMotoclub } from "../../api/calls/IG/Motoclubs/apiCallIG_Motoclubs_AprobarMotoclub"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_MotoclubsSeguidores_SeguirMotoclub } from "../../api/calls/IG/MotoclubsSeguidores/apiCallIG_MotoclubsSeguidores_SeguirMotoclub"
import { bllGlobalsUser_CurrentPersonaMotoclubsSeguidos } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaMotoclubsSeguidos"
import { apiCallIG_MotoclubsSeguidores_DejarDeSeguirMotoclub } from "../../api/calls/IG/MotoclubsSeguidores/apiCallIG_MotoclubsSeguidores_DejarDeSeguirMotoclub"

export namespace bllMotoclubs_Profile {
	//ET = enumTypes
	enum ET {
		setInitialState = "Motoclub_Profile_setInitialState",
		loadMotoclub = "Motoclub_Profile_loadMotoclub",
		updateWithSharedMotoclub = "Motoclubs_Profile_updateWithSharedMotoclub",
		seguirMotoclub = "Motoclub_Profile_seguirMotoclub",
		dejarDeSeguirMotoclub = "Motoclub_Profile_dejarDeSeguirMotoclub",
	}

	export interface IInterface {
		motoclub: sharedMotoclub.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			motoclub: sharedMotoclub.defaultObject,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getMotoclub(apiCallParams: apiCallInterface, params: apiCallIG_Motoclubs_GetMotoclub.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Motoclubs_GetMotoclub.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "motoclub">
				const updateObject: actionSubType = {
					motoclub: apiCallRes.sharedMotoclub,
				}

				utilRedux.TA(D, ET.loadMotoclub, updateObject)
			}
		}
	}

	export function updateMotoclubWithSharedMotoclub(
		apiCallParams: apiCallInterface,
		sharedMotoclub: sharedMotoclub.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "motoclub">
			const updateObject: actionSubType = {
				motoclub: sharedMotoclub,
			}

			utilRedux.TA(D, ET.updateWithSharedMotoclub, updateObject)
		}
	}

	export function seguirMotoclub(
		apiCallParams: apiCallInterface,
		params: apiCallIG_MotoclubsSeguidores_SeguirMotoclub.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_MotoclubsSeguidores_SeguirMotoclub.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(
					bllGlobalsUser_CurrentPersonaMotoclubsSeguidos.setPersonaMotoclubsSeguidos(apiCallRes.sharedMotoclubsSeguidos)
				)

				type actionSubType = Pick<IInterface, "motoclub">
				const updateObject: actionSubType = {
					motoclub: apiCallRes.sharedMotoclub,
				}

				utilRedux.TA(D, ET.seguirMotoclub, updateObject)
			}
		}
	}

	export function dejarDeSeguirMotoclub(
		apiCallParams: apiCallInterface,
		params: apiCallIG_MotoclubsSeguidores_DejarDeSeguirMotoclub.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_MotoclubsSeguidores_DejarDeSeguirMotoclub.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(
					bllGlobalsUser_CurrentPersonaMotoclubsSeguidos.setPersonaMotoclubsSeguidos(apiCallRes.sharedMotoclubsSeguidos)
				)

				type actionSubType = Pick<IInterface, "motoclub">
				const updateObject: actionSubType = {
					motoclub: apiCallRes.sharedMotoclub,
				}

				utilRedux.TA(D, ET.dejarDeSeguirMotoclub, updateObject)
			}
		}
	}
}
