import { globalConfig } from "../globals/globalConfig"
import { globalPathsApp } from "../globals/globalPathsApp"
import { utilFunctions } from "./utilFunctions"

export namespace utilRouter {
	export const getInsideAppUrl = (appPagePath: globalPathsApp.pagesEnum, parameters?: globalPathsApp.IPageParam[]) => {
		var params = ""

		if (parameters) {
			parameters.forEach((p) => {
				params += params === "" ? "?" : "&"
				params += `${p.name}=${p.value}`
			})
		}
		return `/${globalPathsApp.baseUrl}/${appPagePath}${params}`
	}

	export const getInsideAppFullUrl = (
		appPagePath: globalPathsApp.pagesEnum,
		tabName?: string,
		parameters?: globalPathsApp.IPageParam[]
	) => {
		const insideAppUrl = getInsideAppUrl(appPagePath, parameters)
		return `${globalConfig.frontendUrlWithoutEndSlash}${insideAppUrl}`
	}

	export const isInsideApp = () => {
		const baseURL = globalPathsApp.baseUrl.toLowerCase()
		const pathName = window.location.pathname.toLowerCase()

		const slashBaseURL = `/${baseURL}` //Se busca exacto esto porque la url podría ser: /app2/...
		const slashBaseURLslash = `/${baseURL}/`

		//Si no estoy dentro de /app/... retorno null
		if (pathName !== slashBaseURL && pathName.indexOf(slashBaseURLslash) === -1) {
			return false
		}

		return true
	}

	export const getCurrentPage = (): globalPathsApp.pagesEnum | null => {
		const baseURL = globalPathsApp.baseUrl.toLowerCase()
		const pathName = window.location.pathname.toLowerCase()

		const slashBaseURL = `/${baseURL}` //Se busca exacto esto porque la url podría ser: /app2/...
		const slashBaseURLslash = `/${baseURL}/`

		if (!isInsideApp()) {
			return null
		}

		let currentPageString = ""

		if (pathName !== slashBaseURL) {
			currentPageString = pathName.replace(slashBaseURLslash, "")
		}

		const globalAppPage: globalPathsApp.pagesEnum = utilFunctions.findStringItemInStringEnum(
			currentPageString,
			globalPathsApp.pagesEnum
		)

		return globalAppPage ?? globalPathsApp.pagesEnum.Error404
	}

	export const isOnLandingPage = () => getCurrentPage() === null

	export const goToHomeOutsideApp = () => {
		location.replace("/")
	}

	// export const getAppUrl = (appPagePath: globalPathsApp.pagesEnum, parameters?: string) => {
	// 	return `/${globalPathsApp.baseUrl}/${appPagePath}${parameters || ""}`
	// }

	// export const getCurrentPage = (): globalPathsApp.pagesEnum | null => {
	// 	const baseURL = globalPathsApp.baseUrl.toLowerCase()
	// 	const pathName = window.location.pathname.toLowerCase()

	// 	const slashBaseURL = `/${baseURL}` //Se busca exacto esto porque la url podría ser: /app2/...
	// 	const slashBaseURLslash = `/${baseURL}/`

	// 	//Si no estoy dentro de /app/... retorno null
	// 	if (pathName !== slashBaseURL && pathName.indexOf(slashBaseURLslash) === -1) {
	// 		return null
	// 	}

	// 	let currentPageString = ""

	// 	if (pathName !== slashBaseURL) {
	// 		currentPageString = pathName.replace(slashBaseURLslash, "")
	// 	}

	// 	const globalAppPage: globalPathsApp.pagesEnum = utilFunctions.findStringItemInStringEnum(
	// 		currentPageString,
	// 		globalPathsApp.pagesEnum
	// 	)

	// 	return globalAppPage ?? globalPathsApp.pagesEnum.Error404
	// }

	// export const isOnLandingPage = () => getCurrentPage() === null

	// export const isOnPagesWithoutServerLoading = () => {
	// 	const currentPage = getCurrentPage()

	// 	if (currentPage === null) return true //LandingPage
	// 	if (currentPage === globalPathsApp_Enum.UserLogin) return true
	// 	if (currentPage === globalPathsApp_Enum.UserRegistration) return true

	// 	return false
	// }

	// export const getCurrentPage = (): globalPathsApp_Enum => {
	// 	const baseURL = globalPathsApp.globalPathsApp_BaseURL.toLowerCase()
	// 	const pathName = window.location.pathname.toLowerCase()

	// 	const slashBaseURL = `/${baseURL}` //Se busca exacto esto porque la url podría ser: /app2/...
	// 	const slashBaseURLslash = `/${baseURL}/`

	// 	if (pathName !== slashBaseURL && pathName.indexOf(slashBaseURLslash) === -1) {
	// 		return globalPathsApp_Enum.OutsideApp
	// 	}

	// 	let currentPageString = ""

	// 	if (pathName !== slashBaseURL) {
	// 		currentPageString = pathName.replace(slashBaseURLslash, "")
	// 	}

	// 	const globalAppPage: globalPathsApp_Enum = findStringItemInStringEnum(currentPageString, globalPathsApp_Enum)

	// 	return globalAppPage ?? globalPathsApp_Enum.Error404
	// }
}
