/* eslint-disable react/jsx-pascal-case*/
import { Box, Button, Container, Dialog, DialogTitle, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import useWindowSize from "../../../hooks/useWindowSize"
import AnimateMotionContainer from "../../Animate/AnimateMotionContainer"
import SharedBasicScrollbar from "../../Shared/Basic/SharedBasicScrollbar"
import PersonasDelegadasDialog_List from "./PersonasDelegadasDialog_List"

interface IProps {
	isDialogOpen: boolean
	close(): void
}

export const PersonasDelegadasDialog = ({ isDialogOpen, close }: IProps) => {
	return (
		<>
			<Dialog fullWidth maxWidth="xs" open={isDialogOpen} onClose={close}>
				<SharedBasicScrollbar lessToWindowSizeHeight={120}>
					<DialogTitle>{"Cambiar de perfil"}</DialogTitle>
					<Typography
						variant="body2"
						sx={{
							ml: 3,
							display: "flex",
							alignItems: "center",
							color: "text.secondary",
						}}
					>
						Estos son los perfiles que puedes administrar:
					</Typography>

					<Container component={AnimateMotionContainer} sx={{ mx: "auto", pb: 2 }}>
						<PersonasDelegadasDialog_List />

						<Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
							<Button
								size="large"
								variant="contained"
								onClick={close}
								sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}
							>
								Cerrar
							</Button>
						</Box>
					</Container>
				</SharedBasicScrollbar>
			</Dialog>
		</>
	)
}
