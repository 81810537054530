/* eslint-disable react/jsx-pascal-case*/

import { SharedBasicAvatarImg } from "../../Shared/Basic/SharedBasicAvatarImg"
import { globalMock } from "../../../globals/globalMock"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { Theme } from "@mui/material/styles"
import { SxProps } from "@mui/material"

interface IProps {
	avatarSx?: SxProps<Theme>
}

export default function PersonasGlobals_CurrentAvatar({ avatarSx }: IProps) {
	const currentPersona = useSelector((state: IState) => state.GlobalsUser.CurrentPersona.currentPersona)

	return (
		<SharedBasicAvatarImg
			avatarSx={avatarSx}
			src={currentPersona.avatarURL}
			alt={`Imagen de ${currentPersona.nombre} ${currentPersona.apellido1}`}
		/>
	)
}
