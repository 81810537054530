/* eslint-disable react/jsx-pascal-case*/
import { Alert, Box, Card, Container } from "@mui/material"
import { useEffect } from "react"
import { bllSaludonnet_CentrosUrgencias } from "../../../bll/Saludonnet/bllSaludonnet_CentrosUrgencias"
import useGeneral from "../../../hooksData/useGeneral"
import useSntCentrosUrgencias from "../../../hooksData/useSntCentrosUrgencias"
import SharedLoadingComponent from "../../Shared/Loading/SharedLoadingComponent"
import { globalAssets } from "../../../globals/globalAssets"
import useSeguros from "../../../hooksData/useSeguros"
import { bllSeguros_GetSegurosVigentes } from "../../../bll/Seguros/bllSeguros_GetSegurosVigentes"
import useGlobalsUser from "../../../hooksData/useGlobalsUser"
import { utilLogger } from "../../../utils/utilLogger"
import SaludonnetShared_SeguroCard from "../Shared/SaludonnetShared_SeguroCard"
import { bllSeguros_GetSeguro } from "../../../bll/Seguros/bllSeguros_GetSeguro"
import { useSearchParams } from "react-router-dom"
import { bllSeguros_GetTarjetaPiloto } from "../../../bll/Seguros/bllSeguros_GetTarjetaPiloto"
import SharedBasicImage from "../../Shared/Basic/SharedBasicImage"

export default function SaludonnetSeguroTarjetaPiloto() {
	const ug = useGeneral("SaludonnetSeguroTarjetaPiloto")
	const { tarjetaPilotoImagenUrl } = useSeguros()
	const { currentPersona, userIsLogged } = useGlobalsUser()

	useEffect(() => {
		return () => {
			ug.D(bllSeguros_GetTarjetaPiloto.setInitialState())
		}
	}, [])

	useEffect(() => {
		if (userIsLogged && currentPersona.id !== 0) {
			ug.D(bllSeguros_GetTarjetaPiloto.load(ug.apiCallParams, { idPersona: currentPersona.id }))
		}
	}, [userIsLogged, currentPersona])

	return (
		<Container sx={{ position: "relative" }}>
			{ug.serverState.isLoading && <SharedLoadingComponent />}

			{ug.serverState.errorMessage && <Alert severity="error">{ug.serverState.errorMessage}</Alert>}

			<Box
				gap={3}
				display="grid"
				sx={{ p: 3 }}
				gridTemplateColumns={{
					xs: "repeat(1, 1fr)",
					sm: "repeat(2, 1fr)",
					md: "repeat(3, 1fr)",
				}}
			>
				<SharedBasicImage src={tarjetaPilotoImagenUrl} alt={"Tarjeta"} />
			</Box>
		</Container>
	)
}
