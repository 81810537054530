import { combineReducers } from "redux"
import { bllEmpresas_Profile } from "./bllEmpresas_Profile"
import { bllEmpresas_Create } from "./bllEmpresas_Create"
import { bllEmpresas_Settings } from "./bllEmpresas_Settings"

export default combineReducers({
	Profile: bllEmpresas_Profile.reducerFunction,
	Settings: bllEmpresas_Settings.reducerFunction,
	CreateEmpresa: bllEmpresas_Create.reducerFunction,
})

export interface IEmpresas {
	Profile: bllEmpresas_Profile.IInterface
	Settings: bllEmpresas_Settings.IInterface
	CreateEmpresa: bllEmpresas_Create.IInterface
}
