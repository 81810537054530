import { bllDispatchs_GlobalsServer } from "../../../bll/bllDispatchs_GlobalsServer"
import { bllGlobalsServer_ModelErrorsList } from "../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { globalConfig } from "../../../globals/globalConfig"
import { apiCallInterface } from "../../apiCallInterface"
import { apiRegularCall } from "../../apiRegularCall"
import { apiRegularCall_Ax_v2 } from "../../apiRegularCall_Ax"

export namespace apiCallIS_User_GetUserIdByEmail {
	export interface params {
		email: string
	}
	export const paramsName = {
		email: "email",
	}
	export const paramsDesc = { email: "Dirección de email" }

	export interface IRes {
		userId: string
	}

	const validations = async (apiCallParams: apiCallInterface, params: params) => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		return await bllDispatchs_GlobalsServer.setModelErrors(apiCallParams.key, serverModelErrors)
	}

	export const apiCall = async (apiCallParams: apiCallInterface, params: params): Promise<IRes | null> => {
		if (await validations(apiCallParams, params)) {
			return null
		}

		const apiCallMethod = apiRegularCall_Ax_v2.Methods_Enum.get
		const apiCallUrl = `${globalConfig.apiPathBaseSecurity}/User/GetUserIdByEmail`
		const sharedParamsQuery: params | null = params
		const sharedParamsBody = null

		const apiCallRes = await apiRegularCall.call_v2(
			apiCallParams,
			apiCallMethod,
			apiCallUrl,
			sharedParamsQuery,
			sharedParamsBody
		)

		if (!apiCallRes) {
			return null
		}

		return {
			userId: apiCallRes,
		}
	}
}
