/* eslint-disable react/jsx-pascal-case*/
import { Card, CardHeader, Container } from "@mui/material"
import { useSelector } from "react-redux"
import { IState } from "../../../bll/bllReducers"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import OrganizadoresShared_CardList from "./OrganizadoresShared_CardList"
import OrganizadoresShared_Table from "./OrganizadoresShared_Table"

interface IProps {}

export default function OrganizadoresShared_MisOrganizadores({}: IProps) {
	const organizadores = useSelector((state: IState) => state.GlobalsUser.CurrentPersonaOrganizadores.organizadores)

	return (
		<Container sx={{ position: "relative" }}>
			<Card>
				{/* <CardHeader title="Organizadores" sx={{ mb: 3 }} />
				<OrganizadoresGlobals_Table
					organizadores={organizadores}
					showAll={showAll}
					showAllAppPathPage={globalPathsApp.pagesEnum.OrganizadoresLists}
					showAllPathParameters={globalPathsApp.pageParameters.OrganizadoresLists("allCircuits")}
				/> */}

				<OrganizadoresShared_CardList organizadores={organizadores} />
			</Card>
		</Container>
	)
}
