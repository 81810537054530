import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedEscuela } from "../../api/shared/sharedEscuela"
import { apiCallIG_Escuelas_GetEscuela } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_GetEscuela"
import { apiCallIG_Escuelas_ActualizarAvatar } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_ActualizarAvatar"
import { apiCallIG_Escuelas_ActualizarContact } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_ActualizarContact"
import { apiCallIG_Escuelas_ActualizarCover } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_ActualizarCover"
import { apiCallIG_Escuelas_ActualizarDireccionGoogle } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_ActualizarDireccionGoogle"
import { apiCallIG_Escuelas_ActualizarDireccionNotGoogle } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_ActualizarDireccionNotGoogle"
import { apiCallIG_Escuelas_ActualizarGeneralFields } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_ActualizarGeneralFields"
import { apiCallIG_Escuelas_ActualizarProfileFields } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_ActualizarProfileFields"
import { apiCallIG_Escuelas_ActualizarSocialInfo } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_ActualizarSocialInfo"
import { apiCallIG_Escuelas_BloquearEscuela } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_BloquearEscuela"
import { apiCallIG_Escuelas_AprobarEscuela } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_AprobarEscuela"
import { apiCallIG_Escuelas_DesbloquearEscuela } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_DesbloquearEscuela"
import { apiCallIG_Escuelas_DeleteEscuela } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_DeleteEscuela"
import { bllGlobalsGeneralData_AllEscuelas } from "../GlobalsGeneralData/bllGlobalsGeneralData_AllEscuelas"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Escuelas_EnviarNotificacionASeguidores } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_EnviarNotificacionASeguidores"

export namespace bllEscuelas_Settings {
	//ET = enumTypes
	enum ET {
		setInitialState = "Escuelas_Settings_setInitialState",
		loadEscuela = "Escuelas_Settings_loadEscuela",
		updateWithSharedEscuela = "Escuelas_Settings_updateWithSharedEscuela",
		actualizarAvatar = "Escuelas_Settings_actualizarAvatar",
		actualizarCharacteristics = "Escuelas_Settings_actualizarCharacteristics",
		actualizarContact = "Escuelas_Settings_actualizarContact",
		actualizarAdminFields = "Escuelas_Settings_actualizarAdminFields",
		actualizarCover = "Escuelas_Settings_actualizarCover",
		actualizarDireccionGoogle = "Escuelas_Settings_actualizarDireccionGoogle",
		actualizarDireccionNotGoogle = "Escuelas_Settings_actualizarDireccionNotGoogle",
		actualizarGeneralFields = "Escuelas_Settings_actualizarGeneralFields",
		actualizarProfileFields = "Escuelas_Settings_actualizarProfileFields",
		actualizarSocialInfo = "Escuelas_Settings_actualizarSocialInfo",
		deleteEscuela = "Escuelas_Settings_deleteEscuela",
		bloquearEscuela = "Escuelas_Settings_bloquearEscuela",
		desbloquearEscuela = "Escuelas_Settings_desbloquearEscuela",
		aprobarEscuela = "Escuelas_Settings_aprobarEscuela",

		enviarNotificacionASeguidores = "Escuelas_Settings_enviarNotificacionASeguidores",
	}

	export interface IInterface {
		escuela: sharedEscuela.IShared
		isDeleted: boolean
		notificacionEnviada: boolean
	}

	const getInitialState = (): IInterface => {
		return {
			escuela: sharedEscuela.defaultObject,
			isDeleted: false,
			notificacionEnviada: false,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadEscuela(apiCallParams: apiCallInterface, params: apiCallIG_Escuelas_GetEscuela.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Escuelas_GetEscuela.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "escuela">
				const updateObject: actionSubType = {
					escuela: apiCallRes.sharedEscuela,
				}

				utilRedux.TA(D, ET.loadEscuela, updateObject)
			}
		}
	}

	export function updateEscuelaWithSharedEscuela(
		apiCallParams: apiCallInterface,
		sharedEscuela: sharedEscuela.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "escuela">
			const updateObject: actionSubType = {
				escuela: sharedEscuela,
			}

			utilRedux.TA(D, ET.updateWithSharedEscuela, updateObject)
		}
	}

	export function actualizarAvatar(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Escuelas_ActualizarAvatar.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_ActualizarAvatar.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEscuelas_Settings.updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.actualizarAvatar, {})
			}
		}
	}

	export function actualizarContact(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Escuelas_ActualizarContact.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_ActualizarContact.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEscuelas_Settings.updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.actualizarContact, {})
			}
		}
	}

	export function actualizarCover(apiCallParams: apiCallInterface, params: apiCallIG_Escuelas_ActualizarCover.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_ActualizarCover.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEscuelas_Settings.updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.actualizarCover, {})
			}
		}
	}

	export function actualizarDireccionGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Escuelas_ActualizarDireccionGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_ActualizarDireccionGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEscuelas_Settings.updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.actualizarDireccionGoogle, {})
			}
		}
	}

	export function actualizarDireccionNotGoogle(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Escuelas_ActualizarDireccionNotGoogle.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_ActualizarDireccionNotGoogle.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEscuelas_Settings.updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.actualizarDireccionNotGoogle, {})
			}
		}
	}

	export function actualizarGeneralFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Escuelas_ActualizarGeneralFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_ActualizarGeneralFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEscuelas_Settings.updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.actualizarGeneralFields, {})
			}
		}
	}

	export function actualizarProfileFields(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Escuelas_ActualizarProfileFields.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_ActualizarProfileFields.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEscuelas_Settings.updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.actualizarProfileFields, {})
			}
		}
	}

	export function actualizarSocialInfo(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Escuelas_ActualizarSocialInfo.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_ActualizarSocialInfo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(bllEscuelas_Settings.updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.actualizarSocialInfo, {})
			}
		}
	}

	export function deleteEscuela(apiCallParams: apiCallInterface, params: apiCallIG_Escuelas_DeleteEscuela.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Escuelas_DeleteEscuela.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "isDeleted">
				const updateObject: actionSubType = {
					isDeleted: true,
				}

				D(bllGlobalsGeneralData_AllEscuelas.loadAllEscuelas(apiCallParams))

				utilRedux.TA(D, ET.deleteEscuela, updateObject)
			}
		}
	}

	export function bloquearEscuela(apiCallParams: apiCallInterface, params: apiCallIG_Escuelas_BloquearEscuela.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_BloquearEscuela.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.bloquearEscuela, {})
			}
		}
	}

	export function aprobarEscuela(apiCallParams: apiCallInterface, params: apiCallIG_Escuelas_AprobarEscuela.params) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_AprobarEscuela.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.aprobarEscuela, {})
			}
		}
	}

	export function desbloquearEscuela(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Escuelas_DesbloquearEscuela.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Escuelas_DesbloquearEscuela.apiCall(apiCallParams, params)

			if (apiCallRes) {
				await D(updateEscuelaWithSharedEscuela(apiCallParams, apiCallRes.sharedEscuela))

				utilRedux.TA(D, ET.desbloquearEscuela, {})
			}
		}
	}

	export function enviarNotificacionASeguidores(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Escuelas_EnviarNotificacionASeguidores.params
	) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "notificacionEnviada">
			const updateObject: actionSubType = {
				notificacionEnviada: false,
			}

			utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)

			const apiCallRes = await apiCallIG_Escuelas_EnviarNotificacionASeguidores.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "notificacionEnviada">
				const updateObject: actionSubType = {
					notificacionEnviada: true,
				}

				utilRedux.TA(D, ET.enviarNotificacionASeguidores, updateObject)
			}
		}
	}
}
