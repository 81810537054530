import { combineReducers } from "redux"
import { bllNotificaciones_All } from "./bllNotificaciones_All"
import { bllNotificaciones_Suscripciones } from "./bllNotificaciones_Suscripciones"

export default combineReducers({
	All: bllNotificaciones_All.reducerFunction,
	Suscripciones: bllNotificaciones_Suscripciones.reducerFunction,
})

export interface INotificaciones {
	All: bllNotificaciones_All.IInterface
	Suscripciones: bllNotificaciones_Suscripciones.IInterface
}
