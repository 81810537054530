/* eslint-disable react/jsx-pascal-case*/
import { Box } from "@mui/material"
import { sharedPersonaSeguidor } from "../../../api/shared/sharedPersonaSeguidor"
import PersonasShared_Seguidores_CardList_Card from "./PersonasShared_Seguidores_CardList_Card"

interface IProps {
	personasSeguidores: sharedPersonaSeguidor.IShared[]
}

export default function PersonasShared_Seguidores_CardList({ personasSeguidores }: IProps) {
	return (
		<>
			<Box
				gap={3}
				display="grid"
				sx={{ p: 3 }}
				gridTemplateColumns={{
					xs: "repeat(1, 1fr)",
					sm: "repeat(2, 1fr)",
					md: "repeat(3, 1fr)",
				}}
			>
				{personasSeguidores.map((personaSeguidor, i) => (
					<PersonasShared_Seguidores_CardList_Card key={i} personaSeguidor={personaSeguidor} />
				))}

				{personasSeguidores.length === 0 && (
					<Box
						sx={{
							color: "text.secondary",
						}}
					>
						-Aún no hay personas en este listado-
					</Box>
				)}
			</Box>
		</>
	)
}
