/* eslint-disable react/jsx-pascal-case*/
import {
	alpha,
	Avatar,
	Box,
	Card,
	Divider,
	IconButton,
	Stack,
	styled,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { globalPathsApp } from "../../../globals/globalPathsApp"
import { utilNumbers } from "../../../utils/utilNumbers"
import SharedBasicIconify from "../../Shared/Basic/SharedBasicIconify"
import { SharedLinkInsideAppWrapper } from "../../Shared/SharedLinkInsideAppWrapper"
import SharedSvgColor from "../../Shared/SharedSvgColor"
import SharedBasicImage from "../../Shared/Basic/SharedBasicImage"
import { sharedPersona } from "../../../api/shared/sharedPersona"
import SharedBasicTypo from "../../Shared/Basic/SharedBasicTypo"
import { sharedPersonaSeguidor } from "../../../api/shared/sharedPersonaSeguidor"

interface IProps {
	persona: sharedPersona.IShared
}

const StyledOverlay = styled("div")(({ theme }) => ({
	top: 0,
	left: 0,
	zIndex: 8,
	width: "100%",
	height: "100%",
	position: "absolute",
	backgroundColor: alpha(theme.palette.grey[900], 0.64),
}))

export default function PilotosShared_Personas_CardList_Card({ persona }: IProps) {
	const theme = useTheme()

	return (
		<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.PersonasProfile(undefined, persona.id)}>
			<Card sx={{ textAlign: "center" }}>
				<Box sx={{ position: "relative" }}>
					<SharedSvgColor
						src="/assets/shape_avatar.svg"
						sx={{
							width: 144,
							height: 62,
							zIndex: 10,
							left: 0,
							right: 0,
							bottom: -26,
							mx: "auto",
							position: "absolute",
							color: "background.paper",
						}}
					/>

					<Avatar
						alt={persona.nombre}
						src={persona.avatarURL}
						sx={{
							width: 64,
							height: 64,
							zIndex: 11,
							left: 0,
							right: 0,
							bottom: -32,
							mx: "auto",
							position: "absolute",
						}}
					/>

					<StyledOverlay />

					<SharedBasicImage src={persona.coverURL} alt={persona.nombre} ratio="16/9" />
				</Box>

				<SharedBasicTypo variant="subtitle1" sx={{ mt: 6, mb: 0.5 }}>
					{persona.nombre + " " + (persona.apellido1 || "") + " " + (persona.apellido2 || "")}
				</SharedBasicTypo>

				<SharedBasicTypo variant="body2" sx={{ color: theme.palette.text.secondary }}>
					{persona.descripcionCorta}
				</SharedBasicTypo>

				<Stack direction="row" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 3 }}>
					{/* {_socials.map((social) => (
          <IconButton
            key={social.name}
            sx={{
              color: social.color,
              '&:hover': {
                bgcolor: alpha(social.color, 0.08),
              },
            }}
          >
            <Iconify icon={social.icon} />
          </IconButton>
        ))} */}
				</Stack>

				<Divider sx={{ borderStyle: "dashed" }} />

				{/* <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" sx={{ py: 3 }}>
					<div>
						<SharedBasicTypo variant="caption" component="div" sx={{ mb: 0.75, color: "text.disabled" }}>
							Seguidores
						</SharedBasicTypo>
						<SharedBasicTypo variant="subtitle1">{personaSeguidor.cantidadSeguidores}</SharedBasicTypo>
					</div>

					<div>
						<SharedBasicTypo variant="caption" component="div" sx={{ mb: 0.75, color: "text.disabled" }}>
							Precio adulto
						</SharedBasicTypo>

						<SharedBasicTypo variant="subtitle1">prueba</SharedBasicTypo>
					</div>

					<div>
						<SharedBasicTypo variant="caption" component="div" sx={{ mb: 0.75, color: "text.disabled" }}>
							Precio niño
						</SharedBasicTypo>
						<SharedBasicTypo variant="subtitle1">prueba</SharedBasicTypo>
					</div>
				</Box> */}
			</Card>
		</SharedLinkInsideAppWrapper>
	)
}
