import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { sharedEscuela } from "../../api/shared/sharedEscuela"
import { apiCallIG_Escuelas_GetEscuela } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_GetEscuela"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { apiCallIG_Escuelas_BloquearEscuela } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_BloquearEscuela"
import { apiCallIG_Escuelas_DesbloquearEscuela } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_DesbloquearEscuela"
import { apiCallIG_Escuelas_AprobarEscuela } from "../../api/calls/IG/Escuelas/apiCallIG_Escuelas_AprobarEscuela"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_EscuelasSeguidores_SeguirEscuela } from "../../api/calls/IG/EscuelasSeguidores/apiCallIG_EscuelasSeguidores_SeguirEscuela"
import { bllGlobalsUser_CurrentPersonaEscuelasSeguidos } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaEscuelasSeguidos"
import { apiCallIG_EscuelasSeguidores_DejarDeSeguirEscuela } from "../../api/calls/IG/EscuelasSeguidores/apiCallIG_EscuelasSeguidores_DejarDeSeguirEscuela"

export namespace bllEscuelas_Profile {
	//ET = enumTypes
	enum ET {
		setInitialState = "Escuela_Profile_setInitialState",
		loadEscuela = "Escuela_Profile_loadEscuela",
		updateWithSharedEscuela = "Escuelas_Profile_updateWithSharedEscuela",
		seguirEscuela = "Escuela_Profile_seguirEscuela",
		dejarDeSeguirEscuela = "Escuela_Profile_dejarDeSeguirEscuela",
	}

	export interface IInterface {
		escuela: sharedEscuela.IShared
	}

	const getInitialState = (): IInterface => {
		return {
			escuela: sharedEscuela.defaultObject,
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getEscuela(apiCallParams: apiCallInterface, params: apiCallIG_Escuelas_GetEscuela.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Escuelas_GetEscuela.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "escuela">
				const updateObject: actionSubType = {
					escuela: apiCallRes.sharedEscuela,
				}

				utilRedux.TA(D, ET.loadEscuela, updateObject)
			}
		}
	}

	export function updateEscuelaWithSharedEscuela(
		apiCallParams: apiCallInterface,
		sharedEscuela: sharedEscuela.IShared
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			type actionSubType = Pick<IInterface, "escuela">
			const updateObject: actionSubType = {
				escuela: sharedEscuela,
			}

			utilRedux.TA(D, ET.updateWithSharedEscuela, updateObject)
		}
	}

	export function seguirEscuela(
		apiCallParams: apiCallInterface,
		params: apiCallIG_EscuelasSeguidores_SeguirEscuela.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_EscuelasSeguidores_SeguirEscuela.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(bllGlobalsUser_CurrentPersonaEscuelasSeguidos.setPersonaEscuelasSeguidos(apiCallRes.sharedEscuelasSeguidos))

				type actionSubType = Pick<IInterface, "escuela">
				const updateObject: actionSubType = {
					escuela: apiCallRes.sharedEscuela,
				}

				utilRedux.TA(D, ET.seguirEscuela, updateObject)
			}
		}
	}

	export function dejarDeSeguirEscuela(
		apiCallParams: apiCallInterface,
		params: apiCallIG_EscuelasSeguidores_DejarDeSeguirEscuela.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_EscuelasSeguidores_DejarDeSeguirEscuela.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(bllGlobalsUser_CurrentPersonaEscuelasSeguidos.setPersonaEscuelasSeguidos(apiCallRes.sharedEscuelasSeguidos))

				type actionSubType = Pick<IInterface, "escuela">
				const updateObject: actionSubType = {
					escuela: apiCallRes.sharedEscuela,
				}

				utilRedux.TA(D, ET.dejarDeSeguirEscuela, updateObject)
			}
		}
	}
}
