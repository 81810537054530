import { globalInterfaces } from "../../globals/globalInterfaces"
import { utilLogger } from "../../utils/utilLogger"

export namespace sharedEstado {
	export interface IShared {
		id: number
		descripcion: string
	}

	export const sharedEnum = {
		creado: { id: 1, descripcion: "Creado" },
		aprobado: { id: 2, descripcion: "Aprobado" },
		bloqueado: { id: 3, descripcion: "Bloqueado" },
	}

	export const sharedList: IShared[] = [sharedEnum.creado, sharedEnum.aprobado, sharedEnum.bloqueado]

	export const getShared_Desc_FromId = (idEstado: number | null) => {
		if (!idEstado) return "No definido"

		const sharedEstado = sharedList.find((Estado) => Estado.id === idEstado)

		if (sharedEstado) return sharedEstado.descripcion

		utilLogger.error(`Error en getSharedEstadoDescFromId. Vino un idEstado erroneo.`, idEstado)
	}

	export const listForRadioGroup = (): globalInterfaces.IValueNumberLabelString[] => {
		return sharedList.map((Estado) => {
			return {
				value: Estado.id,
				label: Estado.descripcion,
			}
		})
	}
}
