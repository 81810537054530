import { apiCallIG_Personas_GetPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_GetPersona"
import { sharedPersona } from "../../api/shared/sharedPersona"
import { utilRedux } from "../../utils/utilRedux"
import { utilLogger } from "../../utils/utilLogger"
import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Personas_ActualizarPosteoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_ActualizarPosteoDePersona"
import { apiCallIG_Personas_EliminarPosteoDePersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_EliminarPosteoDePersona"
import { apiCallIG_Personas_AgregarPosteoParaPersona } from "../../api/calls/IG/Personas/apiCallIG_Personas_AgregarPosteoParaPersona"
import { apiCallIG_PersonasSeguidores_SeguirPersona } from "../../api/calls/IG/PersonasSeguidores/apiCallIG_PersonasSeguidores_SeguirPersona"
import { apiCallIG_PersonasSeguidores_DejarDeSeguirPersona } from "../../api/calls/IG/PersonasSeguidores/apiCallIG_PersonasSeguidores_DejarDeSeguirPersona"
import { bllGlobalsUser_CurrentPersonaPersonasSeguidas } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaPersonasSeguidas"
import { sharedPosteos } from "../../api/shared/sharedPosteos"
import { apiCallIG_Posteos_GetPosteosByIdPersonaCreadora } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_GetPosteosByIdPersonaCreadora"
import { apiCallIG_Posteos_GetPosteosByIdCircuitoCreador } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_GetPosteosByIdCircuitoCreador"
import { apiCallIG_Posteos_CreatePosteoConFotos } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_CreatePosteoConFotos"
import { apiCallIG_Posteos_CreatePosteo } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_CreatePosteo"
import { apiCallIG_Posteos_DeletePosteo } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_DeletePosteo"
import { apiCallIG_Posteos_UpdatePosteo } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_UpdatePosteo"
import { apiCallIG_Posteos_AddFotoToPosteo } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_AddFotoToPosteo"
import { apiCallIG_Posteos_DeleteFoto } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_DeleteFoto"
import { apiCallIG_Posteos_ComentarPosteo } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_ComentarPosteo"
import { apiCallIG_Posteos_DeleteComentario } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_DeleteComentario"
import { apiCallIG_Posteos_LikePosteo } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_LikePosteo"
import { apiCallIG_Posteos_DeleteLike } from "../../api/calls/IG/Posteos/apiCallIG_Posteos_DeleteLike"

export namespace bllPosteos_General {
	//ET = enumTypes
	enum ET {
		setInitialState = "Posteos_General_setInitialState",
		getPosteosByIdPersonaCreadora = "Posteos_General_getPosteosByIdPersonaCreadora",
		getPosteosByIdCircuitoCreador = "Posteos_General_getPosteosByIdCircuitoCreador",
		createPosteo = "Posteos_General_createPosteo",
		createPosteoConFotos = "Posteos_General_createPosteoConFotos",
		deletePosteo = "Posteos_General_deletePosteo",
		updatePosteo = "Posteos_General_updatePosteo",
		addFotoToPosteo = "Posteos_General_addFotoToPosteo",
		deleteFoto = "Posteos_General_deleteFoto",
		comentarPosteo = "Posteos_General_comentarPosteo",
		deleteComentario = "Posteos_General_deleteComentario",
		likePosteo = "Posteos_General_likePosteo",
		deleteLike = "Posteos_General_deleteLike",
	}

	export interface IInterface {
		posteos: sharedPosteos.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			posteos: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			case ET.createPosteo:
			case ET.createPosteoConFotos:
				return {
					...state,
					posteos: [action.posteo, ...state.posteos],
				}
			case ET.deletePosteo:
				return {
					...state,
					posteos: state.posteos.filter((posteo) => posteo.id !== action.idPosteo),
				}
			case ET.updatePosteo:
			case ET.addFotoToPosteo:
			case ET.deleteFoto:
			case ET.comentarPosteo:
			case ET.deleteComentario:
			case ET.likePosteo:
			case ET.deleteLike:
				return {
					...state,
					posteos: state.posteos.map((posteo) => {
						if (posteo.id === action.posteo.id) {
							return action.posteo
						}
						return posteo
					}),
				}

			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function getPosteosByIdPersonaCreadora(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Posteos_GetPosteosByIdPersonaCreadora.params
	) {
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Posteos_GetPosteosByIdPersonaCreadora.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "posteos">
				const updateObject: actionSubType = {
					posteos: apiCallRes.sharedPosteos,
				}

				utilRedux.TA(D, ET.getPosteosByIdPersonaCreadora, updateObject)
			}
		}
	}

	export function getPosteosByIdCircuitoCreador(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Posteos_GetPosteosByIdCircuitoCreador.params
	) {
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Posteos_GetPosteosByIdCircuitoCreador.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "posteos">
				const updateObject: actionSubType = {
					posteos: apiCallRes.sharedPosteos,
				}

				utilRedux.TA(D, ET.getPosteosByIdCircuitoCreador, updateObject)
			}
		}
	}

	export function createPosteo(apiCallParams: apiCallInterface, params: apiCallIG_Posteos_CreatePosteo.params) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_CreatePosteo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.createPosteo, { posteo: apiCallRes.sharedPosteo })
			}
		}
	}

	export function createPosteoConFotos(
		apiCallParams: apiCallInterface,
		params: apiCallIG_Posteos_CreatePosteoConFotos.params
	) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_CreatePosteoConFotos.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.createPosteoConFotos, { posteo: apiCallRes.sharedPosteo })
			}
		}
	}

	export function deletePosteo(apiCallParams: apiCallInterface, params: apiCallIG_Posteos_DeletePosteo.params) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_DeletePosteo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.deletePosteo, { idPosteo: params.idPosteo })
			}
		}
	}

	export function updatePosteo(apiCallParams: apiCallInterface, params: apiCallIG_Posteos_UpdatePosteo.params) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_UpdatePosteo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.updatePosteo, { posteo: apiCallRes.sharedPosteo })
			}
		}
	}

	export function addFotoToPosteo(apiCallParams: apiCallInterface, params: apiCallIG_Posteos_AddFotoToPosteo.params) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_AddFotoToPosteo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.addFotoToPosteo, { posteo: apiCallRes.sharedPosteo })
			}
		}
	}

	export function deleteFoto(apiCallParams: apiCallInterface, params: apiCallIG_Posteos_DeleteFoto.params) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_DeleteFoto.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.deleteFoto, { posteo: apiCallRes.sharedPosteo })
			}
		}
	}

	export function comentarPosteo(apiCallParams: apiCallInterface, params: apiCallIG_Posteos_ComentarPosteo.params) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_ComentarPosteo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.comentarPosteo, { posteo: apiCallRes.sharedPosteo })
			}
		}
	}

	export function deleteComentario(apiCallParams: apiCallInterface, params: apiCallIG_Posteos_DeleteComentario.params) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_DeleteComentario.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.deleteComentario, { posteo: apiCallRes.sharedPosteo })
			}
		}
	}

	export function likePosteo(apiCallParams: apiCallInterface, params: apiCallIG_Posteos_LikePosteo.params) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_LikePosteo.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.likePosteo, { posteo: apiCallRes.sharedPosteo })
			}
		}
	}

	export function deleteLike(apiCallParams: apiCallInterface, params: apiCallIG_Posteos_DeleteLike.params) {
		return async (D: any) => {
			const apiCallRes = await apiCallIG_Posteos_DeleteLike.apiCall(apiCallParams, params)

			if (apiCallRes) {
				utilRedux.TA(D, ET.deleteLike, { posteo: apiCallRes.sharedPosteo })
			}
		}
	}
}
