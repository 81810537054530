/* eslint-disable react/jsx-pascal-case*/
import { LoadingButton } from "@mui/lab"
import { Alert, Box, Card, CardHeader, Grid, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { apiCallIG_AdminNotificaciones_SendNotificationToAllAppCircuitosUsers } from "../../../api/calls/IG/AdminNotificaciones/apiCallIG_AdminNotificaciones_SendNotificationToAllAppCircuitosUsers"
import { bllAdminNotificaciones_General } from "../../../bll/AdminNotificaciones/bllAdminNotificaciones_General"
import { bllGlobalsServer_ModelErrorsList } from "../../../bll/GlobalsServer/bllGlobalsServer_ModelErrorsList"
import { bllDispatchs_GlobalsServer } from "../../../bll/bllDispatchs_GlobalsServer"
import useAdminNotificaciones from "../../../hooksData/useAdminNotificaciones"
import useGeneral from "../../../hooksData/useGeneral"
import SharedFormTextFieldString from "../../Shared/Form/SharedFormTextFieldString"

export default function AdminNotificaciones_General_Form() {
	const ug = useGeneral("AdminNotificaciones_General_Form")

	const { notificacionEnviada } = useAdminNotificaciones()

	// const [asunto, set_asunto] = useState<string | null>(null)
	const [mensaje, set_mensaje] = useState<string | null>(null)

	useEffect(() => {
		// set_asunto("")
		set_mensaje("")
	}, [])

	useEffect(() => {
		// set_asunto("")
		if (notificacionEnviada) {
			set_mensaje("")
		}
	}, [notificacionEnviada])

	const validations = async () => {
		const serverModelErrors: bllGlobalsServer_ModelErrorsList.IServerModelErrors = {}

		// if (circuito.cantidadSeguidores === 0) {
		// 	 await bllDispatchs_GlobalsServer.setErrorMessage(ug.key, "No hay seguidores para enviar la notificación")
		// 	 return true
		// }

		return await bllDispatchs_GlobalsServer.setModelErrors(ug.key, serverModelErrors)
	}

	const onSubmit = async (e: any) => {
		e.preventDefault()

		if (await validations()) return

		await ug.D(
			bllAdminNotificaciones_General.enviarNotificacionASeguidores(ug.apiCallParams, {
				// asunto,
				mensaje,
			})
		)
	}

	return (
		<Grid item xs={12} md={8}>
			<Card sx={{ p: 3 }}>
				<Box
					sx={{
						display: "grid",
						rowGap: 3,
						columnGap: 2,
					}}
				>
					<form onSubmit={(e) => onSubmit(e)}>
						<Stack spacing={3}>
							<CardHeader title={`Enviar notificación a todos los usuarios`} sx={{ mb: 3 }} />

							{ug.serverState.errorMessage !== "" && <Alert severity="error">{ug.serverState.errorMessage}</Alert>}

							<Stack spacing={2}>
								{/* <SharedFormTextFieldString
									callerKey={ug.key}
									fieldName={apiCallIG_Circuitos_EnviarNotificacionASeguidores.paramsName.asunto}
									label={apiCallIG_Circuitos_EnviarNotificacionASeguidores.paramsDesc.asunto}
									value={asunto}
									handleOnChangeProp={set_asunto}
								/> */}
								<SharedFormTextFieldString
									callerKey={ug.key}
									fieldName={apiCallIG_AdminNotificaciones_SendNotificationToAllAppCircuitosUsers.paramsName.mensaje}
									label={apiCallIG_AdminNotificaciones_SendNotificationToAllAppCircuitosUsers.paramsDesc.mensaje}
									value={mensaje}
									handleOnChangeProp={set_mensaje}
								/>
							</Stack>
						</Stack>

						<Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
							<LoadingButton type="submit" variant="contained" loading={ug.serverState.isLoading}>
								Enviar
							</LoadingButton>
						</Stack>

						{notificacionEnviada && (
							<Alert severity="warning" sx={{ mt: 2 }}>
								Notificación enviada
							</Alert>
						)}
					</form>
				</Box>
			</Card>
		</Grid>
	)
}
