/* eslint-disable react/jsx-pascal-case*/
import { Avatar } from "@mui/material"
import useGeneral from "../../hooksData/useGeneral"
import SharedBasicTypo from "../Shared/Basic/SharedBasicTypo"
import useGlobalsUser from "../../hooksData/useGlobalsUser"

export default function Chat_Rooms_Account() {
	const ug = useGeneral("Chat_Rooms_Account")
	const { currentPersona } = useGlobalsUser()

	return (
		<>
			<Avatar alt={currentPersona.nombre} src={currentPersona.avatarURL || undefined} sx={{ width: 48, height: 48 }} />
			<SharedBasicTypo variant="body2" sx={{ ml: 2, fontWeight: 600 }} color={ug.theme.palette.grey[600]}>
				{currentPersona.nombre}
			</SharedBasicTypo>
		</>
	)
}
