import { apiCallInterface } from "../../api/apiCallInterface"
import { apiCallIG_Circuitos_GetCircuito } from "../../api/calls/IG/Circuitos/apiCallIG_Circuitos_GetCircuito"
import { apiCallIG_Rodadas_GetRodada } from "../../api/calls/IG/Rodadas/apiCallIG_Rodadas_GetRodada"
import { apiCallIG_RodadasPersonas_ActualizarDisclaimer } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_ActualizarDisclaimer"
import { apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada"
import { apiCallIG_RodadasPersonas_RegistrarPersona } from "../../api/calls/IG/RodadasPersonas/apiCallIG_RodadasPersonas_RegistrarPersona"
import { apiCallIG_RodadasSeguidores_DejarDeSeguirRodada } from "../../api/calls/IG/RodadasSeguidores/apiCallIG_RodadasSeguidores_DejarDeSeguirRodada"
import { apiCallIG_RodadasSeguidores_SeguirRodada } from "../../api/calls/IG/RodadasSeguidores/apiCallIG_RodadasSeguidores_SeguirRodada"
import { sharedCircuito } from "../../api/shared/sharedCircuito"
import { sharedRodada } from "../../api/shared/sharedRodada"
import { sharedRodadaPersona } from "../../api/shared/sharedRodadaPersona"
import { utilRedux } from "../../utils/utilRedux"
import { bllGlobalsUser_CurrentPersonaRodadasSeguidas } from "../GlobalsUser/bllGlobalsUser_CurrentPersonaRodadasSeguidas"

export namespace bllRodadas_Profile {
	//ET = enumTypes
	enum ET {
		setInitialState = "Rodadas_Profile_setInitialState",
		loadRodada = "Rodadas_Profile_loadRodada",
		loadCircuito = "Rodadas_Profile_loadCircuito",
		setRodada = "Rodadas_Profile_setRodada",
		seguirRodada = "Rodadas_Profile_seguirRodada",
		dejarDeSeguirRodada = "Rodadas_Profile_dejarDeSeguirRodada",
		loadRodadasPersonas = "Rodadas_Profile_loadRodadasPersonas",
		registerPersonInRodada = "Rodadas_Profile_registerPersonInRodada",
		actualizarDisclaimerDePersona = "Rodadas_Profile_actualizarDisclaimerDePersona",
	}

	export interface IInterface {
		rodada: sharedRodada.IShared
		isLoaded: boolean
		circuito: sharedCircuito.IShared
		rodadasPersonas: sharedRodadaPersona.IShared[]
	}

	const getInitialState = (): IInterface => {
		return {
			rodada: sharedRodada.objectDefault,
			isLoaded: false,
			circuito: sharedCircuito.defaultObject,
			rodadasPersonas: [],
		}
	}

	// export default RF.getReducerFunction(initialState, ET)
	export const reducerFunction = (state = getInitialState(), action: any) => {
		switch (action.type) {
			// case ET.Persona_Profile_Login:

			//Ejemplo usando immer
			// 	return produce(state, (draft) => {
			// 		draft.dialogWorkInProgressOpen = action.dialogWorkInProgressOpen
			// 	})

			//Ejemplo usando spread
			// return {
			// 	...state,
			// 	dialogWorkInProgressOpen: action.dialogWorkInProgressOpen,
			// }

			//Solo pongo los action.type que modifican de manera especial el state
			// case ET.ItemDeleted:
			// 	return {
			// 		...state,
			// 		data: state.data.filter((item) => item.id !== action.id),
			// 		idDeleted: action.id,
			// 	}
			default:
				break
		}

		//Si llegó hasta acá, si el action.type está en el enum, actualizo el state
		if (utilRedux.isActionInEnum(action, ET)) {
			state = utilRedux.actionToState(action, state)
		}

		return state
	}

	export function setInitialState() {
		//D = dispatch
		return async (D: any) => {
			const initialState: IInterface = getInitialState()

			utilRedux.TA(D, ET.setInitialState, initialState)
		}
	}

	export function loadRodada(apiCallParams: apiCallInterface, params: apiCallIG_Rodadas_GetRodada.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Rodadas_GetRodada.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(loadCircuito(apiCallParams, { idCircuito: apiCallRes.sharedRodada.idCircuito }))
				D(loadRodadasPersonas(apiCallParams, { idRodada: params.idRodada }))

				type actionSubType = Pick<IInterface, "rodada" | "isLoaded">
				const updateObject: actionSubType = {
					rodada: apiCallRes.sharedRodada,
					isLoaded: true,
				}

				utilRedux.TA(D, ET.loadRodada, updateObject)
			}
		}
	}

	export function setRodada(sharedRodada: sharedRodada.IShared) {
		//D = dispatch
		return async (D: any) => {
			type actionSubType = Pick<IInterface, "rodada">
			const updateObject: actionSubType = {
				rodada: sharedRodada,
			}

			utilRedux.TA(D, ET.setRodada, updateObject)
		}
	}

	export function loadCircuito(apiCallParams: apiCallInterface, params: apiCallIG_Circuitos_GetCircuito.params) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_Circuitos_GetCircuito.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "circuito">
				const updateObject: actionSubType = {
					circuito: apiCallRes.sharedCircuito,
				}

				utilRedux.TA(D, ET.loadRodada, updateObject)
			}
		}
	}

	export function loadRodadasPersonas(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada.params
	) {
		//D = dispatch
		return async (D: any) => {
			await D(setInitialState())

			const apiCallRes = await apiCallIG_RodadasPersonas_GetRodadasPersonasByIdRodada.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "rodadasPersonas">
				const updateObject: actionSubType = {
					rodadasPersonas: apiCallRes.sharedRodadasPersonas,
				}

				utilRedux.TA(D, ET.loadRodadasPersonas, updateObject)
			}
		}
	}

	export function registerPersonInRodada(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_RegistrarPersona.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasPersonas_RegistrarPersona.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "rodadasPersonas">
				const updateObject: actionSubType = {
					rodadasPersonas: apiCallRes.sharedRodadasPersonas,
				}

				utilRedux.TA(D, ET.registerPersonInRodada, updateObject)
			}
		}
	}

	export function seguirRodada(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasSeguidores_SeguirRodada.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasSeguidores_SeguirRodada.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(bllGlobalsUser_CurrentPersonaRodadasSeguidas.setPersonaRodadasSeguidas(apiCallRes.sharedRodadasSeguidas))
				D(setRodada(apiCallRes.sharedRodada))

				utilRedux.TA(D, ET.seguirRodada, {})
			}
		}
	}

	export function dejarDeSeguirRodada(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasSeguidores_DejarDeSeguirRodada.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasSeguidores_DejarDeSeguirRodada.apiCall(apiCallParams, params)

			if (apiCallRes) {
				D(bllGlobalsUser_CurrentPersonaRodadasSeguidas.setPersonaRodadasSeguidas(apiCallRes.sharedRodadasSeguidas))
				D(setRodada(apiCallRes.sharedRodada))

				utilRedux.TA(D, ET.dejarDeSeguirRodada, {})
			}
		}
	}

	export function actualizarDisclaimerDePersona(
		apiCallParams: apiCallInterface,
		params: apiCallIG_RodadasPersonas_ActualizarDisclaimer.params
	) {
		//D = dispatch
		return async (D: any) => {
			const apiCallRes = await apiCallIG_RodadasPersonas_ActualizarDisclaimer.apiCall(apiCallParams, params)

			if (apiCallRes) {
				type actionSubType = Pick<IInterface, "rodadasPersonas">
				const updateObject: actionSubType = {
					rodadasPersonas: apiCallRes.sharedRodadasPersonas,
				}

				utilRedux.TA(D, ET.actualizarDisclaimerDePersona, updateObject)
			}
		}
	}
}
