/* eslint-disable react/jsx-pascal-case*/

import { Button, Stack } from "@mui/material"
import { globalPathsApp } from "../../globals/globalPathsApp"
import { SharedLinkInsideAppWrapper } from "../Shared/SharedLinkInsideAppWrapper"

export const Layout_LeftMenu_LoginRegistrarse = () => {
	return (
		<Stack direction="row" spacing={1} sx={{ width: "100%" }}>
			<SharedLinkInsideAppWrapper urlDest={globalPathsApp.pageInsideUrl.UsersLogin()} sx={{ width: "50%" }}>
				<Button size="medium" variant="contained" fullWidth>
					Login
				</Button>
			</SharedLinkInsideAppWrapper>

			<SharedLinkInsideAppWrapper
				urlDest={globalPathsApp.pageInsideUrl.UsersRegistration()}
				sx={{ ml: 1, width: "50%" }}
			>
				<Button size="medium" variant="contained" fullWidth>
					Registrarse
				</Button>
			</SharedLinkInsideAppWrapper>
		</Stack>
	)
}
