import { m, MotionProps } from "framer-motion"
import { Box, BoxProps } from "@mui/material"
import { varContainer } from "../../globals/globalAnimate/variants/container"

type TCombinedProps = BoxProps & MotionProps

export interface IProps extends TCombinedProps {
	animate?: boolean
	action?: boolean
}

export default function AnimateMotionContainer({ animate, action = false, children, ...other }: IProps) {
	if (action) {
		return (
			<Box
				component={m.div}
				initial={false}
				animate={animate ? "animate" : "exit"}
				variants={varContainer()}
				{...other}
			>
				{children}
			</Box>
		)
	}

	return (
		<Box component={m.div} initial="initial" animate="animate" exit="exit" variants={varContainer()} {...other}>
			{children}
		</Box>
	)
}
