import { combineReducers } from "redux"
import { bllGlobalsUI_DialogErrorUnexpected } from "./bllGlobalsUI_DialogErrorUnexpected"
import { bllGlobalsUI_DialogErrorForbidden } from "./bllGlobalsUI_DialogErrorForbidden"
import { bllGlobalsUI_DialogWorkInProgress } from "./bllGlobalsUI_DialogWorkInProgress"
import { bllGlobalsUI_FullScreenLoading } from "./bllGlobalsUI_FullScreenLoading"
import { bllGlobalsUI_LeftMenu } from "./bllGlobalsUI_LeftMenu"
import { bllGlobalsUI_Screen } from "./bllGlobalsUI_Screen"

export default combineReducers({
	DialogErrorUnexpected: bllGlobalsUI_DialogErrorUnexpected.reducerFunction,
	DialogErrorForbidden: bllGlobalsUI_DialogErrorForbidden.reducerFunction,
	DialogWorkInProgress: bllGlobalsUI_DialogWorkInProgress.reducerFunction,
	FullScreenLoading: bllGlobalsUI_FullScreenLoading.reducerFunction,
	LeftMenu: bllGlobalsUI_LeftMenu.reducerFunction,
	Screen: bllGlobalsUI_Screen.reducerFunction,
})

export interface IGlobalsUI {
	DialogErrorUnexpected: bllGlobalsUI_DialogErrorUnexpected.IInterface
	DialogErrorForbidden: bllGlobalsUI_DialogErrorForbidden.IInterface
	DialogWorkInProgress: bllGlobalsUI_DialogWorkInProgress.IInterface
	FullScreenLoading: bllGlobalsUI_FullScreenLoading.IInterface
	LeftMenu: bllGlobalsUI_LeftMenu.IInterface
	Screen: bllGlobalsUI_Screen.IInterface
}
